import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { SettingService } from '../../../../../services/setting.service';
import { UserService } from '../../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { SlabCostDetailService } from '@app-admin/services/slab-cost-detail.service';

@Component({
  selector: 'layout-approval-waste',
  templateUrl: './layout-approval-waste.html',
})
export class LayoutApprovalWasteComponent implements OnInit, OnDestroy {
  @Input() costSchemaId: number;
  @Input() calcHeightTable = new EventEmitter<any>();
  @ViewChild('modalViewComments') modalViewComments: any;
  notes: any[];
  loading = false;
  data: any[];
  dataForm: FormGroup;
  currentTab = 'LayoutApprovalWaste';
  currentId: number;

  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    private settingService: SettingService,
    private userService: UserService,
    private slabCostDetailService: SlabCostDetailService,
  ) {}

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    this.save();
  }

  viewComment(item: any) {
    this.currentId = item.value.id;
    this.settingService
      .getMtxNoteLayoutApprovalWasteById(this.currentId)
      .then((res) => {
        if (res.status) {
          this.notes = res.data;
          this.modalViewComments.open();

          //Update last comment when delete comment
          this.slabCostDetailService.getMessages().subscribe((data) => {
            if (!data) {
              return;
            }

            switch (data.messageType) {
              case SlabCostDetailService.MessageTypes.RELOAD_PARENT_COMMENT +
                '_' +
                this.currentTab +
                '_' +
                this.currentId:
                const payload = data.payload;
                if (payload) {
                  item.controls.note.setValue(payload.note);
                  item.controls.updatedOn.setValue(payload.updateOn);
                }
                break;
            }
          });
        } else this.toast.error('view comment failed.');
      });
  }

  loadData() {
    this.loading = true;
    this.settingService
      .getLayoutApprovalWaste(this.costSchemaId)
      .then((response) => {
        if (response.status) {
          this.data = response.data;
          const dataView = [];
          this.data.forEach((e) => {
            dataView.push(this.createItemView(e));
          });
          this.dataForm = this.fb.group({
            dataUpdate: this.fb.array(dataView),
          });
          if (this.userService.isTenantOnly()) this.dataForm.disable();
          this.loading = false;
          this.calcHeightTable.emit();
        } else this.toast.error('Load data failed');
      });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      id: e.id,
      layoutApprovalName: e.layoutApprovalName,
      // wastePercentAdjustment: new PercentPipe('en-US').transform(
      //   e.wastePercentAdjustment,
      //   '.2-2',
      // ),
      wastePercentAdjustment: e.wastePercentAdjustment * 100,
      // wasteAdvancedPercentAdjustment: new PercentPipe('en-US').transform(
      //   e.wasteAdvancedPercentAdjustment,
      //   '.2-2',
      // ),
      wasteAdvancedPercentAdjustment: e.wasteAdvancedPercentAdjustment * 100,
      note: e.note,
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
    });
  }

  createItemUpdate(e) {
    let item = this.data.find((i) => i.id == e.id);
    item.wastePercentAdjustment =
      this.settingService.replaceMask(e.wastePercentAdjustment) / 100;
    item.wasteAdvancedPercentAdjustment =
      this.settingService.replaceMask(e.wasteAdvancedPercentAdjustment) / 100;
    item.note = e.note;
    return {
      id: e.id,
      wastePercentAdjustment:
        this.settingService.replaceMask(e.wastePercentAdjustment) / 100,
      wasteAdvancedPercentAdjustment:
        this.settingService.replaceMask(e.wasteAdvancedPercentAdjustment) / 100,
      note: e.note,
    };
  }

  save() {
    let dataUpdate = [];
    let cellUpdate: any;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach((e) => {
      if (e.dirty) {
        cellUpdate = e;
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (dataUpdate.length > 0) {
      this.settingService
        .updateLayoutApprovalWaste(dataUpdate)
        .then((response) => {
          if (response.status) {
            this.toast.success('Layout Approval waste has been updated');
            cellUpdate?.get('updatedOn').setValue(response.data);
            cellUpdate?.get('updatedBy').setValue(this.userService.getCurrentFullName());
          } else this.toast.error('Update Layout Approval waste failed');
        });
    }
  }
}
