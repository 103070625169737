<div class="content-wrapper">
  <section class="content-header">
    <label style="font-size: medium;"><span><a class="sub-menu-hight">Tile Configuration</a></span> | <span><a class="sub-menu-txt" >Tenant Specific</a></span></label>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li class="active">Tile Configuration</li>
    </ol>
  </section>
  <section class="content">
    <div class="row">
      <div class="col-md-3 col-sm-4 col-xs-12">
        <i class="fa fa-refresh fa-spin fa-lg" *ngIf="!tenants"></i>
        <div class="form-group" *ngIf="tenants">
          <label>Select Tenant</label>
          <select [(ngModel)]="tenantId" (change)="changeTenant()" class="form-control">
            <option value="0">-- Please select a tenant --</option>
            <ng-container *ngFor="let t of tileTenants">
              <option [ngValue]="t.tenantId" *ngIf="t.tenantId > 0">{{t.tenantName}}</option>
            </ng-container>
          </select>
        </div>
      </div>
    </div>

    <div class="nav-tabs-custom" *ngIf="!loadingTenant && tenantId > 0">
      <ul class="nav nav-tabs">
        <li *ngFor="let tab of tabs; let i = index" [class.active]="curTab.id == tab.id" (click)="changeTab(tab)">
          <a href="#{{tab.id}}" data-toggle="tab">{{tab.text}}</a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane box" id="tenantCostSchemas" *ngIf="curTab.id == 'tenantCostSchemas'">
          <tab-tile-tenant-cost-schemas [tenantId]="tenantId" (calcHeightTable)="calcHeightTable('tenantCostSchemas')"></tab-tile-tenant-cost-schemas>
        </div>
        <div class="tab-pane box" id="scheduling-process" *ngIf="curTab.id == 'scheduling-process'">
          <tab-tile-scheduling-process [tenantId]="tenantId" (reloadStatusBatchVersions)="loadStatusBatchVersions()" (calcHeightTable)="calcHeightTable('scheduling-process')"></tab-tile-scheduling-process>
        </div>
        <div class="tab-pane box" id="baseProcess" *ngIf="curTab.id == 'baseProcess'">
          <tab-tile-base-processes [tenantId]="tenantId" (calcHeightTable)="calcHeightTable('baseProcess')"></tab-tile-base-processes>
        </div>
        <div class="tab-pane box" id="application" *ngIf="curTab.id == 'application'">
          <tab-tile-application *ngIf="unitsOfMeasure" [tenantId]="tenantId" [unitsOfMeasure]="unitsOfMeasure" (calcHeightTable)="calcHeightTable('application')"></tab-tile-application>
        </div>

        <div class="tab-pane box" id="serviceClass" *ngIf="curTab.id == 'serviceClass'">
          <tile-service-class [tenantId]="tenantId" ></tile-service-class>
        </div>

        <div class="tab-pane box" id="tileTypes" *ngIf="curTab.id == 'tileTypes'">
          <tab-tile-types [tenantId]="tenantId" (calcHeightTable)="calcHeightTable('tileTypes')"></tab-tile-types>
        </div>
        <div class="tab-pane box" id="thickness" *ngIf="curTab.id == 'thickness'">
          <tab-tile-thickness [tenantId]="tenantId" (calcHeightTable)="calcHeightTable('thickness')"></tab-tile-thickness>
        </div>
        <div class="tab-pane box" id="tileSizes" *ngIf="curTab.id == 'tileSizes'">
          <tab-tile-sizes [tenantId]="tenantId" (calcHeightTable)="calcHeightTable('tileSizes')"></tab-tile-sizes>
        </div>
        <div class="tab-pane box" id="tileSets" *ngIf="curTab.id == 'tileSets'">
          <tab-tile-sets [tenantId]="tenantId" (calcHeightTable)="calcHeightTable('tileSets')"></tab-tile-sets>
        </div>
        <div class="tab-pane box" id="specialConditions" *ngIf="curTab.id == 'specialConditions'">
          <tab-tile-special-conditions [tenantId]="tenantId" (calcHeightTable)="calcHeightTable('specialConditions')"></tab-tile-special-conditions>
        </div>
        <div class="tab-pane box" id="trimTypes" *ngIf="curTab.id == 'trimTypes'">
          <tab-trim-types [tenantId]="tenantId" (calcHeightTable)="calcHeightTable('trimTypes')"></tab-trim-types>
        </div>
        <div class="tab-pane box" id="trimEdges" *ngIf="curTab.id == 'trimEdges'">
          <tab-trim-edges [tenantId]="tenantId" (calcHeightTable)="calcHeightTable('trimEdges')"></tab-trim-edges>
        </div>
        <div class="tab-pane box" id="generalRates" *ngIf="curTab.id == 'generalRates'">
          <tab-tile-general-rates [tenantId]="tenantId" (calcHeightTable)="calcHeightTable('generalRates')"></tab-tile-general-rates>
        </div>
        <div class="tab-pane box" id="changeLog" *ngIf="curTab.id == 'changeLog'">
          <!-- <tab-tile-change-log [tenantId]="tenantId" (calcHeightTable)="calcHeightTable('changeLog')"></tab-tile-change-log> -->
          <tab-change-log-slab-configuration [tenantId]="tenantId" [businessUnitId]="2" (calcHeightTable)="calcHeightTable('changeLog')"></tab-change-log-slab-configuration>
        </div>
      </div>
    </div>
  </section>
</div>
