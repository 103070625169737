import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { PercentPipe, DecimalPipe } from '@angular/common';
import { SettingService } from '../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'tab-global-material-types',
  templateUrl: './global-material-types.html',
})

export class MaterialTypesGlobalComponent implements OnInit {
  @Input() isTenantOnly: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  materialType: any;
  baseWaste: any;
  typeAbbreviation: any;
  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  materialTypeId: any;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    this.baseWaste = new PercentPipe('en-US').transform(0, '.2-2');
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.settingService.getMaterialTypes(0).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      materialTypeId: e.materialTypeId,
      materialGroup: [e.materialGroup, Validators.required],
      typeAbbreviation: [e.typeAbbreviation, Validators.required],
      active: [{value: e.active, disabled: this.isTenantOnly}],
      isRemove: e.isRemove,
      // baseWastePercent: new PercentPipe('en-US').transform(e.baseWastePercent, '.2-2'),
      baseWastePercent: e.baseWastePercent * 100,
      updatedOn: e.updatedOn
    });
  }

  validData() {
    if (this.materialType == null || this.materialType == '' || this.materialType.length < 2) {
      this.toast.error('Material type name needs to be greater than 2 characters');
      return false;
    }
    return true;
  }

  addMaterialTypes() {
    if (this.validData()) {
      this.loading = true;
      this.settingService.addMaterialTypes({
        TenantId: 0,
        MaterialGroup: this.materialType,
        TypeAbbreviation: this.typeAbbreviation,
        baseWastePercent: this.settingService.replaceMask(this.baseWaste) / 100,
      }).then(response => {
        if (response.status) {
          this.materialType = '';
          this.typeAbbreviation = '';
          this.baseWaste = new PercentPipe('en-US').transform(0, '.2-2');
          this.toast.success('New Material Type has been added');
          this.reloadStatusBatchVersions.emit();
          this.loadData();
        }
        else {
          this.loading = false;
          this.toast.error('The Material is added fail');
        }
      });
    }
  }

  deleteMaterialTypes(materialTypeId) {
    this.modalConfirm.open();
    this.materialTypeId = materialTypeId;
  }

  submitDelete() {
    this.loading = true;
    this.modalConfirm.close();
    this.settingService.deleteMaterialTypes(this.materialTypeId).then(response => {
      if (response.status) {
        this.toast.success('This material type has been removed');
        this.loading = true;
        this.loadData();
        // this.settingService.setBidsIsRefesh(this.tenantId);
      }
      else
        this.toast.error(response.message);
      this.loading = false;
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.materialTypeId == e.materialTypeId);
    item.baseWastePercent = this.settingService.replaceMask(e.baseWastePercent) / 100;
    item.materialGroup = e.materialGroup;
    item.typeAbbreviation = e.typeAbbreviation;
    item.active = e.active;
    return {
      materialTypeId: e.materialTypeId,
      baseWastePercent: this.settingService.replaceMask(e.baseWastePercent) / 100,
      materialGroup: e.materialGroup,
      typeAbbreviation: e.typeAbbreviation,
      active: e.active,
      isGlobal: true
    };
  }

  save() {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
      if (e.value.materialGroup == null || e.value.materialGroup.trim() == "" || e.value.materialGroup.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Material type name needs to be greater than 2 characters');
        }
        isValid = false;
      }
    });
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateMaterialTypes(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Material type has been updated');
          if (dataUpdate.length > 0) {
                        // this.settingService.setBidsIsRefesh(this.tenantId);
                        this.reloadStatusBatchVersions.emit();
                    }
        }
        else this.toast.error('Update material type failed');
      });
    }
  }
}
