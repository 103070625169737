<input
  #txt
  type="text"
  [class]="classNames"
  [mask]="mask"
  [suffix]="suffix"
  [allowNegativeNumbers]="allowNegative"
  [(ngModel)]="model"
  [placeholder]="placeholder"
  [required]="required"
  (input)="onInputAcceptEdit()"
>
