import { Component, OnInit } from '@angular/core';
import { PermissionService } from '../../services/permission.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { UserService } from '@app-admin/services/user.service';
declare var $: any;

@Component({
  templateUrl: './permissions.html',
  styleUrls: ['./permissions.scss']
})

export class PermissionsComponent implements OnInit {

  dataForm: FormGroup;

  loading = true;
  moduleSelectedId = 0;
  categoryId = 0;
  isModeChangeOrder = false;
  indexOrderChanged = -1;

  dataSort = {
    orderBy: '',
    type: 'asc'
  };

  hasRoleQXMSecurityAdmin: boolean = false;
  isEditMode: boolean = false;

  tabs = [
    { id: 'slab', name: 'Slab' },
    { id: 'tile', name: 'Tile' },
    { id: 'global', name: 'Global' },
    { id: 'all', name: 'All Permissions' },
  ];
  tabSelected = 'slab';

  constructor(private fb: FormBuilder, private toastr: ToastrService, private userService: UserService,private permissionService: PermissionService) {
    this.hasRoleQXMSecurityAdmin = this.userService.hasRoleQXMSecurityAdmin();
  }

  ngOnInit() {
    $('body').layout('fix');

    this.load();
  }

  load() {
    this.loading = true;
    this.permissionService.getPermissionModules().then(res => {
      this.loading = false;
      if (res.status) {
        let dataUpdate = [];
        this.getDataFiltered(res.data).forEach(x => {
          dataUpdate.push(this.fb.group({
            permissionId: x.permissionId,
            permissionName: x.permissionName,
            description: x.description,
            isDelete: x.isDelete,
            sort: x.sort,
            estimator: x.estimator,
            production: x.production,
            scheduling: x.scheduling,
            shopPro: x.shopPro,
            fieldPro: x.fieldPro,
            director: x.director,
            admin: x.admin,
            general: x.general,
            slab: x.slab,
            tile: x.tile,
            drawing: x.drawing,
            administrative: x.administrative,
            item: x.item,
            security: x.security,
            reporting: x.reporting,
            serviceSlab: x.services.slab,
            serviceTile: x.services.tile,
            serviceGlobal: x.services.global,
            isAllService: x.isAllService,
            isAdmin: x.isAdmin,
            isGlobal: x.isGlobal
          }));
        });
        this.dataForm = this.fb.group({
          dataUpdate: this.fb.array(dataUpdate)
        });
      } else { this.toastr.error('Load permission failed'); }
    });
  }

  private getDataFiltered(data: any) {
    let filterData = [];
    if (this.moduleSelectedId == 1) { filterData = data.filter(x => x.estimator && (x.services.slab || x.services.tile)); }
    else if (this.moduleSelectedId == 2) { filterData = data.filter(x => x.production && x.services.slab); }
    else if (this.moduleSelectedId == 3) { filterData = data.filter(x => x.scheduling && (x.services.slab || x.services.tile)); }
    else if (this.moduleSelectedId == 6) { filterData = data.filter(x => x.shopPro && x.services.slab); }
    else if (this.moduleSelectedId == 7) { filterData = data.filter(x => x.fieldPro && x.services.slab); }
    else if (this.moduleSelectedId == 8) { filterData = data.filter(x => x.inventory && (x.services.slab || x.services.tile)); }
    else if (this.moduleSelectedId == 4) { filterData = data.filter(x => x.director && x.services.slab); }
    else if (this.moduleSelectedId == 5) { filterData = data.filter(x => x.admin && x.services.slab); }
    else filterData = data;

    if (this.categoryId == 1) { return filterData.filter(x => x.general); }
    else if (this.categoryId == 4) { return filterData.filter(x => x.drawing); }
    else if (this.categoryId == 5) { return filterData.filter(x => x.administrative); }
    else if (this.categoryId == 6) { return filterData.filter(x => x.security); }
    else if (this.categoryId == 7) { return filterData.filter(x => x.reporting); }
    else if (this.categoryId == 8) { return filterData.filter(x => x.item); }

    if (this.dataSort.orderBy == 'name' && this.dataSort.type == 'asc') return _.orderBy(filterData, ['permissionName'], ['desc']);
    else if (this.dataSort.orderBy == 'name' && this.dataSort.type == 'desc') return _.orderBy(filterData, ['permissionName'], ['asc']);

    return filterData;
  }

  changeModule() {
    if (this.moduleSelectedId == 2 || this.moduleSelectedId == 6 || this.moduleSelectedId == 7) this.tabSelected = 'slab';
    this.load();
  }

  save() {
    this.permissionService.updatePermissionModules(this.getDataUpdate()).then(res => {
      if (res.status) {
        this.toastr.success('Update permissions successfully');
        this.isEditMode = false;
        this.load();
      }
      else {
        this.toastr.error(res.message ?? 'Update permissions failed');
      }
    });
  }

  private getDataUpdate(forceSort = false) {
    let dataUpdate = [];
    let sort = 1;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(x => {
      dataUpdate.push({
        permissionId: x.value.permissionId,
        permissionName: x.value.permissionName,
        description: x.value.description,
        isDelete: x.value.isDelete,
        sort: forceSort ? sort : x.value.sort,
        general: x.value.general,
        slab: x.value.slab,
        tile: x.value.tile,
        drawing: x.value.drawing,
        administrative: x.value.administrative,
        item: x.value.item,
        security: x.value.security,
        reporting: x.value.reporting,
        isAllService: x.value.isAllService,
        isAdmin: x.value.isAdmin,
        isGlobal: x.value.isGlobal,
      });
      sort += 1;
    });
    return dataUpdate;
  }

  cancel() {
    this.isEditMode = false;
    this.load();
  }

  changeOrder() {
    this.isModeChangeOrder = true;
    this.tabSelected = 'all';
    this.moduleSelectedId = 0;
    this.categoryId = 0;
    this.indexOrderChanged = -1;
    this.dataSort = { orderBy: '', type: 'asc' };
    this.load();
  }

  saveChangeOrder() {
    this.permissionService.updatePermissionModules(this.getDataUpdate(true)).then(res => {
      if (res.status) {
        this.toastr.success('Update order of permissions successfully');
        this.isEditMode = true;
        this.isModeChangeOrder = false;
        this.tabSelected = 'slab';
        this.load();
      }
      else {
        this.toastr.error(res.message ?? 'Update permissions failed');
      }
    });
  }

  moveUp(index) {
    this.indexOrderChanged = index - 1;
    this.swap(index, index - 1);
  }

  moveDown(index) {
    this.indexOrderChanged = index + 1;
    this.swap(index, index + 1);
  }

  swap(index, newIndex) {
    let dataUpdate = this.dataForm.get('dataUpdate').value;
    let temp = dataUpdate[index];
    dataUpdate[index] = dataUpdate[newIndex];
    dataUpdate[newIndex] = temp;

    let newDataUpdate = [];
    dataUpdate.forEach(x => {
      newDataUpdate.push(this.fb.group(x));
    });

    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(newDataUpdate)
    });
  }

  changeCategory() {
    this.load();
  }

  cancelOrder() {
    this.isEditMode = true;
    this.isModeChangeOrder = false;
    this.tabSelected = 'slab';
    this.load();
  }

  sort(orderBy: string) {
    this.dataSort.orderBy = orderBy;
    this.dataSort.type = this.dataSort.type == 'asc' ? 'desc' : 'asc';
    this.load();
  }
}
