import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { SettingService } from "../../../../../services/setting.service";
import { ToastrService } from "ngx-toastr";
import { Config } from "@app-admin/config";
import { CostSchemaService } from "@app-admin/services/cost-schema.service";
import { BsModalComponent } from "ng2-bs3-modal";

@Component({
  selector: "tab-tile-tenant-cost-schemas",
  templateUrl: "./tenant-cost-schemas.html",
})
export class TileTenantCostSchemasComponent implements OnInit {
  @Input() tenantId: any;
  @Output("calcHeightTable") calcHeightTable = new EventEmitter<any>();

  @ViewChild('modalConfirm') modal: BsModalComponent;
  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  costSchemaId = 0;
  costSchemas = [];
  deletingCostSchema = 0;

  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    private costSchemaService: CostSchemaService,
    public settingService: SettingService
  ) {}

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.settingService.getCostSchemas(this.tenantId, Config.BusinessUnitId.Tile).then((response) => {
      if (response.status) {
        this.loading = false;
        this.data = response.data;
        this.filterData();
        this.calcHeightTable.emit();
      } else this.toast.error("Load data failed");
    });

    this.costSchemaService.getCostSchemaAssignForTenant(this.tenantId, 2).then(res => {
      if(res.status) {
        this.costSchemas = res.data;
        this.costSchemaId = 0;
      }
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach((e) => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate),
    });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      costSchemaId: e.costSchemaId,
      alias: e.alias,
      active: !!e.active,
      schemaName: [e.schemaName, Validators.required],
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
    });
  }

  createItemUpdate(e) {
    let item = this.data.find((i) => i.costSchemaId == e.costSchemaId);
    item.schemaName = e.schemaName;
    return {
      schemaId: e.costSchemaId,
      active: e.active,
      alias: e.alias,
      schemaName: e.schemaName,
      tenantId: this.tenantId,
    };
  }

  save() {
    let dataUpdate = [];
    let isValid = true;
    let duplicate = false;
    let frmCtrl: AbstractControl;
    (this.dataForm.get("dataUpdate") as FormArray).controls.forEach((e) => {
      if (e.dirty) {
        frmCtrl = e;
        dataUpdate.push(this.createItemUpdate(e.value));

        e.markAsPristine();
        e.markAsUntouched();
        duplicate = !!(e.get("schemaName") as FormControl).errors;
      }
      if (
        e.value.schemaName == null ||
        e.value.schemaName.trim() == "" ||
        e.value.schemaName.trim().length == 0
      ) {
        if (isValid == true) {
          this.toast.error("Cost Schema cannot be empty");
        }
        isValid = false;
      }
    });
    if (isValid && duplicate) {
      this.toast.error(
        "This cost schema already exists. System doesn't support duplicate cost shemas. Make appropriate changes and retry "
      );
      isValid = false;
    }

    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateCostSchemas(dataUpdate).then((response) => {
        if (response.status) {
          this.toast.success("Cost Schema has been updated");
          this.loadData();
        } else {
          this.toast.error(response.message);

          // Revert the data user changed
          let beforeData = this.data.find((i) => i.costSchemaId == frmCtrl.get('costSchemaId').value)
          frmCtrl.patchValue({
            alias: beforeData.alias,
            active: beforeData.active,
          });
        }
      });
    }
  }

  addCostSchema() {
    this.loading = true;
    this.costSchemaService.addTenantCostSchema(this.tenantId, this.costSchemaId).then(res => {
      this.loading = false;
      if(res.status) {
        this.toast.success("Cost Schema added success");
        this.loadData();
      }
      else {
        this.toast.error(res.message);
      }
    })
  }

  submitRemoveCostSchema() {
    this.modal.close;
    var modalElement = document.getElementsByClassName("modal-backdrop");
    if(modalElement.length > 0) {
      modalElement[0].remove();
    }
    this.loading = true;
    this.costSchemaService.removeTenantCostSchema(this.tenantId, this.deletingCostSchema).then(res => {
      this.loading = false;
      if(res.status) {
        this.toast.success("Cost Schema removed from Tenant");
        this.loadData();
      }
      else {
        this.toast.error(res.message);
      }
    });
  }

  removeCostSchema(costSchemaId) {
    var currentSchema = this.data.find(x => x.costSchemaId == costSchemaId)
    if(currentSchema && currentSchema.isAssigned) {
      this.deletingCostSchema = costSchemaId;
      this.modal.backdrop = false;
      this.modal.open();
    }
    else {
      this.loading = true;
      this.costSchemaService.removeTenantCostSchema(this.tenantId, costSchemaId).then(res => {
        this.loading = false;
        if(res.status) {
          this.toast.success("Cost Schema removed from Tenant");
          this.loadData();
        }
        else {
          this.toast.error(res.message);
        }
      });
    }
  }
}
