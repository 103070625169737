<div class="content-wrapper">
  <section class="content-header">
    <h1>Worker: {{ workerInfo?.fullName }}</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li><a routerLink="/workers"> Workers</a></li>
      <li class="active"> {{workerInfo?.fullName}}</li>
    </ol>
  </section>

  <section class="content" *ngIf="workerInfo">
    <form [formGroup]="formWorker">
      <div class="form-row">
        <div class="form-group col-sm-6 col-12">
          <div class="form-group"
            [class.has-error]="firstName.invalid && (firstName.dirty || firstName.touched)">
            <label>First Name <span class="text-danger">*</span></label>
            <input type="text" trim="blur" class="form-control" formControlName="firstName" required>
            <span *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
              class="help-block">{{getErrorFirstName()}}</span>
          </div>
        </div>
        <div class="form-group col-sm-6 col-12">
          <div class="form-group"
            [class.has-error]="lastName.invalid && (lastName.dirty || lastName.touched)">
            <label>Last Name <span class="text-danger">*</span></label>
            <input type="text" trim="blur" class="form-control" formControlName="lastName" required>
            <span *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
              class="help-block">{{getErrorLastName()}}</span>
          </div>
        </div>
        <div class="form-group col-sm-6 col-12">
          <div class="form-group"
            [class.has-error]="emailAddress.invalid && (emailAddress.dirty || emailAddress.touched)">
            <label>Email Address</label>
            <input type="text" trim="blur" class="form-control" formControlName="emailAddress">
            <span *ngIf="emailAddress.invalid && (emailAddress.dirty || emailAddress.touched)"
              class="help-block">{{getErrorEmail()}}</span>
          </div>
        </div>
        <div class="form-group col-sm-6 col-12">
          <div class="form-group" [class.has-error]="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)">
            <label>Phone Number</label>
            <input type="tel" mask="(000) 000-0000" class="form-control" formControlName="phoneNumber" />
            <span *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)"
              class="help-block">{{getErrorPhone()}}</span>
          </div>
        </div>
        <div class="form-group col-sm-6 col-12">
          <div class="form-group" *ngIf="workerTypes">
            <label class="control-label">Worker Type</label>
            <select formControlName="workerType" class="form-control">
              <option *ngFor="let type of workerTypes" [ngValue]="type.key">{{type.name}}</option>
            </select>
          </div>
        </div>
        <div class="form-group col-sm-6 col-12">
          <div class="form-group">
            <label>Job title</label>
            <input type="text" trim="blur" class="form-control" formControlName="jobTitle">
          </div>
        </div>
        <div class="form-group col-sm-6 col-12">
          <label>Original Start Date</label>
          <div class="input-group date">
            <div class="input-group-addon">
              <i class="fa fa-calendar"></i>
            </div>
            <input type="text" class="form-control pull-right" id="originalStartDate" formControlName="originalStartDate">
          </div>
        </div>
        <div class="form-group col-sm-6 col-12">
          <div class="form-group" *ngIf="users">
            <label class="control-label">Related User</label>
            <select formControlName="relatedUserId" class="form-control">
              <option *ngFor="let u of users" [ngValue]="u.userId" [disabled]="u.userId == '' || u.hasRelatedUser ? true : null">{{u.fullName}}</option>
            </select>
          </div>
        </div>
        <div class="form-group col-sm-6 col-12">
          <div class="form-group"
            [class.has-error]="userName.invalid && (userName.dirty || userName.touched)">
            <label>User Name <span class="text-danger">*</span></label>
            <input type="text" trim="blur" class="form-control" formControlName="userName" required>
            <span *ngIf="userName.invalid && (userName.dirty || firstName.touched)"
              class="help-block">{{getErrorUserName()}}</span>
          </div>
        </div>
        <div class="form-group col-sm-6 col-12">
          <div class="form-group" [class.has-error]="pin.invalid && (pin.dirty || pin.touched)">
            <label>PIN</label>
            <input type="tel" mask="00000" class="form-control" formControlName="pin" required/>
            <span *ngIf="pin.invalid && (pin.dirty || pin.touched)"
              class="help-block">{{getErrorPin()}}</span>
          </div>
        </div>
        <div class="form-group col-sm-12 col-12">
          <div class="form-group">
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="active"/> Active</label>
            </div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-12">
          <button type="button" class="btn btn-primary" [disabled]="formWorker.invalid || loading" (click)="save()">Save</button>
          <a type="button" class="btn btn-default" style="margin-left: 5px;" routerLink="/workers">Cancel</a>
        </div>
      </div>
    </form>
  </section>
</div>
