<div class="content-wrapper">
  <section class="content-header">
    <h1>Tenant Specific</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/dashboards/tenant-specific"><i class="fa fa-dashboard"></i> Dashboard</a></li>
      <li class="active">Tenant Specific</li>
    </ol>
  </section>

  <section class="content">
    <div class="row">
      <div class="col-md-3 col-sm-4 col-xs-12">
        <i class="fa fa-refresh fa-spin fa-lg" *ngIf="!tenants"></i>
        <div class="form-group" *ngIf="tenants">
          <label>Tenant</label>
          <select [(ngModel)]="tenantId" (change)="changeTenant()" class="form-control">
            <option value="0">-- Please select a tenant --</option>
            <ng-container *ngFor="let t of tenants">
              <option [ngValue]="t.tenantId" *ngIf="t.tenantId > 0">{{t.tenantName}}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="col-md-9 col-sm-4 col-xs-12">
        <div class="form-group" *ngIf="tenants">
          <label class="control-label">&nbsp;</label>
          <div class="checkbox">
            <label>
              <input type="checkbox" [(ngModel)]="showInactive" (change)="onShowInactive()"/> Show Inactive
            </label>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showLoading">
      <p class="text-center">
        <i class="fa fa-refresh fa-spin fa-3x" *ngIf="showLoading"></i>
      </p>
    </div>
    <div *ngIf="!showLoading">
      <div class="form-group">
        <button type="button" class="btn btn-primary" (click)="assign()">Assign</button>
      </div>
      <div class="table-responsive" *ngIf="dashboards">
        <form [formGroup]="dataForm">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th [style.width.px]="40" class="text-center">#</th>
                <th>Dashboard Name
                  <!-- <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'dashboardName' && filter.order == 'desc'"></i>
                  <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'dashboardName' && filter.order == 'asc'"></i> -->
                </th>
                <th>Dashboard Description</th>
                <th>Dashboard Alias</th>
                <!-- <th>Connection Name</th> -->
                <th>Menu Name</th>
                <th>Dashboard Category
                  <!-- <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'dashboardCategoryName' && filter.order == 'desc'"></i>
                  <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'dashboardCategoryName' && filter.order == 'asc'"></i> -->
                </th>
                <th [style.width.px]="80" class="text-center">Standard</th>

                <th [style.width.px]="110" class="text-center">Assign Roles</th>
                <th [style.width.px]="80" class="text-center">Active</th>
                <th [style.width.px]="80" class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <ng-container formArrayName="dataUpdate">
                <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                  <tr>
                    <td [style.width.px]="40" class="text-center">{{ i + 1 }}</td>
                    <!-- <td [style.width.px]="60" class="text-center">
                      <input [style.width.px]="40" type="number" oninput="validity.valid||(value='');" min="1" [max]="dashboards.length" [value]="item.orderIndex" (change)="updateOrderIndex($event, item)"/>
                    </td> -->
                    <td>{{ item.value.dashboardName }}</td>
                    <td>{{ item.value.dashboardDescription }}</td>
                    <td>
                      <input type="text" class="form-control" formControlName="dashboardAlias" (blur)="save()" (keyup.enter)="save()">
                    </td>
                    <!-- <td>{{ item.biConnectionName }}</td> -->
                    <td>{{ item.value.menuName }}</td>
                    <td>{{ item.value.dashboardCategoryName }}</td>
                    <td [style.width.px]="80" class="text-center">
                      <i class="fa fa-lg" style="cursor: pointer" [ngClass]="!item.value.isStandard ? 'fa-toggle-off text-danger' : 'fa-toggle-on text-success'" (click)="update(item.value.id, null, null, !item.value.isStandard)"></i>
                    </td>
                    <td [style.width.px]="110" class="text-center">
                      <a href="javascript:;" (click)="assignRoles(item.value.dashboardId)" title="Assign Roles" class="fa fa-lg fa-pencil-square-o text-success"></a>
                    </td>
                    <td [style.width.px]="80" class="text-center">
                      <i class="fa fa-lg" style="cursor: pointer" [ngClass]="!item.value.active ? 'fa-toggle-off text-danger' : 'fa-toggle-on text-success'" (click)="update(item.value.id, null, !item.value.active, null)"></i>
                    </td>
                    <td [style.width.px]="80" class="text-center">
                      <!-- <a href="javascript:;" class="fa fa-lg fa-pencil-square-o text-success" title="Edit" (click)="update(item)"></a>&nbsp;&nbsp;&nbsp; -->
                      <a href="javascript:;" class="fa fa-lg fa-trash-o text-danger" title="Delete" (click)="delete(item.value.id)"></a>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  </section>
</div>

<!-- Modal -->

<bs-modal #modalAssign>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Assign Tenant Dashboard</h4>
  </bs-modal-header>
  <bs-modal-body>
    <form novalidate [formGroup]="assignForm">
      <div class="nav-tabs-custom">
        <div class="tab-content">
          <div class="tab-pane box active" id="info">

            <div class="form-group">
              <label>Global Dashboard
                <span class="text-danger">*</span>
              </label>
              <select formControlName="dashboardId" class="form-control" required>
                <ng-container *ngFor="let rc of globalDashboards">
                  <option [ngValue]="rc.dashboardId">{{rc.dashboardName}}</option>
                </ng-container>
              </select>
            </div>

            <div class="form-group">
              <label>Dashboard Alias
                <span class="text-danger">*</span>
              </label>
              <input type="text" trim="blur" class="form-control" formControlName="dashboardAlias" maxlength="250" required>
            </div>

          </div>
        </div>
      </div>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalAssign.close()">Cancel</button>
    <button type="button" [disabled]="assignForm.invalid" class="btn btn-primary" (click)="submitAssign()">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalConfirmDelete>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure unassign this dashboard?
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirmDelete.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalAssignRole>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Assign role of dashboard for this tenant</h4>
  </bs-modal-header>
  <bs-modal-body>
    <div class="form-group">
      <div class="checkbox" *ngFor="let role of listRoles; let i = index">
        <label>
          <input type="checkbox" [(ngModel)]="role.isCheck">{{role.roleName}}
        </label>
      </div>
    </div>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalAssignRole.close()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="submitAssignRole()">Submit</button>
  </bs-modal-footer>
</bs-modal>
