import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class TenantEdgeConfigService {

  controller = 'TenantEdgeConfiguration';

  constructor(private http: AuthHttp) { }

  add(tenantId: number, edgeTypeId: number): Promise<any> {
    return this.http.post(`${this.controller}/Add/${tenantId}/${edgeTypeId}`).toPromise();
  }

  remove(tenantId: number, edgeTypeId: number, replaceId: number): Promise<any> {
    return this.http.delete(`${this.controller}/Remove/${tenantId}/${edgeTypeId}/${replaceId}`).toPromise();
  }

  checkRemove(tenantId: number, edgeTypeId: number): Promise<any> {
    return this.http.get(`${this.controller}/CheckRemove/${tenantId}/${edgeTypeId}`).toPromise();
  }

  GetUnassignEdges(tenantId: number): Promise<any> {
    return this.http.get(`${this.controller}/GetUnassignEdges/${tenantId}`).toPromise();
  }
}
