import { Component } from '@angular/core';
import { RouteDictionary } from '../../routes';
import { ObjectRoles } from '../../services/user.service';
import { PermissionService } from '../../services/permission.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent {
  public listMenu = [];

  private menu = [
    {
      name: 'Tenants',
      link: RouteDictionary.Tenants,
      icon: 'fa-institution',
      permissions: [
        ObjectRoles.SuperAdmin
      ]
    },
    {
      name: 'Users',
      link: RouteDictionary.Users,
      icon: 'fa-user',
      permissions: [
        ObjectRoles.SuperAdmin
      ]
    },
    {
      name: 'Workers',
      link: RouteDictionary.Workers,
      icon: 'fa-user',
      permissions: [
        ObjectRoles.SuperAdmin
      ]
    },
    {
      name: 'Roles',
      icon: 'fa-users',
      subMenu: [
        {
          name: 'Global',
          link: RouteDictionary.GlobalRoles,
          icon: 'fa-circle-o',
          permissions: [
            ObjectRoles.SuperAdmin
          ]
        },
        {
          name: 'Tenant',
          link: RouteDictionary.Roles,
          icon: 'fa-circle-o',
          permissions: [
            ObjectRoles.SuperAdmin
          ]
        }
      ]
    },
    // {
    //   name: 'Locations',
    //   icon: 'fa-map-marker',
    //   subMenu: [
    //     {
    //       name: 'Service Locations',
    //       link: RouteDictionary.ServiceLocations,
    //       icon: 'fa-circle-o',
    //       permissions: [
    //         ObjectRoles.SuperAdmin
    //       ]
    //     },
    //     {
    //       name: 'Regions',
    //       link: RouteDictionary.Regions,
    //       icon: 'fa-circle-o',
    //       permissions: [
    //         ObjectRoles.SuperAdmin
    //       ]
    //     }
    //   ]
    // },
    {
      name: 'Permissions',
      link: RouteDictionary.Permissions,
      icon: 'fa-user-circle-o',
      permissions: [
        ObjectRoles.SuperAdmin
      ]
    },
    {
      name: 'License Types',
      link: RouteDictionary.LicenseTypes,
      icon: 'fa-certificate',
      permissions: [
        ObjectRoles.SuperAdmin
      ]
    },
    {
      name: 'Reports',
      link: RouteDictionary.Reports,
      icon: 'fa-bar-chart',
      permissions: [
        ObjectRoles.SuperAdmin
      ]
    },
    {
      name: 'Dashboards',
      icon: 'fa-bar-chart',
      subMenu: [
        {
          name: 'Tenant Specific',
          link: RouteDictionary.DashboardTenantSpecific,
          icon: 'fa-circle-o',
          permissions: [
            ObjectRoles.SuperAdmin
          ]
        },
        {
          name: 'Global',
          link: RouteDictionary.DashboardGlobal,
          icon: 'fa-circle-o',
          permissions: [
            ObjectRoles.SuperAdmin
          ]
        },
        {
          name: 'BI Connections',
          link: RouteDictionary.DashboardBIConnections,
          icon: 'fa-circle-o',
          permissions: [
            ObjectRoles.SuperAdmin
          ]
        }
      ]
    },
    {
      name: 'Tenant Channel',
      link: RouteDictionary.TenantChannels,
      icon: 'fa-circle-o',
      permissions: [
        ObjectRoles.SuperAdmin
      ]
    },
    {
      name: 'Slab Configuration',
      icon: 'fa-cog',
      subMenu: [
        {
          name: 'Cost Schemas',
          link: RouteDictionary.CostSchemas,
          icon: 'fa-circle-o',
          permissions: [
            ObjectRoles.SuperAdmin, ObjectRoles.TenantOnly
          ]
        },
        {
          name: 'Price Schemas',
          link: RouteDictionary.SlabPricing,
          icon: 'fa-circle-o',
          permissions: [
            ObjectRoles.SuperAdmin, ObjectRoles.TenantOnly
          ]
        },
        {
          name: 'Tenant Specific',
          link: RouteDictionary.SlabConfiguration,
          icon: 'fa-circle-o',
          permissions: [
            ObjectRoles.SuperAdmin, ObjectRoles.TenantOnly
          ]
        },
        {
          name: 'Global',
          link: RouteDictionary.GlobalConfiguration,
          icon: 'fa-circle-o',
          permissions: [
            ObjectRoles.SuperAdmin, ObjectRoles.TenantOnly
          ]
        },
      ]
    },
    {
      name: 'Tile Configuration',
      icon: 'fa-cog',
      subMenu: [
        {
          name: 'Cost Schemas',
          link: RouteDictionary.TileCostSchemas,
          icon: 'fa-circle-o',
          permissions: [
            ObjectRoles.SuperAdmin, ObjectRoles.TenantOnly
          ]
        },
        // {
        //   name: 'Price Schemas',
        //   link: RouteDictionary.SlabPricing,
        //   icon: 'fa-circle-o',
        //   permissions: [
        //     ObjectRoles.SuperAdmin, ObjectRoles.TenantOnly
        //   ]
        // },
        {
          name: 'Tenant Specific',
          link: RouteDictionary.TileTenantSpecificConfiguration,
          icon: 'fa-circle-o',
          permissions: [
            ObjectRoles.SuperAdmin, ObjectRoles.TenantOnly
          ]
        },
        {
          name: 'Global',
          link: RouteDictionary.TileGlobalConfiguration,
          icon: 'fa-circle-o',
          permissions: [
            ObjectRoles.SuperAdmin, ObjectRoles.TenantOnly
          ]
        },
      ]
    },
    // {
    //   name: 'Pricing Recalculation',
    //   link: RouteDictionary.PricingRecalculation,
    //   icon: 'fa-repeat',
    //   permissions: [
    //     ObjectRoles.SuperAdmin
    //   ]
    // },
    // {
    //   name: 'Compare Tool',
    //   link: RouteDictionary.CompareTool,
    //   icon: 'fa-balance-scale',
    //   permissions: [
    //     ObjectRoles.SuperAdmin
    //   ]
    // },
  ];

  constructor(private userService: UserService, private permissionService: PermissionService, private toastr: ToastrService) { }

  ngOnInit() {
    this.permissionService.getTenantsRolesOfUser().then(response => {
      if (response.status) {
        this.userService.roles = response.data.roles;
        this.userService.tenants = response.data.tenants;
        this.userService.ready = true;
        const userRoles = this.userService.roles;
        this.listMenu = _.filter(this.menu, function (o) {
          if (_.has(o, 'subMenu') && !_.has(o, 'permissions')) return true;
          return _.intersection(userRoles, o.permissions).length;
        });
        _.forEach(this.listMenu, function (menu) {
          if (_.has(menu, 'subMenu') && !_.has(menu, 'permissions')) {
            menu.subMenu = _.filter(menu.subMenu, function (o) {
              return _.intersection(userRoles, o.permissions).length;
            });
          }
        });
        $('ul').tree();
      }
      else {
        this.toastr.error('Load menu failed');
      }
    });
  }
}
