import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';

@Component({
  selector: 'tab-waste',
  templateUrl: './waste.html',
})
export class WasteComponent implements OnInit, OnDestroy {
  @Input() costSchemaId: number;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  currentTab = 'ApplicationWaste';

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}

  change(tabStr: string) {
    if (this.currentTab == tabStr) return;
    this.currentTab = tabStr;
  }
}
