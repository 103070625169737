<div class="row">
  <div class="col-md-3 col-sm-4 col-xs-12">
    <div class="form-group">
      <label>Select Complextity</label>
      <select [(ngModel)]="complexityId" (change)="filterData()" class="form-control">
        <option *ngFor="let t of complexities" [ngValue]="t.complexityId">{{t.complexityName}}</option>
      </select>
    </div>
  </div>

  <div class="col-xs-12">
    <h5 class="text-center" style="text-decoration: underline; font-weight: bold">REDUCTION OF OUTPUT PER HOUR</h5>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Application</th>
              <th [style.width.px]="120">Percentage</th>
              <th>Last comment</th>
              <th>Last Updated</th>
              <th>Updated By</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>{{item.value.applicationName}}</td>
                  <td [style.width.px]="120">
                    <qxm-input-percent
                      [myFormGroup]="dataForm"
                      myFormArrayName="dataUpdate"
                      [myFormGroupName]="i"
                      myFormControlName="percentage"
                      (acceptChange)="save();">
                    </qxm-input-percent>
                  </td>
                  <td>
                    <input #note type="text" class="form-control" formControlName="note" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td>{{item.value.updatedBy}}<span><a (click) ="viewComment(item)" class="btn btn-primary pull-right" >View Comments</a></span></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
<app-view-comment #modalViewComments [notes] = 'notes'></app-view-comment>
