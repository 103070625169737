import { BsModalComponent } from 'ng2-bs3-modal';
import { CostSchemaService } from './../../../../../services/cost-schema.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { CommonPricingComponent } from "../../../common-pricing";
import { FormBuilder, Validators, FormGroup, FormControl } from "@angular/forms";
import { SettingService } from "@app-admin/services/setting.service";
import { UserService } from '@app-admin/services/user.service';
import { ToastrService } from "ngx-toastr";
import { Config } from '@app-admin/config';
declare var $: any;
@Component({
  templateUrl: "./cost-schema-detail.html",
  styleUrls: ["./cost-schema-detail.scss"],
})

export class TileCostSchemaDetailComponent extends CommonPricingComponent implements OnInit {

  @ViewChild('modal') modal: BsModalComponent;
  activeCostSchemas: any;
  reassignSchemaId: number = 0;
  form: FormGroup;
  tenantId: number = -1;
  openDetail: boolean = false;
  schemaId: number;
  loading: boolean = false;


  constructor(fb: FormBuilder, toast: ToastrService, settingService: SettingService,
    userService: UserService, cdr: ChangeDetectorRef, activatedRoute: ActivatedRoute,
    private router: Router, private costSchema: CostSchemaService) {
    super(fb, toast, settingService, userService, cdr);
    this.form = fb.group({
      schemaName: [ "", Validators.required],
      description: "",
      active: false,
    });

    activatedRoute.params.subscribe(params => {
      this.schemaId = params.id;
      if (!Number(this.schemaId)) this.router.navigate(['/not-found']);
      else {
        this.settingService.getCostSchemaDetail(this.schemaId).then(res => {
          if(res.status){
            const schema = res.data;
            this.form = fb.group({
              schemaName: [schema.schemaName, Validators.required],
              description: schema.description,
              active: schema.active,
            });
          }
        });
        super.changeData();
      }
    });


  }

  ngOnInit() {
    this.tabs = [
      { id: "ProcessStandard", text: "Tile Process" },
      { id: "ApplicationBase", text: "Application Base" },
      { id: "SubTypeWeights", text: "Sub-Type" },
      { id: "Complexity", text: "Complexity" },
      { id: "ApplicationWeights", text: "Application" },
      { id: "TieredVolumeSizing", text: "Application Volume" },
      { id: "TileType", text: "Tile Type" },
      { id: "TileSize", text: "Tile Size" },
      { id: "TilePattern", text: "Tile Pattern" },
      { id: "TileSet", text: "Tile Set" },
      { id: "TileCuttingType", text: "Tile Cutting Type" },
      { id: "SpecialCondition", text: "Special Condition" },
      { id: "Trim", text: "Trim" },
      { id: "SiteAccessDifficulty", text: "Site Access" },
      { id: "BuildingLevels", text: "Building Levels" },
      { id: "Waste", text: "Waste" },
      { id: "ChangeLogSlabCost", text: "Change Log" },
    ];
    this.curTab = this.tabs[0];

    super.ngOnInit();
  }

  get schemaName(): FormControl {
    return this.form.get("schemaName") as FormControl;
  }
  get description(): FormControl {
    return this.form.get("description") as FormControl;
  }
  get active(): FormControl {
    return this.form.get("active") as FormControl;
  }

  save() {
    const schema: any = {};
    schema.schemaId = this.schemaId;
    schema.schemaName = this.schemaName.value;
    schema.description = this.description.value;
    schema.active = this.active.value;
    this.settingService.updateCostSchema(schema).then((res) => {
      if (res.status) {
        this.toast.success("Cost schema has been updated.");
      } else {
        this.toast.error(res.message);
      }
    });
  }
  copy(){
    this.costSchema.copy(this.schemaId).then(res => {
      if (res.status) {
        // Redirect & reload
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate([`/tile-cost-schema-detail/${res.data}`]);
          this.toast.success('Cost Schema has been copied.');
        });
      }
      else this.toast.error('Copy Cost schema has been failed!');
      this.loading = false;
    });
  }
  confirmDelete(){
     if(this.reassignSchemaId > 0){
        this.modal.close();
        this.costSchema.delete(this.schemaId, this.reassignSchemaId).then(res => {
        this.loading = false;
        if(res.status){
          this.router.navigate(['/tile-cost-schemas']);
          this.toast.success('Cost Schema has been deleted.');
        }
        else {
          this.toast.error(res.message);
        }
      });
     }
  }
  delete(){
    this.loading = true;
    this.costSchema.isAssignedToChannel(this.schemaId).then(res=> {
      if(res.status){
        if(res.data) {
          this.costSchema.getCostSchemasActive(Config.BusinessUnitId.Tile).then(resCS => {
            if(resCS.status){
              this.activeCostSchemas = resCS.data.filter(i=> i.schemaId != this.schemaId);
            }
            else{
              this.toast.success('Channel has been load failed.');
            }
          });
          this.modal.open();
        }
        else {
          this.costSchema.delete(this.schemaId, 0).then(response => {
            this.loading = false;
            if(response.status){
               this.router.navigate(['/tile-cost-schemas']);
               this.toast.success('Cost Schema has been deleted.');
            }
            else{
              this.toast.error(res.message);
            }
          });

        }
      }
      else {
        this.toast.error(res.message);
        this.loading = false;
      }

    });
  }
  showSlab(schema: any) {
    this.openDetail = true;
    this.schemaId = schema.schemaId;
    this.form.patchValue({
      schemaName: schema.schemaName,
      description: schema.description,
      active: schema.active,
    });
    super.changeTenant();
  }
  calcHeightTable(id: string) {
    if (this.curTab.id == "ChangeLogSlabCost") {
      setTimeout(() => {
        try {
          let maxHeightOfTable =
            $(window).height() -
            $("#" + id + " .table-responsive tbody").offset().top -
            $("footer").outerHeight() -
            31 -
            64;
          $("#" + id + " .table-responsive tbody").css(
            "max-height",
            maxHeightOfTable
          );
          let widthOfScrollBar =
            $("#" + id + " .table-responsive tbody").width() -
            $("#" + id + " .table-responsive tbody tr").width();
          $("#" + id + " .table-responsive thead").width(
            "calc(100% - " + widthOfScrollBar + "px)"
          );
        } catch {}
      });
    } else {
      super.calcHeightTable(id);
    }
  }
}
