<div class="row">

  <div class="col-12">
    <label style="font-size: medium; margin-left: 10px;">
      <span>
        <a (click)="change(1)" [ngClass]="{'sub-menu-hight': curTab == 1, 'sub-menu-txt': curTab != 1}">Base</a>
      </span>
      |
      <span>
        <a (click)="change(2)" [ngClass]="{'sub-menu-hight': curTab == 2, 'sub-menu-txt': curTab != 2}">Type</a>
      </span>
      |
      <span>
        <a (click)="change(3)" [ngClass]="{'sub-menu-hight': curTab == 3, 'sub-menu-txt': curTab != 3}">Cutting</a>
      </span>
      |
      <span>
        <a (click)="change(4)" [ngClass]="{'sub-menu-hight': curTab == 4, 'sub-menu-txt': curTab != 4}">Pattern</a>
      </span>
      |
      <span>
        <a (click)="change(5)" [ngClass]="{'sub-menu-hight': curTab == 5, 'sub-menu-txt': curTab != 5}">Size</a>
      </span>
      |
      <!-- <span>
        <a (click)="change(5)" [ngClass]="{'sub-menu-hight': curTab == 5, 'sub-menu-txt': curTab != 5}">Set</a>
      </span>
      | -->
      <span>
        <a (click)="change(6)" [ngClass]="{'sub-menu-hight': curTab == 6, 'sub-menu-txt': curTab != 6}">Complexity</a>
      </span>
    </label>
  </div>

  <tab-tile-application-waste *ngIf="curTab == 1" [costSchemaId]="costSchemaId" (calcHeightTable)="calcHeightTable"></tab-tile-application-waste>
  <tab-tile-type-waste *ngIf="curTab == 2" [costSchemaId]="costSchemaId" (calcHeightTable)="calcHeightTable"></tab-tile-type-waste>
  <tab-tile-cutting-waste *ngIf="curTab == 3" [costSchemaId]="costSchemaId" (calcHeightTable)="calcHeightTable"></tab-tile-cutting-waste>
  <tab-tile-pattern-waste *ngIf="curTab == 4" [costSchemaId]="costSchemaId" (calcHeightTable)="calcHeightTable"></tab-tile-pattern-waste>
  <tab-tile-size-waste *ngIf="curTab == 5" [costSchemaId]="costSchemaId" (calcHeightTable)="calcHeightTable"></tab-tile-size-waste>
  <!-- <tab-tile-set-waste *ngIf="curTab == 5" [costSchemaId]="costSchemaId" (calcHeightTable)="calcHeightTable"></tab-tile-set-waste> -->
  <tab-tile-complexity-waste *ngIf="curTab == 6" [costSchemaId]="costSchemaId" (calcHeightTable)="calcHeightTable"></tab-tile-complexity-waste>

</div>
