import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { WorkerService } from '@app-admin/services/worker.service';
import { Config } from '@app-admin/config';
declare var $: any;

@Component({
  templateUrl: './worker-details.html'
})

export class WorkerDetailsComponent implements OnInit {

  workerId: any;
  workerInfo: any;
  isGeneratingPin: boolean;
  isSaving: boolean;
  loading = false;

  formWorker: UntypedFormGroup;

  users = [];

  workerTypes = [
    { key: 0, name: 'Employee' },
    { key: 1, name: 'Contractor' }
  ];

  constructor(
    activatedRoute: ActivatedRoute,
    private router: Router,
    private workerService: WorkerService,
    private fb: FormBuilder,
    private toastr: ToastrService) {

    activatedRoute.params.subscribe(params => {
      this.workerId = params.id;
      if (!Number(this.workerId)) { this.router.navigate(['/not-found']); }

      this.formWorker = fb.group({
        peopleId: [this.workerId],
        firstName: [''],
        lastName: [''],
        avatar:[Config.avatarDefault],
        emailAddress: ['', Validators.pattern(Config.validateEmail)],
        jobTitle: [''],
        originalStartDate: [new Date()],
        relatedUserId: [-1],
        phoneNumber: [null, [ Validators.required ,Validators.pattern(Config.validatePhone)]],
        workerType: [0, Validators.required],
        active: [false, Validators.required],
        pin: ['', Validators.compose([Validators.required, Validators.pattern(Config.validatePIN)])],
        userName:['',Validators.required],
        useServiceLocationHour: [null],
      });
    });
  }

  ngOnInit() {
    $('body').layout('fix');
    this.reload();
  }

  reload() {
    this.getUserInfo(() => {
      this.getUser();

      setTimeout(() => {
        $('#originalStartDate').datepicker({ autoclose: true }).on('changeDate', (e) => {
          this.originalStartDate.setValue(e.date.toLocaleDateString('en-US'));
        });
      });
    });
  }

  getUserInfo(callback = null) {
    this.workerService.getWorkerInfo(this.workerId).then(res => {
      if (res.status) {
        this.workerInfo = res.data;

        if (callback !== null) callback();

        this.formWorker.patchValue({
          peopleId: this.workerInfo.peopleId,
          firstName: this.workerInfo.firstName,
          lastName: this.workerInfo.lastName,
          avatar: this.workerInfo.profilePicture || Config.avatarDefault,
          emailAddress: this.workerInfo.emailAddress,
          jobTitle: this.workerInfo.jobTitle,
          originalStartDate: new Date(this.workerInfo.originalStartDate).toLocaleDateString('en-US'),
          relatedUserId: this.workerInfo.relatedUserId != null ? this.workerInfo.relatedUserId : -1,
          phoneNumber: this.workerInfo.mobileNumber,
          workerType: this.workerInfo.workerType,
          active: this.workerInfo.active,
          pin: this.workerInfo.pin,
          userName:this.workerInfo.userName,
          useServiceLocationHour: this.workerInfo.useServiceLocationHour,
        });
      } else this.toastr.error(res.data.error);
    });
  }

  get peopleId(): UntypedFormControl {
    return this.formWorker.get('peopleId') as UntypedFormControl;
  }

  get emailAddress(): UntypedFormControl {
    return this.formWorker.get('emailAddress') as UntypedFormControl;
  }
  getErrorEmail() {
    return this.emailAddress.hasError('pattern') ? 'Invalid email format' : '';
  }

  get phoneNumber(): UntypedFormControl {
    return this.formWorker.get('phoneNumber') as UntypedFormControl;
  }
  getErrorPhone() {
    return this.phoneNumber.hasError('pattern') ? 'Invalid phone number format' : '';
  }

  get pin(): UntypedFormControl {
    return this.formWorker.get('pin') as UntypedFormControl;
  }

  get userName(): UntypedFormControl {
    return this.formWorker.get('userName') as UntypedFormControl;
  }

  getErrorPin() {
    if (this.pin.hasError('required')) return 'PIN is required';
    else if (this.pin.hasError('pattern')) return 'Invalid PIN format';
    else return '';
  }

  getErrorUserName() {
    if (this.userName.hasError('required')) return 'User Name is required';
    else return '';
  }

  get firstName(): UntypedFormControl {
    return this.formWorker.get('firstName') as UntypedFormControl;
  }

  getErrorFirstName() {
    if (this.firstName.hasError('required')) return 'First Name is required';
    else return '';
  }

  get lastName(): UntypedFormControl {
    return this.formWorker.get('lastName') as UntypedFormControl;
  }

  getErrorLastName() {
    if (this.lastName.hasError('required')) return 'First Name is required';
    else return '';
  }

  get originalStartDate(): UntypedFormControl {
    return this.formWorker.get('originalStartDate') as UntypedFormControl;
  }

  generatePIN() {
    this.isGeneratingPin = true;
    this.isSaving = true;
    this.pin.disable();
    this.workerService.generatePIN(this.peopleId.value).then(res => {
      this.isGeneratingPin = false;
      this.isSaving = false;
      this.pin.enable();
      if (res.status) {
        this.pin.setValue(res.data);
        if (this.peopleId.value > 0) {
          this.toastr.success('Pin updated');
        }
      } else this.toastr.error('Save failed');
    });
  }

  getUser() {
    this.workerService.getAllUserForRelatedWorkerByTenant(this.workerInfo.tenantId).then(res => {
        if (res.status) {
          let userData = res.data
          userData = [{ userId: -1, fullName: 'None', hasRelatedUser: false }, ...userData];
          this.users = userData;
        }
        else {
          this.toastr.error("Load authorized users failed");
        }
      });
  }

  save() {
    this.loading = true;
    this.workerService.updateWorker(this.formWorker.getRawValue()).then(res => {
      this.loading = false;
      if (res.status) {
        this.reload();
        this.toastr.success('Worker updated');
      } else this.toastr.error(res.message);
    });
  }
}
