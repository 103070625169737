<div class="row">
  <div class="col-xs-12">
    <h4 class="text-bold" style="margin-bottom: 15px">Edge Configuration</h4>
  </div>

  <div class="col-md-3 col-sm-4 col-xs-12">
    <div class="form-group">
      <label>Edge Types</label>
      <div class="row">
        <div class="col-sm-8">
          <select [(ngModel)]="edgeTypeId" class="form-control" name="edgeTypeId">
            <option [value]="0">Select a edge type</option>
            <option *ngFor="let item of unassignData" [ngValue]="item.edgeId">{{item.edgeType}}</option>
          </select>
        </div>
        <div class="col-sm-4">
          <button type="button" [disabled]="loading || edgeTypeId == 0" class="btn btn-primary" (click)="add()">
            <span><i class="fa fa-spinner fa-spin fa-fw" *ngIf="loading"></i></span>
            <strong *ngIf="!loading">+ </strong> Add
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-12">
    <label class="control-label"></label>
    <div class="form-group">
      <div>
        <label class="radio-inline">
          <input type="radio" name="sortOption" [value]="0" [(ngModel)]="sortOption" (change)="changeSortOption()"
            > Sort Order
        </label>
        <label class="radio-inline">
          <input type="radio" name="sortOption" [value]="1" [(ngModel)]="sortOption" (change)="changeSortOption()"
            > Alpha-Sorted
        </label>
      </div>
    </div>
  </div>

  <div class="col-xs-12">
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th *ngIf="sortOption == 0" [style.width.px]="30"></th>
              <th>Edge Type</th>
              <th>Edge Alias</th>
              <th>Helper Text</th>
              <th [style.width.px]="100" class="text-center">Straight</th>
              <th [style.width.px]="100" class="text-center">Radius</th>
              <th [style.width.px]="100" class="text-center">Undermount</th>
              <th [style.width.px]="100" class="text-center">Onsite</th>
              <th [style.width.px]="130" class="text-center">Frequently Used</th>
              <th [style.width.px]="130" class="text-center">Apply Height</th>
              <th [style.width.px]="180">Updated On</th>
              <th>Updated By</th>
              <!-- <th [style.width.px]="96"></th> -->
              <th [style.width.px]="100"></th>
            </tr>
          </thead>
          <tbody cdkDropList (cdkDropListDropped)="drop($event)">
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr cdkDrag class="moveable" [cdkDragDisabled]="sortOption == 1">
                  <td class="icon-moveable" [style.width.px]="30" *ngIf="sortOption == 0">
                    <i class="fa fa-bars" aria-hidden="true"></i>
                  </td>
                  <td>{{item.value.edgeType}}</td>
                  <td>
                    <input type="text" trim="blur" maxlength="200" class="form-control" formControlName="alias" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>
                    <input type="text" trim="blur" maxlength="200" class="form-control" formControlName="helperText" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td [style.width.px]="100" class="text-center">
                    <input type="checkbox" formControlName="straight" (change)="save()">
                  </td>
                  <td [style.width.px]="100" class="text-center">
                    <input type="checkbox" formControlName="radius" (change)="save()">
                  </td>
                  <td [style.width.px]="100" class="text-center">
                    <input type="checkbox" formControlName="undermount" (change)="save()">
                  </td>
                  <td [style.width.px]="100" class="text-center">
                    <input type="checkbox" formControlName="onsite" (change)="save()">
                  </td>
                  <td [style.width.px]="130" class="text-center">
                    <input type="checkbox" formControlName="frequentlyUsed" (change)="save()">
                  </td>
                  <td [style.width.px]="130" class="text-center">
                    <input type="checkbox" formControlName="applyHeight" (change)="save()">
                  </td>
                  <td [style.width.px]="180">{{item.value.updatedOn | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
                  <td>{{item.value.updatedBy}}</td>
                  <td [style.width.px]="100">
                    <button [disabled]="loading" (click)="remove(item.value.edgeId)" type="button" class="btn btn-primary">
                      Remove
                    </button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<!-- <bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you want to remove this edge? Removing the edge type will convert all bids with this edge to None.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal> -->

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    <p>Are you sure?  This type is currently being used.  Select another type that will replace the removed type.</p>
    <form novalidate>
      <div class="col-12">
        <div class="form-group">
          <label class="control-label">Edge Type</label>
          <select class="form-control" [(ngModel)]="replaceId" name="replaceId" required>
            <ng-container *ngFor="let item of dataSorted">
              <option *ngIf="item.edgeId != edgeTypeId" [ngValue]="item.edgeId">{{item.edgeType}}</option>
            </ng-container>
          </select>
        </div>
      </div>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitRemove(true)" id="remove">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalConfirm2>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    <p>Are you sure? This will remove option for tenant.</p>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm2.close()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitRemove(false)">Submit</button>
  </bs-modal-footer>
</bs-modal>
