/// <reference types="@types/google.maps" />
import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalComponent } from 'ng2-bs3-modal';
import { ToastrService } from 'ngx-toastr';
import { AddressService } from '@app-admin/services/address.service';
import { LocationAddressesService } from '../location-addresses.service';

@Component({
  selector: 'location-addresses',
  templateUrl: './location-addresses.html'
})

export class LocationAddressesComponent implements OnInit, AfterViewInit {

  @ViewChild("pac") placeAutocompleteInput: ElementRef;
  @Input() currentRow: any;
  @Input() states: any[];
  @Input() loopIndex: number;

  addresses: any[];

  // Modal Address
  loadingAddress = false;
  @ViewChild('modalAddress') modalAddress: BsModalComponent;
  formEditAddress: FormGroup;

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private addressService: AddressService,
    private locationAddressesService: LocationAddressesService,
    private cdr: ChangeDetectorRef
  ) {

    this.formEditAddress = this.formBuilder.group({
      id: [0],
      locationId: [0],
      addressType: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: ['', Validators.compose([Validators.required, Validators.pattern('([0-9]{5}|[0-9]{9})')])],
      placeId: [''],
      active: [true]
    });
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit(){
    this.initPac();
  }

  private loadData() {
    this.addressService.getAddressesByLocationId(this.currentRow.locationId).then(response => {
      if (response.status) {
        this.addresses = response.data;
      } else {
        this.toastr.error('Load data failed');
      }
    });
  }

  // Modal Address
  get addressId(): FormControl {
    return this.formEditAddress.get('id') as FormControl;
  }
  get addressType(): FormControl {
    return this.formEditAddress.get('addressType') as FormControl;
  }
  get address1(): FormControl {
    return this.formEditAddress.get('address1') as FormControl;
  }
  get address2(): FormControl {
    return this.formEditAddress.get('address2') as FormControl;
  }
  get city(): FormControl {
    return this.formEditAddress.get('city') as FormControl;
  }
  get state(): FormControl {
    return this.formEditAddress.get('state') as FormControl;
  }
  get postalCode(): FormControl {
    return this.formEditAddress.get('postalCode') as FormControl;
  }
  get placeId(): FormControl{
    return this.formEditAddress.get('placeId') as FormControl;
  }

  openPopupAddress(item) {
    this.formEditAddress.reset();
    this.formEditAddress.patchValue({
      id: item.id,
      locationId: item.locationId,
      addressType: item.addressType,
      address1: item.address1,
      address2: item.address2,
      city: item.city,
      state: item.state,
      postalCode: item.postalCode,
      active: item.active,
      placeId: item.placeId
    });
    this.modalAddress.open();
    if(!!item.placeId){
      this.city.disable();
      this.state.disable();
      this.postalCode.disable();
    }
  }

  submitAddress() {
    this.updateAddress(this.formEditAddress.value, false);
  }

  updateAddress(item, onlyActive: boolean) {
    if (onlyActive) {
      item.active = !item.active;
      this.formEditAddress.patchValue({
        active: item.active
      });
      let formData = this.formEditAddress.value;
      if(!!formData.placeId){
        formData.postalCode = this.formEditAddress.get('postalCode').value;
        formData.city = this.formEditAddress.get('city').value;
        formData.state = this.formEditAddress.get('state').value;
      }

      this.addressService.updateAddress(item.id, formData).then(response => {
        if (response.status) {
          this.toastr.success(response.message);
        }
        else {
          item.active = !item.active;
          this.toastr.error(response.message);
        }
      });
    }
    else {
      this.loadingAddress = true;
      let formData = this.formEditAddress.value;
      if(!!formData.placeId){
        formData.postalCode = this.formEditAddress.get('postalCode').value;
        formData.city = this.formEditAddress.get('city').value;
        formData.state = this.formEditAddress.get('state').value;
      }
      this.addressService.updateAddress(this.addressId.value, formData).then(response => {
        this.loadingAddress = false;
        if (response.status) {
          this.loadData();
          this.modalAddress.close();
          this.toastr.success(response.message);
        }
        else {
          this.toastr.error(response.message);
        }
      });
    }
  }

  deleteAddress(id: number) {
    this.addressService.deleteAddress(id).then(response => {
      this.loadingAddress = false;
      if (response.status) {
        this.loadData();
        this.toastr.success(response.message);
      } else {
        this.toastr.error(response.message);
      }
    });
  }

  initPac(){
    let autocomplete = new google.maps.places.Autocomplete(this.placeAutocompleteInput.nativeElement, {
      componentRestrictions: { country: ['us'] },
    });

    autocomplete.addListener('place_changed', () => {
      let place = autocomplete.getPlace();

      let addressObj = this.getAddressObj(place);
      this.patchAddressForm(addressObj);
    });
  }

  getAddressObj(place: google.maps.places.PlaceResult) {
    let streetNumber = place.address_components.find(x => x.types.find(t => ['street_number'].includes(t)));
    let route = place.address_components.find(x => x.types.find(t => ['route'].includes(t)));
    let city = place.address_components.find(x => x.types.find(t => ['locality'].includes(t)));
    if (!city) {
      city = place.address_components.find(x => x.types.find(t => ['sublocality_level_1'].includes(t)));
    }
    let state = place.address_components.find(x => x.types.find(t => ['administrative_area_level_1'].includes(t)));
    let postalCode = place.address_components.find(x => x.types.find(t => ['postal_code'].includes(t)));

    return {
      address: `${streetNumber?.short_name || ''} ${route?.short_name || ''}`,
      city: city?.short_name || '',
      stateAbbreviation: state?.short_name || '',
      postalCode: postalCode?.short_name || '',

      placeId: place.place_id,
      origin: '',
      destination: place.formatted_address,
      orgLat: 0,
      orgLng: 0,
      dstLat: place.geometry.location.lat(),
      dstLng: place.geometry.location.lng(),
      duration: 0,
      durationInHour: 0,
      distance: 0,
      distanceInMile: 0,
    };
  }

  patchAddressForm(addressObj) {
    this.address1.setValue(addressObj.address);
    this.city.setValue(addressObj.city);
    this.state.setValue(addressObj.stateAbbreviation);
    this.postalCode.setValue(addressObj.postalCode);

    this.placeAutocompleteInput.nativeElement.blur();
    this.onPacOutFocus(false);
    this.placeId.setValue(addressObj.placeId);

    this.cdr.detectChanges();
  }

  onPacOutFocus(isCustomAddress){
    if(isCustomAddress){
      this.city.enable();
      this.state.enable();
      this.postalCode.enable();
      this.placeId.setValue(null);
      this.city.setValue(null);
      this.state.setValue(null);
      this.postalCode.setValue(null);
    }else{
      this.city.disable();
      this.state.disable();
      this.postalCode.disable();
    }
  }

  disableSubmit(){
    return !this.formEditAddress.valid || this.loadingAddress;
  }
}
