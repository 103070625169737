import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TenantChannelsService } from '../tenant-channels.service';

@Component({
  selector: 'reassign-channel-modal',
  templateUrl: './reassign-channel-modal.html'
})

export class ReassignChannelModalComponent implements OnInit, OnDestroy {

  frm: FormGroup;
  channels: { channelId: number, channelName: string }[];

  currentRow: { tenantChannelId: number, currentChannelName: string };
  subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private tenantChannelsService: TenantChannelsService
  ) {
    this.frm = this.fb.group({
      tenantChannelId: [null, Validators.required],
      channelId: [null, Validators.required],
      currentChannelName: [{ value: '', disabled: true }, Validators.required],
    });

    this.subscription = this.tenantChannelsService.getMessages().subscribe(data => {
      if (!data) {
        return;
      }

      switch (data.messageType) {
        case TenantChannelsService.MessageTypes.SEND_TO_MODAL_REASSIGN_CHANNEL:
          const payload = data.payload;
          this.currentRow = payload.currentRow;
          this.channels = payload.channels;

          // Form data
          this.frm.patchValue({
            tenantChannelId: this.currentRow.tenantChannelId,
            channelId: null,
            currentChannelName: this.currentRow.currentChannelName,
          });
          break;
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  save() {
    const value = this.frm.value;

    // Remove if existed
    let existedIndex = this.tenantChannelsService.arrReassignChannel.findIndex(x => x.tenantChannelId == value.tenantChannelId);
    if (existedIndex >= 0) {
      this.tenantChannelsService.arrReassignChannel.splice(existedIndex, 1);
    }

    // Add to arr
    this.tenantChannelsService.arrReassignChannel.push({
      tenantChannelId: value.tenantChannelId,
      channelId: value.channelId,
    });
  }
}
