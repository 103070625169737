<div class="col-xs-12">
  <h5 class="text-center" style="text-decoration: underline; font-weight: bold">APPLICATION WASTE %</h5>
  <p class="text-center">
    <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
  </p>

  <div class="table-responsive" *ngIf="!loading">
    <form [formGroup]="dataForm" (submit)="save()">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Application</th>
            <th [style.width.px]="120">Standard Percentage</th>
            <th [style.width.px]="120">Advanced Percentage</th>
            <th>Last Comment</th>
            <th>Last Updated</th>
            <th>Updated By</th>
          </tr>
        </thead>
        <tbody>
          <ng-container formArrayName="dataUpdate">
            <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
              <tr>
                <td>{{item.value.applicationName}}</td>
                <td [style.width.px]="120">
                  <qxm-input-percent
                    [myFormGroup]="dataForm"
                    myFormArrayName="dataUpdate"
                    [myFormGroupName]="i"
                    myFormControlName="wastePercentAdjustment"
                    (acceptChange)="save();">
                  </qxm-input-percent>
                </td>
                <td [style.width.px]="120">
                  <qxm-input-percent
                    [myFormGroup]="dataForm"
                    myFormArrayName="dataUpdate"
                    [myFormGroupName]="i"
                    myFormControlName="wasteAdvancedPercentAdjustment"
                    (acceptChange)="save();">
                  </qxm-input-percent>
                </td>
                <td>
                  <input #note type="text" class="form-control" formControlName="note" (blur)="save()" (keyup.enter)="save()">
                </td>
                <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td>{{item.value.updatedBy}}<span><a (click) ="viewComment(item)" class="btn btn-primary pull-right" >View Comments</a></span></td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </form>
  </div>
</div>

<app-view-comment
  #modalViewComments
  [notes]="notes"
  [tab]="currentTab"
  [parentId]="currentId"
></app-view-comment>
