<div class="row">
  <div *ngIf="!isTenantOnly">
    <div class="col-xs-12">
      <h4 class="text-bold" style="margin-bottom: 15px">Material Type Configuration</h4>
    </div>
    <div class="col-md-3 col-sm-4 col-xs-12">
      <div class="form-group">
        <label>Material Type</label>
        <input type="text" class="form-control" trim="blur" [(ngModel)]="materialType">
      </div>
    </div>
    <div class="col-md-3 col-sm-4 col-xs-12">
      <div class="form-group">
        <label>Abbreviation Type</label>
        <input type="text" class="form-control" trim="blur" [(ngModel)]="typeAbbreviation">
      </div>
    </div>
    <div class="col-md-3 col-sm-4 col-xs-12">
      <div class="form-group">
        <label>Default Waste%</label>
        <div class="row">
          <div class="col-sm-8">
            <qxm-input-percent-ngmodel
              [(model)]="baseWaste">
            </qxm-input-percent-ngmodel>
          </div>
          <div class="col-sm-4">
            <button type="button" class="btn btn-primary" (click)="addMaterialTypes()">Add</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-12">
    <br/>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Material Type</th>
              <th [style.width.px]="120">Abbreviation Type</th>
              <th [style.width.px]="120">Default Waste%</th>
              <th [style.width.px]="100">Active</th>
              <th>Updated On</th>
              <th [style.width.px]="96" *ngIf="!isTenantOnly"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td *ngIf="!isTenantOnly">
                    <div class="form-group" style="margin-bottom: 0" [class.has-error]="item.controls.materialGroup.invalid && item.controls.materialGroup.dirty && item.controls.materialGroup.touched">
                      <input type="text" class="form-control" formControlName="materialGroup" trim="blur" required (blur)="save()" (keyup.enter)="save()">
                    </div>
                  </td>
                  <td *ngIf="isTenantOnly">{{item.value.materialGroup}}</td>
                  <td [style.width.px]="120" *ngIf="!isTenantOnly">
                    <input type="text" class="form-control text-right" formControlName="typeAbbreviation" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td [style.width.px]="120" *ngIf="isTenantOnly">{{item.value.typeAbbreviation}}</td>
                  <td [style.width.px]="120" *ngIf="!isTenantOnly">
                    <qxm-input-percent
                      [myFormGroup]="dataForm"
                      myFormArrayName="dataUpdate"
                      [myFormGroupName]="i"
                      myFormControlName="baseWastePercent"
                      (acceptChange)="save()">
                    </qxm-input-percent>
                  </td>
                  <td [style.width.px]="120" *ngIf="isTenantOnly">{{item.value.baseWastePercent}}</td>
                  <td [style.width.px]="100" class="text-center">
                    <input [disabled]="isTenantOnly" type="checkbox" formControlName="active" (change)="save()">
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td [style.width.px]="96" *ngIf="!isTenantOnly">
                    <button type="button" class="btn btn-primary" [disabled]='!item.value.isRemove' (click)="deleteMaterialTypes(item.value.materialTypeId)">Remove</button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you want to remove this material type? Removing the material type will remove all rules and bids associated to this material type.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal>
