import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class WorkerService {

  controller = 'Peoples';

  constructor(private http: AuthHttp) {
  }

  getAllWorkersByFilter(filter): Promise<any> {
    return this.http.post(`${this.controller}/GetAllWorkersByFilter`, JSON.stringify(filter)).toPromise();
  }

  getWorkerInfo(workerId): Promise<any> {
    return this.http.get(`${this.controller}/GetWorkerById/` + workerId).toPromise();
  }

  generatePIN(id): Promise<any> {
    return this.http.put(`${this.controller}/GeneratePIN/${id}`).toPromise();
  }

  getAllUserForRelatedWorkerByTenant(tenantId): Promise<any> {
    return this.http.get(`Users/GetAllUserForRelatedWorkerByTenant/${tenantId}`).toPromise();
  }

  updateWorker(data): Promise<any>{
    return this.http.put('Setting/UpdatePeople', JSON.stringify(data)).toPromise();
  }
}
