import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class TenantAppConfigService {

  controller = 'TenantApplicationConfiguration';

  constructor(private http: AuthHttp) { }

  add(tenantId: number, applicationId: number, serviceClassId: number): Promise<any> {
    return this.http.post(`${this.controller}/Add/${tenantId}/${applicationId}/${serviceClassId}`).toPromise();
  }

  remove(tenantId: number, applicationId: number, replaceApplicationId: number): Promise<any> {
    return this.http.delete(`${this.controller}/Remove/${tenantId}/${applicationId}/${replaceApplicationId}`).toPromise();
  }

  checkRemove(tenantId: number, applicationId: number): Promise<any> {
    return this.http.get(`${this.controller}/CheckRemove/${tenantId}/${applicationId}`).toPromise();
  }

  getUnassignApplications(tenantId: number, businessUnitId: number): Promise<any> {
    return this.http.get(`${this.controller}/GetUnassignApplications/${tenantId}/${businessUnitId}`).toPromise();
  }
}
