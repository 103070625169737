import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { TenantExecutionProcessConfigService } from '@app-admin/services/tenant-execution-process-config.service';
import { Config } from '@app-admin/config';
import { UserService } from '@app-admin/services/user.service';

@Component({
  selector: 'tab-scheduling-process',
  templateUrl: './scheduling-process.html',
  styleUrls: ['./scheduling-process.scss'],
})

export class SchedulingProcessComponent implements OnInit {
  @Input() tenantId: number;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  @ViewChild('modalConfirm2') modalConfirm2: BsModalComponent;
  loading = false;
  data: any[] = [];
  // unassignData: any[] = [];
  dataFilter: any[] = [];
  dataForm: FormGroup;
  id: number = 0;
  replaceId: number = 0;
  dataReplace: any[] = []

  // FormGroup
  formAdd: FormGroup;
  processTypes: any[] = [
    { processTypeId: Config.WorkOrderTypeId.Fab, processType: 'Fabrication' },
    { processTypeId: Config.WorkOrderTypeId.Install, processType: 'Install' },
    { processTypeId: Config.WorkOrderTypeId.Measure, processType: 'Measure' },
  ];

  constructor(private fb: FormBuilder
    , private toast: ToastrService
    , private tenantExecutionProcessConfigService: TenantExecutionProcessConfigService
    , private userService: UserService) {
  }

  ngOnInit() {
    this.loadData();

    this.formAdd = this.fb.group({
      processName: ['', Validators.required],
      processTypeId: [Config.WorkOrderTypeId.Fab],
      businessUnitId: [Config.BusinessUnitId.Slab],
      tenantId: [this.tenantId],
    });
  }

  get processName(): FormControl {
    return this.formAdd.get('processName') as FormControl;
  }

  getErrorProcessName() {
    return this.processName.hasError('required') ? 'Category name cannot be empty' : '';
  }

  get processTypeId(): FormControl {
    return this.formAdd.get('processTypeId') as FormControl;
  }

  private loadData(): void {
    this.loading = true;
    this.tenantExecutionProcessConfigService.getAssignData(this.tenantId, Config.BusinessUnitId.Slab).then(response => {
      this.loading = false;
      if (!response.status) {
        this.toast.error('Load data failed');
        return;
      }
      this.data = response.data;
      this.filterData();
      this.calcHeightTable.emit();
    });

    // this.tenantExecutionProcessConfigService.getUnassignData(this.tenantId).then(res => {
    //   if (!res.status) {
    //     this.toast.error(res.message);
    //     return;
    //   }
    //   this.unassignData = res.data;
    // });
  }

  private filterData(): void {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  private createItemView(e): FormGroup {
    return this.fb.group({
      id: e.id,
      processName: [{ value: e.processName, disabled: !e.isConfigurable }, Validators.required],
      processTypeId: [{ value: e.processTypeId, disabled: !e.isConfigurable }],
      isComplete: [{ value: e.isComplete, disabled: !e.isConfigurable }],
      updatedAt: e.updatedAt,
      updatedBy: e.updatedBy,
      isConfigurable: e.isConfigurable,
    });
  }

  private createItemUpdate(e): any {
    let item = this.data.find(i => i.id == e.id);
    item.processName = e.processName;
    return {
      id: e.id,
      processName: e.processName,
      processTypeId: e.processTypeId,
      isComplete: e.isComplete,
      tenantId: this.tenantId,
    };
  }

  save(): void {
    let dataUpdate = [];
    let cellUpdate: any;
    let isValid = true;
    let duplicate = false;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      const rawValue = (e as FormGroup).getRawValue();
      if (e.dirty) {
        cellUpdate = e;
        dataUpdate.push(this.createItemUpdate(rawValue));

        e.markAsPristine();
        e.markAsUntouched();
        duplicate = !!(e.get('processName') as FormControl).errors;
      }
      if (rawValue.processName == null || rawValue.processName.trim() == "" || rawValue.processName.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Category Name cannot be empty');
        }
        isValid = false;
      }

    });
    if (isValid && duplicate) {
      this.toast.error('This Category already exists. System doesn\'t support duplicate categories. Make appropriate changes and retry ');
      isValid = false;
    }

    if (isValid && dataUpdate.length > 0) {
      this.tenantExecutionProcessConfigService.updateData(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Category updated');
          this.loadData();
          if (dataUpdate.length > 0) {
            this.reloadStatusBatchVersions.emit();
          }

          // Update UpdateAt
          cellUpdate?.get('updatedAt').setValue(response.data);
          cellUpdate?.get('updatedBy').setValue(this.userService.getCurrentFullName());
        }
        else {
          this.toast.error(response.message);
        }
      });
    }
  }

  add(): void {
    this.loading = true;
    this.tenantExecutionProcessConfigService.add(this.formAdd.getRawValue()).then(res => {
      this.loading = false;
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.toast.success('Category added.');
      this.loadData();
    })
  }

  remove(id: number): void {
    if (!id) return;
    this.id = id;
    this.tenantExecutionProcessConfigService.checkRemove(this.tenantId, this.id).then(res => {
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      if (res.data) {
        let existed = this.data.find(x => x.id == this.id);
        if (!existed) return;
        this.dataReplace = this.data.filter(x => x.id != this.id && x.processTypeId == existed.processTypeId);
        if (this.dataReplace.length <= 0) {
          this.toast.error('Please create another schedule category with the same process type to reassign.');
          return;
        }
        this.modalConfirm.open();
      } else {
        this.modalConfirm2.open();
        // this.submitRemove(false);
      }
    });
  }

  submitRemove(allowReplace: boolean): void {
    if (allowReplace && this.replaceId <= 0) return;
    if (this.id == this.replaceId) return;

    this.modalConfirm.close();
    this.modalConfirm2.close();
    this.loading = true;
    this.tenantExecutionProcessConfigService.remove(this.id, this.replaceId).then(res => {
      this.loading = false;
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.toast.success('Category removed.');
      this.loadData();
      this.id = 0;
      this.replaceId = 0;
    });
  }

  checkDuplicate(e) {
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(m => {
      const rawValue = (m as FormGroup).getRawValue();
      if (e.id.value != rawValue.id
        && e.processName.value.trim().toUpperCase() == rawValue.processName.trim().toUpperCase()) {
        e.processName.setErrors({ 'hasDup': true });
      }
    });
  }

  drop(event) {
    if (event.previousIndex != event.currentIndex) {
      this.loading = true;

      let previousRow = this.data[event.previousIndex];
      let currentRow = this.data[event.currentIndex];

      if (!previousRow?.isConfigurable || !currentRow?.isConfigurable) {
        this.toast.warning('Your selected row is not allow to move.');
        this.loadData();
        return;
      }

      let id = previousRow?.id ?? 0
      let sortNumber = currentRow?.sortNumber ?? 0
      if (id == 0 || sortNumber == 0) return;

      this.tenantExecutionProcessConfigService.sortData(id, sortNumber, Config.BusinessUnitId.Slab).then(res => {
        if (!res.status) {
          this.toast.error('Sort failed');
          return;
        }
        this.loadData();
      });
    }
  }
}
