import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { SettingService } from '../../../../../../services/setting.service';
import { UserService } from '../../../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tab-tile-site-access-difficulty',
  templateUrl: './site-access-difficulty.html',
})

export class TileSiteAccessDifficultyComponent implements OnInit, OnDestroy {
  @Input() costSchemaId: any;
  siteAccesses: any[];
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @ViewChild('modalViewComments') modalViewComments: any;
  notes: any[];
  siteAccessId: any;
  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;

  constructor(private fb: FormBuilder, private toast: ToastrService, private settingService: SettingService, private userService: UserService) {
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array([])
    });
  }

  ngOnInit() {
    this.getSiteAccess();
  }
  getSiteAccess() {
    this.settingService.getSiteAccess().then((response) => {
      if (response.status) {
        this.siteAccesses = response.data;
        this.siteAccessId = this.siteAccesses[0].siteAccessId;
        this.loadData();
      }
      else this.toast.error("Can't get list site access");
    });
  }
  ngOnDestroy(){
    this.save();
  }
  viewComment(item){
    const id = item.value.id;
    this.settingService.getMtxNoteSiteAccessDifficultyById(id).then(res=> {
      if(res.status){
        this.notes = res.data;
        // this.viewComments.open();
        this.modalViewComments.open();
      }
      else this.toast.error('view comment failed.');
    });
  }
  loadData() {
    this.loading = true;
    this.settingService.getSiteAccessDifficulty(this.costSchemaId).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error("Load data failed");
    });
  }

  filterData() {
    this.dataFilter = this.data.filter(e => e.siteAccessId == this.siteAccessId);
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
    if (this.userService.isTenantOnly()) this.dataForm.disable();
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      id: e.id,
      processName: e.processName,
      // percentAdjustment: new PercentPipe('en-US').transform(e.percentAdjustment, '.2-2'),
      percentAdjustment: e.percentAdjustment * 100,
      note: e.note,
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.id == e.id);
    item.percentAdjustment = this.settingService.replaceMask(e.percentAdjustment) / 100;
    item.note = e.note;
    return {
      id: e.id,
      percentAdjustment: this.settingService.replaceMask(e.percentAdjustment) / 100,
      note: e.note,
    };
  }

  save() {
    let dataUpdate = [];
    let cellUpdate: any;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        cellUpdate = e;
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (dataUpdate.length > 0) {
      this.settingService.updateSiteAccessDifficulty(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Site access difficulty has been updated');
          cellUpdate?.get('updatedOn').setValue(response.data);
          cellUpdate?.get('updatedBy').setValue(this.userService.getCurrentFullName());
        }
        else this.toast.error('Update site access difficulty failed');
      });
    }
  }
}
