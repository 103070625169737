import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CommonPricingComponent } from '../../common-pricing';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from '../../../../services/setting.service';
import { UserService } from '../../../../services/user.service';
import { Config } from '@app-admin/config';

@Component({
  templateUrl: './tile-tenant-specific-configuration.html'
})

export class TileTenantSpecificConfigurationComponent extends CommonPricingComponent implements OnInit {
  channels: any;
  processes: any;
  unitsOfMeasure: any;

  constructor(fb: FormBuilder, toast: ToastrService, settingService: SettingService,
    userService: UserService, cdr: ChangeDetectorRef) {
    super(fb, toast, settingService, userService, cdr);
  }

  ngOnInit() {
    this.tabs = [
      { id: 'tenantCostSchemas', text: 'Tile Cost Schemas' },
      { id: 'scheduling-process', text: 'Scheduling Process' },
      { id: 'baseProcess', text: 'Base Processes' },
      { id: 'application', text: 'Applications' },
      { id: 'serviceClass', text: 'Service Class' },
      { id: 'tileTypes', text: "Tile Types" },
      { id: 'thickness', text: "Thickness" },
      { id: 'tileSizes', text: "Tile Sizes" },
      { id: 'tileSets', text: "Tile Sets" },
      { id: 'specialConditions', text: "Special Conditions" },
      { id: 'trimTypes', text: "Trim Types" },
      { id: 'trimEdges', text: "Trim Edges" },
      { id: 'generalRates', text: 'General Rates' },
      { id: 'changeLog', text: 'Change Log' },
    ];
    this.curTab = this.tabs[0];

    this.settingService.getDefaultChannels(-1, 0, 0).then(response => {
      if (response.status) this.channels = response.data;
      else this.toast.error('Can\'t get list channel');
    });

    this.settingService.getAllUnitsOfMeasure().then(response => {
      if (response.status) this.unitsOfMeasure = response.data;
      else this.toast.error('Can\'t get list units of measure');
    });

    super.ngOnInit(Config.BusinessUnitId.Tile);
  }
}
