<div class="row">
  <div class="col-xs-12">
    <h4 class="text-bold" style="margin-bottom: 15px">Onsite Polishing</h4>
  </div>
  <div class="col-xs-12">
    <div class="form-group">
      <label>Instructions:</label>
      <p>It's been determined that some material needs to be polished or re-polished onsite regardless of whether polishing has been selected. Some material types may require more polishing than others. The formulas determine the quantity of the surface area and then use the Onsite Polishing process rate to determine labor cost and time to complete.</p>
    </div>
  </div>
  <div class="col-xs-12">
    <a (click) ="clearWeights()" class="btn btn-primary pull-right" >Clear Weights</a>
  </div>
  <div class="col-xs-12">
    <h5 class="text-center" style="text-decoration: underline; font-weight: bold">Percent of Line Item Area</h5>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Material Group</th>
              <th [style.width.px]="120">Percentage</th>
              <th>Last Comment</th>
              <th>Last Updated</th>
              <th>Updated By</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>{{item.value.materialGroup}}</td>
                  <td [style.width.px]="120">
                    <qxm-input-percent
                      [myFormGroup]="dataForm"
                      myFormArrayName="dataUpdate"
                      [myFormGroupName]="i"
                      myFormControlName="percentOfPolishOnsite"
                      (acceptChange)="save();">
                    </qxm-input-percent>
                  </td>
                  <td>
                    <input #note type="text" class="form-control" formControlName="note" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td>{{item.value.updatedBy}}<span><a (click) ="viewComment(item)" class="btn btn-primary pull-right" >View Comments</a></span></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
<app-view-comment #modalViewComments [notes] = 'notes' [tab] = 'currentTab' [parentId] = 'currentId'></app-view-comment>
