import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { SettingService } from '../../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'tab-tile-global-trim-edges',
  templateUrl: './global-trim-edges.html',
})

export class TileTrimEdgesGlobalComponent implements OnInit {
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;

  // Form
  trimEdgeName: string;
  linInchesPerHour: number;

  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  trimEdgeId: number;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.settingService.getTileTrimEdges(0).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      trimEdgeId: e.trimEdgeId,
      trimEdgeName: [e.trimEdgeName, Validators.required],
      active: [{value: e.active, disabled: false}],
      isRemove: e.isRemove,
      updatedOn: e.updatedOn,
      linInchesPerHour: e.linInchesPerHour,
    });
  }

  validData() {
    if (this.trimEdgeName == null || this.trimEdgeName == '' || this.trimEdgeName.length < 2) {
      this.toast.error('Trim Edge name needs to be greater than 2 characters');
      return false;
    }
    return true;
  }

  add() {
    if (this.validData()) {
      this.loading = true;
      this.settingService.addTileTrimEdges({
        tenantId: 0,
        trimEdgeName: this.trimEdgeName,
        linInchesPerHour: this.linInchesPerHour || 0,
      }).then(response => {
        if (response.status) {
          this.trimEdgeName = '';
          this.toast.success('New Trim Edge has been added');
          this.loadData();
        }
        else {
          this.loading = false;
          this.toast.error('The Trim Edge is added fail');
        }
      });
    }
  }

  delete(trimEdgeId) {
    this.modalConfirm.open();
    this.trimEdgeId = trimEdgeId;
  }

  submitDelete() {
    this.loading = true;
    this.modalConfirm.close();
    this.settingService.deleteTileTrimEdges(this.trimEdgeId).then(response => {
      if (response.status) {
        this.toast.success('This Trim Edge has been removed');
        this.loading = true;
        this.loadData();
        // this.settingService.setBidsIsRefesh(this.tenantId);
      }
      else
        this.toast.error(response.message);
      this.loading = false;
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.trimEdgeId == e.trimEdgeId);
    item.trimEdgeName = e.trimEdgeName;
    item.active = e.active;
    return {
      trimEdgeId: e.trimEdgeId,
      trimEdgeName: e.trimEdgeName,
      active: e.active,
      isGlobal: true,
      linInchesPerHour: e.linInchesPerHour,
    };
  }

  save() {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
      if (e.value.trimEdgeName == null || e.value.trimEdgeName.trim() == "" || e.value.trimEdgeName.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Trim Edge name needs to be greater than 2 characters');
        }
        isValid = false;
      }
    });
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateTileTrimEdges(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Trim Edge has been updated');
        }
        else this.toast.error('Update Trim Edge failed');
      });
    }
  }
}
