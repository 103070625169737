<div class="content-wrapper">
  <section class="content-header">
    <h1>Global</h1>
    <ol class="breadcrumb">
      <li style="cursor: pointer;"><a routerLink="/dashboards/global"><i class="fa fa-dashboard"></i> Dashboard</a></li>
      <li style="cursor: pointer;"><a routerLink="/dashboards/global"><i class="fa fa-dashboard"></i> Global</a></li>
      <li class="active">{{ dashboardDetail?.dashboardName }}</li>
    </ol>
  </section>

  <section class="content" *ngIf="dashboardDetail">
    <div class="nav-tabs-custom">
      <ul class="nav nav-tabs">
        <li class="active"><a href="#detail" data-toggle="tab">Detail</a></li>
        <li><a href="#configuration" data-toggle="tab">Configuration</a></li>
        <li><a href="#tenants" data-toggle="tab">Tenants</a></li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane box active" id="detail">
          <form [formGroup]="updateDetailForm" novalidate>
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-xs-12">
                <div class="form-group"
                  [class.has-error]="dashboardName.invalid && (dashboardName.dirty || dashboardName.touched)">
                  <label>Dashboard Name <span class="text-danger">*</span></label>
                  <input type="text" trim="blur" class="form-control" formControlName="dashboardName" maxlength="50"
                    required>
                  <ng-container *ngIf="dashboardName.invalid && (dashboardName.dirty || dashboardName.touched)">
                    <span *ngIf="dashboardName.errors.required" class="help-block">Dashboard Name is required.</span>
                  </ng-container>
                </div>
                <div class="form-group">
                  <label>Description</label>
                  <input type="text" trim="blur" class="form-control" formControlName="dashboardDescription" maxlength="100">
                </div>
                <div class="form-group"
                  [class.has-error]="dashboardCategoryId.invalid && (dashboardCategoryId.dirty || dashboardCategoryId.touched)">
                  <label>Dashboard Category <span class="text-danger">*</span></label>
                  <select class="form-control" formControlName="dashboardCategoryId" required>
                    <option *ngFor="let t of dashboardCategories" [ngValue]="t.id">{{t.name}}</option>
                  </select>
                  <ng-container *ngIf="dashboardCategoryId.invalid && (dashboardCategoryId.dirty || dashboardCategoryId.touched)">
                    <span *ngIf="dashboardCategoryId.errors.required" class="help-block">Category is required.</span>
                  </ng-container>
                </div>
                <div class="form-group">
                  <label>BI Connection</label>
                  <input type="text" class="form-control" formControlName="biConnectionName" readonly>
                </div>
                <div class="form-group">
                  <label>Image</label>
                  <input #imageInput
                    type="file"
                    accept="image/*"
                    (change)="processFile(imageInput)">
                </div>
              </div>
              <div class="col-xs-12">
                <div class="form-group">
                  <button type="button" class="btn btn-primary" [disabled]="updateDetailForm.invalid || showLoading"
                    (click)="save()">Save</button>
                  <a type="button" class="btn btn-default" style="margin-left: 5px;" [disabled]="showLoading"
                    routerLink="/dahsboards/global">Cancel</a>
                  <i *ngIf="showLoading" class="fa fa-spinner fa-spin fa-lg" style="margin-right: 15px;"></i>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="tab-pane box" id="configuration">

          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="heading_connection">
              <h4 class="panel-title">
                <a class="collapsed" role="button" data-toggle="collapse"
                  href="#collapse_connection" aria-expanded="false" aria-controls="collapse_connection">
                  Connection
                </a>
              </h4>
            </div>
            <div id="collapse_connection" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="heading_connection">
              <div class="panel-body">
                <form [formGroup]="updateConnectionForm" novalidate>
                  <div class="row">
                    <div class="col-lg-4 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label>BI Connection</label>
                        <input type="text" class="form-control" formControlName="biConnectionName" readonly>
                      </div>

                      <!-- If Wyn -->
                      <div class="form-group" [class.has-error]="dashboardObjectName.invalid && (dashboardObjectName.dirty || dashboardObjectName.touched)">
                        <label>Source Dashboard
                          <!-- <span class="text-danger">*</span> -->
                        </label>
                        <select formControlName="dashboardObjectName" class="form-control" (change)="setSourceDashboard()">
                          <ng-container *ngFor="let t of wynDashboards">
                            <option [ngValue]="t.id">{{t.title}}</option>
                          </ng-container>
                        </select>
                        <!-- <span *ngIf="dashboardObjectName.invalid && (dashboardObjectName.dirty || dashboardObjectName.touched)" class="help-block">{{getErrorDashboardObjectName()}}</span> -->
                      </div>

                      <!-- If DOMO -->
                      <div class="form-group">
                        <label>Embed Code
                          <!-- <span class="text-danger">*</span> -->
                        </label>
                        <input type="text" class="form-control" formControlName="embedCode">
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="heading_parameters">
              <h4 class="panel-title">
                <a class="collapsed" role="button" data-toggle="collapse"
                  href="#collapse_parameters" aria-expanded="false" aria-controls="collapse_parameters">
                  Parameters
                </a>
              </h4>
            </div>
            <div id="collapse_parameters" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="heading_parameters">
              <div class="panel-body">
                <form [formGroup]="updateParamsForm" novalidate>
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" formControlName="parameterTenantId" required> TenantId <span class="text-danger">* (Will be required on all dashboards)</span>
                    </label>
                  </div>
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" formControlName="parameterUserId"> UserId
                    </label>
                  </div>
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" formControlName="parameterSelectorUserId"> SelectorUserId
                    </label>
                  </div>
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" formControlName="parameterAccountId"> AccountId (Implement later)
                    </label>
                  </div>
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" formControlName="parameterProjectId"> ProjectId (Implement later)
                    </label>
                  </div>
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" formControlName="parameterBidId"> BidId (Implement later)
                    </label>
                  </div>
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" formControlName="parameterContractId"> ContractId (Implement later)
                    </label>
                  </div>
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" formControlName="parameterCommunityId"> CommunityId (Implement later)
                    </label>
                  </div>
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" formControlName="parameterQuoteId"> QuoteId (Implement later)
                    </label>
                  </div>
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" formControlName="parameterPriceSchemaId"> PriceSchemaId (Implement later)
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <!-- <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="heading_security">
              <h4 class="panel-title">
                <a class="collapsed" role="button" data-toggle="collapse"
                  href="#collapse_security" aria-expanded="false" aria-controls="collapse_security">
                  Security
                </a>
              </h4>
            </div>
            <div id="collapse_security" class="panel-collapse collapse" role="tabpanel"
              aria-labelledby="heading_security">
              <div class="panel-body">
                TBD
              </div>
            </div>
          </div> -->

        </div>
        <div class="tab-pane box" id="tenants">
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th [style.width.px]="40" class="text-center">#</th>
                  <th>Tenant Name</th>
                  <th>Company Name</th>
                  <th>Updated On</th>
                  <th>Updated By</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of tenants; let index = index">
                  <td [style.width.px]="40">{{ index + 1 }}</td>
                  <td>{{ item.tenantName }}</td>
                  <td>{{ item.companyName }}</td>
                  <td>{{ item.updatedOn | date:'MM/dd/yyyy h:mm a' }}</td>
                  <td>{{ item.updatedBy }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
