import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'qxm-input-percent-ngmodel',
  templateUrl: './qxm-input-percent-ngmodel.html',
  // styleUrls: ['./qxm-input-percent-ngmodel.scss']
})

export class QxmInputPercentNgmodelComponent implements OnInit {

  @Input() classNames: string = 'form-control text-right';
  // TODO: Update Angular and ngx-mask to make percent.2 work
  // @Input() mask: string = 'percent.2';
  @Input() mask: string = 'separator.2';
  @Input() suffix: string = '%';
  @Input() allowNegative: boolean = true;

  // 2 ways binding
  @Input() model: number | string;
  @Output() modelChange: EventEmitter<number | string> = new EventEmitter<number | string>();

  // Optional
  @Input() placeholder: string = '';
  @Input() required: boolean = false;

  @Output() acceptChange = new EventEmitter<any>();
  @ViewChild('txt') txt: ElementRef;

  constructor() { }

  ngOnInit() { }

  private acceptEdit(): void {
    this.acceptEditSuccess();
  }

  private acceptEditSuccess(): void {
    this.modelChange.emit(this.model)
    this.acceptChange?.emit();
  }

  onInputAcceptEdit(): void {
    this.acceptEdit();
  }
}
