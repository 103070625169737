import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { SettingService } from '../../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { TenantTileThicknessConfigService } from '@app-admin/services/tenant-tile-thickness-config.service';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'tab-tile-thickness',
  templateUrl: './thickness.html'
})

export class TileThicknessComponent implements OnInit {
  @Input() tenantId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;

  loading = false;
  data: any[];
  unassignData: any[] = [];
  dataFilter: any[];
  dataForm: FormGroup;
  thicknessId: number = 0;
  replaceId: number = 0;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService
    , private tenantTileThicknessConfigService: TenantTileThicknessConfigService) {
  }

  ngOnInit() {
    this.loadData();
  }

  private loadData(): void {
    this.loading = true;
    this.settingService.getTenantTileThicknesses(this.tenantId).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });

    this.tenantTileThicknessConfigService.getUnassignThicknesses(this.tenantId).then(res => {
      if (!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.unassignData = res.data;
    });
  }

  private filterData(): void {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  private createItemView(e): FormGroup {
    return this.fb.group({
      id: e.id,
      thicknessId: e.thicknessId,
      thickness: [e.thickness, Validators.required],
      active:[e.active],
      isRemove: e.isRemove,
      customNameAlias: e.customNameAlias,
      updatedAt: e.updatedAt,
      updatedBy: e.updatedBy,
      orderIndex: e.orderIndex,
    });
  }

  private createItemUpdate(e): any {
    let item = this.data.find(i => i.id == e.id);
    item.thickness = e.thickness;
    item.active = e.active;
    item.orderIndex = e.orderIndex;
    return {
      id: e.id,
      customNameAlias: e.customNameAlias,
      thickness: e.thickness,
      active: e.active,
      isGlobal: false,
      tenantId: this.tenantId,
      orderIndex: e.orderIndex,
    };
  }

  save(): void {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
      if (e.value.customNameAlias == null || e.value.customNameAlias.trim() == "" || e.value.customNameAlias.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Thickness name needs to be greater than 2 characters');
        }
        isValid = false;
      }
    });
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateTenantTileThickness(dataUpdate[0]).then(response => {
        if (response.status) {
          this.toast.success('Thickness has been updated');
          // this.loadData();
          // TODO: Update just UpdatedAt, UpdatedBy
        }
        else this.toast.error('Update Thickness failed');
      });
    }
  }

  add(): void {
    this.loading = true;
    this.tenantTileThicknessConfigService.add(this.tenantId, this.thicknessId).then(res => {
      this.loading = false;
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.toast.success('Thickness added.');
      this.loadData();
      this.thicknessId = 0;
    })
  }

  remove(thicknessId: number): void {
    if (!thicknessId) return;
    this.thicknessId = thicknessId;
    this.tenantTileThicknessConfigService.checkRemove(this.tenantId, this.thicknessId).then(res => {
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      if (res.data) {
        this.modalConfirm.open();
      } else {
        this.submitRemove(false);
      }
    });
  }

  submitRemove(allowReplace: boolean): void {
    if (allowReplace && this.replaceId <= 0) return;

    this.modalConfirm.close();
    this.loading = true;
    this.tenantTileThicknessConfigService.remove(this.tenantId, this.thicknessId, this.replaceId).then(res => {
      this.loading = false;
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.toast.success('Thickness removed.');
      this.loadData();
      this.thicknessId = 0;
      this.replaceId = 0;
    });
  }
}
