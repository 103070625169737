import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TileTenantChannelsService } from '../tenant-channels.service';
import { Subscription } from 'rxjs';
import { TileCurrentRow } from '../current-row.model';
import { TileCostSchemaAssignmentService } from '@app-admin/services/tile-cost-schema-assignment.service';
import { Config } from '@app-admin/config';
import { UserService } from '@app-admin/services/user.service';

@Component({
  selector: 'tile-assigned-cost-schemas-modal',
  templateUrl: './assigned-cost-schemas-modal.html'
})

export class TileAssignedCostSchemasModalComponent implements OnInit, OnDestroy {

  assignCostSchemaForm: FormGroup;
  unassignedCostSchemas: any[];

  currentRow: TileCurrentRow;
  subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    private costSchemaAssignmentService: TileCostSchemaAssignmentService,
    private tenantChannelsService: TileTenantChannelsService,
    private userService: UserService,
  ) {
    this.assignCostSchemaForm = this.fb.group({
      channelId: [null, Validators.required],
      channelName: [{ value: '', disabled: true }, Validators.required],
      tenantId: [null, Validators.required],
      costSchemaIds: [null, Validators.required],
    });

    this.subscription = this.tenantChannelsService.getMessages().subscribe(data => {
      if (!data) {
        return;
      }

      switch (data.messageType) {
        case TileTenantChannelsService.MessageTypes.SEND_TO_MODAL:
          const payload = data.payload;
          this.currentRow = payload.currentRow;
          this.unassignedCostSchemas = payload.unassignedCostSchemas;

          // Form data
          this.assignCostSchemaForm.patchValue({
            channelId: this.currentRow.channelId,
            channelName: this.currentRow.channelNameAlias,
            tenantId: this.currentRow.tenantId,
          });
          break;
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  save() {
    const userName = this.userService.getCurrentFullName();
    const costSchemaIds: number[] = this.assignCostSchemaForm.get('costSchemaIds').value;
    for (let costSchemaId of costSchemaIds) {
      let cs = this.unassignedCostSchemas.find(x => x.schemaId == costSchemaId);
      this.tenantChannelsService.arrAdd.push({
        tenantId: this.currentRow.tenantId,
        channelId: this.currentRow.channelId,
        assignedOn: new Date(),
        description: '',
        id: 0,
        isDefault: false,
        schemaId: costSchemaId,
        schemaName: cs.schemaName,
        schemaNameAlias: cs.schemaNameAlias,
        updatedBy: userName,
        updatedOn: new Date(),
        businessUnit: 'Slab',
        businessUnitId: Config.BusinessUnitId.Slab,
      });
    }

    // this.costSchemaAssignmentService.assignCostSchemasToChannel(this.assignCostSchemaForm.value).then(response => {
    //   if (response.status) {
    //     // Reload parent component
    //     this.tenantChannelsService.sendMessage(
    //       TenantChannelsService.MessageTypes.RELOAD_PARENT_COMP
    //     );
    //   } else {
    //     this.toast.error(response.message);
    //   }
    // });
  }
}
