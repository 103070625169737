<div class="row">
  <div class="col-12">
    <label style="font-size: medium; margin-left: 10px">
      <span>
        <a
          (click)="change('ApplicationWaste')"
          [ngClass]="{'sub-menu-hight': currentTab == 'ApplicationWaste', 'sub-menu-txt': currentTab != 'ApplicationWaste'}"
          >Application Waste
        </a>
      </span>
      |
      <span>
        <a
          (click)="change('MaterialWaste')"
          [ngClass]="{'sub-menu-hight': currentTab == 'MaterialWaste', 'sub-menu-txt': currentTab != 'MaterialWaste'}"
          >Material Waste
        </a>
      </span>
      |
      <span>
        <a
          (click)="change('DirectionalityWaste')"
          [ngClass]="{'sub-menu-hight': currentTab == 'DirectionalityWaste', 'sub-menu-txt': currentTab != 'DirectionalityWaste'}"
          >Directionality Waste
        </a>
      </span>
      |
      <span>
        <a
          (click)="change('VeinMatchingWaste')"
          [ngClass]="{'sub-menu-hight': currentTab == 'VeinMatchingWaste', 'sub-menu-txt': currentTab != 'VeinMatchingWaste'}"
          >Vein Matching Waste
        </a>
      </span>
      |
      <span>
        <a
          (click)="change('LayoutApprovalWaste')"
          [ngClass]="{'sub-menu-hight': currentTab == 'LayoutApprovalWaste', 'sub-menu-txt': currentTab != 'LayoutApprovalWaste'}"
          >Layout Approval Waste
        </a>
      </span>
    </label>
  </div>
  <!-- <div class="col-md-9 col-sm-8 col-xs-12">
    <div class="form-group">
      <label>Instructions:</label>
      <p>
        Assign Waste Percentage adjustments for each application. A positive
        waste percentage will increase the base level waste percentage defined
        for material type. A negative will decrease the base level waste
        percentage. There may be many other factors impacting waste, so it's
        important to think in relative terms. Be sure base waste percentage for
        each material type has been set.
      </p>
    </div>
  </div> -->

  <material-waste *ngIf="currentTab == 'MaterialWaste'" [costSchemaId]="costSchemaId" [calcHeightTable]="calcHeightTable"></material-waste>
  <application-waste *ngIf="currentTab == 'ApplicationWaste'" [costSchemaId]="costSchemaId" [calcHeightTable]="calcHeightTable"></application-waste>
  <directionality-waste *ngIf="currentTab == 'DirectionalityWaste'" [costSchemaId]="costSchemaId" [calcHeightTable]="calcHeightTable"></directionality-waste>
  <vein-matching-waste *ngIf="currentTab == 'VeinMatchingWaste'" [costSchemaId]="costSchemaId" [calcHeightTable]="calcHeightTable"></vein-matching-waste>
  <layout-approval-waste *ngIf="currentTab == 'LayoutApprovalWaste'" [costSchemaId]="costSchemaId" [calcHeightTable]="calcHeightTable"></layout-approval-waste>

</div>
