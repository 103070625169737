import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tab-global-scheduling-process-categories',
  templateUrl: './global-scheduling-process-categories.html',
  styleUrls: ['./global-scheduling-process-categories.scss'],
})
export class SchedulingProcessCategoriesGlobalComponent implements OnInit {

  @Output() calcHeightTable = new EventEmitter<any>();

  currentTab = 'Fabrication';

  constructor() {
  }

  ngOnInit() {
  }

  change(tabStr: string) {
    if (this.currentTab == tabStr) return;
    this.currentTab = tabStr;
  }
}
