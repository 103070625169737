import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class TenantTrimEdgeConfigService {

  controller = 'TenantTrimEdgeConfiguration';

  constructor(private http: AuthHttp) { }

  add(tenantId: number, trimEdgeId: number): Promise<any> {
    return this.http.post(`${this.controller}/Add/${tenantId}/${trimEdgeId}`).toPromise();
  }

  remove(tenantId: number, trimEdgeId: number, replaceId: number): Promise<any> {
    return this.http.delete(`${this.controller}/Remove/${tenantId}/${trimEdgeId}/${replaceId}`).toPromise();
  }

  checkRemove(tenantId: number, trimEdgeId: number): Promise<any> {
    return this.http.get(`${this.controller}/CheckRemove/${tenantId}/${trimEdgeId}`).toPromise();
  }

  getUnassignTrimEdges(tenantId: number): Promise<any> {
    return this.http.get(`${this.controller}/GetUnassignTrimEdges/${tenantId}`).toPromise();
  }
}
