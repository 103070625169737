import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { SettingService } from '../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { TenantMaterialConfigService } from '@app-admin/services/tenant-material-config.service';
import { Config } from '@app-admin/config';
import { TenantsService } from '@app-admin/services/tenants.service';

@Component({
  selector: 'tab-material-types',
  templateUrl: './material-types.html',
  styleUrls: ['./material-types.scss']
})

export class MaterialTypesComponent implements OnInit {
  @Input() tenantId: number;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
    @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  @ViewChild('modalConfirm2') modalConfirm2: BsModalComponent;
  // materialType: any;
  // baseWaste: any;
  // typeAbbreviationAlias: any;
  loading = false;
  data: any[] = [];
  unassignData: any[] = [];
  dataFilter: any[] = [];
  dataForm: FormGroup;
  materialTypeId: number = 0;
  replaceId: number = 0;
  serviceClass : any[] = [];
  serviceClassId :any;
  serviceClassMode: number = 0;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService, public tenantService: TenantsService
    , private tenantMaterialConfigService: TenantMaterialConfigService) {

  }

  ngOnInit() {
    // this.baseWaste = new PercentPipe('en-US').transform(0, '.2-2');
    this.loadData();
  }

  private loadData(): void {
    this.loading = true;

    Promise.all([
      this.settingService.getMaterialTypes(this.tenantId),
      this.settingService.getServiceClassByServiceType(this.tenantId, Config.BusinessUnitId.Slab),
      this.tenantService.getTenantDetails(this.tenantId),
    ]).then(res => {
      this.loading = false;
      if (!res[0].status || !res[1].status) {
        this.toast.error('Load data failed');
        return;
      }
      this.data = res[0].data;
      this.serviceClass = res[1].data;
      this.serviceClassMode = res[2].data.serviceClassMode;
      this.filterData();
      this.calcHeightTable.emit();
    });

    this.tenantMaterialConfigService.getUnassignMaterialTypes(this.tenantId).then(res => {
      if (!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.unassignData = res.data;
    });
  }

  private filterData(): void {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  private createItemView(e): FormGroup {
    return this.fb.group({
      materialTypeId: e.materialTypeId,
      materialGroup: [e.materialGroup, Validators.required],
      typeAbbreviationAlias: [e.typeAbbreviationAlias, Validators.required],
      active:[e.active],
      serviceClassId: e.serviceClassId,
      isRemove: e.isRemove,
      alias: e.alias,
     // baseWastePercent: new PercentPipe('en-US').transform(e.baseWastePercent, '.2-2'),
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
    });
  }

  // validData() {
  //   if (this.tenantId == 0) {
  //     this.toast.error('Please select a tenant');
  //     return false;
  //   }
  //   else if (this.materialType == null || this.materialType == '' || this.materialType.length < 2) {
  //     this.toast.error('Material type name needs to be greater than 2 characters');
  //     return false;
  //   }
  //   return true;
  // }


  // deleteMaterialTypes(materialTypeId) {
  //   this.modalConfirm.open();
  //   this.materialTypeId = materialTypeId;
  // }

  // submitDelete() {
  //   this.loading = true;
  //   this.modalConfirm.close();
  //   this.settingService.deleteMaterialTypes(this.materialTypeId).then(response => {
  //     if (response.status) {
  //       this.toast.success('Material type has been removed');
  //       this.loading = true;
  //       this.loadData();
  //       // this.settingService.setBidsIsRefesh(this.tenantId);
  //     }
  //     else
  //       this.toast.error(response.message);
  //     this.loading = false;
  //   });
  // }

  private createItemUpdate(e): any {
    let item = this.data.find(i => i.materialTypeId == e.materialTypeId);
    item.baseWastePercent = this.settingService.replaceMask(e.baseWastePercent) / 100;
    item.materialGroup = e.materialGroup;
    item.typeAbbreviationAlias = e.typeAbbreviationAlias;
    item.active = e.active;
    return {
      materialTypeId: e.materialTypeId,
      alias: e.alias,
      materialGroup: e.materialGroup,
      typeAbbreviationAlias: e.typeAbbreviationAlias,
      active: e.active,
      serviceClassId: e.serviceClassId,
      isGlobal: false,
      tenantId: this.tenantId
    };
  }

  save(): void {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
      if (e.value.materialGroup == null || e.value.materialGroup.trim() == "" || e.value.materialGroup.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Material type name needs to be greater than 2 characters');
        }
        isValid = false;
      }
    });
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateMaterialTypes(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Material type has been updated');
          this.loadData();
          if (dataUpdate.length > 0) {
                        // this.settingService.setBidsIsRefesh(this.tenantId);
                        this.reloadStatusBatchVersions.emit();
                    }
        }
        else this.toast.error('Update material type failed');
      });
    }
  }

  add(): void {
    this.loading = true;
    this.tenantMaterialConfigService.add(this.tenantId, this.materialTypeId).then(res => {
      this.loading = false;
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.toast.success('Material Type added.');
      this.loadData();
      this.materialTypeId = 0;
    })
  }

  remove(materialTypeId: number): void {
    if (!materialTypeId) return;
    this.materialTypeId = materialTypeId;
    this.tenantMaterialConfigService.checkRemove(this.tenantId, this.materialTypeId).then(res => {
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      if (res.data) {
        this.modalConfirm.open();
      } else {
        this.modalConfirm2.open();
        // this.submitRemove(false);
      }
    });
  }

  submitRemove(allowReplace: boolean): void {
    if (allowReplace && this.replaceId <= 0) return;

    this.modalConfirm.close();
    this.modalConfirm2.close();
    this.loading = true;
    this.tenantMaterialConfigService.remove(this.tenantId, this.materialTypeId, this.replaceId).then(res => {
      this.loading = false;
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.toast.success('Material Type removed.');
      this.loadData();
      this.materialTypeId = 0;
      this.replaceId = 0;
    });
  }
}
