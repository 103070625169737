import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { SettingService } from '../../../../services/setting.service';
import { UserService } from '../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { SlabCostDetailService } from '@app-admin/services/slab-cost-detail.service';

@Component({
    selector: 'tab-edge-base',
    templateUrl: './edge-base.html',
    styleUrls: ['./edge-base.scss']
})

export class EdgeBaseComponent implements OnInit, OnDestroy {
    @Input() costSchemaId: any;
    @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
    @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
    @ViewChild('modalViewComments') modalViewComments: any;
    notes: any;
    //channelId: any;
    loading = false;
    data: any;
    dataFilter: any;
    dataForm: FormGroup;
    currentTab = 'EdgeBase';
    currentId: number;

    constructor(private fb: FormBuilder, private toast: ToastrService, private settingService: SettingService, private userService: UserService,
        private slabCostDetailService: SlabCostDetailService) {

    }

    ngOnInit() {
        this.loadData();
    }
    ngOnDestroy() {
        this.save();
    }
    viewComment(item) {
        this.currentId = item.value.id;
        this.settingService.getMtxNoteEdgeBaseById(this.currentId).then(res => {
            if (res.status) {
                this.notes = res.data;
                // this.viewComments.open();
                this.modalViewComments.open();

                //Update last comment when delete comment
                this.slabCostDetailService.getMessages().subscribe(data => {
                    if (!data) {
                        return;
                    }

                    switch (data.messageType) {
                        case SlabCostDetailService.MessageTypes.RELOAD_PARENT_COMMENT + "_" + this.currentTab + "_" + this.currentId:
                            const payload = data.payload;
                            if (payload) {
                                item.controls.note.setValue(payload.note);
                                item.controls.updatedOn.setValue(payload.updateOn);
                            }
                            break;
                    }
                });
            }
            else this.toast.error('view comment failed.');
        });
    }
    loadData() {
        this.loading = true;
        this.settingService.getEdgeBase(this.costSchemaId).then(response => {
            if (response.status) {
                this.data = response.data;
                this.filterData();
                this.loading = false;
                this.calcHeightTable.emit();
            }
            else this.toast.error("Load data failed");
        });
    }

    filterData() {
        this.dataFilter = this.data;//.filter(e => e.channelId == this.channelId);
        let dataUpdate = [];
        this.dataFilter.forEach(e => {
            dataUpdate.push(this.createItemView(e));
        });
        this.dataForm = this.fb.group({
            dataUpdate: this.fb.array(dataUpdate)
        });
        if (this.userService.isTenantOnly()) this.dataForm.disable();
    }

    createItemView(e): FormGroup {
        return this.fb.group({
            id: e.id,
            edgeType: e.edgeType,
            baseOutputPerHour: new DecimalPipe('en-US').transform(e.baseOutputPerHour, '.2-2'),
            linInchesPerHour: new DecimalPipe('en-US').transform(e.linInchesPerHour, '.2-2'),
            note: e.note,
            updatedOn: e.updatedOn,
            updatedBy: e.updatedBy,
        });
    }

    createItemUpdate(e) {
        let item = this.data.find(i => i.id == e.id);
        item.baseOutputPerHour = this.settingService.replaceMask(e.baseOutputPerHour);
        item.linInchesPerHour = this.settingService.replaceMask(e.linInchesPerHour);
        item.note = e.note;
        return {
            schemaProcessId: e.id,
            baseOutputPerHour: this.settingService.replaceMask(e.baseOutputPerHour),
            linInchesPerHour: this.settingService.replaceMask(e.linInchesPerHour),
            note: e.note
        };
    }

    save() {
        let dataUpdate = [];
        let cellUpdate: any;
        (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
            if (e.dirty) {
                const baseOutputPerHour = e.get('baseOutputPerHour');
                const linInchesPerHour = e.get('linInchesPerHour');
                const note = e.get('note');
                cellUpdate = e;
                if (baseOutputPerHour.dirty) {
                    linInchesPerHour.setValue(baseOutputPerHour.value ? baseOutputPerHour.value * 12 : 0);
                    dataUpdate.push(this.createItemUpdate(e.value));
                    e.markAsPristine();
                    e.markAsUntouched();
                    return;
                }
                if (linInchesPerHour.dirty) {
                    baseOutputPerHour.setValue(linInchesPerHour.value ? linInchesPerHour.value / 12 : 0);
                    dataUpdate.push(this.createItemUpdate(e.value));
                    e.markAsPristine();
                    e.markAsUntouched();
                    return;
                }
                if (note.dirty) {
                    dataUpdate.push(this.createItemUpdate(e.value));
                    e.markAsPristine();
                    e.markAsUntouched();
                    return;
                }
            }
        });
        if (dataUpdate.length > 0) {
            this.settingService.updateEdgeBase(dataUpdate).then(response => {
                if (response.status) {
                    this.toast.success('Edge base has been updated');
                    cellUpdate?.get('updatedOn').setValue(response.data);
                    cellUpdate?.get('updatedBy').setValue(this.userService.getCurrentFullName());
                    if (dataUpdate.length > 0) {
                        // this.settingService.setBidsIsRefesh(this.tenantId);
                        this.reloadStatusBatchVersions.emit();
                    }
                }
                else this.toast.error('Update edge base failed');
            });
        }
    }
}
