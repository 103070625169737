<ng-container [formGroup]="myFormGroup">
  <ng-container [formArrayName]="myFormArrayName">
    <ng-container [formGroupName]="myFormGroupName">
      <input
        #txt
        type="text"
        [class]="classNames"
        [mask]="mask"
        [suffix]="suffix"
        [allowNegativeNumbers]="allowNegative"
        [formControlName]="myFormControlName"
        [placeholder]="placeholder"
        [required]="required"
        (blur)="onInputAcceptEdit()"
        (focusin)="startEdit()"
        (focusout)="onInputAcceptEdit()"
        (keyup.tab)="onInputAcceptEdit()"
        (keydown.tab)="onInputAcceptEdit()"
        (keyup.enter)="onInputAcceptEdit()"
        (keyup.esc)="cancelEdit()"
      >
      <!-- [readonly]="editing ? null: 'readonly'" -->
      <!-- (blur)="trimValue()" -->
      <!-- [formControl]="formControl" -->
    </ng-container>
  </ng-container>
</ng-container>
