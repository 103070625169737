<div class="row">
  <div class="col-xs-12">
    <h4 class="text-bold" style="margin-bottom: 15px">Application Configuration</h4>
  </div>
  <!-- <div class="col-md-3 col-sm-4 col-xs-12">
    <div class="form-group">
      <label>Application</label>
      <input type="text" class="form-control" trim="blur" [(ngModel)]="application" maxlength="200" trim="blur">
    </div>
  </div>
  <div class="col-md-3 col-sm-4 col-xs-12">
    <div class="form-group">
      <label>Unit Of Measure</label>
      <select [(ngModel)]="ddlUnitOfdMeasure"   class="form-control">
        <option *ngFor="let t of unitsOfMeasure" [ngValue]="t.unitId">{{t.unit}}</option>
      </select>
    </div>
  </div>
  <div class="col-md-3 col-sm-4 col-xs-12">
    <div class="form-group">
      <label>Supply Rate</label>
      <div class="row">
        <div class="col-sm-8">
          <input type="text" class="form-control text-right" currencyMask [(ngModel)]="txtDefaultSupplyRate">
        </div>
        <div class="col-sm-4">
          <button type="button" class="btn btn-primary" (click)="addApplication()">Add</button>
        </div>
      </div>
    </div>
  </div> -->

  <div class="col-12">
    <div class="row" style="margin-left: 10px;">
      <div class="col-lg-2 col-sm-3">
        <div class="form-group">
          <label class="control-label">Applications</label>
          <select [(ngModel)]="applicationId" class="form-control" name="applicationId">
            <option [value]="0">Select an application</option>
            <option *ngFor="let item of unassignData" [ngValue]="item.applicationId">{{item.applicationName}}</option>
          </select>
        </div>
      </div>

      <div *ngIf="serviceClassMode == 1" class="col-lg-2 col-sm-3">
        <div class="form-group">
          <label class="control-label">Service Class</label>
          <select [(ngModel)]="serviceClassId" class="form-control">
            <option [value]="0">Select a service class</option>
            <option *ngFor="let t of serviceClass" [ngValue]="t.serviceClassId">{{t.serviceClassName}}</option>
          </select>
        </div>
      </div>

      <div class="col-lg-2 col-sm-4" style="margin-top: 25px">
        <button type="button" [disabled]="loading || applicationId == 0" class="btn btn-primary" (click)="add()">
          <span><i class="fa fa-spinner fa-spin fa-fw" *ngIf="loading"></i></span>
          <strong *ngIf="!loading">+ </strong> Add
        </button>
      </div>
    </div>
  </div>

  <div class="col-xs-12">
    <br/>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Application</th>
              <th>Aplication Alias</th>
              <th>Description</th>
              <th *ngIf="serviceClassMode == 1">Service Class</th>
              <th [style.width.px]="80">Frequent</th>
              <th [style.width.px]="80">Active</th>
              <th [style.width.px]="130">Unit Of Measure</th>
              <th [style.width.px]="120">Supply Rate</th>
              <th>Updated On</th>
              <th>Updated By</th>
              <!-- <th [style.width.px]="96"></th> -->
              <th [style.width.px]="100"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>{{item.value.applicationName}}</td>
                  <td>
                    <div class="form-group" style="margin-bottom: 0">
                      <input type="text" class="form-control" formControlName="alias" maxlength="200" trim="blur" (blur)="save()" (keyup.enter)="save()">
                    </div>
                  </td>
                  <td>
                    <div class="form-group" style="margin-bottom: 0">
                      <input type="text" class="form-control" formControlName="description" maxlength="200" trim="blur" required (blur)="save()" (keyup.enter)="save()">
                    </div>
                  </td>
                  <td *ngIf="serviceClassMode == 1">
                    <select class="form-control" formControlName="serviceClassId" (change)="save()">
                      <option [value]="0">Unassigned</option>
                      <option *ngFor="let t of serviceClass" [ngValue]="t.serviceClassId">{{t.serviceClassName}}</option>
                    </select>
                  </td>
                  <td [style.width.px]="80" class="text-center"><input type="checkbox" formControlName="isFrequent" [(ngModel)] = 'item.value.isFrequent' (change) = 'save()'/></td>
                  <td [style.width.px]="80" class="text-center"><input type="checkbox" formControlName="active" [(ngModel)] = 'item.value.active' (change) = 'save()'/></td>
                  <td [style.width.px]="130">{{item.value.unit}}</td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" currencyMask formControlName="defaultSupplyRate" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td>{{item.value.updatedBy}}</td>
                  <!-- <td [style.width.px]="96">
                    <button type="button" class="btn btn-primary" (click)="deleteApplication(item.value.applicationId)">Remove</button>
                  </td> -->
                  <td [style.width.px]="100">
                    <button [disabled]="loading" (click)="remove(item.value.applicationId)" type="button" class="btn btn-primary">
                      Remove
                    </button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<!-- <bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you want to remove this application? Removing the application type will remove all rules and bids associated to this application.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal> -->

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    <p>Are you sure?  This type is currently being used.  Select another type that will replace the removed type.</p>
    <form novalidate>
      <div class="col-12">
        <div class="form-group">
          <label class="control-label">Application</label>
          <select class="form-control" [(ngModel)]="replaceId" name="replaceId" required>
            <ng-container *ngFor="let item of data">
              <option *ngIf="item.applicationId != applicationId" [ngValue]="item.applicationId">{{item.applicationName}}</option>
            </ng-container>
          </select>
        </div>
      </div>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitRemove(true)" id="remove">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalConfirm2>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    <p>Are you sure? This will remove weights associated to this application type.</p>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm2.close()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitRemove(false)">Submit</button>
  </bs-modal-footer>
</bs-modal>
