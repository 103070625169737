import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';
import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardService {
  constructor(private http: AuthHttp) { }

  upload(id, data: any): Promise<any> {
    return this.http.post('http://reports.ezfieldrep.com/api/Upload/' + id, data).toPromise();
  }

  getAllDashboardCategory(): Promise<any> {
    return this.http.get('Dashboard/GetAllDashboardCategory').toPromise();
  }

  /* getListDashboards(filter: any): Promise<any> {
    return this.http.post('Dashboard/GetListDashboards', JSON.stringify(filter)).toPromise();
  } */

  addNewDashboard(dashboard: any): Promise<any> {
    return this.http.post('Dashboard/AddNewDashboard', JSON.stringify(dashboard)).toPromise();
  }

  updateDashboard(dashboardId: number, model: any): Promise<any> {
    return this.http.put(`Dashboards/UpdateDashboard/${dashboardId}`, JSON.stringify(model)).toPromise();
  }

  /* activeDashboard(id, status): Promise<any> {
    return this.http.put(`Dashboard/SetActiveDashboard/${id}/${status}`).toPromise();
  }

  setDashboardStandard(id, isStandard): Promise<any> {
    return this.http.get(`Dashboard/setDashboardStandard/${id}/${isStandard}`).toPromise();
  } */


  deleteDashboard(dashboardId): Promise<any> {
    return this.http.delete('Dashboard/DeleteDashboard/' + dashboardId).toPromise();
  }

  loginActiveDashboard() {
    const body = {
      "Password": 'Abc123456@',
      "User": 'cpq',
      "Custom": 'string'
    }
    return this.http.requestSpecial("POST", environment.activeReportServer + 'accounts/login', JSON.stringify(body), null).toPromise();
  }

  getListTenantFromActiveDashboard(token) {
    const headers = new HttpHeaders({ "AuthToken": token });
    return this.http.requestSpecial("GET", environment.activeReportServer + "tags?selector={'IsSystem':true}", null, headers).toPromise();
  }

  getListDashboardOfTenantFromActiveDashboard(token, id) {
    const headers = new HttpHeaders({ "AuthToken": token });
    return this.http.requestSpecial("GET", environment.activeReportServer + `reports?selector={'Tags':['${id}']}`, null, headers).toPromise();
  }

  loadRoleByTenantId(tenantId, dashboardId): Promise<any> {
    return this.http.get(`Dashboard/LoadRoleByTenantId/${tenantId}/${dashboardId}`).toPromise();
  }

  assignDashboardPermission(tenantId, dashboardId, info): Promise<any> {
    return this.http.post(`Dashboard/AssignDashboardPermission/${tenantId}/${dashboardId}`, JSON.stringify(info)).toPromise();
  }

  updateDashboardOrderIndex(dashboard: any): Promise<any> {
    return this.http.post('Dashboard/UpdateDashboardOrderIndex', JSON.stringify(dashboard)).toPromise();
  }

  addBiConnection(conn: any): Observable<any> {
    return this.http.post('Dashboards/AddBiConnection', JSON.stringify(conn));
  }

  updateBiConnection(id: number, conn: any): Observable<any> {
    return this.http.put(`Dashboards/UpdateBiConnection/${id}`, JSON.stringify(conn));
  }

  deleteBiConnection(id: number): Observable<any> {
    return this.http.delete(`Dashboards/DeleteBiConnection/${id}`);
  }

  getBiConnections(): Observable<any> {
    return this.http.get('Dashboards/GetBiConnections');
  }

  getDashboards(): Observable<any> {
    return this.http.get('Dashboards/GetDashboards');
  }

  getDashboardById(dashboardId: number): Observable<any> {
    return this.http.get(`Dashboards/GetDashboardById/${dashboardId}`);
  }

  getTenantDashboards(tenantId: number): Observable<any> {
    return this.http.get(`Dashboards/GetTenantDashboards/${tenantId}`);
  }

  getTenantDashboardsByDashboardId(dashboardId: number): Observable<any> {
    return this.http.get(`Dashboards/GetTenantDashboardsByDashboardId/${dashboardId}`);
  }

  deleteTenantDashboard(id: number): Observable<any> {
    return this.http.delete(`Dashboards/DeleteTenantDashboard/${id}`);
  }

  addTenantDashboard(model: any): Observable<any> {
    return this.http.post(`Dashboards/AddTenantDashboard`, JSON.stringify(model));
  }

  updateTenantDashboard(id: number, model: any): Observable<any> {
    return this.http.put(`Dashboards/UpdateTenantDashboard/${id}`, JSON.stringify(model));
  }
}
