import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CommonPricingComponent } from '../common-pricing';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from '../../../services/setting.service';
import { UserService } from '../../../services/user.service';

@Component({
  templateUrl: './global-configuration.html'
})

export class GlobalConfigurationComponent extends CommonPricingComponent implements OnInit {
  channels: any;
  itemCategories: any;
  unitsOfMeasure: any;

  constructor(fb: FormBuilder, toast: ToastrService, settingService: SettingService,
    userService: UserService, cdr: ChangeDetectorRef) {
    super(fb, toast, settingService, userService, cdr);
  }

  ngOnInit() {
    this.tabs = [
      { id: 'application', text: 'Applications' },
      { id: 'materialTypes', text: "Material Types" },
      { id: 'edge', text: 'Edges' },
      { id: 'thickness', text: 'Thickness' },
      { id: 'scheduling-process-categories', text: 'Schedule Categories' },
    ];
    this.curTab = this.tabs[0];

    this.settingService.getDefaultChannels(-1, 0, 0).then(response => {
      if (response.status) this.channels = response.data;
      else this.toast.error('Can\'t get list channel');
    });

    this.settingService.getItemCategories().then(response => {
      if (response.status) this.itemCategories = response.data;
      else this.toast.error('Can\'t get list item categories');
    });
    this.settingService.getAllUnitsOfMeasure().then(response => {
      if (response.status) this.unitsOfMeasure = response.data;
      else this.toast.error('Can\'t get list units of measure');
    });

    super.ngOnInit();
  }
}
