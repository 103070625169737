import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../../../services/dashboard.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalComponent } from 'ng2-bs3-modal';
import { catchError, finalize, of } from 'rxjs';

@Component({
  selector: 'page-bi-connections',
  templateUrl: './bi-connections.html',
})
export class BIConnectionsComponent implements OnInit {

  @ViewChild('modalDelete') modalDelete: BsModalComponent;

  showLoading: boolean = true;
  biForm: FormGroup;
  biConnections: any[];
  connIdDelete: number;
  // allowDelete: boolean = true;

  constructor(
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.biForm = this.formBuilder.group({
      connectionName: ['', Validators.required],
      domain: [''],
      clientId: [''],
      clientSecret: [''],
    });
    this.loadData();
  }

  // Getter & Setter
  get connectionName(): FormControl {
    return this.biForm.get('connectionName') as FormControl;
  }
  getErrorConnectionName() {
    return this.connectionName.hasError('required') ? 'Connection name is required' : '';
  }

  private loadData(): void {
    this.showLoading = true;
    this.dashboardService.getBiConnections()
      .pipe(catchError(val => of(val.error)))
      .pipe(finalize(() => this.showLoading = false))
      .subscribe(response => {
        if (!response.status) {
          return;
        }
        this.biConnections = response.data;
      });
  }

  add(): void {
    console.log(this.biForm.value);
    this.dashboardService.addBiConnection(this.biForm.value)
      .pipe(catchError(val => of(val.error)))
      .subscribe(response => {
        if (!response.status) {
          return;
        }
        this.biForm.reset();
        this.loadData();
      });
  }

  edit(conn: any): void {
    this.dashboardService.updateBiConnection(conn.id, conn)
      .pipe(catchError(val => of(val.error)))
      .subscribe(response => {
        if (!response.status) {
          return;
        }
        this.loadData();
      });
  }

  delete(): void {
    this.dashboardService.deleteBiConnection(this.connIdDelete)
      .pipe(catchError(val => of(val.error)))
      .subscribe(response => {
        if (!response.status) {
          return;
        }
        this.modalDelete.close();
        this.loadData();
      });
  }
}
