import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { SettingService } from '../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { Config } from '@app-admin/config';

@Component({
  selector: 'tab-global-application',
  templateUrl: './global-application.html'
})

export class ApplicationGlobalComponent implements OnInit {
  @Input() unitsOfMeasure: any;
  @Input() isTenantOnly: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  application: any;
  txtDefaultSupplyRate: any;
  txtDefaultStandardWasteRate: string;
  txtDefaultAdvancedWasteRate: string;
  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  applicationId: any;
  ddlUnitOfdMeasure: any;
  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {

  }

  ngOnInit() {
    this.txtDefaultSupplyRate = Config.percentFormat(0);
    this.txtDefaultStandardWasteRate = Config.percentFormat(0);
    this.txtDefaultAdvancedWasteRate = Config.percentFormat(0);
    this.ddlUnitOfdMeasure = this.unitsOfMeasure[0].unitId;
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.settingService.getApplications(0, Config.BusinessUnitId.Slab).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      applicationId: e.applicationId,
      applicationName: [e.applicationName, Validators.required],
      defaultSupplyRate: [Config.numberFormat(e.defaultSupplyRate)],
      defaultStandardWasteRate: [Config.percentFormat(e.defaultStandardWasteRate, true)],
      defaultAdvancedWasteRate: [Config.percentFormat(e.defaultAdvancedWasteRate, true)],
      unit: e.unit,
      updatedOn: e.updatedOn
    });
  }

  validData() {
    if (this.application == null || this.application == '') {
      this.toast.error('Application cannot be empty');
      return false;
    }
    return true;
  }

  addApplication() {
    if (this.validData()) {
      this.loading = true;
      this.settingService.addApplications({
        tenantId: 0,
        applicationName: this.application,
        unitOfMeasure: this.ddlUnitOfdMeasure,
        defaultSupplyRate: this.settingService.replaceMask(this.txtDefaultSupplyRate),
        defaultStandardWasteRate: this.settingService.replaceMask(this.txtDefaultStandardWasteRate),
        defaultAdvancedWasteRate: this.settingService.replaceMask(this.txtDefaultAdvancedWasteRate),
        businessUnitId: Config.BusinessUnitId.Slab,
        applicationTypeId: Config.ApplicationTypeId.Slab,
      }).then(response => {
        if (response.status) {
          this.application = '';
          this.ddlUnitOfdMeasure = this.unitsOfMeasure[0].unitId;
          this.txtDefaultSupplyRate = Config.percentFormat(0);
          this.txtDefaultStandardWasteRate = Config.percentFormat(0);
          this.txtDefaultAdvancedWasteRate = Config.percentFormat(0);
          this.toast.success('New Application has been added');
          this.reloadStatusBatchVersions.emit();
          this.loadData();
        }
        else {
          if (response.data == 0) {
            this.toast.error('This application already exists.  System doesn\'t support duplicate applications.  Make appropriate changes and retry.');
          }
          else {
            this.toast.error('The Application is added fail');
          }
          this.loading = false;
        }
      });
    }
  }

  deleteApplication(applicationId) {
    this.modalConfirm.open();
    this.applicationId = applicationId;
  }

  submitDelete() {
    this.loading = true;
    this.modalConfirm.close();
    this.settingService.deleteApplication(this.applicationId).then(response => {
      if (response.status) {
        this.toast.success('Application has been removed');
        this.loading = true;
        this.loadData();
        // this.settingService.setBidsIsRefesh(this.tenantId);
      }
      else
        this.toast.error(response.message);
      this.loading = false;
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.applicationId == e.applicationId);
    item.defaultSupplyRate = this.settingService.replaceMask(e.defaultSupplyRate);
    item.defaultStandardWasteRate = this.settingService.replaceMask(e.defaultStandardWasteRate);
    item.defaultAdvancedWasteRate = this.settingService.replaceMask(e.defaultAdvancedWasteRate);
    item.applicationName = e.applicationName;
    return {
      applicationId: e.applicationId,
      defaultSupplyRate: this.settingService.replaceMask(e.defaultSupplyRate),
      defaultStandardWasteRate: item.defaultStandardWasteRate,
      defaultAdvancedWasteRate: item.defaultAdvancedWasteRate,
      applicationName: e.applicationName,
      isGlobal: true
    };
  }

  save() {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
      if (e.value.applicationName == null || e.value.applicationName.trim() == "" || e.value.applicationName.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Application cannot be empty');
        }
        isValid = false;
      }
    });
    if (isValid && this.dataForm.invalid) {
      this.toast.error('This application already exists. System doesn\'t support duplicate applications. Make appropriate changes and retry ');
      isValid = false;
    }
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateApplications(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Application has been updated');
          if (dataUpdate.length > 0) {
                        this.reloadStatusBatchVersions.emit();
                    }
        }
        else {
          this.toast.error('Update application failed');
        }
      });
    }
  }

  checkDuplicate(e) {
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(m => {
      if (e.applicationId.value != m.value.applicationId && e.applicationName.value.trim().toUpperCase() == m.value.applicationName.trim().toUpperCase()) {
        e.applicationName.setErrors({ 'hasDup': true });

      }
    });
  }
}
