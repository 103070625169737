import { Router } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from '../../../services/setting.service';
import { UserService } from '../../../services/user.service';
import { Config } from '@app-admin/config';
declare var $: any;

@Component({
    templateUrl: './slab-cost.html',
    styleUrls: ['./slab-cost.scss']
})
export class SlabCostComponent implements OnInit {
    costSchemas: any;
    isDisabledBtnAdd = false;
    loading = false;
    isTenantOnly: boolean = false;

    constructor(private toast: ToastrService,private settingService: SettingService, private router: Router, private userService: UserService) {
        this.isTenantOnly = this.userService.isTenantOnly();
    }

    ngOnInit() {
        this.settingService.getAllSchemas(Config.BusinessUnitId.Slab).then(response => {
            if(response.status){ this.costSchemas = response.data; }
            else {this.toast.error('Can\'t get schemas')}
        });
    }


    add(){
        this.loading = true;
        this.isDisabledBtnAdd = !this.isDisabledBtnAdd;
        this.settingService.createCostSchema({schemaName: `New Cost Schema on ${(new Date()).toLocaleDateString('en-US')} ${(new Date()).toLocaleTimeString('en-US')}`, schemaTypeId: 1, businessUnitId: Config.BusinessUnitId.Slab})
        .then(res=> {
            this.isDisabledBtnAdd = !this.isDisabledBtnAdd;
            if(res.status) {
                const id = res.data.schemaId;
                this.router.navigate([`/cost-schema-detail/${id}`]);
                this.toast.success('Cost Schema has been created.');
             }
            else {this.toast.error('Create Cost Schema has been failed!');}
            this.loading = false;
        });
    }
}
