<div class="content-wrapper">
  <section class="content-header">
    <h1>{{tenantInfo?.tenantName}} Configuration</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li><a routerLink="/tenants"> Tenants</a></li>
      <li class="active"> {{tenantInfo?.tenantName}}</li>
    </ol>
  </section>

  <section class="content" *ngIf="tenantInfo">
    <div class="nav-tabs-custom">
      <ul class="nav nav-tabs">
        <li class="active"><a href="#details" data-toggle="tab">Detail</a></li>
        <li><a href="#subscription" data-toggle="tab">Subscription(s)</a></li>
        <li><a href="#addresses" data-toggle="tab">Location(s)</a></li>
        <li><a href="#configurations" data-toggle="tab">Configuration(s)</a></li>
        <li><a href="#integrations" data-toggle="tab">Integration(s)</a></li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane box active" id="details">
          <form [formGroup]="tenantDetailForm" novalidate>
            <div class="row" style="position:relative;">
              <div class="col-lg-4 col-sm-6 col-xs-12">
                <div class="form-group"
                  [class.has-error]="tenantName.invalid && (tenantName.dirty || tenantName.touched)">
                  <label>Tenant Name <span class="text-danger">*</span></label>
                  <input type="text" trim="blur" class="form-control" formControlName="tenantName" maxlength="50"
                    required>
                  <ng-container *ngIf="tenantName.invalid && (tenantName.dirty || tenantName.touched)">
                    <span *ngIf="tenantName.errors.required" class="help-block">Tenant name is required.</span>
                    <span *ngIf="tenantName.errors.pattern" class="help-block">Invalid tenant name format. Tenant name
                      should not contain special characters and upper case characters.</span>
                    <span *ngIf="tenantName.errors.isExistTenant" class="help-block">Tenant name is already exist</span>
                  </ng-container>
                </div>
                <div class="form-group" [class.has-error]="company.invalid && (company.dirty || company.touched)">
                  <label>Company <span class="text-danger">*</span></label>
                  <input type="text" trim="blur" class="form-control" formControlName="company" maxlength="100"
                    required>
                  <ng-container *ngIf="company.invalid && (company.dirty || company.touched)">
                    <span *ngIf="company.errors.required" class="help-block">Company is required.</span>
                  </ng-container>
                </div>
                <div class="form-group" [class.has-error]="website.invalid && (website.dirty || website.touched)">
                  <label>Website</label>
                  <input type="text" trim="blur" class="form-control" formControlName="website" maxlength="100">
                  <ng-container *ngIf="website.invalid && (website.dirty || website.touched)">
                    <span *ngIf="website.errors.pattern" class="help-block">Invalid url format of website.</span>
                  </ng-container>
                </div>
                <div class="form-group" [class.has-error]="phone.invalid && (phone.dirty || phone.touched)">
                  <label>Phone</label>
                  <input type="tel" mask="(000) 000-0000" class="form-control" formControlName="phone" />
                  <ng-container *ngIf="phone.invalid && (phone.dirty || phone.touched)">
                    <span *ngIf="phone.errors" class="help-block">Invalid phone number format.</span>
                  </ng-container>
                </div>
                <div class="form-group"
                  [class.has-error]="timeZoneId.invalid && (timeZoneId.dirty || timeZoneId.touched)">
                  <label>Timezone <span class="text-danger">*</span></label>
                  <select class="form-control" formControlName="timeZoneId" required>
                    <option *ngFor="let t of timezones" [ngValue]="t.id">{{t.displayName}}</option>
                  </select>
                  <ng-container *ngIf="timeZoneId.invalid && (timeZoneId.dirty || timeZoneId.touched)">
                    <span *ngIf="timeZoneId.errors.required" class="help-block">Timezone is required.</span>
                  </ng-container>
                </div>
                <div class="form-group" [class.has-error]="addressSuggestOptionType.invalid && (addressSuggestOptionType.dirty || addressSuggestOptionType.touched)">
                  <label>Address Suggest Otions <span class="text-danger">*</span></label>
                  <select class="form-control" formControlName="addressSuggestOptionType" required>
                    <option *ngFor="let opt of googleAddressSuggestOptions" [value]="opt.value" [title]="opt.description">{{opt.name}}</option>
                  </select>
                </div>

              </div>
              <div class="col-lg-4 col-sm-6 col-xs-12">
                <div class="form-group" [class.has-error]="address1.invalid && (address1.dirty || address1.touched)">
                  <label>Address 1 <span class="text-danger">*</span></label>
                  <input type="text" trim="blur" class="form-control" formControlName="address1" [placeholder]="''" maxlength="200" (blur)="onPacOutFocusAddAddress(true,true)" required #pacTenantAddress>
                  <ng-container *ngIf="address1.invalid && (address1.dirty || address1.touched)">
                    <span *ngIf="address1.errors.required" class="help-block">Address 1 is required</span>
                  </ng-container>
                </div>
                <div class="form-group">
                  <label>Address 2</label>
                  <input type="text" trim="blur" class="form-control" formControlName="address2" maxlength="200">
                </div>
                <div class="form-group" [class.has-error]="city.invalid && (city.dirty || city.touched)">
                  <label>City <span class="text-danger">*</span></label>
                  <input type="text" trim="blur" class="form-control" formControlName="city" maxlength="100" required>
                  <ng-container *ngIf="city.invalid && (city.dirty || city.touched)">
                    <span *ngIf="city.errors.required" class="help-block">City is required</span>
                  </ng-container>
                </div>
                <div class="form-group" [class.has-error]="state.invalid && (state.dirty || state.touched)">
                  <label>State <span class="text-danger">*</span></label>
                  <select class="form-control" formControlName="state" required>
                    <option *ngFor="let s of states" [ngValue]="s.stateCode">{{s.stateCode}}</option>
                  </select>
                  <ng-container *ngIf="state.invalid && (state.dirty || state.touched)">
                    <span *ngIf="state.errors.required" class="help-block">State is required</span>
                  </ng-container>
                </div>
                <div class="form-group" [class.has-error]="postalCode.invalid && (postalCode.dirty || postalCode.touched)">
                  <label>Postal Code <span class="text-danger">*</span></label>
                  <input type="text" trim="blur" class="form-control" formControlName="postalCode" mask="00000" required>
                  <ng-container *ngIf="postalCode.invalid && (postalCode.dirty || postalCode.touched)">
                    <span *ngIf="postalCode.errors.required" class="help-block">Postal code is required</span>
                    <span *ngIf="postalCode.errors.pattern" class="help-block">Invalid postal code format</span>
                  </ng-container>
                </div>
                <div class="form-group" [class.has-error]="addressSuggestRadiusRange.invalid && (addressSuggestRadiusRange.dirty || addressSuggestRadiusRange.touched)">
                  <label>Address Suggest Radius (miles) <span class="text-danger">*</span></label>
                  <input type="number" min="1" max="999999999" trim="blur" class="form-control" formControlName="addressSuggestRadiusRange" required/>
                  <ng-container *ngIf="addressSuggestRadiusRange.invalid && (addressSuggestRadiusRange.dirty || addressSuggestRadiusRange.touched)">
                    <span *ngIf="addressSuggestRadiusRange.errors.required" class="help-block">Address Suggest Radius is required.</span>
                    <span *ngIf="addressSuggestRadiusRange.errors.min" class="help-block">Address Suggest Radius must be larger than 0.</span>
                    <span *ngIf="addressSuggestRadiusRange.errors.pattern" class="help-block">Invalid Address Suggest Radius format</span>
                  </ng-container>
                </div>
              </div>
              <div class="col-xs-12">
                <div class="form-group">
                  <button type="button" class="btn btn-primary" [disabled]="isDisableDetailFormSave()"
                    (click)="save()">Save</button>
                  <a type="button" class="btn btn-default" style="margin-left: 5px;" [disabled]="loading"
                    routerLink="/tenants">Cancel</a>
                  <i *ngIf="loading" class="fa fa-spinner fa-spin fa-lg" style="margin-right: 15px;"></i>
                </div>
              </div>
              <div class="form-group" style="position: absolute; right: 20px; top: -50px">
                <label><input type="checkbox" formControlName="active" /> Active</label>
              </div>
            </div>
          </form>
        </div>
        <div class="tab-pane box" id="subscription">
          <div class="form-group">
            <button type="button" class="btn btn-primary" (click)="openPopupSubscription()">Add Subscription</button>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Module</th>
                  <th>Start Date</th>
                  <th>Term</th>
                  <th>Amount</th>
                  <th>Next Billing Date</th>
                  <th>Available User</th>
                  <th>Asigned User</th>
                  <th>Allowed User</th>
                  <th>Status</th>
                  <!-- <div class="form-group">
                    <label>Available User</label>
                    <input type="text" class="form-control" formControlName="available"
                      thousandSeparator="," separatorLimit="100000000000">
                  </div>
                  <div class="form-group">
                    <label></label>
                    <input type="text" class="form-control" formControlName="assigned"
                      thousandSeparator="," separatorLimit="100000000000">
                  </div>
                  <div class="form-group">
                    <label></label>
                    <input type="text" class="form-control" formControlName="maxAlowed"
                      thousandSeparator="," separatorLimit="100000000000">
                  </div> -->
                  <th [style.width.px]="80" class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="tenantSubscription?.length == 0">
                  <td class="text-center">No Modules</td>
                </tr>
                <tr *ngFor="let item of tenantSubscription">
                  <td>{{item.module.moduleName}}</td>
                  <td>{{item.startDate | date:'MM/dd/yyyy'}}</td>
                  <td>{{item.termId == 1 ? 'Monthly' : 'Annually' }}</td>
                  <td>{{item.amount | currency}}</td>
                  <td>{{item.nextBillingDate | date:'MM/dd/yyyy'}}</td>
                  <td>{{item.maxAllowed - item.assigned}}</td>
                  <td>{{item.assigned}}</td>
                  <td>{{item.maxAllowed}}</td>
                  <td>{{item.status == 1 ? 'Active' : 'Inactive' }}</td>
                  <!-- <td [style.width.px]="80" class="text-center">
                    <a href="javascript:;" class="fa fa-lg" [ngClass]="item.active == 0 ? 'fa-toggle-off text-danger' : 'fa-toggle-on text-success'" (click)="updateAddress(item, true)"></a>
                  </td> -->
                  <td [style.width.px]="80" class="text-center">
                    <a href="javascript:;" class="fa fa-lg fa-pencil-square-o text-success" title="Edit"
                      (click)="openPopupSubscription(item); getLicenseTypes(item.module.moduleId);"></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tab-pane box" id="addresses">
          <div class="form-group">
            <button type="button" class="btn btn-primary" (click)="openPopupLocation()">New Location</button>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th [style.width.px]="30"></th>
                  <th [style.width.px]="120">Address Type</th>
                  <th [style.width.px]="120">Location Name</th>
                  <th [style.width.px]="80" class="text-center">Active</th>
                  <th [style.width.px]="80" class="text-center">Action</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="locations?.length == 0">
                  <td class="text-center">No Location</td>
                </tr>
                <ng-container *ngFor="let item of locations; let loopIndex= index">
                  <tr>
                    <td [style.width.px]="30">
                      <a data-toggle="collapse" href="#collapse_{{item.locationId}}" aria-expanded="false"
                        aria-controls="collapse_{{item.locationId}}">
                        <i class="fa fa-arrows-v"></i>
                      </a>
                    </td>
                    <td [style.width.px]="120">{{ item.addressType }}</td>
                    <td [style.width.px]="120">{{ item.locationName }}</td>
                    <td [style.width.px]="80" class="text-center">
                      <a href="javascript:;" class="fa fa-lg"
                        [ngClass]="item.active == 0 ? 'fa-toggle-off text-danger' : 'fa-toggle-on text-success'"
                        (click)="changeActive(item)"></a>
                    </td>
                    <td [style.width.px]="80" class="text-center">
                      <a href="javascript:;" class="fa fa-lg fa-pencil-square-o text-success" title="Edit"
                        (click)="openPopupLocation(item)"></a>
                      &nbsp;&nbsp;&nbsp;
                      <a href="javascript:;" class="fa fa-lg fa-trash text-danger" title="Delete"
                        *ngIf="!item.active"
                        (click)="confirmDeleteLocation(item.locationId)"></a>
                    </td>
                    <td>
                      <button type="button" class="btn btn-primary" (click)="openPopupAddress(item.locationId)">Add new
                        address</button>
                    </td>
                  </tr>
                  <location-addresses [loopIndex]="loopIndex" [currentRow]="item" [states]="states"></location-addresses>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        <div class="tab-pane box" id="configurations">
          <div class="row">
            <div class="col-lg-4 col-sm-6 col-xs-12">
              <div class="form-group">
                <a href="javascript:;" class="fa fa-lg"
                  [ngClass]="!tenantInfo.isShowEdgeImage ? 'fa-toggle-off text-danger' : 'fa-toggle-on text-success'"
                  (click)="updateIsShowEdgeImage()">
                </a>
                <label (click)="updateIsShowEdgeImage()" style="padding-left:10px; cursor:pointer">Show Edge Images</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-sm-6 col-xs-12">
              <div class="form-group">
                <a href="javascript:;" class="fa fa-lg"
                  [ngClass]="!tenantInfo.isRunDragAlong ? 'fa-toggle-off text-danger' : 'fa-toggle-on text-success'"
                  (click)="updateIsRunDragAlong()">
                </a>
                <label (click)="updateIsRunDragAlong()" style="padding-left:10px; cursor:pointer">Auto Run Drag Along</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-sm-6 col-xs-12">
              <div class="form-group">
                <label>Order Pro Scheduling</label>
                <div>
                  <label class="radio-inline">
                    <input type="radio" name="orderProScheduling" [value]=1 [(ngModel)]="tenantInfo.orderProScheduling" (change)="updateOrderProScheduling()"> Manual
                  </label>
                  <label class="radio-inline">
                    <input type="radio" name="orderProScheduling" [value]=2 [(ngModel)]="tenantInfo.orderProScheduling" (change)="updateOrderProScheduling()" [disabled]="!hasScheduleProLicense()">  Schedule Pro Managed
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-lg-4 col-sm-6 col-xs-12">
              <div class="form-group">
                <label>Service Class Mode</label>
                <select [(ngModel)]="tenantInfo.serviceClassMode" class="form-control" (change)="updateServiceClassMode()">
                  <option [ngValue]=1>Application Mode</option>
                  <option [ngValue]=2>Material Mode</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4 col-sm-6 col-xs-12">
              <div class="form-group">
                <label>Tenant Order Reference</label>
                <input type="text" trim="blur" class="form-control" maxlength="100" [value]="tenantInfo.startOrderReferenceNumber" (blur)="updateTenantOrderRef($event.target.value)">
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane box" id="integrations">
          <form [formGroup]="tenantIntegrateForm" novalidate>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Label</label>
                  <input type="text" trim="blur" class="form-control" formControlName="label" maxlength="100">
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group" [class.has-error]="url.invalid && (url.dirty || url.touched)">
                  <label>URL</label>
                  <input type="text" trim="blur" class="form-control" formControlName="url" maxlength="100">
                  <ng-container *ngIf="url.invalid && (url.dirty || url.touched)">
                    <span *ngIf="url.errors.pattern" class="help-block">Invalid url format of website.</span>
                  </ng-container>
                </div>
              </div>
              <div class="col-md-4" style="margin-top: 30px">
                <div class="form-group">
                  <label><input type="checkbox" formControlName="active" /> Active</label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</div>

<bs-modal #modalSubscription [keyboard]="false" [backdrop]="'static'">
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">{{modal_title_subscription}}</h4>
  </bs-modal-header>
  <bs-modal-body>
    <form novalidate [formGroup]="formAddOrEditSubscription">
      <div class="form-group">
        <label>Module</label>
        <select (change)="getLicenseTypes(moduleId.value)" formControlName="moduleId" class="form-control" required>
          <option value="">-- Please select a module --</option>
          <ng-container *ngFor="let m of modules">
            <option [ngValue]="m.moduleId">{{m.moduleName}}</option>
          </ng-container>
          <ng-container *ngIf="moduleId.invalid && (moduleId.dirty || moduleId.touched)">
            <span *ngIf="moduleId.errors.required" class="help-block">Module is required</span>
          </ng-container>
        </select>
      </div>
      <div class="form-group">
        <label>Service Types</label>
        <div *ngFor="let s of serviceTypes" class="row" style="margin: 0;">
          <input class="form-check-input" type="checkbox" [checked]="false" [id]="'serviceType'+s.serviceId">
          <label class="form-check-label" [htmlFor]="'serviceType-'+s.serviceId">{{s.serviceName}}</label>
        </div>
        <!-- <select formControlName="serviceTypeIds" class="form-control" multiple required>
          <option *ngFor="let s of serviceTypes" [ngValue]="s.serviceId">{{s.serviceName}}</option>
        </select> -->
      </div>
      <div class="form-group">
        <label>Start Date</label>
        <div class="input-group date">
          <div class="input-group-addon">
            <i class="fa fa-calendar"></i>
          </div>
          <input type="text" class="form-control pull-right" id="startDate" formControlName="startDate">
        </div>
      </div>

      <div class="form-group">
        <label>Term</label>
        <select class="form-control" formControlName="termId">
          <option value="1">Monthly</option>
          <option value="2">Annually</option>
        </select>
      </div>
      <div class="form-group">
        <label>Amount</label>
        <input type="text" class="form-control" formControlName="amount" currencyMask>
      </div>
      <div class="form-group">
        <label>Next Billing Date</label>
        <div class="input-group date">
          <div class="input-group-addon">
            <i class="fa fa-calendar"></i>
          </div>
          <input type="text" class="form-control" id="nextBillingDate" formControlName="nextBillingDate">
        </div>
      </div>
      <div class="form-group">
        <label>Status</label>
        <select class="form-control" formControlName="status">
          <option value="0">Inactive</option>
          <option value="1">Active</option>
        </select>
      </div>
    </form>
    <h4 class="modal-title">Licenses</h4>
    <div class="table-responsive">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th [style.width.px]="300">License Type</th>
            <th *ngIf="moduleId.value == 6 || moduleId.value == 7">Workers</th>
            <th *ngIf="!(moduleId.value == 6 || moduleId.value == 7)">Users</th>
            <th *ngIf="moduleId.value == 3">Workers</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="!(licenseTypes?.length > 0)" class="text-center">No License Types</tr>
          <tr *ngFor="let lt of licenseTypes">
            <td [style.width.px]="300">{{lt.licenseType}}</td>
            <td *ngIf="moduleId.value == 6 || moduleId.value == 7">
              <input type="text" class="form-control text-center" [(ngModel)]="lt.maxWorker" mask="separator.0"
                separatorLimit="100000000000">
            </td>
            <td *ngIf="!(moduleId.value == 6 || moduleId.value == 7)">
              <input type="text" class="form-control text-center" [(ngModel)]="lt.maxUser" mask="separator.0"
                separatorLimit="100000000000" (focus)="old=lt.maxUser" (blur)="checkUserCountValid(lt, old)" (keyup.enter)="checkUserCountValid(lt, old)">
            </td>
            <td *ngIf="moduleId.value == 3">
              <input type="text" class="form-control text-center" [(ngModel)]="lt.maxWorker" mask="separator.0"
                separatorLimit="100000000000">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <i *ngIf="loadingSubscription" class="fa fa-spinner fa-spin fa-lg" aria-hidden="true"
      style="margin-right: 15px;"></i>
    <button type="button" [disabled]="loadingSubscription" class="btn btn-default"
      (click)="modalSubscription.close()">Cancel</button>
    <button type="button" [disabled]="!formAddOrEditSubscription.valid || loadingSubscription || !userCountValid" class="btn btn-primary"
      (click)="submitSubscription()">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalAddress [keyboard]="false" [backdrop]="'static'">
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Add Address</h4>
  </bs-modal-header>
  <bs-modal-body>
    <form novalidate [formGroup]="formAddAddress">
      <div class="form-group" [class.has-error]="addressType.invalid && (addressType.dirty || addressType.touched)">
        <label>Address Type <span class="text-danger">*</span></label>
        <select class="form-control" formControlName="addressType" required>
          <option value="Service">Service</option>
          <option *ngIf="isShowCorporate()" value="Corporate">Corporate</option>
          <option value="Billing">Billing</option>
          <option value="Report">Report</option>
        </select>
        <ng-container *ngIf="addressType.invalid && (addressType.dirty || addressType.touched)">
          <span *ngIf="addressType.errors.required" class="help-block">Address type is required</span>
        </ng-container>
      </div>
      <div class="form-group" [class.has-error]="address1.invalid && (address1.dirty || address1.touched)">
        <label>Address 1 <span class="text-danger">*</span></label>
        <input type="text" trim="blur" class="form-control" formControlName="address1" [placeholder]="''" maxlength="200" (blur)="onPacOutFocusAddAddress(true)" required #pacAddAddress>
        <ng-container *ngIf="address1.invalid && (address1.dirty || address1.touched)">
          <span *ngIf="address1.errors.required" class="help-block">Address 1 is required</span>
        </ng-container>
      </div>
      <div class="form-group">
        <label>Address 2</label>
        <input type="text" trim="blur" class="form-control" formControlName="address2" maxlength="200">
      </div>
      <div class="form-group" [class.has-error]="city.invalid && (city.dirty || city.touched)">
        <label>City <span class="text-danger">*</span></label>
        <input type="text" trim="blur" class="form-control" formControlName="city" maxlength="100" required>
        <ng-container *ngIf="city.invalid && (city.dirty || city.touched)">
          <span *ngIf="city.errors.required" class="help-block">City is required</span>
        </ng-container>
      </div>
      <div class="form-group" [class.has-error]="state.invalid && (state.dirty || state.touched)">
        <label>State <span class="text-danger">*</span></label>
        <select class="form-control" formControlName="state" required>
          <option *ngFor="let s of states" [ngValue]="s.stateCode">{{s.stateCode}}</option>
        </select>
        <ng-container *ngIf="state.invalid && (state.dirty || state.touched)">
          <span *ngIf="state.errors.required" class="help-block">State is required</span>
        </ng-container>
      </div>
      <div class="form-group" [class.has-error]="postalCode.invalid && (postalCode.dirty || postalCode.touched)">
        <label>Postal Code <span class="text-danger">*</span></label>
        <input type="text" trim="blur" class="form-control" formControlName="postalCode" mask="00000" required>
        <ng-container *ngIf="postalCode.invalid && (postalCode.dirty || postalCode.touched)">
          <span *ngIf="postalCode.errors.required" class="help-block">Postal code is required</span>
          <span *ngIf="postalCode.errors.pattern" class="help-block">Invalid postal code format</span>
        </ng-container>
      </div>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <i *ngIf="loadingAddress" class="fa fa-spinner fa-spin fa-lg" aria-hidden="true" style="margin-right: 15px;"></i>
    <button type="button" [disabled]="loadingAddress" class="btn btn-default"
      (click)="modalAddress.close()">Cancel</button>
    <button type="button" [disabled]="!formAddAddress.valid || loadingAddress" class="btn btn-primary"
      (click)="submitAddress()">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalLocation [keyboard]="false" [backdrop]="'static'">
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">{{modal_title_location}}</h4>
  </bs-modal-header>
  <bs-modal-body>
    <form novalidate [formGroup]="formAddOrEditLocation">
      <div class="form-group" [class.has-error]="locationAddressType.errors?.required">
        <label>Address Type <span class="text-danger">*</span></label>
        <select class="form-control" formControlName="locationAddressType" (change)="onChangeAddressType($event)" required>
          <option value="Service">Service</option>
          <option value="Billing">Billing</option>
          <option value="Report">Report</option>
          <option *ngIf="isShowCorporate()" value="Corporate">Corporate</option>
        </select>
        <ng-container *ngIf="locationAddressType.errors?.required">
          <span class="help-block">Address type is required</span>
        </ng-container>
      </div>
      <div class="form-group" [class.has-error]="locationName.invalid && (locationName.dirty || locationName.touched)">
        <label>Location Name <span class="text-danger">*</span></label>
        <input type="text" trim="blur" class="form-control" formControlName="locationName" maxlength="200" required>
        <ng-container *ngIf="locationName.invalid && (locationName.dirty || locationName.touched)">
          <span *ngIf="locationName.errors.required" class="help-block">Location Name is required</span>
        </ng-container>
      </div>
      <!-- <div class="form-group" *ngIf="locationId.value > 0 && !(countActiveLocations() == 1 && activeL.value)">
        <label><input type="checkbox" formControlName="active" /> Active</label>
      </div> -->
      <ng-contaner *ngIf="locationId.value <= 0">
        <div class="form-group" [class.has-error]="address1L.invalid && (address1L.dirty || address1L.touched)">
          <label>Address 1 <span class="text-danger">*</span></label>
          <input type="text" trim="blur" class="form-control" formControlName="address1" [placeholder]="''" maxlength="200" (blur)="onPacOutFocus(true)" required #pac>
          <ng-container *ngIf="address1L.invalid && (address1L.dirty || address1L.touched)">
            <span *ngIf="address1L.errors.required" class="help-block">Address 1 is required</span>
          </ng-container>
        </div>
        <div class="form-group">
          <label>Address 2</label>
          <input type="text" trim="blur" class="form-control" formControlName="address2" maxlength="200">
        </div>
        <div class="form-group" [class.has-error]="cityL.invalid && (cityL.dirty || cityL.touched)">
          <label>City <span class="text-danger">*</span></label>
          <input type="text" trim="blur" class="form-control" formControlName="city" maxlength="100" required>
          <ng-container *ngIf="cityL.invalid && (cityL.dirty || cityL.touched)">
            <span *ngIf="cityL.errors.required" class="help-block">City is required</span>
          </ng-container>
        </div>
        <div class="form-group" [class.has-error]="stateL.invalid && (stateL.dirty || stateL.touched)">
          <label>State <span class="text-danger">*</span></label>
          <select class="form-control" formControlName="state" required>
            <option *ngFor="let s of states" [ngValue]="s.stateCode">{{s.stateCode}}</option>
          </select>
          <ng-container *ngIf="stateL.invalid && (stateL.dirty || stateL.touched)">
            <span *ngIf="state.errors.required" class="help-block">State is required</span>
          </ng-container>
        </div>
        <div class="form-group" [class.has-error]="postalCodeL.invalid && (postalCodeL.dirty || postalCodeL.touched)">
          <label>Postal Code <span class="text-danger">*</span></label>
          <input type="text" trim="blur" class="form-control" formControlName="postalCode" mask="00000" required>
          <ng-container *ngIf="postalCodeL.invalid && (postalCodeL.dirty || postalCodeL.touched)">
            <span *ngIf="postalCodeL.errors.required" class="help-block">Postal code is required</span>
            <span *ngIf="postalCodeL.errors.pattern" class="help-block">Invalid postal code format</span>
          </ng-container>
        </div>
      </ng-contaner>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <i *ngIf="loadingLocation" class="fa fa-spinner fa-spin fa-lg" aria-hidden="true" style="margin-right: 15px;"></i>
    <button type="button" [disabled]="loadingLocation" class="btn btn-default"
      (click)="modalLocation.close()">Cancel</button>
    <button type="button" [disabled]="!!!placeIdL.value || !formAddOrEditLocation.valid || loadingLocation" class="btn btn-primary"
      (click)="submitLocation()">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalDeleteLocation data-backdrop="static" data-keyboard="false">
  <bs-modal-header [showDismiss]="false">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    <label>Are you sure?</label>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalDeleteLocation.close()">No</button>
    <button #btn class="btn btn-primary" (click)="deleteLocation()" autofocus>Yes</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalReassignLocation data-backdrop="static" data-keyboard="false">
  <bs-modal-header [showDismiss]="false">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    <label>Are you sure? This will require reassigning projects to an active service location.</label>
    <div>
      <label class="row col-sm-12">Choose another location.</label>
      <select class="row col-sm-12" style="width: 300px; height: 40px; margin-left: 10px;" (change)="btn.focus()"
        [(ngModel)]="dstLocationId">
        <option [value]="0">None</option>
        <option *ngFor="let l of filterLocations" [value]="l.locationId">{{ l.locationName }}</option>
      </select>
    </div>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalReassignLocation.close()">No</button>
    <button #btn class="btn btn-primary" [disabled]="dstLocationId == 0" (click)="reassignLocation()"
      autofocus>Yes</button>
  </bs-modal-footer>
</bs-modal>
