import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { SettingService } from '../../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { TenantTrimEdgeConfigService } from '@app-admin/services/tenant-trim-edge-config.service';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'tab-trim-edges',
  templateUrl: './trim-edges.html'
})

export class TrimEdgesComponent implements OnInit {
  @Input() tenantId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;

  loading = false;
  data: any[] = [];
  unassignData: any[] = [];
  dataFilter: any[] = [];
  dataForm: FormGroup;
  trimEdgeId: number = 0;
  replaceId: number = 0;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService
    , private tenantTrimEdgeConfigService: TenantTrimEdgeConfigService) {

  }

  ngOnInit() {
    this.loadData();
  }

  private loadData(): void {
    this.loading = true;
    this.settingService.getTileTrimEdges(this.tenantId).then(response => {
      this.loading = false;
      if (!response.status) {
        this.toast.error('Load data failed');
        return;
      }
      this.data = response.data;
      this.filterData();
      this.calcHeightTable.emit();
    });

    this.tenantTrimEdgeConfigService.getUnassignTrimEdges(this.tenantId).then(res => {
      if (!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.unassignData = res.data;
    });
  }

  private filterData(): void {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  private createItemView(e): FormGroup {
    return this.fb.group({
      trimEdgeId: e.trimEdgeId,
      trimEdgeName: [e.trimEdgeName, Validators.required],
      active:[e.active],
      isRemove: e.isRemove,
      alias: e.alias,
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
      frequentlyUsed: [e.frequentlyUsed],
    });
  }

  private createItemUpdate(e): any {
    let item = this.data.find(i => i.trimEdgeId == e.trimEdgeId);
    item.baseWastePercent = this.settingService.replaceMask(e.baseWastePercent) / 100;
    item.trimEdgeName = e.trimEdgeName;
    item.active = e.active;
    return {
      trimEdgeId: e.trimEdgeId,
      alias: e.alias,
      trimEdgeName: e.trimEdgeName,
      active: e.active,
      isGlobal: false,
      tenantId: this.tenantId,
      frequentlyUsed: e.frequentlyUsed,
    };
  }

  save(): void {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
      if (e.value.trimEdgeName == null || e.value.trimEdgeName.trim() == "" || e.value.trimEdgeName.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Trim Edge name needs to be greater than 2 characters');
        }
        isValid = false;
      }
    });
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateTileTrimEdges(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Trim Edge has been updated');
          this.loadData();
        }
        else this.toast.error('Update Trim Edge failed');
      });
    }
  }

  add(): void {
    this.loading = true;
    this.tenantTrimEdgeConfigService.add(this.tenantId, this.trimEdgeId).then(res => {
      this.loading = false;
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.toast.success('Trim Edge added.');
      this.loadData();
      this.trimEdgeId = 0;
    })
  }

  remove(trimEdgeId: number): void {
    if (!trimEdgeId) return;
    this.trimEdgeId = trimEdgeId;
    this.tenantTrimEdgeConfigService.checkRemove(this.tenantId, this.trimEdgeId).then(res => {
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      if (res.data) {
        this.modalConfirm.open();
      } else {
        this.submitRemove(false);
      }
    });
  }

  submitRemove(allowReplace: boolean): void {
    if (allowReplace && this.replaceId <= 0) return;

    this.modalConfirm.close();
    this.loading = true;
    this.tenantTrimEdgeConfigService.remove(this.tenantId, this.trimEdgeId, this.replaceId).then(res => {
      this.loading = false;
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.toast.success('Trim Edge removed.');
      this.loadData();
      this.trimEdgeId = 0;
      this.replaceId = 0;
    });
  }
}
