<div class="content-wrapper">
  <section class="content-header">
    <h1>BI Connections</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/dashboards/bi-connections"><i class="fa fa-dashboard"></i> Dashboard</a></li>
      <li class="active">BI Connections</li>
    </ol>
  </section>

  <section class="content">
    <h4 style="line-height: 1.5" *ngIf="showLoading">Loading...</h4>
    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true" *ngIf="!showLoading">

      <!-- Add new -->
      <div class="panel panel-default">
        <div class="panel-heading" role="tab" id="headingAdd">
          <h4 class="panel-title">
            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseAdd"
              aria-expanded="false" aria-controls="collapseAdd">
              <button type="button" class="btn btn-primary">+ Connection</button>
            </a>
          </h4>
        </div>
        <div id="collapseAdd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingAdd">
          <div class="panel-body">
            <div class="row">
              <div class="col-lg-8 col-xs-12">
                <form [formGroup]="biForm" novalidate>
                  <div class="row">
                    <div class="col-sm-6 col-xs-12">
                      <div class="form-group"
                        [class.has-error]="connectionName.invalid && (connectionName.dirty || connectionName.touched)">
                        <label class="control-label">Name</label>
                        <input type="text" trim="blur" class="form-control" placeholder="Name"
                          formControlName="connectionName" maxlength="50" required>
                        <span *ngIf="connectionName.invalid && (connectionName.dirty || connectionName.touched)"
                          class="help-block">{{getErrorConnectionName()}}&nbsp;</span>
                      </div>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label class="control-label">Domain</label>
                        <input type="text" trim="blur" class="form-control" placeholder="Domain"
                          formControlName="domain" maxlength="250">
                      </div>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label class="control-label">Client Id</label>
                        <input type="text" trim="blur" class="form-control" placeholder="Domain"
                          formControlName="clientId" maxlength="250">
                      </div>
                    </div>
                    <div class="col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label class="control-label">Client Secret</label>
                        <input type="text" trim="blur" class="form-control" placeholder="Domain"
                          formControlName="clientSecret" maxlength="250">
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <button type="button" class="btn btn-primary" style="margin-top: 16px" (click)="add()">Add Connection</button>
          </div>
        </div>
      </div>

      <!-- List exsited -->
      <div class="panel panel-default" *ngFor="let conn of biConnections">
        <div class="panel-heading" role="tab" id="heading_{{conn.id}}">
          <h4 class="panel-title">
            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion"
              href="#collapse_{{conn.id}}" aria-expanded="false" aria-controls="collapse_{{conn.id}}">
              <ng-container *ngIf="conn.connectionName">{{conn.connectionName}}</ng-container>
              <ng-container *ngIf="!conn.connectionName">
                <i>(Please enter connection name)</i>
              </ng-container>
            </a>
          </h4>
        </div>
        <div id="collapse_{{conn.id}}" class="panel-collapse collapse" role="tabpanel"
          aria-labelledby="heading_{{conn.id}}">
          <div class="panel-body">
            <div class="row">
              <div class="col-lg-6 col-xs-12">
                <div class="row">
                  <div class="col-sm-6 col-xs-12">
                    <div class="form-group" [class.has-error]="!conn.connectionName">
                      <label class="control-label">Name</label>
                      <input type="text" trim="blur" class="form-control" placeholder="Name"
                        [(ngModel)]="conn.connectionName" [disabled]="!conn.allowDelete" maxlength="50" required>
                      <span *ngIf="!conn.connectionName" class="help-block">Connection name is required</span>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label class="control-label">Domain</label>
                      <input type="text" trim="blur" class="form-control" placeholder="Domain"
                        [(ngModel)]="conn.domain" [disabled]="!conn.allowDelete" maxlength="250">
                    </div>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label class="control-label">Client Id</label>
                      <input type="text" trim="blur" class="form-control" placeholder="Client Id"
                        [(ngModel)]="conn.clientId" [disabled]="!conn.allowDelete" maxlength="250">
                    </div>
                  </div>
                  <div class="col-sm-6 col-xs-12">
                    <div class="form-group">
                      <label class="control-label">Client Secret</label>
                      <input type="text" trim="blur" class="form-control" placeholder="Client Secret"
                        [(ngModel)]="conn.clientSecret" [disabled]="!conn.allowDelete" maxlength="250">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button type="button" class="btn btn-primary" style="margin-top: 16px" *ngIf="conn.allowDelete"
              (click)="edit(conn)">Save</button>
            <button type="button" class="btn btn-default" style="margin: 16px 0 0 8px" *ngIf="conn.allowDelete"
              (click)="connIdDelete = conn.id; modalDelete.open()">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<bs-modal #modalDelete>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you want to delete this Connection?
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalDelete.close()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="delete()">Submit</button>
  </bs-modal-footer>
</bs-modal>
