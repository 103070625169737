import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SettingService } from '../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import Thickness from '../../global-configuration/global-thickness/thickness.model';
import EdgeType from '../../global-configuration/global-thickness/edgeType.model';
import { TenantSlabThicknessConfigService } from '@app-admin/services/tenant-slab-thickness-config.service';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'tab-thickness',
  templateUrl: './thickness.html'
})

export class ThicknessComponent implements OnInit {

  @Input() tenantId: number;
  @Output() calcHeightTable = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  @ViewChild('modalConfirm2') modalConfirm2: BsModalComponent;

  loading = false;
  data: Thickness[];
  unassignData: any[] = [];
  dataFilter: Thickness[];
  dataForm: FormGroup;
  edgeTypes: EdgeType[];
  thicknessId: number = 0;
  replaceId: number = 0;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService
    , private tenantSlabThicknessConfigService: TenantSlabThicknessConfigService) {

  }

  ngOnInit() {
    this.loadData();
  }

  private loadData(): void {
    this.loading = true;
    Promise.all([
      this.settingService.getEdgeTypes(this.tenantId),
      this.settingService.getTenantThicknesses(this.tenantId),
    ]).then(res => {
      if (!res[0].status || !res[1].status) {
        this.toast.error('Load data failed');
      }
      this.edgeTypes = res[0].data.filter(x => x.undermount);

      this.data = res[1].data;
      this.filterData();
      this.loading = false;
      this.calcHeightTable.emit();
    });

    this.tenantSlabThicknessConfigService.getUnassignThicknesses(this.tenantId).then(res => {
      if (!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.unassignData = res.data;
    });
  }

  private filterData(): void {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  private createItemView(e): FormGroup {
    return this.fb.group({
      id: e.id,
      thicknessId: e.thicknessId,
      customNameAlias: e.customNameAlias,
      defaultUndermountEdge: e.defaultUndermountEdge,
      defaultUndermountEdgeId: e.defaultUndermountEdgeId,
      active: !!e.active,
      slabThicknessName: [e.slabThicknessName, Validators.required],
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
    });
  }

  private createItemUpdate(e): any {
    // let item = this.data.find(i => i.thicknessId == e.thicknessId);
    // item.slabThicknessName = e.slabThicknessName;
    return {
      id: e.id,
      customNameAlias: e.customNameAlias,
      defaultUndermountEdgeId: e.defaultUndermountEdgeId,
      active: e.active,
    };
  }

  save(): void {
    let dataUpdate = [];
    let isValid = true;
    let duplicate = false;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));

        e.markAsPristine();
        e.markAsUntouched();
        duplicate = !!(e.get('customNameAlias') as FormControl).errors;
      }
      if (e.value.customNameAlias == null || e.value.customNameAlias.trim() == "" || e.value.customNameAlias.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Thickness cannot be empty');
        }
        isValid = false;
      }


    });
    if (isValid && duplicate) {
      this.toast.error('This thickness already exists. System doesn\'t support duplicate thicknesses. Make appropriate changes and retry ');
      isValid = false;
    }

    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateTenantThickness(dataUpdate[0]).then(response => {
        if (response.status) {
          this.toast.success('Thickness has been updated');
          this.loadData();
        }
        else {
          this.toast.error(response.message);
        }
      });
    }
  }

  // checkDuplicate(e) {
  //   (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(m => {
  //     if (e.thicknessId.value != m.value.thicknessId && e.customNameAlias.value.trim().toUpperCase() == m.value.customNameAlias.trim().toUpperCase()) {
  //       e.customNameAlias.setErrors({ 'hasDup': true });
  //     }
  //   });
  // }

  add(): void {
    this.loading = true;
    this.tenantSlabThicknessConfigService.add(this.tenantId, this.thicknessId).then(res => {
      this.loading = false;
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.toast.success('Thickness added.');
      this.loadData();
      this.thicknessId = 0;
    })
  }

  remove(thicknessId: number): void {
    if (!thicknessId) return;
    this.thicknessId = thicknessId;
    this.tenantSlabThicknessConfigService.checkRemove(this.tenantId, this.thicknessId).then(res => {
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      if (res.data) {
        this.modalConfirm.open();
      } else {
        this.modalConfirm2.open();
        // this.submitRemove(false);
      }
    });
  }

  submitRemove(allowReplace: boolean): void {
    if (allowReplace && this.replaceId <= 0) return;

    this.modalConfirm.close();
    this.modalConfirm2.close();
    this.loading = true;
    this.tenantSlabThicknessConfigService.remove(this.tenantId, this.thicknessId, this.replaceId).then(res => {
      this.loading = false;
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.toast.success('Thickness removed.');
      this.loadData();
      this.thicknessId = 0;
      this.replaceId = 0;
    });
  }
}
