import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { SettingService } from '../../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { Config } from '@app-admin/config';

@Component({
  selector: 'tab-tile-general-rates',
  templateUrl: './general-rates.html'
})

export class TileGeneralRatesComponent implements OnInit {
  @Input() tenantId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();

  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;

  constructor(private fb: FormBuilder, private toast: ToastrService, private settingService: SettingService) {

  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.settingService.getGeneralRates(this.tenantId, Config.BusinessUnitId.Tile).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }

  filterData() {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      rateId: e.rateId,
      rateDescription: e.rateDescription,
      // rate: new CurrencyPipe('en-US').transform(e.rate, null, null, '.4-4'),
      rate: e.rate,
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.rateId == e.rateId);
    item.rateDescription = e.rateDescription;
    item.rate = this.settingService.replaceMask(e.rate);
    return {
      rateId: e.rateId,
      rateDescription: e.rateDescription,
      // rate: this.settingService.replaceMask(e.rate)
      rate: e.rate,
    };
  }

  save() {
    let dataUpdate = [];
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (dataUpdate.length > 0) {
      this.settingService.updateGeneralRates(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('General rates has been updated');
          this.loadData();
          if (dataUpdate.length > 0) {
            // this.settingService.setBidsIsRefreshByTenant(this.tenantId);
          }
        }
        else this.toast.error('Update general rates failed');
      });
    }
  }
}
