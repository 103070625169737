<div class="row">
  <div class="col-lg-12 col-md-12 col-xs-12">
    <div class="panel-body">
      <div style="margin-bottom: 1.0rem;">
        <button type="button" class="btn btn-primary mr-2" style="margin-right: 2.0rem;" (click)="openModal()">Assign Tenant</button>
      </div>

      <div class="table-responsive" *ngIf="assignedTenants?.length > 0">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th [style.width.px]="300">Tenant</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of assignedTenants">
              <tr>
                <td [style.width.px]="300"><b>{{ item.tenantName }}</b> (assigned {{ item.countAssigned }} license{{ item.countAssigned > 1 ? 's' : '' }})</td>
                <td>
                  <button type="button" class="btn btn-primary" (click)="openModalConfirmRemove(item.tenantId)"
                    *ngIf="!!item.isModify" style="margin-right: 15px;">Remove</button>
                  <button type="button" class="btn btn-default" (click)="item.isModify = !!!item.isModify" *ngIf="!!!item.isModify">Modify</button>
                  <button type="button" class="btn btn-default" (click)="cancelModify(item)" *ngIf="!!item.isModify">Canncel</button>
                </td>
              </tr>
              <ng-container *ngIf="!!item.isModify">
                <tr>
                  <th [style.width.px]="30"></th>
                  <th>Module</th>
                  <th>License Type</th>
                </tr>
                <tr *ngFor="let child of item.childs">
                  <td [style.width.px]="30"></td>
                  <td>{{ child.moduleName }}</td>
                  <td>
                    <select class="form-control" style="cursor: pointer;" (change)="validate(child, $event.target.value)" [(ngModel)]="child.licenseTypeId">
                      <option [value]="0">None</option>
                      <option *ngFor="let lt of getLicenseTypesByModuleId(child.moduleId, item.tenantId)" [value]="lt.licenseTypeId">
                        {{ getDisplayLicenseType(lt) }}
                      </option>
                    </select>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<bs-modal #modal data-backdrop="static" data-keyboard="false">
  <bs-modal-header [showDismiss]="false">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    <label>Are you sure? {{ valueValidate == 0 ? 'This will remove all roles from this module.' : 'This will remove roles that are not compliant with license type.' }}</label>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button #btn class="btn btn-primary" (click)="confirm(true);" autofocus>Yes</button>
    <button class="btn btn-secondary" (click)="confirm(false);">No</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalConfirmRemove data-backdrop="static" data-keyboard="false">
  <bs-modal-header [showDismiss]="false">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    <label>Are you sure? This will remove user from Tenant.</label>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button #btn class="btn btn-primary" (click)="confirmRemove(true);" autofocus>Yes</button>
    <button class="btn btn-secondary" (click)="confirmRemove(false);">No</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalAddTenant>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Select Tenant(s)</h4>
  </bs-modal-header>
  <bs-modal-body>
    <div class="table-responsive">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th [style.width.px]="70"></th>
            <th>Tenant</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let t of tmpTenants">
            <td [style.width.px]="70">
              <input type="checkbox" [(ngModel)]="t.isAssigned">
            </td>
            <td>{{ t.tenantName }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalAddTenant.close()">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="disabledBtn()" (click)="addTenants()">Assign</button>
  </bs-modal-footer>
</bs-modal>
