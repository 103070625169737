import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class CostSchemaService {

constructor(private http: AuthHttp) { }
 copy(id): Promise<any>{
  return this.http.get(`CostSchemas/Copy/${id}`).toPromise();
 }
 delete(id, reassignCostSchemaId): Promise<any>{
  return this.http.delete(`CostSchemas/${id}/${reassignCostSchemaId}`).toPromise();
 }
 isAssignedToChannel(costSchemaId): Promise<any>{
  return this.http.get(`CostSchemas/IsAssignedToChannel/${costSchemaId}`).toPromise();
  }

  getCostSchemasActive(businessUnitId: number, tenantId?: number): Promise<any> {
    return this.http.get(`CostSchemas/GetAllActive/${businessUnitId}` + (tenantId ? `?tenantId=${tenantId}` : ``)).toPromise();
  }

 clearAllComments(schemaId):Promise<any>{
    return this.http.delete(`CostSchemas/ClearAllComments/${schemaId}`).toPromise();
 }
 addTenantCostSchema(tenantId, costSchemaId): Promise<any>{
  return this.http.get(`CostSchemas/AddTenantCostSchema/${tenantId}/${costSchemaId}`).toPromise();
 }
 removeTenantCostSchema(tenantId, costSchemaId): Promise<any>{
  return this.http.get(`CostSchemas/RemoveTenantCostSchema/${tenantId}/${costSchemaId}`).toPromise();
 }
 getCostSchemaAssignForTenant(tenantId, businessUnitId): Promise<any>{
  return this.http.get(`CostSchemas/GetCostSchemaAssignForTenant/${tenantId}/${businessUnitId}`).toPromise();
 }
}
