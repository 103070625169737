import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class TenantExecutionProcessConfigService {

  controller = 'TenantExecutionProcessConfiguration';

  constructor(private http: AuthHttp) { }

  getAssignData(tenantId: number, businessUnitId: number): Promise<any> {
    return this.http.get(`${this.controller}/GetAssignData/${tenantId}/${businessUnitId}`).toPromise();
  }

  updateData(model: any): Promise<any> {
    return this.http.post(`${this.controller}/UpdateData`, JSON.stringify(model)).toPromise();
  }

  sortData(id: number, sortNumber: number, businessUnitId: number): Promise<any> {
    return this.http.post(`${this.controller}/SortData/${id}/${sortNumber}/${businessUnitId}`).toPromise();
  }

  add(model: any): Promise<any> {
    return this.http.post(`${this.controller}/Add/`, JSON.stringify(model)).toPromise();
  }

  remove(id: number, replaceId: number): Promise<any> {
    return this.http.delete(`${this.controller}/Remove/${id}/${replaceId}`).toPromise();
  }

  checkRemove(tenantId: number, id: number): Promise<any> {
    return this.http.get(`${this.controller}/CheckRemove/${tenantId}/${id}`).toPromise();
  }

  // getUnassignData(tenantId: number): Promise<any> {
  //   return this.http.get(`${this.controller}/GetUnassignData/${tenantId}`).toPromise();
  // }
}
