<div class="row">

  <!-- <div>
    <div class="col-xs-12">
      <h4 class="text-bold" style="margin-bottom: 15px">Schedule Categories</h4>
    </div>
  </div> -->

  <div class="col-12">
    <label style="font-size: medium; margin-left: 10px">
      <!-- <span>
        <a
          (click)="change('Measure')"
          [ngClass]="{'sub-menu-hight': currentTab == 'Measure', 'sub-menu-txt': currentTab != 'Measure'}"
          >Measure
        </a>
      </span>
      | -->
      <span>
        <a
          (click)="change('Fabrication')"
          [ngClass]="{'sub-menu-hight': currentTab == 'Fabrication', 'sub-menu-txt': currentTab != 'Fabrication'}"
          >Schedule Categories
        </a>
      </span>
      <!-- |
      <span>
        <a
          (click)="change('Install')"
          [ngClass]="{'sub-menu-hight': currentTab == 'Install', 'sub-menu-txt': currentTab != 'Install'}"
          >Install
        </a>
      </span> -->
    </label>
  </div>

  <tab-global-fabrication *ngIf="currentTab == 'Fabrication'" [calcHeightTable]="calcHeightTable"></tab-global-fabrication>

</div>
