import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../../services/report.service';
import { BsModalComponent } from 'ng2-bs3-modal';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SettingService } from '../../services/setting.service';
declare var $: any;

@Component({
  selector: 'page-reports',
  templateUrl: './reports.html',
  encapsulation: ViewEncapsulation.None
})

export class ReportsComponent implements OnInit {
  @ViewChild('modal') modal: BsModalComponent;
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  @ViewChild('modalConfirmDelete') modalConfirmDelete: BsModalComponent;
  @ViewChild('modalAssignRole') modalAssignRole: BsModalComponent;

  @ViewChild('imageInput') imageInput: ElementRef;
  loadingTenant: any;
  tenants: any;
  listRoles;
  tenantId = 0;
  reportIdDelete = 0;
  reportIdAssign = 0;
  modal_title: string;

  reports: any;
  reportCategories: any;
  reportsOfTenantFromActiveReport: any;
  form: FormGroup;
  loading_submit: boolean;
  waiting_loading: string;
  error_message = '';

  paginationPage = 1;
  paginationCount: number;

  filter: PaginationFilter = {
    orderBy: 'orderIndex',
    order: 'asc',
    itemPerPage: 50,
    pageNumber: 1,
    tenantId: 1,
    active: true
  };
  isShowInactive: boolean = false;

  MODULE_TYPE = {
    All : 0,
    SalePro : 1,
    OrderPro : 2,
    SchedulePro : 3,
    ShopPro : 6,
    FieldPro : 7,
    Director : 4
  }

  REPORT_CATEGORIES = {
    Proposals : 1,
    ChangeOrders : 2,
    Management: 4,
    Contracts: 5,
    ProductionSalesOrders: 6,
    Communities: 7,
    MeasureWorkOrder: 8,
    FabricationWorkOrder: 9,
    InstallWorkOrder: 10,
    SalesModule: 11,
    ScheduleModule: 12,
    ProductionModule: 13,
    ShopModule: 14,
    PriceDirectorModule: 15
  }

  constructor(private settingService: SettingService, private reportService: ReportService, formBuilder: FormBuilder, private toastr: ToastrService) {
    this.form = formBuilder.group({
      reportId: [0],
      reportName: ['', Validators.required],
      reportDescription: [''],
      reportCategoryId: ['', Validators.required],
      reportObjectName: [null, Validators.required],
      sourceReport: ['', Validators.required],
      tenantId: [''],
      imageId: [''],
    });
  }

  get reportId(): FormControl {
    return this.form.get('reportId') as FormControl;
  }

  get reportName(): FormControl {
    return this.form.get('reportName') as FormControl;
  }

  get reportDescription(): FormControl {
    return this.form.get('reportDescription') as FormControl;
  }

  get reportCategoryId(): FormControl {
    return this.form.get('reportCategoryId') as FormControl;
  }
  getErrorReportCategoryId() {
    return this.reportCategoryId.hasError('required') ? 'Report category is required' : '';
  }

  get reportObjectName(): FormControl {
    return this.form.get('reportObjectName') as FormControl;
  }
  getErrorReportObjectName() {
    return this.reportObjectName.hasError('required') ? 'Source report is required' : '';
  }

  get sourceReport(): FormControl {
    return this.form.get('sourceReport') as FormControl;
  }

  ngOnInit() {
    $('body').layout('fix');
    if (sessionStorage.getItem('tenantId')) this.tenantId = Number(sessionStorage.getItem('tenantId'));

    this.loadingTenant = true;
    this.settingService.getTenant().then(response => {
      if (response.status) this.tenants = response.data;
      else this.toastr.error('Can\'t get list tenant');
      this.loadingTenant = false;
    });

    this.reportService.getAllReportCategory().then(res => {
      if (res.status) this.reportCategories = res.data;
      else this.toastr.error('Can\'t get list report category');
    });
    this.getListReport();
  }

  changeTenant() {
    sessionStorage.setItem('tenantId', this.tenantId.toString());
    this.getListReport();
  }

  setSourceReport() {
    this.sourceReport.setValue(this.reportsOfTenantFromActiveReport.find(rc => rc.id == this.reportObjectName.value).title);
    //this.sourceReport.setValue(this.reportsOfTenantFromActiveReport.find(rc => rc._id == this.reportObjectName.value).Name);
  }

  getListReport() {
    this.reports = null;
    this.waiting_loading = 'Loading...';
    this.filter.active = !this.isShowInactive;
    this.filter.tenantId = this.tenantId;
    if (this.filter.tenantId == 0) return;
    this.reportService.getListReportsWithStatus(this.filter).then(response => {
      if (response.status && response.data.total > 0) {
        this.reports = response.data.listData;
        this.paginationCount = response.data.total;
        this.waiting_loading = '';
      } else {
        this.waiting_loading = 'No data';
      }
      this.loadingTenant = false;
    }).catch(() => {
      this.reports = [];
      this.loadingTenant = false;
      this.waiting_loading = 'No data';
      this.paginationCount = 0;
    });
  }

  getListReportOfTenantFromActiveReport() {
    this.reportsOfTenantFromActiveReport = [];
    var tenantName = this.tenants.find(t => t.tenantId == this.tenantId).tenantName;
    this.reportService.getDocumentsByTenantName('rdl', tenantName).then(res => {
      this.modal.open();
      this.reportsOfTenantFromActiveReport =res.data;
    });
  }

  addNewReport() {
    this.modal_title = 'New Report';
    this.form.reset();
    this.form.patchValue({
      reportId: 0,
      reportName: '',
      reportDescription: '',
      reportCategoryId: '',
      reportObjectName: null,
      sourceReport: '',
      tenantId: this.tenantId,
      imageId: '',
    });
    this.imageInput!.nativeElement!.value = '';
    this.getListReportOfTenantFromActiveReport();
  }

  update(t) {
    this.modal_title = 'Edit Report';
    this.form.reset();
    this.form.patchValue({
      reportId: t.reportId,
      reportName: t.reportName,
      reportDescription: t.reportDescription,
      reportCategoryId: t.reportCategoryId,
      reportObjectName: t.reportObjectName,
      sourceReport: t.sourceReport,
      tenantId: this.tenantId,
      imageId: t.imageId,
    });
    // let file = this.dataURLtoFile(t.imageId, `${t.reportId}.jpg`);
    // this.imageInput.nativeElement.files.push(file);
    // this.imageInput!.nativeElement!.value = t.imageId ? `${t.reportId}.jpg` : '';
    this.imageInput!.nativeElement!.value = '';
    this.getListReportOfTenantFromActiveReport();
  }

  activeReport(report, status) {
    this.reportService.activeReport(report.reportId, status).then(response => {
      if (response.status) {
        if (status) {
          this.toastr.success('Activate report successfully');
        } else {
          this.toastr.success('Deactivate report successfully');
        }
        report.active = !report.active;
      } else {
        if (status) {
          this.toastr.error('Activate report failed');
        } else {
          this.toastr.error('Deactivate report failed');
        }
      }
    });
  }

  setReportStandard(report, status) {
    this.reportService.setReportStandard(report.reportId, status).then(response => {
      if (response.status) {
        if (status) {
          this.toastr.success('Set report standard successfully');
        } else {
          this.toastr.success('Set report unstandard successfully');
        }
        report.IsStandard = !report.IsStandard;
      } else {
        if (status) {
          this.toastr.error('Set report standard failed');
        } else {
          this.toastr.error('Set report unstandard failed');
        }
      }
    });
  }
  assignRoles(reportId) {
    var report = this.reports.find(x => x.reportId == reportId);
    this.reportIdAssign = reportId;
    this.reportService.loadRoleByTenantId(this.tenantId, this.reportIdAssign).then(res => {
      if (res.status) {
        this.listRoles = res.data;
        if(report) {
          var moduleId = this.checkModuleCategory(report.reportCategoryId);
          this.listRoles = this.listRoles.filter(x => (moduleId == this.MODULE_TYPE.All || x.moduleId == moduleId));
        }
        this.modalAssignRole.open();
      }
      else {
        this.toastr.error('Load list roles failed.');
      }
    });
  }

  submitAssignRole() {
    let listRole = [];
    this.listRoles.forEach(e => {
      if (e.isCheck == '1') listRole.push(e.roleId);
    });

    this.reportService.assignReportPermission(this.tenantId, this.reportIdAssign, listRole).then(response => {
      if (response.status) {
        this.toastr.success('Assign role for report successfully.', 'Success');
        this.modalAssignRole.close();
      } else {
        this.toastr.error('Assign role for report failed.', 'Error');
        this.modalAssignRole.close();
      }
      // this.getListReport();
    }).catch(() => {
      this.toastr.error('Internal Server Error', 'Error');
      this.modalAssignRole.close();
    });
  }

  updateOrderIndex(event: any, row: any) {
    if(!event.target.value){
      return;
    }
    this.loading_submit = true;
    row.orderIndex = event.target.value;
    this.reportService.updateReportOrderIndexWithStatus(row).then(response => {
      this.loading_submit = false;
      if (response.status && response.data >= 0) {
        this.toastr.success(response.message, 'Report has been updated');
        this.getListReport();
        this.modal.close();
      }
      else {
        this.reportName.setErrors({ 'isExistReport': true });
        this.error_message = response.message;
      }
    });
  }
  submit() {
    if (this.form.valid) {
      this.loading_submit = true;
      if (this.reportId.value > 0) {
        this.reportService.updateReport(this.form.value).then(response => {
          this.loading_submit = false;
          if (response.status && response.data >= 0) {
            this.toastr.success(response.message, 'Report has been updated');
            this.getListReport();
            this.modal.close();
          }
          else {
            this.reportName.setErrors({ 'isExistReport': true });
            this.error_message = response.message;
          }
        });
      }
      else {
        this.reportService.addNewReport(this.form.value).then(response => {
          this.loading_submit = false;
          if (response.status && response.data > 0) {
            this.toastr.success(response.message, 'New report has been created');
            this.getListReport();
            this.modal.close();
          }
          else {
            this.reportName.setErrors({ 'isExistReport': true });
            this.error_message = response.message;
          }
        });
      }
    }
  }

  deleteReport(reportId) {
    this.reportIdDelete = reportId;
    this.modalConfirmDelete.open();
  }

  submitDelete() {
    this.modalConfirmDelete.close();
    this.reportService.deleteReport(this.reportIdDelete).then(response => {
      this.loading_submit = false;
      if (response.status) {
        this.toastr.success(response.message, 'Report has been removed');
        this.getListReport();
      }
    }).catch(() => {
      this.loading_submit = false;
      this.toastr.error('Internal Server Error', 'Error');
    });
  }

  changeFilter(orderBy: string) {
    if (!orderBy) {
      this.filter.orderBy = 'reportName';
      this.filter.order = 'desc';
    }
    else if (orderBy === this.filter.orderBy) {
      if (this.filter.order === 'desc') {
        this.filter.order = 'asc';
      } else {
        this.filter.order = 'desc';
      }
    }
    else {
      this.filter.orderBy = orderBy;
      this.filter.order = 'desc';
    }
    this.getListReport();
  }

  goToPage(n: number): void {
    if (this.filter.pageNumber === n) {
      return;
    }
    this.filter.pageNumber = n;
    this.getListReport();
  }

  onNext(): void {
    this.filter.pageNumber = +this.filter.pageNumber;
    this.filter.pageNumber++;
    this.getListReport();
  }

  onPrev(): void {
    this.filter.pageNumber = +this.filter.pageNumber;
    this.filter.pageNumber--;
    this.getListReport();
  }

  selectedFile: ImageSnippet;

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      this.selectedFile = new ImageSnippet(event.target.result, file);

      this.form.get('imageId').setValue(this.selectedFile.src);
    });

    reader.readAsDataURL(file);
  }

  // dataURLtoFile(dataurl, filename) {

  //   var arr = dataurl.split(','),
  //       mime = arr[0].match(/:(.*?);/)[1],
  //       bstr = atob(arr[1]),
  //       n = bstr.length,
  //       u8arr = new Uint8Array(n);

  //   while(n--){
  //       u8arr[n] = bstr.charCodeAt(n);
  //   }

  //   return new File([u8arr], filename, {type:mime});
  // }
  changeShowInactive() {
    this.getListReport();
  }

  checkModuleCategory(categoryid) {
    switch(categoryid) {
      case this.REPORT_CATEGORIES.Proposals:
      case this.REPORT_CATEGORIES.Management:
        return this.MODULE_TYPE.All;

      case this.REPORT_CATEGORIES.ChangeOrders:
      case this.REPORT_CATEGORIES.Contracts:
      case this.REPORT_CATEGORIES.ProductionSalesOrders:
      case this.REPORT_CATEGORIES.SalesModule:
        return this.MODULE_TYPE.SalePro;

      case this.REPORT_CATEGORIES.Communities:
        return this.MODULE_TYPE.OrderPro;

      case this.REPORT_CATEGORIES.MeasureWorkOrder:
      case this.REPORT_CATEGORIES.FabricationWorkOrder:
      case this.REPORT_CATEGORIES.InstallWorkOrder:
      case this.REPORT_CATEGORIES.ScheduleModule:
        return this.MODULE_TYPE.SchedulePro

      case this.REPORT_CATEGORIES.ProductionModule:
        return this.MODULE_TYPE.FieldPro

      case this.REPORT_CATEGORIES.ShopModule:
        return this.MODULE_TYPE.ShopPro

      case this.REPORT_CATEGORIES.PriceDirectorModule:
        return this.MODULE_TYPE.Director;
    }
  }
}

export class PaginationFilter {
  orderBy: string;
  order: string;
  itemPerPage: number;
  pageNumber: number;
  tenantId: number;
  active: boolean;
}

class ImageSnippet {
  constructor(public src: string, public file: File) {}
}
