import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { SettingService } from '../../../../../../services/setting.service';
import { UserService } from '../../../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tab-tile-size-weights',
  templateUrl: './tile-size-weights.html',
})

export class TileSizeWeightsComponent implements OnInit, OnDestroy {
  @Input() costSchemaId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @ViewChild('modalViewComments') modalViewComments: any;
  notes:any;
  beginFilterData = new EventEmitter<any>();
  countToBeginFilterData = 0;

  tileTypes: any;
  typeId: any;
  processFilter: any;
  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;

  constructor(private fb: FormBuilder, private toast: ToastrService,
    private settingService: SettingService, private userService: UserService) {
    this.beginFilterData.subscribe(() => {
      this.countToBeginFilterData = this.countToBeginFilterData + 1;
      if (this.countToBeginFilterData == 2) {
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
    });
  }

  ngOnInit() {
    this.loadData();
  }
  ngOnDestroy(){
    this.save();
  }
  viewComment(item:any){
    const id = item.value.id;
    this.settingService.getMtxNoteTileSizeWeightById(id).then(res=> {
      if(res.status){
        this.notes = res.data;
        // this.viewComments.open();
        this.modalViewComments.open();
      }
      else this.toast.error('view comment failed.');
    });
  }
  loadData(resetProcessId = true) {
    this.loading = true;
    this.countToBeginFilterData = 0;

    this.settingService.getAllTileTypes().then(response => {
      if (response.status) {
        this.tileTypes = response.data;
        this.filterProcess(resetProcessId);
        this.beginFilterData.emit();
      }
      else this.toast.error('Can\'t get list process');
    });

    this.settingService.getTileSizeWeight(this.costSchemaId).then(response => {
      if (response.status) {
        this.data = response.data;
        this.beginFilterData.emit();
      }
      else this.toast.error("Load data failed");
    });
  }

  filterProcess(resetProcessId = true) {
    this.processFilter = this.tileTypes;
    if (resetProcessId) this.typeId = this.processFilter[0].typeId;
  }

  filterData() {
    this.dataFilter = this.data.filter(e => e.typeId == this.typeId);
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
    if (this.userService.isTenantOnly()) this.dataForm.disable();
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      id: e.id,
      sizeDescription: e.sizeDescription,
      // percentage: new PercentPipe('en-US').transform(e.percentage, '.2-2'),
      percentage: e.percentage * 100,
      note: e.note,
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.id == e.id);
    item.percentage = this.settingService.replaceMask(e.percentage) / 100;
    item.note = e.note;
    return {
      id: e.id,
      percentage: this.settingService.replaceMask(e.percentage) / 100,
      note: e.note,
    };
  }

  save() {
    let dataUpdate = [];
    let cellUpdate: any;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        cellUpdate = e;
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (dataUpdate.length > 0) {
      this.settingService.updateTileSizeWeight(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Application weights has been updated');
          cellUpdate?.get('updatedOn').setValue(response.data);
          cellUpdate?.get('updatedBy').setValue(this.userService.getCurrentFullName());
        }
        else this.toast.error('Update application weights failed');
      });
    }
  }
}
