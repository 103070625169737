import { DecimalPipe, PercentPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class Config {

  public static avatarDefault = 'assets/img/avatar_default.jpg';
  public static validateEmail = '[a-zA-Z0-9._-]+[@]+[a-zA-Z0-9-]+[.]+[a-z]{2,6}?([.][a-z]{2})?';
  public static validatePhone = '[0-9]{10}';
  public static validatePIN = '[0-9]{5}';

  public static BusinessUnitId = {
    Slab: 1,
    Tile: 2,
  };

  public static ApplicationTypeId = {
    Slab: 0,
    FlatWork: 1,
    Vertical: 2,
  };

  public static ProcessTypeId = {
    Tile: 1,
    Fab: 2,
    Install: 3,
  };

  public static WorkOrderTypeId = {
    Fab: 1,
    Install: 2,
    Measure: 3,
  }

  public static percentFormat(value: number, isDivide: boolean = false): string {
    value = isDivide ? value / 100 : value;
    return new PercentPipe('en-US').transform(value, '.2-2');
  }

  public static numberFormat(value: number): string {
    return new DecimalPipe('en-US').transform(value, '.2-2');
  }
}
