import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from '@app-admin/services/setting.service';
import { WorkerService } from '@app-admin/services/worker.service';
declare var $: any;

@Component({
  templateUrl: './workers.html'
})

export class WorkersComponent implements OnInit {

  submittingSearch: boolean;
  workers: Array<any>;
  waiting_loading: string;
  paginationCount: number;
  arrTenant: any;
  tenantId = 0;
  filter: PaginationFilter = {
    textSearch: '',
    tenant: 0,
    itemPerPage: 20,
    pageNumber: 1,
    active: true
  };
  showInactive = false;

  constructor(private workerService: WorkerService, private toastr: ToastrService, private settingService: SettingService) {
    //
  }

  ngOnInit() {
    $('body').layout('fix');
    if (sessionStorage.getItem('tenantId')) {
      this.tenantId = Number(sessionStorage.getItem('tenantId'));
      this.filter.tenant = this.tenantId;
    }
    this.getAllTenant();
    this.getAllWorkers();
  }

  changeTenant() {
    sessionStorage.setItem('tenantId', this.tenantId.toString());
    this.filter.tenant = this.tenantId;
    this.filter.pageNumber = 1;
    this.getAllWorkers();
  }

  getAllTenant() {
    this.arrTenant = null;
    this.settingService.getTenant().then(response => {
      if (response.status) {
        this.arrTenant = response.data;
      } else {
        this.toastr.error('Can\'t get list tenant');
      }
    });
  }

  getAllWorkers() {
    this.workers = null;
    this.submittingSearch = true;
    this.waiting_loading = 'Loading...';
    this.workerService.getAllWorkersByFilter(this.filter).then(response => {
      this.submittingSearch = false;
      if (response.status && response.data.total > 0) {
        this.workers = response.data.listWorker;
        this.paginationCount = response.data.total;
      } else {
        this.waiting_loading = 'No result found.';
      }
    }).catch(() => {
      this.toastr.error('Internal Server Error', 'Error');
    });
  }

  goToPage(n: number): void {
    if (this.filter.pageNumber === n) {
      return;
    }
    this.filter.pageNumber = n;
    this.getAllWorkers();
  }

  onNext(): void {
    this.filter.pageNumber = +this.filter.pageNumber;
    this.filter.pageNumber++;
    this.getAllWorkers();
  }

  onPrev(): void {
    this.filter.pageNumber = +this.filter.pageNumber;
    this.filter.pageNumber--;
    this.getAllWorkers();
  }

  search() {
    this.filter.pageNumber = 1;
    this.getAllWorkers();
  }

  onShowInactive(){
    this.filter.active = !this.showInactive;
    this.getAllWorkers();
  }
}

export class PaginationFilter {
  textSearch: string;
  tenant: number;
  itemPerPage: number;
  pageNumber: number;
  active: boolean;
}
