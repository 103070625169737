import { Routes, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TenantsService } from '../../services/tenants.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
// import { Route } from '@angular/compiler/src/core';
declare var $: any;
import * as _ from 'lodash';

@Component({
  templateUrl: './tenants.html'
})

export class TenantsComponent implements OnInit {
  @ViewChild('modal') modal: BsModalComponent;
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  modal_title: string;

  tenants: any;
  timezones: any;

  form: FormGroup;
  loading_submit: boolean;
  waiting_loading: string;
  error_message = '';

  paginationPage = 1;
  paginationCount: number;

  filter: PaginationFilter = {
    orderBy: 'tenantId',
    order: 'desc',
    itemPerPage: 20,
    pageNumber: 1,
    isShowAll: false,
  };

  constructor(private service: TenantsService, private formBuilder: FormBuilder, private toastr: ToastrService, private router: Router) {
    this.form = this.formBuilder.group({
      tenantId: [0],
      tenantName: ['', [Validators.required, Validators.pattern('^[a-z0-9._-]+$')]],
      company: ['', [Validators.required]],
      website: ['', [Validators.required, Validators.pattern('^(?:https?:\/\/)?(?:[-a-z0-9]+\\.)+[-a-z0-9]+(?:(?:(?:\/[-=&?.#a-z0-9]+)+)\/?)?$')]],
      phone: ['', [Validators.pattern('[0-9]{10}')]],
      timeZoneId: ['', Validators.required],
      active: [true]
    });
  }

  get tenantId(): FormControl {
    return this.form.get('tenantId') as FormControl;
  }
  get tenantName(): FormControl {
    return this.form.get('tenantName') as FormControl;
  }
  get company(): FormControl {
    return this.form.get('company') as FormControl;
  }
  get website(): FormControl {
    return this.form.get('website') as FormControl;
  }
  get phone(): FormControl {
    return this.form.get('phone') as FormControl;
  }
  get timeZoneId(): FormControl {
    return this.form.get('timeZoneId') as FormControl;
  }

  ngOnInit() {
    $('body').layout('fix');
    this.getListTimeZone();
  }

  getListTimeZone() {
    this.service.getTimeZone().then(response => {
      if (response.status) {
        this.timezones = response.data;
        this.getListTenant();
      }
      else this.toastr.error('Can\'t load list timezone');
    });
  }

  getListTenant() {
    let _this = this;
    this.tenants = null;
    this.waiting_loading = 'Loading...';
    this.service.GetListTenant(this.filter).then(response => {
      if (response.status && response.data.total > 0) {
        this.tenants = _.map(response.data.listTenant, function(o) {
          if (!!o.timeZoneId) o.timeZone = _.find(_this.timezones, ['id', o.timeZoneId])?.displayName;
          if (o.subscriptions?.length > 0) {
            o.modules = _.map(o.subscriptions, function(x) {
              let moduleName = 'Unknow';
              if (x.moduleId == 1) moduleName = 'Sales Pro';
              if (x.moduleId == 2) moduleName = 'Order Pro';
              if (x.moduleId == 3) moduleName = 'Schedule Pro';
              if (x.moduleId == 4) moduleName = 'Director';
              if (x.moduleId == 5) moduleName = 'Admin';
              if (x.moduleId == 6) moduleName = 'Shop Pro';
              if (x.moduleId == 7) moduleName = 'Field Pro';
              if (x.moduleId == 8) moduleName = 'Inventory Pro';
              return moduleName;
            }).join(', ');
          }
          return o;
        });
        // if (!this.isShowAll) this.tenants = _.filter(this.tenants, ['active', true]);
        this.paginationCount = response.data.total;
      } else { this.waiting_loading = 'No data'; }
    });
  }

  addNewTenant() {
    this.router.navigate(['/new-tenant']);
    // this.modal_title = 'New Tenant';
    // this.form.reset();
    // this.form.setValue({
    //   tenantId: 0,
    //   tenantName: '',
    //   company: '',
    //   website: '',
    //   phone: '',
    //   timeZoneId: '',
    //   active: true
    // });
    // this.modal.open();
  }

  updateTenantStatus(t) {
    t.active = !t.active;
    this.service.UpdateTenant(t).then(response => {
      if (response.status && response.data) {
        if (t.active) this.toastr.success(response.message, 'Tenant has been Activated');
        else this.toastr.success(response.message, 'Tenant has been deactivated');
      }
      else {
        t.active = !t.active;
        this.toastr.error(response.message, 'Update tenant Error');
      }
    }).catch(() => {
      t.active = !t.active;
      this.toastr.error('Internal Server Error', 'Error');
    });
  }

  submit() {
    if (this.form.valid) {
      this.loading_submit = true;
      if (this.tenantId.value > 0) {
        this.service.UpdateTenant(this.form.value).then(response => {
          this.loading_submit = false;
          if (response.status && response.data) {
            this.toastr.success(response.message, 'Tenant has been updated');
            this.getListTenant();
            this.modal.close();
          }
          else {
            this.tenantName.setErrors({ 'isExistTenant': true });
            this.error_message = response.message;
          }
        }).catch(() => {
          this.loading_submit = false;
          this.toastr.error('Internal Server Error', 'Error');
        });
      }
      else {
        this.loading_submit = false;
        this.toastr.error("This function is not available now.");
        // this.service.CreateTenant(this.form.value).then(response => {
        //   this.loading_submit = false;
        //   if (response.status && response.data) {
        //     this.service.insertData(response.data).then(result => {
        //       if (result.status) {
        //         this.toastr.success(response.message, 'Create new tenant successfully');
        //       }
        //       else this.toastr.error('Internal Server Error Create Data DPT ' + result.message, 'Error');
        //     }).catch(() => {
        //       this.toastr.error('Internal Server Error Create Data DPT ', 'Error');
        //     });
        //     this.getListTenant();
        //     this.modal.close();
        //   }
        //   else {
        //     this.tenantName.setErrors({ 'isExistTenant': true });
        //     this.error_message = response.message;
        //   }
        // }).catch(() => {
        //   this.loading_submit = false;
        //   this.toastr.error('Internal Server Error', 'Error');
        // });
      }
    }
  }

  // delete(id: number) {
  //   this.model = new Tenant();
  //   this.model.tenantId = id;
  //   this.modalConfirm.open();
  // }

  // submitDelete() {
  //   document.getElementById('submitDelete').setAttribute('disabled', 'disabled');
  //   document.getElementById('cancelDelete').setAttribute('disabled', 'disabled');
  //   this.service.RemoveTenant(this.model)
  //     .then(response => {
  //       document.getElementById('submitDelete').removeAttribute('disabled');
  //       document.getElementById('cancelDelete').removeAttribute('disabled');
  //       if (!response.status) {
  //         this.toastr.error(response.message, 'Error');
  //       } else {
  //         this.toastr.success(response.message, 'Success');
  //         this.getListTenant();
  //       }
  //       this.modalConfirm.close();
  //     })
  //     .catch(() => {
  //       this.toastr.error('Internal Server Error', 'Error');
  //       document.getElementById('submitDelete').removeAttribute('disabled');
  //       document.getElementById('cancelDelete').removeAttribute('disabled');
  //     });
  // }

  changeFilter(orderBy: string) {
    if (!orderBy) {
      this.filter.orderBy = 'tenantName';
      this.filter.order = 'desc';
    } else if (orderBy === this.filter.orderBy) {
      if (this.filter.order === 'desc') { this.filter.order = 'asc'; } else { this.filter.order = 'desc'; }
    } else {
      this.filter.orderBy = orderBy;
      this.filter.order = 'desc';
    }

    this.getListTenant();
  }

  goToPage(n: number): void {
    if (this.filter.pageNumber === n) { return; }
    this.filter.pageNumber = n;
    this.getListTenant();
  }

  onNext(): void {
    this.filter.pageNumber = + this.filter.pageNumber;
    this.filter.pageNumber++;
    this.getListTenant();
  }

  onPrev(): void {
    this.filter.pageNumber = + this.filter.pageNumber;
    this.filter.pageNumber--;
    this.getListTenant();
  }

  showall(event) {
    this.filter.isShowAll = event.target.checked;
    this.getListTenant();
  }
}

export class PaginationFilter {
  orderBy: string;
  order: string;
  itemPerPage: number;
  pageNumber: number;
  isShowAll: boolean;
}
