import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../../../services/dashboard.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { catchError, finalize, of } from 'rxjs';
import { SettingService } from '@app-admin/services/setting.service';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'page-dashboard-tenant-specific',
  templateUrl: './dashboard-tenant-specific.html',
})
export class DashboardTenantSpecificComponent implements OnInit {

  showLoading: boolean = true;
  dashboards: any[];
  tenants: any[];
  tenantId: number = 0;
  showInactive = false;

  // Assign Dashboard
  assignForm: FormGroup;
  @ViewChild('modalAssign') modalAssign: BsModalComponent;
  globalDashboards: any[];

  // Delete Dashboard
  dashboardIdDelete = 0;
  @ViewChild('modalConfirmDelete') modalConfirmDelete: BsModalComponent;

  // Assign roles
  dashboardIdAssign = 0;
  @ViewChild('modalAssignRole') modalAssignRole: BsModalComponent;
  listRoles: any[];

  // Update Dashboard
  dataForm: FormGroup;

  constructor(
    private dashboardService: DashboardService,
    private settingService: SettingService,
    private fb: FormBuilder,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.assignForm = this.fb.group({
      dashboardId: [0, Validators.required],
      tenantId: [0],
      dashboardAlias: ['', Validators.required],
      active: [true],
      isStandard: [true],
    });
    this.tenantId = this.getLocalTenantId();
    this.loadData();
    this.getTenants();
  }

  private loadData(): void {
    if (this.tenantId <= 0) return;
    this.showLoading = true;
    this.dashboardService.getTenantDashboards(this.tenantId)
      .pipe(catchError(val => of(val.error)))
      .pipe(finalize(() => this.showLoading = false))
      .subscribe(response => {
        if (!response.status) {
          return;
        }
        this.dashboards = response.data.filter(x => this.showInactive ? true : x.active);
        this.getGlobalDashboards();

        this.initUpdateForm();
      });
  }

  private initUpdateForm(): void {
    let dataUpdate = [];
    this.dashboards.forEach(e => {
        dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  assign(): void {
    this.assignForm.reset({
      dashboardId: 0,
      dashbaordAlias: 0,
      active: true,
      isStandard: true
    });
    this.modalAssign.open();
  }

  submitAssign(): void {
    if (this.assignForm.invalid) return;

    this.assignForm.get('tenantId').setValue(this.tenantId);

    this.showLoading = true;
    this.dashboardService.addTenantDashboard(this.assignForm.value)
      .pipe(catchError(val => of(val.error)))
      .pipe(finalize(() => {}))
      .subscribe(response => {
        if (!response.status) {
          this.toastr.error(response.message);
          return;
        }
        this.toastr.success('New dashboard assigned');
        this.loadData();
        this.modalAssign.close();
      });
  }

  private getGlobalDashboards(): void {
    // this.showLoading = true;
    this.dashboardService.getDashboards()
      .pipe(catchError(val => of(val.error)))
      .pipe(finalize(() => {}))
      .subscribe(response => {
        if (!response.status) {
          return;
        }
        const assignedDashboardIds = this.dashboards.map(x => x.dashboardId);
        this.globalDashboards = response.data.filter(x => !assignedDashboardIds.includes(x.dashboardId));
      });
  }

  changeTenant(): void {
    this.setLocalTenantId();
    this.loadData();
  }

  private setLocalTenantId(): void {
    localStorage.setItem('tenantId', this.tenantId.toString());
  }

  private getLocalTenantId(): number {
    return Number(localStorage.getItem('tenantId')) || 0;
  }

  private getTenants(): void {
    // this.showLoading = true;
    this.settingService.getTenant().then(response => {
      // this.showLoading = false;
      if (!response.status) {
        this.toastr.error(response.message);
        return;
      }
      this.tenants = response.data;
    });
  }

  onShowInactive(): void {
    this.loadData();
  }

  delete(id: number): void {
    this.dashboardIdDelete = id;
    this.modalConfirmDelete.open();
  }

  submitDelete(): void {
    this.showLoading = true;
    this.modalConfirmDelete.close();
    this.dashboardService.deleteTenantDashboard(this.dashboardIdDelete)
      .pipe(catchError(val => of(val.error)))
      .pipe(finalize(() => {}))
      .subscribe(response => {
        if (!response.status) {
          this.toastr.error(response.message);
          return;
        }
        this.dashboardIdDelete = 0;
        this.toastr.success('Dashboard removed');
        this.loadData();
      });
  }

  update(id: number, dashboardAlias?: string, active?: boolean, isStandard?: boolean): void {
    if (id <= 0) return;
    this.dashboardService.updateTenantDashboard(id, { dashboardAlias, active, isStandard })
      .pipe(catchError(val => of(val.error)))
      .pipe(finalize(() => {}))
      .subscribe(response => {
        if (!response.status) {
          this.toastr.error(response.message);
          return;
        }
        this.toastr.success('Dashboard updated');
        // this.loadData();
        let item = this.dashboards.find(x => x.id == id);
        if (!!item) {
          if (dashboardAlias != null) {
            item.dashboardAlias = dashboardAlias;
          }
          if (active != null) {
            item.active = active;
          }
          if (isStandard != null) {
            item.isStandard = isStandard;
          }
        }
        this.initUpdateForm();
      });
  }

  assignRoles(dashboardId): void {
    this.dashboardIdAssign = dashboardId;
    this.dashboardService.loadRoleByTenantId(this.tenantId, this.dashboardIdAssign).then(res => {
      if (!res.status) {
        this.toastr.error(res.message);
        return;
      }
      this.listRoles = res.data;
      this.modalAssignRole.open();
    });
  }

  submitAssignRole(): void {
    let listRole = [];
    this.listRoles.forEach(e => {
      if (e.isCheck == '1') listRole.push(e.roleId);
    });

    this.dashboardService.assignDashboardPermission(this.tenantId, this.dashboardIdAssign, listRole).then(response => {
      if (!response.status) {
        this.toastr.error(response.message);
        return;
      }
      this.toastr.success('Assign role for dashboard successfully.');
      this.modalAssignRole.close();
    });
  }

  private createItemView(e): FormGroup {
    return this.fb.group({
      id: e.id,
      dashboardId: e.dashboardId,
      dashboardName: e.dashboardName,
      dashboardDescription: e.dashboardDescription,
      dashboardAlias: e.dashboardAlias,
      menuName: e.menuName,
      dashboardCategoryName: e.dashboardCategoryName,
      isStandard: e.isStandard,
      active: e.active,
    });
  }

  private createItemUpdate(e): any {
    let item = this.dashboards.find(i => i.id == e.id);
    item.dashboardAlias = e.dashboardAlias;
    return {
      id: e.id,
      dashboardAlias: e.dashboardAlias,
    };
  }

  save(): void {
    let dataUpdate = [];
    let cellUpdate: any;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        cellUpdate = e;
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (dataUpdate.length > 0) {
      const model = dataUpdate.length > 0 ? dataUpdate[0] : null;
      if (!!model) {
        this.update(model.id, model.dashboardAlias, null, null);
      }
    }
  }
}
