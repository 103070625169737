<div class="row">

  <div class="col-12">
    <label style="font-size: medium; margin-left: 10px;">
      <span>
        <a (click)="change(1)" [ngClass]="{'sub-menu-hight': curTab == 1, 'sub-menu-txt': curTab != 1}">Trim Types</a>
      </span>
      |
      <span>
        <a (click)="change(6)" [ngClass]="{'sub-menu-hight': curTab == 6, 'sub-menu-txt': curTab != 6}">Trim Edge</a>
      </span>
      |
      <span>
        <a (click)="change(2)" [ngClass]="{'sub-menu-hight': curTab == 2, 'sub-menu-txt': curTab != 2}">Trim Application</a>
      </span>
      |
      <span>
        <a (click)="change(3)" [ngClass]="{'sub-menu-hight': curTab == 3, 'sub-menu-txt': curTab != 3}">Trim Tile Set</a>
      </span>
      |
      <span>
        <a (click)="change(4)" [ngClass]="{'sub-menu-hight': curTab == 4, 'sub-menu-txt': curTab != 4}">Trim Complexity</a>
      </span>
      |
      <span>
        <a (click)="change(5)" [ngClass]="{'sub-menu-hight': curTab == 5, 'sub-menu-txt': curTab != 5}">Trim Volume</a>
      </span>
    </label>
  </div>

  <tab-tile-trim-types *ngIf="curTab == 1" [costSchemaId]="costSchemaId" (calcHeightTable)="calcHeightTable"></tab-tile-trim-types>
  <tab-tile-trim-applications *ngIf="curTab == 2" [costSchemaId]="costSchemaId" (calcHeightTable)="calcHeightTable"></tab-tile-trim-applications>
  <tab-tile-trim-sets *ngIf="curTab == 3" [costSchemaId]="costSchemaId" (calcHeightTable)="calcHeightTable"></tab-tile-trim-sets>
  <tab-tile-trim-complexities *ngIf="curTab == 4" [costSchemaId]="costSchemaId" (calcHeightTable)="calcHeightTable"></tab-tile-trim-complexities>
  <tab-tile-trim-volumes *ngIf="curTab == 5" [costSchemaId]="costSchemaId" (calcHeightTable)="calcHeightTable"></tab-tile-trim-volumes>
  <tab-tile-trim-edges *ngIf="curTab == 6" [costSchemaId]="costSchemaId" (calcHeightTable)="calcHeightTable"></tab-tile-trim-edges>

</div>
