import { NgModule } from '@angular/core';
import { IConfig, NgxMaskModule } from 'ngx-mask';

// Mask currency
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '$',
  suffix: '',
  thousands: ',',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.NATURAL,
};

// Mask
const maskConfig: Partial<IConfig> = {
  // TODO: Why this config is not work?
  allowNegativeNumbers: true,
};

@NgModule({
  declarations: [],
  imports: [
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(maskConfig),
  ],
  exports: [
    NgxCurrencyModule,
    NgxMaskModule,
  ],
})
export class SharedModule {}
