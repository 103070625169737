import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../../../../services/dashboard.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize, of } from 'rxjs';
import { ReportService } from '@app-admin/services/report.service';

@Component({
  selector: 'page-dashboard-global-detail',
  templateUrl: './dashboard-global-detail.html',
})
export class DashboardGlobalDetailComponent implements OnInit {

  showLoading: boolean = true;
  updateDetailForm: FormGroup;
  updateConnectionForm: FormGroup;
  updateParamsForm: FormGroup;
  dashboardDetail: any;
  dashboardParams: any;
  dashboardConnection: any;
  dashboardId: number;
  dashboardCategories: any[];
  selectedFile: ImageSnippet;
  @ViewChild('imageInput') imageInput: ElementRef;
  wynDashboards: any[];
  tenants: any[];

  constructor(
    activatedRoute: ActivatedRoute,
    private router: Router,
    private dashboardService: DashboardService,
    private reportService: ReportService,
    private fb: FormBuilder,
    private toastr: ToastrService) {

    activatedRoute.params.subscribe(params => {
      this.dashboardId = Number(params.id);
      if (!this.dashboardId) {
        this.router.navigate(['/not-found']);
      }
    });

    this.updateDetailForm = this.fb.group({
      dashboardId: [0, Validators.required],
      dashboardName: ['', Validators.required],
      dashboardDescription: [''],
      dashboardCategoryId: [null, Validators.required],
      biConnectionName: [''],
      imageId: [''],
    });

    this.updateParamsForm = this.fb.group({
      parameterTenantId: [false, Validators.requiredTrue],
      parameterUserId: [false],
      parameterSelectorUserId: [false],
      parameterAccountId: [false],
      parameterProjectId: [false],
      parameterBidId: [false],
      parameterContractId: [false],
      parameterCommunityId: [false],
      parameterQuoteId: [false],
      parameterPriceSchemaId: [false],
    });

    this.updateConnectionForm = this.fb.group({
      biConnectionId: [0, Validators.required],
      biConnectionName: [''],
      embedCode: [''],
      dashboardObjectName: [null],
      sourceDashboard: [''],
    });
  }

  ngOnInit(): void {
    this.loadData();

    this.getDashboardCategories();

    this.getTenantDashboardsByDashboardId();

    this.getWynDashboards();
  }

  // Getter & Setter
  get dashboardName(): FormControl {
    return this.updateDetailForm.get('dashboardName') as FormControl;
  }

  get dashboardCategoryId(): FormControl {
    return this.updateDetailForm.get('dashboardCategoryId') as FormControl;
  }

  get imageId(): FormControl {
    return this.updateDetailForm.get('imageId') as FormControl;
  }

  getErrorDashboardCategoryId() {
    return this.dashboardCategoryId.hasError('required') ? 'Dashboard category is required' : '';
  }

  get sourceDashboard(): FormControl {
    return this.updateConnectionForm.get('sourceDashboard') as FormControl;
  }

  get dashboardObjectName(): FormControl {
    return this.updateConnectionForm.get('dashboardObjectName') as FormControl;
  }
  // getErrorDashboardObjectName() {
  //   return this.dashboardObjectName.hasError('required') ? 'Source dashboard is required' : '';
  // }

  //

  private loadData(): void {
    this.showLoading = true;
    this.dashboardService.getDashboardById(this.dashboardId)
      .pipe(catchError(val => of(val.error)))
      .pipe(finalize(() => this.showLoading = false))
      .subscribe(response => {
        if (!response.status) {
          return;
        }
        this.dashboardDetail = response.data.detail;
        this.dashboardParams = response.data.params;
        this.dashboardConnection = response.data.connection;

        this.updateDetailForm.patchValue({
          dashboardId: this.dashboardId,
          dashboardName: this.dashboardDetail.dashboardName,
          dashboardDescription: this.dashboardDetail.dashboardDescription,
          dashboardCategoryId: this.dashboardDetail.dashboardCategoryId,
          biConnectionName: this.dashboardDetail.biConnectionName,
        });

        this.updateParamsForm.patchValue({
          parameterTenantId: this.dashboardParams.parameterTenantId,
          parameterUserId: this.dashboardParams.parameterUserId,
          parameterSelectorUserId: this.dashboardParams.parameterSelectorUserId,
          parameterAccountId: this.dashboardParams.parameterAccountId,
          parameterProjectId: this.dashboardParams.parameterProjectId,
          parameterBidId: this.dashboardParams.parameterBidId,
          parameterContractId: this.dashboardParams.parameterContractId,
          parameterCommunityId: this.dashboardParams.parameterCommunityId,
          parameterQuoteId: this.dashboardParams.parameterQuoteId,
          parameterPriceSchemaId: this.dashboardParams.parameterPriceSchemaId,
        });

        this.updateConnectionForm.patchValue({
          biConnectionId: this.dashboardDetail.biConnectionId,
          biConnectionName: this.dashboardDetail.biConnectionName,
          embedCode: this.dashboardConnection.embedCode,
          dashboardObjectName: this.dashboardConnection.dashboardObjectName,
          sourceDashboard: this.dashboardConnection.sourceDashboard,
        });
      });
  }

  save(): void {
    if (this.updateDetailForm.invalid) return;
    this.dashboardService.updateDashboard(this.dashboardId, {
      ...this.updateDetailForm.value,
      connection: {
        ...this.updateConnectionForm.value,
      },
      parameters: {
        ...this.updateParamsForm.value,
      }
    }).then(response => {
      if (!response.status) {
        this.toastr.error(response.message);
        return;
      }
      this.toastr.success('Dashboard updated');
    });
  }

  //

  private getDashboardCategories(): void {
    this.dashboardService.getAllDashboardCategory().then(res => {
      if (!res.status) {
        this.toastr.error(res.message);
        return;
      }
      this.dashboardCategories = res.data;
    });
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      this.selectedFile = new ImageSnippet(event.target.result, file);

      this.imageId.setValue(this.selectedFile.src);
    });

    reader.readAsDataURL(file);

    // this.imageInput!.nativeElement!.value = '';
  }

  setSourceDashboard() {
    this.sourceDashboard.setValue(this.wynDashboards.find(rc => rc.id == this.dashboardObjectName.value).title);
  }

  private getWynDashboards(): void {
    this.reportService.getDocumentsByTenantName('dbd', 'all').then(res => {
      if (!res.status) {
        this.toastr.error(res.message);
        return;
      }
      this.wynDashboards = res.data;
    });
  }

  private getTenantDashboardsByDashboardId(): void {
    this.showLoading = true;
    this.dashboardService.getTenantDashboardsByDashboardId(this.dashboardId)
      .pipe(catchError(val => of(val.error)))
      .pipe(finalize(() => this.showLoading = false))
      .subscribe(response => {
        if (!response.status) {
          return;
        }
        this.tenants = response.data;
      });
  }
}

class ImageSnippet {
  constructor(public src: string, public file: File) {}
}
