import { Component, OnInit, Input, ViewChild, EventEmitter } from '@angular/core';
import { BsModalComponent } from 'ng2-bs3-modal';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from '@app-admin/services/setting.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import SchedulingProcessCategory from '@app-admin/pages/settings/global-configuration/global-scheduling-process-categories/schedulingProcessCategory.model';
import { Config } from '@app-admin/config';

@Component({
  selector: 'tab-tile-global-install',
  templateUrl: './install.html',
  styleUrls: ['./install.scss'],
})
export class TileInstallGlobalComponent implements OnInit {

  @Input() calcHeightTable = new EventEmitter<any>();
  @ViewChild('modal') modal: BsModalComponent;
  loading = false;
  data: SchedulingProcessCategory[];
  dataFilter: SchedulingProcessCategory[];
  dataForm: FormGroup;

  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  removeId: number = 0;

  // FormGroup
  formAdd: FormGroup;
  processTypes: any[] = [
    { processTypeId: Config.WorkOrderTypeId.Install, processType: 'Install' },
  ];

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService) {
  }

  ngOnInit() {
    this.loadData();

    this.formAdd = this.fb.group({
      processName: ['', Validators.required],
      processTypeId: [Config.WorkOrderTypeId.Install],
      businessUnitId: [Config.BusinessUnitId.Tile],
    });
  }

  get processName(): FormControl {
    return this.formAdd.get('processName') as FormControl;
  }

  getErrorProcessName() {
    return this.processName.hasError('required') ? 'Category name cannot be empty' : '';
  }

  get processTypeId(): FormControl {
    return this.formAdd.get('processTypeId') as FormControl;
  }

  private loadData() {
    this.loading = true;
    Promise.all([
      this.settingService.getExecutionProcesses(Config.BusinessUnitId.Tile),
    ]).then(res => {
      if (!res[0].status) {
        this.toast.error('Load data failed');
      }

      this.data = res[0].data;
      this.filterData();
      this.loading = false;
      this.calcHeightTable.emit();
    });
  }

  private filterData() {
    this.dataFilter = this.data;
    const dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  private createItemView(e): FormGroup {
    return this.fb.group({
      id: e.id,
      processName: [{ value: e.processName, disabled: !e.isConfigurable }, Validators.required],
      processTypeId: [{ value: e.processTypeId, disabled: !e.isConfigurable }],
      updatedAt: e.updatedAt,
      isConfigurable: e.isConfigurable,
    });
  }

  private createItemUpdate(e) {
    let item = this.data.find(i => i.id === e.id);
    item.processName = e.processName;
    return {
      id: e.id,
      processName: e.processName,
      processTypeId: e.processTypeId,
    };
  }

  save() {
    let dataUpdate = [];
    let cellUpdate: any;
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      const rawValue = (e as FormGroup).getRawValue();
      if (e.dirty) {
        cellUpdate = e;
        dataUpdate.push(this.createItemUpdate(rawValue));
        e.markAsPristine();
        e.markAsUntouched();
      }
      if (rawValue.processName == null || rawValue.processName.trim() == "" || rawValue.processName.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Category Name cannot be empty');
        }
        isValid = false;
      }
    });
    if (isValid && this.dataForm.invalid) {
      this.toast.error('This category already exists. System doesn\'t support duplicate categories. Make appropriate changes and retry ');
      isValid = false;
    }
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateExecutionProcess(dataUpdate[0]).then(response => {
        if (!response.status) {
          this.toast.error(response.message);
          return;
        }
        this.toast.success('Category updated.');

        // Update UpdateAt
        cellUpdate?.get('updatedAt').setValue(response.data);
      });
    }
  }

  checkDuplicate(e) {
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(m => {
      const rawValue = (m as FormGroup).getRawValue();
      if (e.id.value != rawValue.id
        && e.processName.value.trim().toUpperCase() == rawValue.processName.trim().toUpperCase()) {
        e.processName.setErrors({ 'hasDup': true });
      }
    });
  }

  drop(event) {
    if (event.previousIndex != event.currentIndex) {
      this.loading = true;

      let previousRow = this.data[event.previousIndex];
      let currentRow = this.data[event.currentIndex];

      if (!previousRow?.isConfigurable || !currentRow?.isConfigurable) {
        this.toast.warning('Your selected row is not allow to move.');
        this.loadData();
        return;
      }

      let id = previousRow?.id ?? 0
      let sortNumber = currentRow?.sortNumber ?? 0
      if (id == 0 || sortNumber == 0) return;

      this.settingService.sortExecutionProcess(id, sortNumber).then(res => {
        if (!res.status) {
          this.toast.error('Sort failed');
          return;
        }
        this.loadData();
      });
    }
  }

  delete(id: number) {
    this.modalConfirm.open();
    this.removeId = id;
  }

  submitDelete() {
    if (!this.removeId) return;

    this.loading = true;
    this.modalConfirm.close();
    this.settingService.deleteExecutionProcess(this.removeId).then(response => {
      this.loading = false;
      if (!response.status) {
        this.toast.error(response.message);
        return;
      }
      this.removeId = 0;
      this.toast.success('Category removed');
      this.loadData();
    });
  }

  add(): void {
    if (this.validData()) {
      this.loading = true;
      this.settingService.addExecutionProcess(this.formAdd.value).then(response => {
        this.loading = false;
        if (!response.status) {
          this.toast.error(response.message);
          return;
        }
        this.formAdd.patchValue({
          processName: '',
          processTypeId: Config.WorkOrderTypeId.Install,
        });
        this.toast.success('New category added');
        this.loadData();
      });
    }
  }

  private validData(): boolean {
    if (this.processName == null || this.processName.value == '' || this.processName.value.length < 2) {
      this.toast.error('Category Name needs to be greater than 2 characters');
      return false;
    }
    if (this.processTypeId.value <= 0) {
      this.toast.error('Process Type cannot be empty');
      return false;
    }
    return true;
  }
}
