import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TileTenantChannelsService } from '../tenant-channels.service';
import { TileCurrentRow } from '../current-row.model';
import { BsModalComponent } from 'ng2-bs3-modal';
import { TileCostSchemaAssignmentService } from '@app-admin/services/tile-cost-schema-assignment.service';

@Component({
  selector: 'tile-assigned-cost-schemas',
  templateUrl: './assigned-cost-schemas.html'
})

export class TileAssignedCostSchemasComponent implements OnInit {

  @Input() currentRow: TileCurrentRow;
  @Input() enabledEdit: boolean;

  unassignedCostSchemas: any[];
  assignedCostSchemas: any[];
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  removeId: number;
  removeSchemaId: number;

  constructor(
    private toast: ToastrService,
    private costSchemaAssignmentService: TileCostSchemaAssignmentService,
    public tenantChannelsService: TileTenantChannelsService
  ) {
  }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.costSchemaAssignmentService.getCostSchemaAssignsByChannelId(this.currentRow.tenantId, this.currentRow.channelId, 0).then(response => {
      if (response.status) {
        this.assignedCostSchemas = response.data.assignedCostSchemas;
        this.unassignedCostSchemas = response.data.unassignedCostSchemas;
      } else {
        this.toast.error('Load data failed');
      }
    });
  }

  passingDataToModal() {
    const costSchemaIds: number[] = this.tenantChannelsService.arrAdd.map(x => x.schemaId);

    this.tenantChannelsService.sendMessage(
      TileTenantChannelsService.MessageTypes.SEND_TO_MODAL,
      { currentRow: this.currentRow, unassignedCostSchemas: this.unassignedCostSchemas.filter(x => !costSchemaIds.includes(x.schemaId))}
    );
  }

  updateIsDefault(item, e) {
    // Not allow uncheck
    if (!e.target.checked) {
      e.target.checked = true;
      return;
    }

    let channelId = this.currentRow.channelId;
    let existed = this.tenantChannelsService.arrUpdateIsDefault.find(x => x.channelId == channelId);
    if (existed) {
      // Update, because one channel just have one cost schema default at one time
      existed.costSchemaAssignmentId = item.id;
    } else {
      // Add
      this.tenantChannelsService.arrUpdateIsDefault.push({ channelId, costSchemaAssignmentId: item.id, isDefault: true });
    }

    // Update current
    let curItemDefault = this.assignedCostSchemas.find(x => x.id == item.id);
    curItemDefault.isDefault = true;

    // Update previous
    let prevItemDefault = this.assignedCostSchemas.find(x => x.id != item.id && x.isDefault);
    if (!prevItemDefault) {
      prevItemDefault = this.tenantChannelsService.arrAdd.find(x => x.channelId == channelId && x.isDefault);
    }
    prevItemDefault.isDefault = false;

    // this.costSchemaAssignmentService.updateIsDefault({ id: item.id, isDefault: true }).then(response => {
    //   if (!response.status) {
    //     this.toast.error(response.message);
    //     return;
    //   }
    //   this.tenantChannelsService.sendMessage(
    //     TenantChannelsService.MessageTypes.RELOAD_PARENT_COMP
    //   );
    // });
  }

  updateIsDefault2(item, e) {
    // Not allow uncheck
    if (!e.target.checked) {
      e.target.checked = true;
      return;
    }

    // Remove existed update data
    let channelId = this.currentRow.channelId;
    let existedIndex = this.tenantChannelsService.arrUpdateIsDefault.findIndex(x => x.channelId == channelId);
    if (existedIndex >= 0) {
      this.tenantChannelsService.arrUpdateIsDefault.splice(existedIndex, 1);
    }

    // Update current
    let curItemDefault = this.tenantChannelsService.arrAdd.find(x => x.channelId == channelId && x.schemaId == item.schemaId);
    curItemDefault.isDefault = true;

    // Update previous
    let prevItemDefault = this.assignedCostSchemas.find(x => x.isDefault);
    if (!prevItemDefault) {
      prevItemDefault = this.tenantChannelsService.arrAdd.find(x => x.channelId == channelId && x.isDefault);
    }
    prevItemDefault.isDefault = false;
  }

  delete(id: number, schemaId: number) {
    this.modalConfirm.open();
    this.removeId = id;
    this.removeSchemaId = schemaId;
  }

  submitDelete() {
    if (this.removeId > 0) {
      this.tenantChannelsService.arrDelete.push(this.removeId);

      // Remove item from arr data
      var index = this.assignedCostSchemas.findIndex(x => x.id == this.removeId);
      this.assignedCostSchemas.splice(index, 1);
    } else {
      let channelId = this.currentRow.channelId;
      let index = this.tenantChannelsService.arrAdd.findIndex(x => x.channelId == channelId && x.schemaId == this.removeSchemaId);
      this.tenantChannelsService.arrAdd.splice(index, 1);
    }

    this.removeId = 0;
    this.removeSchemaId = 0;
    this.modalConfirm.close();

    // this.costSchemaAssignmentService.unassignCostSchema(this.removeId).then(response => {
    //   if (!response.status) {
    //     this.toast.error(response.message);
    //     this.modalConfirm.close();
    //     return;
    //   }
    //   this.tenantChannelsService.sendMessage(
    //     TenantChannelsService.MessageTypes.RELOAD_PARENT_COMP
    //   );
    //   this.removeId = 0;
    //   this.modalConfirm.close();
    // });
  }

  getArrAdd() {
    return this.tenantChannelsService.arrAdd.filter(x => x.channelId == this.currentRow.channelId);
  }
}
