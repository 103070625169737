import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from '../../../services/dashboard.service';
import { catchError, finalize, of } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalComponent } from 'ng2-bs3-modal';

@Component({
  selector: 'page-dashboard-global',
  templateUrl: './dashboard-global.html',
})
export class DashboardGlobalComponent implements OnInit {

  showLoading: boolean = true;
  dashboards: any[];
  dashboardCategories: any[];
  biConnections: any[];

  // Add Dashboard
  addForm: FormGroup;
  @ViewChild('modalAdd') modalAdd: BsModalComponent;

  // Delete Dashboard
  dashboardIdDelete = 0;
  @ViewChild('modalConfirmDelete') modalConfirmDelete: BsModalComponent;

  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    private toastr: ToastrService,
    private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.loadData();

    this.addForm = this.fb.group({
      dashboardName: ['', Validators.required],
      dashboardDescription: [''],
      dashboardCategoryId: [null, Validators.required],
      biConnectionId: [null, Validators.required],
    });

    this.getDashboardCategories();
    this.getBiConnections();
  }

  // Getter & Setter
  get dashboardName(): FormControl {
    return this.addForm.get('dashboardName') as FormControl;
  }

  get dashboardDescription(): FormControl {
    return this.addForm.get('dashboardDescription') as FormControl;
  }

  get dashboardCategoryId(): FormControl {
    return this.addForm.get('dashboardCategoryId') as FormControl;
  }

  get biConnectionId(): FormControl {
    return this.addForm.get('biConnectionId') as FormControl;
  }

  getErrorDashboardCategoryId() {
    return this.dashboardCategoryId.hasError('required') ? 'Dashboard category is required' : '';
  }

  getErrorBiConnectionId() {
    return this.biConnectionId.hasError('required') ? 'BI Connection is required' : '';
  }

  private loadData(): void {
    this.showLoading = true;
    this.dashboardService.getDashboards()
      .pipe(catchError(val => of(val.error)))
      .pipe(finalize(() => this.showLoading = false))
      .subscribe(response => {
        if (!response.status) {
          return;
        }
        this.dashboards = response.data;
      });
  }

  add(): void {
    this.addForm.reset();
    this.modalAdd.open();
  }

  submitAdd(): void {
    if (this.addForm.invalid) return;

    this.dashboardService.addNewDashboard(this.addForm.value).then(response => {
      if (!response.status || response.data <= 0) {
        this.toastr.error(response.message);
        return;
      }

      this.toastr.success('New dashboard created');
      this.loadData();
      this.modalAdd.close();
    });
  }

  delete(dashboardId: number): void {
    this.dashboardIdDelete = dashboardId;
    this.modalConfirmDelete.open();
  }

  submitDelete(): void {
    this.modalConfirmDelete.close();
    this.dashboardService.deleteDashboard(this.dashboardIdDelete).then(response => {
      if (!response.status) {
        this.toastr.error(response.message);
        return;
      }
      this.dashboardIdDelete = 0;
      this.toastr.success('Dashboard removed');
      this.loadData();
    });
  }

  update(dashboard: any): void {
    this.router.navigate([`dashboards/global/detail/${dashboard.dashboardId}`]);
  }

  //

  private getDashboardCategories(): void {
    this.dashboardService.getAllDashboardCategory().then(res => {
      if (!res.status) {
        this.toastr.error(res.message);
        return;
      }
      this.dashboardCategories = res.data;
    });
  }

  private getBiConnections(): void {
    this.dashboardService.getBiConnections()
      .pipe(catchError(val => of(val.error)))
      .pipe(finalize(() => this.showLoading = false))
      .subscribe(response => {
        if (!response.status) {
          return;
        }
        this.biConnections = response.data;
      });
  }
}
