import { LicenseTypesComponent } from './pages/license-types/license-types';
import { LicenseTypeService } from './services/license-type.service';
import { SlabCostDetailComponent } from './pages/settings/slab-cost/slab-cost-detail/slab-cost-detail';
import { NewTenantComponent } from './pages/tenants/new-tenant/new-tenant';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BsModalModule } from 'ng2-bs3-modal';

import { ToastrModule } from 'ngx-toastr';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { Routing } from './routes';
import { AuthHttp } from './app.auth-http';

// components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MenuComponent } from './components/menu/menu.component';
import { PaginationComponent } from './components/pagination/pagination';
import { QxmInputPercentComponent } from './components/qxm-input-percent/qxm-input-percent';
import { QxmInputPercentNgmodelComponent } from './components/qxm-input-percent-ngmodel/qxm-input-percent-ngmodel';
import { DragDropModule } from '@angular/cdk/drag-drop';

// pages
import { HomeComponent } from './pages/home/home';
import { TenantsComponent } from './pages/tenants/tenants';
import { TenantDetailsComponent } from './pages/tenants/details/details';
import { LocationAddressesComponent } from './pages/tenants/details/location-addresses/location-addresses';
import { UsersComponent } from './pages/users/users';
import { WorkersComponent } from './pages/workers/workers';
import { UserDetailsComponent } from './pages/users/details/details';
import { WorkerDetailsComponent } from './pages/workers/worker-details/worker-details';
import { UserAssignedTenantsComponent } from './pages/users/details/assigned-tenants/assigned-tenants';
import { RolesComponent } from './pages/roles/roles';
import { PermissionsComponent } from './pages/permissions/permissions';
import { ReportsComponent } from './pages/reports/reports';
import { RegionsComponent } from './pages/regions/regions';
import { LoginComponent } from './pages/login/login.component';
import { SlabCostComponent } from './pages/settings/slab-cost/slab-cost';
import { SlabPricingComponent } from './pages/settings/slab-pricing/slab-pricing';
import { SlabConfigurationComponent } from './pages/settings/slab-configuration/slab-configuration';
import { GlobalConfigurationComponent } from './pages/settings/global-configuration/global-configuration';
import { TenantChannelContainerComponent } from './pages/settings/tenant-channels/tenant-channel-container';
import { PricingRecalculationComponent } from './pages/pricing-recalculation/pricing-recalculation';
import { CompareToolComponent } from './pages/compare-tool/compare-tool';
import { LogOfBatchComponent } from './pages/compare-tool/log-of-batch';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

// Dashboard
import { DashboardTenantSpecificComponent } from './pages/dashboards/dashboard-tenant-specific/dashboard-tenant-specific';
import { DashboardGlobalComponent } from './pages/dashboards/dashboard-global/dashboard-global';
import { DashboardGlobalDetailComponent } from './pages/dashboards/dashboard-global/dashboard-global-detail/dashboard-global-detail';
import { BIConnectionsComponent } from './pages/dashboards/bi-connections/bi-connections';

// Tile
import { TileGlobalConfigurationComponent } from './pages/settings/tile/tile-global-configuration/tile-global-configuration';
import {
  TileTypesGlobalComponent,
  TileSizesGlobalComponent,
  TileSetsGlobalComponent,
  TileApplicationGlobalComponent,
  TileSpecialConditionsGlobalComponent,
  TileTrimTypesGlobalComponent,
  TileTrimEdgesGlobalComponent,
  TileThicknessGlobalComponent,
  TileSchedulingCategoriesGlobalComponent,
  TileInstallGlobalComponent,
} from './pages/settings/tile/tile-global-configuration';
import { TileTenantSpecificConfigurationComponent } from './pages/settings/tile/tile-tenant-specific-configuration/tile-tenant-specific-configuration';
import {
  TileTenantCostSchemasComponent,
  TileBaseProcessesComponent,
  TileApplicationComponent,
  TileTypesComponent,
  TileSizesComponent,
  TileSetsComponent,
  TileGeneralRatesComponent,
  TileSpecialConditionComponent,
  TrimTypesComponent,
  TrimEdgesComponent,
  TileThicknessComponent,
  TileSchedulingProcessComponent,
  TileServiceClassComponent
} from './pages/settings/tile/tile-tenant-specific-configuration';
import { TileCostSchemaComponent } from './pages/settings/tile/tile-cost-schema/tile-cost-schema';
import { TileCostSchemaDetailComponent } from './pages/settings/tile/tile-cost-schema';
import {
  TileProcessStandardComponent,
  TileSubTypeWeightsComponent,
  TileApplicationBaseComponent,
  TileApplicationComplexityComponent,
  TileApplicationWeightsComponent,
  TileTieredVolumeSizingComponent,
  TileTypeWeightsComponent,
  TileSetWeightsComponent,
  TileSizeWeightsComponent,
  TilePatternWeightsComponent,
  TileCuttingTypeWeightsComponent,
  TileSpecialConditionWeightsComponent,
  TileSiteAccessDifficultyComponent,
  TileBuildingLevelsComponent,
  TileWasteComponent,
  TileApplicationWasteComponent,
  TileTypeWasteComponent,
  // TileSetWasteComponent,
  TileComplexityWasteComponent,
  TileCuttingWasteComponent,
  TileSizeWasteComponent,
  TilePatternWasteComponent,
  TileTrimComponent,
  TileTrimTypesComponent,
  TileTrimApplicationsComponent,
  TileTrimSetsComponent,
  TileTrimComplexitiesComponent,
  TileTrimVolumesComponent,
  TileTrimEdgesComponent,
} from './pages/settings/tile/tile-cost-schema/cost-schema-detail';

// import { TileTenantChannelContainerComponent } from './pages/settings/tile/tenant-channels/tenant-channel-container';
import {
  TileTenantChannelChangeLogComponent,
  TileTenantChannelComponent,
  TileAssignedCostSchemasComponent,
  TileAssignedCostSchemasModalComponent,
  TileReassignChannelModalComponent,
} from './pages/settings/tile/tenant-channels';

import {
  ProcessStandardComponent,
  // ChannelWeightsComponent,
  SubTypeWeightsComponent,
  ApplicationWeightsComponent,
  MaterialWeightsComponent,
  MaterialDirectionComponent,
  LayoutApprovalComponent,
  EdgeBaseComponent,
  EdgeProcessComponent,
  EdgeMaterialComponent,
  BookMatchWeightsComponent,
  TieredVolumeSizingComponent,
  ThicknessWeightsComponent,
  SiteAccessDifficultyComponent,
  BuildingLevelsComponent,
  AdjustOnsiteComponent,
  OnsitePolishComponent,
  ApplicationWasteComponent,
  MaterialWasteComponent,
  DirectionalityWasteComponent,
  VeinMatchingWasteComponent,
  LayoutApprovalWasteComponent,
  WasteComponent,
  ChangeLogSlabCostComponent,
  SupplyOverridesComponent,
  ViewCommentComponent
} from './pages/settings/slab-cost';
import {
  ChangeLogSlabPricingComponent
} from './pages/settings/slab-pricing';
import {
  BaseProcessesComponent,
  EdgeComponent,
  MaterialTypesComponent,
  ApplicationComponent,
  ThicknessComponent,
  TenantCostSchemasComponent,
  ChangeLogSlabConfigurationComponent,
  SchedulingProcessComponent,
  ServiceClassComponent
} from './pages/settings/slab-configuration';
import {
  ItemTypesComponent,
  DefaultItemPropertiesComponent,
  MaterialRemovalComponent,
  // TaxRegionsComponent,
  DefaultTaxByChannelComponent,
  GeneralRatesComponent,
  MaterialTypesGlobalComponent,
  ApplicationGlobalComponent,
  ThicknessGlobalComponent,
  EdgeGlobalComponent,
  SchedulingProcessCategoriesGlobalComponent,
  FabricationGlobalComponent,
} from './pages/settings/global-configuration';
import {
  TenantChannelChangeLogComponent,
  TenantChannelComponent,
  AssignedCostSchemasComponent,
  AssignedCostSchemasModalComponent,
  ReassignChannelModalComponent
} from './pages/settings/tenant-channels';
// services
import { UserService } from './services/user.service';
import { WorkerService } from './services/worker.service';
import { PermissionService } from './services/permission.service';
import { TenantsService } from './services/tenants.service';
import { NotFoundComponent } from './pages/not-found/not-found';
import { AuthGuard } from './services/auth-guard.service';
import { SettingService } from './services/setting.service';
import { ReportService } from './services/report.service';
import { DashboardService } from './services/dashboard.service';
import { CompareService } from './services/compare.service';
import { CalculatorService } from './services/calculate.service';
import { CostSchemaAssignmentService } from './services/cost-schema-assignment.service';
import { CostSchemaService } from './services/cost-schema.service';
import { ChannelService } from './services/channel.service';
import { LocationService } from './services/location.service';
import { AddressService } from './services/address.service';
import { SlabGroupService } from './services/slab-group.service';
import { GlobalRolesComponent } from './pages/global-roles/global-roles';

import { Config } from './config';

import { TileCostSchemaAssignmentService } from './services/tile-cost-schema-assignment.service';
import { RoleDetailComponent } from './pages/roles/role-detail/role-detail';
import { SharedModule } from './shared.module';
import { TenantAppConfigService } from './services/tenant-app-config.service';
import { TenantMaterialConfigService } from './services/tenant-material-config.service';
import { TenantSlabThicknessConfigService } from './services/tenant-slab-thickness-config.service';
import { TenantTileThicknessConfigService } from './services/tenant-tile-thickness-config.service';
import { TenantEdgeConfigService } from './services/tenant-edge-config.service';
import { TenantTileTypeConfigService } from './services/tenant-tile-type-config.service';
import { TenantTileSizeConfigService } from './services/tenant-tile-size-config.service';
import { TenantTileSetConfigService } from './services/tenant-tile-set-config.service';
import { TenantTrimTypeConfigService } from './services/tenant-trim-type-config.service';
import { TenantTrimEdgeConfigService } from './services/tenant-trim-edge-config.service';
import { TenantExecutionProcessConfigService } from './services/tenant-execution-process-config.service';
import { ServiceSubscriptionService } from './services/service-subscription.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    PaginationComponent,
    QxmInputPercentComponent,
    QxmInputPercentNgmodelComponent,

    LoginComponent,
    NotFoundComponent,
    HomeComponent,
    TenantsComponent,
    TenantDetailsComponent,
    LocationAddressesComponent,
    NewTenantComponent,
    UsersComponent,
    WorkersComponent,
    UserDetailsComponent,
    WorkerDetailsComponent,
    UserAssignedTenantsComponent,
    RolesComponent,
    RoleDetailComponent,
    GlobalRolesComponent,
    PermissionsComponent,
    LicenseTypesComponent,
    ReportsComponent,
    PricingRecalculationComponent,
    CompareToolComponent,
    LogOfBatchComponent,

    // Dashboard
    DashboardTenantSpecificComponent,
    DashboardGlobalComponent,
    DashboardGlobalDetailComponent,
    BIConnectionsComponent,

    SlabCostComponent,
    ViewCommentComponent,
    SlabCostDetailComponent,
    RegionsComponent,
    ProcessStandardComponent,
    // ChannelWeightsComponent,
    SubTypeWeightsComponent,
    ApplicationWeightsComponent,
    MaterialTypesGlobalComponent,
    MaterialTypesComponent,
    MaterialWeightsComponent,
    MaterialDirectionComponent,
    LayoutApprovalComponent,
    EdgeBaseComponent,
    EdgeProcessComponent,
    EdgeMaterialComponent,
    BookMatchWeightsComponent,
    TieredVolumeSizingComponent,
    ThicknessWeightsComponent,
    SiteAccessDifficultyComponent,
    BuildingLevelsComponent,
    AdjustOnsiteComponent,
    OnsitePolishComponent,
    WasteComponent,
    MaterialWasteComponent,
    ApplicationWasteComponent,
    DirectionalityWasteComponent,
    VeinMatchingWasteComponent,
    LayoutApprovalWasteComponent,
    ChangeLogSlabCostComponent,

    SlabPricingComponent,
    ChangeLogSlabPricingComponent,

    SlabConfigurationComponent,
    BaseProcessesComponent,
    ApplicationGlobalComponent,
    ThicknessGlobalComponent,
    SchedulingProcessCategoriesGlobalComponent,
    FabricationGlobalComponent,
    ApplicationComponent,
    SchedulingProcessComponent,
    ThicknessComponent,
    TenantCostSchemasComponent,
    ChangeLogSlabConfigurationComponent,
    EdgeBaseComponent,
    EdgeGlobalComponent,
    EdgeComponent,
    SupplyOverridesComponent,
    ServiceClassComponent,

    GlobalConfigurationComponent,
    ItemTypesComponent,
    DefaultItemPropertiesComponent,
    MaterialRemovalComponent,
    // TaxRegionsComponent,
    DefaultTaxByChannelComponent,
    GeneralRatesComponent,

    TenantChannelContainerComponent,
    TenantChannelChangeLogComponent,
    TenantChannelComponent,
    AssignedCostSchemasComponent,
    AssignedCostSchemasModalComponent,
    ReassignChannelModalComponent,

    // Tile
    TileGlobalConfigurationComponent,
    TileApplicationGlobalComponent,
    TileTypesGlobalComponent,
    TileSizesGlobalComponent,
    TileSetsGlobalComponent,
    TileSpecialConditionsGlobalComponent,
    TileTrimTypesGlobalComponent,
    TileTrimEdgesGlobalComponent,
    TileThicknessGlobalComponent,
    TileSchedulingCategoriesGlobalComponent,
    TileInstallGlobalComponent,

    TileTenantSpecificConfigurationComponent,
    TileTenantCostSchemasComponent,
    TileBaseProcessesComponent,
    TileApplicationComponent,
    TileTypesComponent,
    TileSizesComponent,
    TileSetsComponent,
    TileGeneralRatesComponent,
    TileSpecialConditionComponent,
    TrimTypesComponent,
    TrimEdgesComponent,
    TileThicknessComponent,
    TileSchedulingProcessComponent,
    TileServiceClassComponent,

    TileCostSchemaComponent,
    TileCostSchemaDetailComponent,
    TileProcessStandardComponent,
    TileSubTypeWeightsComponent,
    TileApplicationBaseComponent,
    TileApplicationComplexityComponent,
    TileApplicationWeightsComponent,
    TileTieredVolumeSizingComponent,
    TileTypeWeightsComponent,
    TileSetWeightsComponent,
    TileSizeWeightsComponent,
    TilePatternWeightsComponent,
    TileCuttingTypeWeightsComponent,
    TileSpecialConditionWeightsComponent,
    TileSiteAccessDifficultyComponent,
    TileBuildingLevelsComponent,
    TileWasteComponent,
    TileApplicationWasteComponent,
    TileTypeWasteComponent,
    // TileSetWasteComponent,
    TileComplexityWasteComponent,
    TileCuttingWasteComponent,
    TileSizeWasteComponent,
    TilePatternWasteComponent,
    TileTrimComponent,
    TileTrimTypesComponent,
    TileTrimApplicationsComponent,
    TileTrimSetsComponent,
    TileTrimComplexitiesComponent,
    TileTrimVolumesComponent,
    TileTrimEdgesComponent,

    // TileTenantChannelContainerComponent,
    TileTenantChannelChangeLogComponent,
    TileTenantChannelComponent,
    TileAssignedCostSchemasComponent,
    TileAssignedCostSchemasModalComponent,
    TileReassignChannelModalComponent
  ],
  imports: [
    DragDropModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AngularMultiSelectModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    Routing,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    NgxTrimDirectiveModule,
    BsModalModule
  ],
  providers: [
    AuthHttp,
    UserService,
    WorkerService,
    TenantsService,
    PermissionService,
    LicenseTypeService,
    AuthGuard,
    SettingService,
    ReportService,
    DashboardService,
    CompareService,
    CalculatorService,
    CostSchemaAssignmentService,
    SlabGroupService,
    CostSchemaService,
    ChannelService,
    LocationService,
    AddressService,
    Config,
    TileCostSchemaAssignmentService,
    TenantAppConfigService,
    TenantMaterialConfigService,
    TenantSlabThicknessConfigService,
    TenantTileThicknessConfigService,
    TenantEdgeConfigService,
    TenantTileSizeConfigService,
    TenantTileSetConfigService,
    TenantTileTypeConfigService,
    TenantTrimTypeConfigService,
    TenantTrimEdgeConfigService,
    TenantExecutionProcessConfigService,
    ServiceSubscriptionService
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
