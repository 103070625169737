<div class="content-wrapper">
  <div *ngIf="showWarning" class="alert alert-warning" role="alert">
    <strong>Changes have been made to {{tenantName}}. Click <a href="javascript:;" class="alert-link" (click)="modalUpdateStatusBatchVersions.open()">here</a> to publish changes to the Tenant Application. Last batch update was made at {{ lastUpdateNewest | date: 'hh:mm a MM/dd/yyyy' }}</strong>
  </div>
  <div *ngIf="showInprogress" class="alert alert-warning" role="alert">
    <strong>System is currently {{inprogressPercent}}% complete processing changes. No further changes can be published while batch is being processed</strong>
    <button class="btn btn-primary pull-right" style="margin-top: -.8rem" type="button" (click)="republishBatch()">RePublish Batch</button>
  </div>
  <div *ngIf="showError" class="alert alert-warning" role="alert">
    <strong>System is currently experiencing problems attempting to publish the batch changes. Please notify admin to resolve</strong>
  </div>

  <section class="content-header">
    <label style="font-size: medium;"><span><a class="sub-menu-hight">Slab Configuration</a></span> | <span><a class="sub-menu-txt" >Tenant Specific</a></span></label>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li class="active">Slab Configuration</li>
    </ol>
  </section>
  <section class="content">
    <div class="row">
      <div class="col-md-3 col-sm-4 col-xs-12">
        <i class="fa fa-refresh fa-spin fa-lg" *ngIf="!tenants"></i>
        <div class="form-group" *ngIf="tenants">
          <label>Select Tenant</label>
          <select [(ngModel)]="tenantId" (change)="changeTenant()" class="form-control">
            <option value="0">-- Please select a tenant --</option>
            <ng-container *ngFor="let t of tenants">
              <option [ngValue]="t.tenantId" *ngIf="t.tenantId > 0">{{t.tenantName}}</option>
            </ng-container>
          </select>
        </div>
      </div>
    </div>

    <div class="nav-tabs-custom" *ngIf="!loadingTenant && tenantId > 0">
      <ul class="nav nav-tabs">
        <li *ngFor="let tab of tabs; let i = index" [class.active]="curTab.id == tab.id" (click)="changeTab(tab)">
          <a href="#{{tab.id}}" data-toggle="tab">{{tab.text}}</a>
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane box" id="tenantCostSchemas" *ngIf="curTab.id == 'tenantCostSchemas'">
          <tab-tenant-cost-schemas [tenantId]="tenantId" (reloadStatusBatchVersions)="loadStatusBatchVersions()" (calcHeightTable)="calcHeightTable('application')"></tab-tenant-cost-schemas>
        </div>
        <div class="tab-pane box" id="scheduling-process" *ngIf="curTab.id == 'scheduling-process'">
          <tab-scheduling-process [tenantId]="tenantId" (reloadStatusBatchVersions)="loadStatusBatchVersions()" (calcHeightTable)="calcHeightTable('scheduling-process')"></tab-scheduling-process>
        </div>
        <div class="tab-pane box" id="baseProcess" *ngIf="curTab.id == 'baseProcess'">
          <tab-base-processes [tenantId]="tenantId" (reloadStatusBatchVersions)="loadStatusBatchVersions()" (calcHeightTable)="calcHeightTable('baseProcess')"></tab-base-processes>
        </div>
        <div class="tab-pane box" id="application" *ngIf="curTab.id == 'application'">
          <tab-application [tenantId]="tenantId" (reloadStatusBatchVersions)="loadStatusBatchVersions()" (calcHeightTable)="calcHeightTable('application', $event)"></tab-application>
        </div>
        <div class="tab-pane box" id="serviceClass" *ngIf="curTab.id == 'serviceClass'">
          <service-class [tenantId]="tenantId"></service-class>
        </div>
        <div class="tab-pane box" id="materialTypes" *ngIf="curTab.id == 'materialTypes'">
          <tab-material-types [tenantId]="tenantId" (reloadStatusBatchVersions)="loadStatusBatchVersions()" (calcHeightTable)="calcHeightTable('materialTypes')"></tab-material-types>
        </div>
        <div class="tab-pane box" id="edge" *ngIf="curTab.id == 'edge'">
          <tab-edge [tenantId]="tenantId" (reloadStatusBatchVersions)="loadStatusBatchVersions()" (calcHeightTable)="calcHeightTable('edge')"></tab-edge>
        </div>

        <div class="tab-pane box" id="generalRates" *ngIf="curTab.id == 'generalRates'">
          <tab-general-rates [tenantId]="tenantId" (reloadStatusBatchVersions)="loadStatusBatchVersions()" (calcHeightTable)="calcHeightTable('generalRates')"></tab-general-rates>
        </div>
        <div class="tab-pane box" id="thickness" *ngIf="curTab.id == 'thickness'">
          <tab-thickness [tenantId]="tenantId" (calcHeightTable)="calcHeightTable('thickness')"></tab-thickness>
        </div>
        <div class="tab-pane box" id="changeLog" *ngIf="curTab.id == 'changeLog'">
          <tab-change-log-slab-configuration [tenantId]="tenantId" [businessUnitId]="1" (calcHeightTable)="calcHeightTable('changeLog')"></tab-change-log-slab-configuration>
        </div>
      </div>
    </div>
  </section>
</div>

<bs-modal #modalUpdateStatusBatchVersions>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Update status batch versions</h4>
  </bs-modal-header>
  <bs-modal-body>
    <form novalidate [formGroup]="statusBatchVersionsForm">
      <div class="form-group">
        <label>Batch Id</label>
        <input type="text" class="form-control" formControlName="batchVersionId" readonly>
      </div>
      <div class="form-group">
        <label>Name of the Batch</label>
        <input type="text" trim="blur" class="form-control" formControlName="batchName" maxlength="100">
      </div>
      <div class="form-group">
        <label>Short Note</label>
        <input type="text" trim="blur" class="form-control" formControlName="note" maxlength="200">
      </div>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalUpdateStatusBatchVersions.close()">Cancel</button>
    <button type="button" [disabled]="statusBatchVersionsForm.disabled" class="btn btn-primary" (click)="updateStatus()">Publish</button>
  </bs-modal-footer>
</bs-modal>
