import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';
import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class ServiceSubscriptionService {
  controllerName: string = "ServiceSubscriptions";

  constructor(private http: AuthHttp) { }


    getServiceTypes(): Promise<any> {
      return this.http.get(`${this.controllerName}/GetServiceTypes`).toPromise();
    }

}
