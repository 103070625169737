import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'qxm-input-percent',
  templateUrl: './qxm-input-percent.html',
  // styleUrls: ['./qxm-input-percent.scss']
})

export class QxmInputPercentComponent implements OnInit {

  @Input() classNames: string = 'form-control text-right';
  // TODO: Update Angular and ngx-mask to make percent.2 work
  // @Input() mask: string = 'percent.2';
  @Input() mask: string = 'separator.2';
  @Input() suffix: string = '%';
  @Input() allowNegative: boolean = true;

  @Input() myFormGroup: UntypedFormGroup;
  @Input() myFormArrayName: string;
  @Input() myFormGroupName: string;
  @Input() myFormControlName: string;

  // Optional
  @Input() placeholder: string = '';
  @Input() required: boolean = false;

  @Output() acceptChange = new EventEmitter<any>();
  @ViewChild('txt') txt: ElementRef;

  value = '';
  editing = false;

  private acceptEditSubject = new Subject<any>();

  constructor() { }

  get formControl(): UntypedFormControl {
    return this.myFormGroup.get(this.myFormArrayName)['controls'][this.myFormGroupName].get(this.myFormControlName) as UntypedFormControl;
  }

  ngOnInit() {
    this.setValueVar();
    this.setDebounces();
  }

  cancelEdit(): void {
    this.formControl.setValue(this.value);
    this.editing = false;
  }

  startEdit(): void {
    this.editing = true;
    var value = this.formControl.value;
    this.txt.nativeElement.setSelectionRange(0, value?.toString().length);
    this.setValueVar();
  }

  // private trimValue() {
  //   this.formControl.setValue(this.formControl.value.trim());
  // }

  private acceptEdit(): void {
    if (this.editing && (!this.required || this.formControl.value != '') ) {
      this.acceptEditSuccess();
    } else {
      this.startEdit();
    }
  }

  private acceptEditSuccess(): void {
    // this.trimValue();
    this.setValueVar();
    this.editing = false;
    this.acceptChange.emit();
  }

  private setValueVar(): void {
    this.value = this.formControl.value;
  }

  private setDebounces(): void {
    this.acceptEditSubject.pipe(debounceTime(100)).subscribe(() => {
      this.acceptEdit();
    });
  }

  onInputAcceptEdit(): void {
    this.acceptEditSubject.next(0);
  }
}
