<div class="row">
  <div class="col-xs-12">
    <h4 class="text-bold" style="margin-bottom: 15px">Onsite Application Adjustments</h4>
  </div>
  <!-- <div class="col-md-3 col-sm-4 col-xs-12">
    <div class="form-group">
      <label>Select Channel</label>
      <select [(ngModel)]="channelId" (change)="filterData()" class="form-control">
        <option *ngFor="let c of channels" [ngValue]="c.channelId">{{c.channelName}}</option>
      </select>
    </div>
  </div> -->
  <div class="col-md-9 col-sm-8 col-xs-12">
    <div class="form-group">
      <label>Instructions:</label>
      <p>Provide percentages for each application type where install team is required to expand the working surface area or add structural engineering. This doesn't affect the output per hour metric. This adjustment increases the amount of working area for each application. The system supports adjustment configurations by channel.</p>
    </div>
  </div>
  <div class="col-xs-12">
    <a (click) ="clearWeights()" class="btn btn-primary pull-right" >Clear Weights</a>
  </div>
  <div class="col-xs-12">
    <h5 class="text-center" style="text-decoration: underline; font-weight: bold">Percentage of Onsite Adjustments</h5>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Application</th>
              <th [style.width.px]="120">Percentage</th>
              <th>Last Comment</th>
              <th>Last Updated</th>
              <th>Updated By</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>{{item.value.applicationName}}</td>
                  <td [style.width.px]="120">
                    <qxm-input-percent
                      [myFormGroup]="dataForm"
                      myFormArrayName="dataUpdate"
                      [myFormGroupName]="i"
                      myFormControlName="onsitePercentAdjustment"
                      (acceptChange)="save();">
                    </qxm-input-percent>
                  </td>
                  <td>
                    <input #note type="text" class="form-control" formControlName="note" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td>{{item.value.updatedBy}}<span><a (click) ="viewComment(item)" class="btn btn-primary pull-right" >View Comments</a></span></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
<app-view-comment #modalViewComments [notes] = 'notes' [tab] = 'currentTab' [parentId] = 'currentId'></app-view-comment>
