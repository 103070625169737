import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { SettingService } from '../../../../../../services/setting.service';
import { UserService } from '../../../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tab-tile-application-complexity',
  templateUrl: './application-complexity.html'
})

export class TileApplicationComplexityComponent implements OnInit, OnDestroy {
  @Input() costSchemaId: any;
  complexities: any[];
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @ViewChild('modalViewComments') modalViewComments: any;
  notes:any;
  complexityId: any;
  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;

  constructor(private fb: FormBuilder, private toast: ToastrService,
    private settingService: SettingService, private userService: UserService) {
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array([])
    });
  }

  ngOnInit() {
    this.getComplexities();
  }
  getComplexities() {
    this.settingService.getComplexities().then((response) => {
      if (response.status) {
        this.complexities = response.data;
        this.complexityId = this.complexities[0].complexityId;
        this.loadData();
      }
      else this.toast.error("Can't get list complexities");
    });
  }
  ngOnDestroy(){
    this.save();
  }
  viewComment(item){
    const id = item.value.id;
    this.settingService.getMtxNoteApplicationComplexityById(id).then(res=> {
      if(res.status){
        this.notes = res.data;
        // this.viewComments.open();
        this.modalViewComments.open();
      }
      else this.toast.error('view comment failed.');
    });
  }
  loadData() {
    this.loading = true;
    this.settingService.getApplicationComplexity(this.costSchemaId).then(response => {
      if (response.status) {
        this.data = response.data;
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error("Load data failed");
    });
  }

  filterData() {
    this.dataFilter = this.data.filter(e => e.complexityId == this.complexityId);
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
    if (this.userService.isTenantOnly()) this.dataForm.disable();
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      id: e.id,
      applicationName: e.applicationName,
      // percentage: new PercentPipe('en-US').transform(e.percentage, '.2-2'),
      percentage: e.percentage * 100,
      note: e.note,
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.id == e.id);
    item.percentage = this.settingService.replaceMask(e.percentage) / 100;
    item.note = e.note;
    return {
      id: e.id,
      percentage: this.settingService.replaceMask(e.percentage) / 100,
      note: e.note,
    };
  }

  save() {
    let dataUpdate = [];
    let cellUpdate: any;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        cellUpdate = e;
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (dataUpdate.length > 0) {
      this.settingService.updateApplicationComplexity(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Sub-type weights has been updated');
          cellUpdate?.get('updatedOn').setValue(response.data);
          cellUpdate?.get('updatedBy').setValue(this.userService.getCurrentFullName());
        }
        else this.toast.error('Update sub-type weights failed');
      });
    }
  }
}
