import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { SettingService } from '../../../../../services/setting.service';
import { UserService } from '../../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { SlabCostDetailService } from '@app-admin/services/slab-cost-detail.service';

@Component({
  selector: 'material-waste',
  templateUrl: './material-waste.html',
})
export class MaterialWasteComponent implements OnInit, OnDestroy {
  @Input() costSchemaId: number;
  @Input() calcHeightTable = new EventEmitter<any>();
  @ViewChild('modalViewComments') modalViewComments: any;
  notes: any[];
  loading = false;
  dataMaterial: any[];
  materialForm: FormGroup;
  currentTab = 'MaterialWaste';
  currentId: number;

  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    private settingService: SettingService,
    private userService: UserService,
    private slabCostDetailService: SlabCostDetailService,
  ) {}

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    this.save();
  }

  viewComment(item: any) {
    this.currentId = item.value.matWasteId;
    this.settingService
      .getMtxNoteMaterialWasteById(this.currentId)
      .then((res) => {
        if (res.status) {
          this.notes = res.data;
          this.modalViewComments.open();

          //Update last comment when delete comment
          this.slabCostDetailService.getMessages().subscribe((data) => {
            if (!data) {
              return;
            }

            switch (data.messageType) {
              case SlabCostDetailService.MessageTypes.RELOAD_PARENT_COMMENT +
                '_' +
                this.currentTab +
                '_' +
                this.currentId:
                const payload = data.payload;
                if (payload) {
                  item.controls.note.setValue(payload.note);
                  item.controls.updatedOn.setValue(payload.updateOn);
                }
                break;
            }
          });
        } else this.toast.error('view comment failed.');
      });
  }

  loadData() {
    this.loading = true;
    this.settingService
      .getMaterialWaste(this.costSchemaId)
      .then((response) => {
        if (response.status) {
          this.dataMaterial = response.data;
          const dataView = [];
          this.dataMaterial.forEach((e) => {
            dataView.push(this.matItemView(e));
          });
          this.materialForm = this.fb.group({
            dataUpdate: this.fb.array(dataView),
          });
          if (this.userService.isTenantOnly()) this.materialForm.disable();
          this.loading = false;
          this.calcHeightTable.emit();
        } else this.toast.error('Load data failed');
      });
  }

  matItemView(e): FormGroup {
    return this.fb.group({
      matWasteId: e.matWasteId,
      materialGroup: e.materialGroup,
      // wastePercentAdjustment: new PercentPipe('en-US').transform(
      //   e.wastePercentAdjustment,
      //   '.2-2',
      // ),
      wastePercentAdjustment: e.wastePercentAdjustment * 100,
      // wasteAdvancedPercentAdjustment: new PercentPipe('en-US').transform(
      //   e.wasteAdvancedPercentAdjustment,
      //   '.2-2',
      // ),
      wasteAdvancedPercentAdjustment: e.wasteAdvancedPercentAdjustment * 100,
      note: e.note,
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
    });
  }

  matItemUpdate(e) {
    let item = this.dataMaterial.find((i) => i.matWasteId == e.matWasteId);
    item.wastePercentAdjustment =
      this.settingService.replaceMask(e.wastePercentAdjustment) / 100;
    item.wasteAdvancedPercentAdjustment =
      this.settingService.replaceMask(e.wasteAdvancedPercentAdjustment) / 100;
    item.note = e.note;
    return {
      matWasteId: e.matWasteId,
      wastePercentAdjustment:
        this.settingService.replaceMask(e.wastePercentAdjustment) / 100,
      wasteAdvancedPercentAdjustment:
        this.settingService.replaceMask(e.wasteAdvancedPercentAdjustment) / 100,
      note: e.note,
    };
  }

  save() {
    let dataUpdate = [];
    let cellUpdate: any;
    (this.materialForm.get('dataUpdate') as FormArray).controls.forEach((e) => {
      if (e.dirty) {
        cellUpdate = e;
        dataUpdate.push(this.matItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    console.log('updte', dataUpdate);
    if (dataUpdate.length > 0) {
      this.settingService.updateMaterialWaste(dataUpdate).then((response) => {
        if (response.status) {
          this.toast.success('Material waste has been updated');
          cellUpdate?.get('updatedOn').setValue(response.data);
          cellUpdate?.get('updatedBy').setValue(this.userService.getCurrentFullName());
        } else this.toast.error('Update material waste failed');
      });
    }
  }
}
