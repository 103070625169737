import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@app-admin/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  userName = '';

  constructor(private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userName = this.userService.getCurrentUsername();
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['login']);
  }
}
