<div class="row">
  <div class="col-xs-12">
    <h4 class="text-bold" style="margin-bottom: 15px">Service Class Configuration</h4>
  </div>


  <div class="col-md-3 col-sm-4 col-xs-12">
    <form [formGroup]="formAdd" (submit)="addServiceClass()" novalidate>
      <div class="row">
        <div class="col-sm-8">
          <div class="form-group" [class.has-error]="serviceClassName.invalid && (serviceClassName.dirty || serviceClassName.touched)">
            <label class="control-label">Service Class</label>
            <input type="text" trim="blur" class="form-control" formControlName="serviceClassName" maxlength="200" required>
            <span *ngIf="serviceClassName.invalid && (serviceClassName.dirty || serviceClassName.touched)" class="help-block">{{getErrorServiceClassName()}}&nbsp;</span>
          </div>
        </div>
        <div class="col-sm-4" >
          <button type="submit" class="btn btn-primary" style="margin-top: 25px">
            <span><i class="fa fa-spinner fa-spin fa-fw" *ngIf="loading"></i></span>
            <strong *ngIf="!loading">+ </strong> Add
          </button>
        </div>
      </div>
    </form>
  </div>

  <div class="col-xs-10">
    <br/>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th [style.width.px]="350">Service Class</th>
              <th [style.width.px]="350">Updated On</th>
              <th [style.width.px]="350">Updated By</th>
              <th [style.width.px]="80"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container>
              <ng-container  *ngFor="let item of data; let i = index;">
                <tr>
                  <td [style.width.px]="350">{{item.serviceClassName}}</td>
                  <td [style.width.px]="350">{{item.updatedAt | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td [style.width.px]="350">{{item.updateBy}}</td>
                  <td [style.width.px]="80">
                    <button [disabled]="loading" (click)="removeServiceClass(item.serviceClassId)" type="button" class="btn btn-primary">
                      Remove
                    </button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
    </div>
  </div>
</div>

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure?
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitRemoveServiceClass()" id="removeServiceClass">Submit</button>
  </bs-modal-footer>
</bs-modal>
