import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env-admin/environment';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class SettingService {
  constructor(private http: AuthHttp) { }

  replaceMask(str) {
    if (str == null || str === '') { return 0; }
    if (typeof str === 'number') { return str; }
    if (str.indexOf('$') >= 0) { str = str.replace('$', ''); }
    if (str.indexOf(',') >= 0) { str = str.replace(/,/g, ''); }
    if (str.indexOf('%') >= 0) { str = str.replace(/%/g, ''); }
    return parseFloat(str);
  }
  createCostSchema(schema){
    return this.http.post('CostSchemas', JSON.stringify(schema)).toPromise();
  }

  setBidsIsRefreshByTenant(tenantId: number): Promise<any> {
    return this.http.get(`Setting/SetBidsIsRefreshByTenant/${tenantId}`).toPromise();
  }

  setBidsIsRefreshByCostSchema(costSchemaId: number): Promise<any> {
    return this.http.get(`Setting/SetBidsIsRefreshByCostSchema/${costSchemaId}`).toPromise();
  }

  // setBidsIsRefreshByPriceSchema(schemaPriceId, tenantId): Promise<any> {
  //   return this.http.get(`Setting/SetBidsIsRefreshByCostSchema/${schemaPriceId}/${tenantId}`).toPromise();
  // }

  // BatchVersions
  getStatusBatchVersions(costSchemaId): Promise<any> {
    return this.http.get('Dpt/LoadStatusBatchVersions/' + costSchemaId).toPromise();
  }
  updateStatusBatchVersions(info, isUpdateAll): Promise<any> {
    return this.http.put(`Dpt/UpdateStatusBatchVersions/${isUpdateAll}`, info).toPromise();
  }

  // Get data filter
  getTenant(): Promise<any> {
    return this.http.get('GetAllTenant').toPromise();
  }

  getListState(): Promise<any> {
    return this.http.get('Utilities/States').toPromise();
  }

  getDefaultChannels(tenantId: number, moduleId: number, channelId: number): Promise<any> {
    return this.http.get(`Utilities/DefaultChannels?tenantId=${tenantId}&moduleId=${moduleId}&channelId=${channelId}`).toPromise();
  }

  getBidSubType(): Promise<any> {
    return this.http.get('Utilities/BidSubType').toPromise();
  }

  getProcess(costSchemaId): Promise<any> {
    return this.http.get('Utilities/Process/' + costSchemaId).toPromise();
  }

  getProcessByProcessTypeId(processTypeId: number): Promise<any> {
    return this.http.get(`Utilities/GetProcessByProcessTypeId/${processTypeId}`).toPromise();
  }

  getApplicationByBusinessUnitId(businessUnitId: number): Promise<any> {
    return this.http.get(`Utilities/GetApplicationByBusinessUnitId/${businessUnitId}`).toPromise();
  }

  getAllTileTypes(): Promise<any> {
    return this.http.get(`Utilities/GetTileTypes`).toPromise();
  }

  getMaterialDirectionality(): Promise<any> {
    return this.http.get('Utilities/MaterialDirectionality').toPromise();
  }

  getServiceClassByServiceType(tenantId, serviceTypeId): Promise<any> {
    return this.http.get(`Utilities/GetServiceClassByServiceType/${tenantId}/${serviceTypeId}`).toPromise();
  }

  getAllUnitsOfMeasure(): Promise<any> {
    return this.http.get('Utilities/GetAllUnitsOfMeasure').toPromise();
  }

  getSlabThickness(): Promise<any> {
    return this.http.get('Utilities/SlabThickness').toPromise();
  }
  addThickness(info): Promise<any> {
    return this.http.post('Thickness/AddThickness', info).toPromise();
  }
  updateThickness(info): Promise<any> {
    return this.http.post('Thickness/UpdateThickness', info).toPromise();
  }
  deleteThickness(thicknessId: number, dstThicknessId: number): Promise<any> {
    return this.http.get(`Thickness/RemoveThickness/${thicknessId}?reassignThicknessId=${dstThicknessId}`).toPromise();
  }
  deleteThicknessCheck(thicknessId: number): Promise<any> {
    return this.http.get(`Thickness/RemoveThicknessCheck/${thicknessId}`).toPromise();
  }

  getTenantThicknesses(tenantId: number): Promise<any> {
    return this.http.get(`Thickness/GetTenantThicknesses/${tenantId}`).toPromise();
  }
  updateTenantThickness(info): Promise<any> {
    return this.http.post('Thickness/UpdateTenantThickness', info).toPromise();
  }

  getSiteAccess(): Promise<any> {
    return this.http.get('Utilities/SiteAccess').toPromise();
  }

  getBuildingLevel(): Promise<any> {
    return this.http.get('Utilities/BuildingLevels').toPromise();
  }

  getAllMaterialType(type): Promise<any> {
    return this.http.get('Utilities/AllMaterialType/' + type).toPromise();
  }

  getTaxAssignments(costSchemaId): Promise<any> {
    return this.http.get('Utilities/GetTaxAssignments/' + costSchemaId).toPromise();
  }

  getItemCategories(): Promise<any> {
    return this.http.get(`Utilities/GetItemCategories`).toPromise();
  }

  getItemPropertyTypes(costSchemaId): Promise<any> {
    return this.http.get(`Utilities/GetItemPropertyTypes/` + costSchemaId).toPromise();
  }

  removeComment(id){
    return this.http.delete('Setting/DeleteComment/' + id).toPromise();
  }
  // Get data
  getProcessStandard(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetProcessStandard/' + costSchemaId).toPromise();
  }
  getMtxNoteProcessStandardById(id): Promise<any>{
    return this.http.get('Setting/GetProcessStandard/ViewComment/'+id).toPromise();
  }
  getMtxNoteSubTypeWeightsById(id): Promise<any>{
    return this.http.get('Setting/GetSubTypeWeights/ViewComment/'+id).toPromise();
  }
  getMtxNoteApplicationWeightsById(id): Promise<any>{
    return this.http.get('Setting/GetApplicationWeights/ViewComment/'+id).toPromise();
  }
  getMtxNoteApplicationWasteById(id): Promise<any>{
    return this.http.get('Setting/GetSiteAplicationWaste/ViewComment/'+id).toPromise();
  }
  getMtxNoteMaterialWeightsById(id): Promise<any>{
    return this.http.get('Setting/GetMaterialWeights/ViewComment/'+id).toPromise();
  }
  getMtxNoteMaterialDirectionById(id): Promise<any>{
    return this.http.get('Setting/GetMaterialDirection/ViewComment/'+id).toPromise();
  }
  getMtxNoteLayoutApprovalById(id): Promise<any>{
    return this.http.get('Setting/GetLayoutApproval/ViewComment/'+id).toPromise();
  }
  getMtxNoteEdgeBaseById(id): Promise<any>{
    return this.http.get('Setting/GetEdgeBase/ViewComment/'+id).toPromise();
  }
  getMtxNoteEdgeMaterial(id):Promise<any>{
    return this.http.get('Setting/GetEdgeMaterial/ViewComment/'+id).toPromise();
  }
  getMtxNoteEdgeProcessById(id): Promise<any>{
    return this.http.get('Setting/GetEdgeProcess/ViewComment/'+id).toPromise();
  }
  getMtxNoteBookmatchWeightsById(id): Promise<any>{
    return this.http.get('Setting/GetBookmatchWeights/ViewComment/'+id).toPromise();
  }
  getMtxNoteTiredVolumeSizingById(id): Promise<any>{
    return this.http.get('Setting/GetTieredVolumeSizing/ViewComment/'+id).toPromise();
  }
  getMtxNoteThinknessWeightsById(id): Promise<any>{
    return this.http.get('Setting/GetThicknessWeights/ViewComment/'+id).toPromise();
  }
  getMtxNoteSupplyOverridesById(id): Promise<any>{
    return this.http.get('Setting/GetSupplyOverrides/ViewComment/'+id).toPromise();
  }
  getMtxNoteSiteAccessDifficultyById(id): Promise<any>{
    return this.http.get('Setting/GetSiteAccessDifficulty/ViewComment/'+id).toPromise();
  }
  getMtxNoteBuildingLevelsById(id): Promise<any>{
    return this.http.get('Setting/GetBuildingLevels/ViewComment/'+id).toPromise();
  }
  getMtxNoteAdjustOnsiteById(id): Promise<any>{
    return this.http.get('Setting/GetAdjustOnsite/ViewComment/'+id).toPromise();
  }
  getMtxNoteOnsitePolishById(id): Promise<any>{
    return this.http.get('Setting/GetOnsitePolish/ViewComment/'+id).toPromise();
  }
  getMtxNoteAplicationWasteById(id): Promise<any>{
    return this.http.get('Setting/GetSiteAplicationWaste/ViewComment/'+id).toPromise();
  }
  getMtxNoteMaterialWasteById(id): Promise<any>{
    return this.http.get('Setting/GetMaterialWaste/ViewComment/'+id).toPromise();
  }
  getMtxNoteEdgeMaterialById(id): Promise<any>{
    return this.http.get('Setting/GetEdgeMaterial/ViewComment/'+id).toPromise();
  }

  updateProcessStandard(info): Promise<any> {
    return this.http.post('Setting/UpdateProcessStandard', info).toPromise();
  }

  getChannelWeights(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetChannelWeights/' + costSchemaId).toPromise();
  }
  updateChannelWeights(info): Promise<any> {
    return this.http.post('Setting/UpdateChannelWeights', info).toPromise();
  }

  getSubTypeWeights(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetSubTypeWeights/' + costSchemaId).toPromise();
  }
  updateSubTypeWeights(info): Promise<any> {
    return this.http.post('Setting/UpdateSubTypeWeights', info).toPromise();
  }

  getApplicationWeights(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetApplicationWeights/' + costSchemaId).toPromise();
  }
  updateApplicationWeights(info): Promise<any> {
    return this.http.post('Setting/UpdateApplicationWeights', info).toPromise();
  }

  getMaterialTypes(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetMaterialTypes/' + costSchemaId).toPromise();
  }
  addMaterialTypes(info): Promise<any> {
    return this.http.post('Setting/AddMaterialTypes', info).toPromise();
  }
  updateMaterialTypes(info): Promise<any> {
    return this.http.post('Setting/UpdateMaterialTypes', info).toPromise();
  }
  deleteMaterialTypes(materialTypeId): Promise<any> {
    return this.http.get('Setting/DeleteMaterialTypes/' + materialTypeId).toPromise();
  }

  getMaterialWeights(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetMaterialWeights/' + costSchemaId).toPromise();
  }
  updateMaterialWeights(info): Promise<any> {
    return this.http.post('Setting/UpdateMaterialWeights', info).toPromise();
  }

  getMaterialDirection(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetMaterialDirection/' + costSchemaId).toPromise();
  }
  updateMaterialDirection(info): Promise<any> {
    return this.http.post('Setting/UpdateMaterialDirection', info).toPromise();
  }

  getEdgeBase(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetEdgeBase/' + costSchemaId).toPromise();
  }
  updateEdgeBase(info): Promise<any> {
    return this.http.post('Setting/UpdateEdgeBase', info).toPromise();
  }

  getEdgeProcess(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetEdgeProcess/' + costSchemaId).toPromise();
  }
  updateEdgeProcess(info): Promise<any> {
    return this.http.post('Setting/UpdateEdgeProcess', info).toPromise();
  }

  getEdgeMaterials(costSchemaId, processId, materialTypeId): Promise<any> {
    return this.http.get(`Setting/GetEdgeMaterial/${costSchemaId}/${processId}/${materialTypeId}`).toPromise();
  }
  getEdgeMaterial(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetEdgeMaterial/' + costSchemaId).toPromise();
  }
  updateEdgeMaterial(info): Promise<any> {
    return this.http.post('Setting/UpdateEdgeMaterial', info).toPromise();
  }

  getBookMatchWeights(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetBookMatchWeights/' + costSchemaId).toPromise();
  }
  updateBookMatchWeights(info): Promise<any> {
    return this.http.post('Setting/UpdateBookMatchWeights', info).toPromise();
  }

  getTieredVolumeSizing(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTieredVolumeSizing/' + costSchemaId).toPromise();
  }
  updateTieredVolumeSizing(info): Promise<any> {
    return this.http.post('Setting/UpdateTieredVolumeSizing', info).toPromise();
  }
  getProcessVolumeTiers(costSchemaId, processId): Promise<any> {
    return this.http.get(`Setting/GetProcessVolumeTiers/${costSchemaId}/${processId}`).toPromise();
  }
  addNewTier(costSchemaId, processId, value): Promise<any> {
    return this.http.get(`Setting/AddNewTier/${costSchemaId}/${processId}/${value}`).toPromise();
  }
  removeTier(costSchemaId, processId, tierId): Promise<any> {
    return this.http.get(`Setting/RemoveTier/${costSchemaId}/${processId}/${tierId}`).toPromise();
  }
  resetTier(costSchemaId, processId): Promise<any> {
    return this.http.get(`Setting/ResetTier/${costSchemaId}/${processId}`).toPromise();
  }
  saveTier(costSchemaId, processId): Promise<any> {
    return this.http.get(`Setting/SaveTier/${costSchemaId}/${processId}`).toPromise();
  }

  getThicknessWeights(tenantId): Promise<any> {
    return this.http.get('Setting/GetThicknessWeights/' + tenantId).toPromise();
  }
  updateThicknessWeights(info): Promise<any> {
    return this.http.post('Setting/UpdateThicknessWeights', info).toPromise();
  }

  getSiteAccessDifficulty(tenantId): Promise<any> {
    return this.http.get('Setting/GetSiteAccessDifficulty/' + tenantId).toPromise();
  }
  updateSiteAccessDifficulty(info): Promise<any> {
    return this.http.post('Setting/UpdateSiteAccessDifficulty', info).toPromise();
  }

  getBuildingLevels(tenantId): Promise<any> {
    return this.http.get('Setting/GetBuildingLevels/' + tenantId).toPromise();
  }
  updateBuildingLevels(info): Promise<any> {
    return this.http.post('Setting/UpdateBuildingLevels', info).toPromise();
  }

  getAdjustOnsite(tenantId): Promise<any> {
    return this.http.get('Setting/GetAdjustOnsite/' + tenantId).toPromise();
  }
  updateAdjustOnsite(info): Promise<any> {
    return this.http.post('Setting/UpdateAdjustOnsite', info).toPromise();
  }

  getOnsitePolish(tenantId): Promise<any> {
    return this.http.get('Setting/GetOnsitePolish/' + tenantId).toPromise();
  }
  updateOnsitePolish(info): Promise<any> {
    return this.http.post('Setting/UpdateOnsitePolish', info).toPromise();
  }

  getApplicationWaste(tenantId): Promise<any> {
    return this.http.get('Setting/GetApplicationWaste/' + tenantId).toPromise();
  }
  getMaterialWaste(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetMaterialWaste/' + costSchemaId).toPromise();
  }

  updateMaterialWaste(info): Promise<any> {
    return this.http.post('Setting/UpdateMaterialWaste', JSON.stringify(info)).toPromise();
  }

  updateApplicationWaste(info): Promise<any> {
    return this.http.post('Setting/UpdateApplicationWaste', JSON.stringify(info)).toPromise();
  }

  getCostSchemas(tenantId: number, businessUnitId: number): Promise<any> {
    return this.http.get(`Setting/GetCostSchemas/${tenantId}/${businessUnitId}`).toPromise();
  }
  updateCostSchemas(info): Promise<any> {
    return this.http.post('Setting/UpdateCostSchemas', info).toPromise();
  }

  getBaseProcesses(tenantId: number, businessUnitId: number): Promise<any> {
    return this.http.get(`Setting/GetBaseProcesses/${tenantId}/${businessUnitId}`).toPromise();
  }
  updateBaseProcesses(info): Promise<any> {
    return this.http.post('Setting/UpdateBaseProcesses', info).toPromise();
  }

  getApplications(tenantId, businessUnitId): Promise<any> {
    return this.http.get(`Setting/GetApplications/${tenantId}/${businessUnitId}`).toPromise();
  }
  
  addApplications(info): Promise<any> {
    return this.http.post('Setting/AddApplications', info).toPromise();
  }
  updateApplications(info): Promise<any> {
    return this.http.post('Setting/UpdateApplications', info).toPromise();
  }
  deleteApplication(materialTypeId): Promise<any> {
    return this.http.get('Setting/DeleteApplication/' + materialTypeId).toPromise();
  }

  getEdgeTypes(tenantId: number): Promise<any> {
    return this.http.get('Setting/GetEdgeTypes/' + tenantId).toPromise();
  }
  getGlobalEdgeTypes(): Promise<any> {
    return this.http.get('Utilities/EdgeTypes').toPromise();
  }

  // START: ExecutionProcesses
  getExecutionProcesses(businessUnitId: number): Promise<any> {
    return this.http.get(`Setting/GetExecutionProcesses/${businessUnitId}`).toPromise();
  }
  updateExecutionProcess(model: any): Promise<any> {
    return this.http.post(`Setting/UpdateExecutionProcess`, JSON.stringify(model)).toPromise();
  }
  sortExecutionProcess(id: number, sortNumber: number): Promise<any> {
    return this.http.post(`Setting/SortExecutionProcess/${id}/${sortNumber}`).toPromise();
  }
  deleteExecutionProcess(id: number): Promise<any> {
    return this.http.delete(`Setting/DeleteExecutionProcess/${id}`).toPromise();
  }
  addExecutionProcess(model: any): Promise<any> {
    return this.http.post(`Setting/AddExecutionProcess`, JSON.stringify(model)).toPromise();
  }
  // END: ExecutionProcesses

  addEdgeTypes(info): Promise<any> {
    return this.http.post('Setting/AddEdgeTypes', info).toPromise();
  }
  updateEdges(info): Promise<any> {
    return this.http.post('Setting/UpdateEdges', info).toPromise();
  }
  deleteEdgeType(materialTypeId): Promise<any> {
    return this.http.get('Setting/DeleteEdgeType/' + materialTypeId).toPromise();
  }

  getMaterialRemoval(tenantId): Promise<any> {
    return this.http.get('Setting/GetMaterialRemoval/' + tenantId).toPromise();
  }
  addMaterialRemoval(info): Promise<any> {
    return this.http.post('Setting/AddMaterialRemoval', info).toPromise();
  }
  updateMaterialRemovals(info): Promise<any> {
    return this.http.post('Setting/UpdateMaterialRemovals', info).toPromise();
  }
  deleteMaterialRemoval(materialTypeId): Promise<any> {
    return this.http.get('Setting/DeleteMaterialRemoval/' + materialTypeId).toPromise();
  }

  getSupplyOverride(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetSupplyOverride/' + costSchemaId).toPromise();
  }
  updateSupplyOverride(info): Promise<any> {
    return this.http.post('Setting/UpdateSupplyOverride', info).toPromise();
  }

  getGeneralRates(tenantId: number, businessUnitId: number): Promise<any> {
    return this.http.get(`Setting/GetGeneralRates/${tenantId}/${businessUnitId}`).toPromise();
  }
  updateGeneralRates(info): Promise<any> {
    return this.http.post('Setting/UpdateGeneralRates', info).toPromise();
  }

  getTaxRegions(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTaxRegions/' + costSchemaId).toPromise();
  }
  addTaxRegions(info): Promise<any> {
    return this.http.post('Setting/AddTaxRegions', info).toPromise();
  }
  updateTaxRegions(info): Promise<any> {
    return this.http.post('Setting/UpdateTaxRegions', info).toPromise();
  }
  removeTaxRegions(id): Promise<any> {
    return this.http.delete('Setting/RemoveTaxRegions/' + id).toPromise();
  }

  getDefaultTaxByChannel(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetDefaultTaxByChannel/' + costSchemaId).toPromise();
  }
  updateDefaultTaxByChannel(info): Promise<any> {
    return this.http.post('Setting/UpdateDefaultTaxByChannel', info).toPromise();
  }

  getItemTypes(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetItemTypes/' + costSchemaId).toPromise();
  }
  addItemTypes(info): Promise<any> {
    return this.http.post('Setting/AddItemTypes', info).toPromise();
  }
  updateItemTypes(info): Promise<any> {
    return this.http.post('Setting/UpdateItemTypes', info).toPromise();
  }
  removeItemTypes(id): Promise<any> {
    return this.http.delete('Setting/RemoveItemTypes/' + id).toPromise();
  }

  getDefaultItemProperties(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetDefaultItemProperties/' + costSchemaId).toPromise();
  }
  addDefaultItemProperties(info): Promise<any> {
    return this.http.post('Setting/AddDefaultItemProperties', info).toPromise();
  }
  updateDefaultItemProperties(info): Promise<any> {
    return this.http.post('Setting/UpdateDefaultItemProperties', info).toPromise();
  }
  removeDefaultItemProperties(id): Promise<any> {
    return this.http.delete('Setting/RemoveDefaultItemProperties/' + id).toPromise();
  }
  addItemPropertyType(info): Promise<any> {
    return this.http.post('ItemProperties/AddItemPropertyType', JSON.stringify(info)).toPromise();
  }

  getPriceSchemas(tenantId: number, businessUnitId: number): Promise<any> {
    return this.http.get(`PriceSchemas/GetPriceSchemas/${tenantId}?businessUnitId=${businessUnitId}`).toPromise();
  }

  getLayoutApproval(costSchemaId): Promise<any> {
    return this.http.get(`Setting/GetLayoutApproval/${costSchemaId}`).toPromise();
  }
  updateLayoutApproval(info): Promise<any> {
    return this.http.post('Setting/UpdateLayoutApproval', info).toPromise();
  }

  getDataLogSlabCost(paging): Promise<any> {
    return this.http.post('Setting/GetListLogAdminUpdateSlabCost', JSON.stringify(paging)).toPromise();
  }
  getDataLogSlabPricing(paging): Promise<any> {
    return this.http.post('Setting/GetListLogAdminUpdateSlabPricing', JSON.stringify(paging)).toPromise();
  }

  getListLogAdminUpdateTenantSpecific(paging): Promise<any> {
    return this.http.post('Setting/GetListLogAdminUpdateTenantSpecific', JSON.stringify(paging)).toPromise();
  }

  rerunBatchVersions(costSchemaId): Promise<any> {
    return this.http.get(`Dpt/RerunBatchVersions/${costSchemaId}`).toPromise();
  }

  getListModule(): Promise<any> {
    return this.http.get(`Module/GetListModule`).toPromise();
  }

  getAllSchemas(businessUnitId: number): Promise<any>{
    return this.http.get(`CostSchemas/GetAll/${businessUnitId}`).toPromise();
  }

  getCostSchemaDetail(id): Promise<any>{
    return this.http.get('CostSchemas/'+ id).toPromise();
  }

  updateCostSchema(schema): Promise<any>{
    return this.http.post('CostSchemas/UpdateSchema', JSON.stringify(schema)).toPromise();
  }

  getRegions(filter: any): Promise<any> {
    return this.http.post('Setting/GetRegions', JSON.stringify(filter)).toPromise();
  }

  addRegions(name, active): Promise<any> {
    return this.http.get(`Setting/AddRegions/${name}/${active}`).toPromise();
  }

  setActive(regionId, active): Promise<any> {
    return this.http.get(`Setting/SetActive/${regionId}/${active}`).toPromise();
  }

  deleteRegion(regionId): Promise<any> {
    return this.http.get(`Setting/DeleteRegion/${regionId}`).toPromise();
  }

  uploadFile(data): Promise<any> {
    const headers = new HttpHeaders({
      'Authorization': sessionStorage.getItem('Authorization'),
      'TenantName': "Admin",
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Key'
    });
    return this.http.requestSpecial("POST", environment.apiURL + 'Setting/UploadEdgeTypeImage', data, headers).toPromise();
  }

  // Tile
  getTileTypes(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTileTypes/' + costSchemaId).toPromise();
  }
  addTileTypes(info): Promise<any> {
    return this.http.post('Setting/AddTileTypes', info).toPromise();
  }
  updateTileTypes(info): Promise<any> {
    return this.http.post('Setting/UpdateTileTypes', info).toPromise();
  }
  deleteTileTypes(tileTypeId): Promise<any> {
    return this.http.get('Setting/DeleteTileTypes/' + tileTypeId).toPromise();
  }

  getTileSizes(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTileSizes/' + costSchemaId).toPromise();
  }
  addTileSizes(info): Promise<any> {
    return this.http.post('Setting/AddTileSizes', info).toPromise();
  }
  updateTileSizes(info): Promise<any> {
    return this.http.post('Setting/UpdateTileSizes', info).toPromise();
  }
  deleteTileSizes(tileSizeId): Promise<any> {
    return this.http.get('Setting/DeleteTileSizes/' + tileSizeId).toPromise();
  }

  getTileSets(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTileSets/' + costSchemaId).toPromise();
  }
  addTileSets(info): Promise<any> {
    return this.http.post('Setting/AddTileSets', info).toPromise();
  }
  updateTileSets(info): Promise<any> {
    return this.http.post('Setting/UpdateTileSets', info).toPromise();
  }
  deleteTileSets(tileSetId): Promise<any> {
    return this.http.get('Setting/DeleteTileSets/' + tileSetId).toPromise();
  }

  getSpecialConditions(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetSpecialConditions/' + costSchemaId).toPromise();
  }
  addSpecialConditions(info): Promise<any> {
    return this.http.post('Setting/AddSpecialConditions', info).toPromise();
  }
  updateSpecialConditions(info): Promise<any> {
    return this.http.post('Setting/UpdateSpecialConditions', info).toPromise();
  }
  deleteSpecialConditions(tileTypeId): Promise<any> {
    return this.http.get('Setting/DeleteSpecialConditions/' + tileTypeId).toPromise();
  }

  getApplicationBase(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetApplicationBase/' + costSchemaId).toPromise();
  }
  updateApplicationBase(info): Promise<any> {
    return this.http.post('Setting/UpdateApplicationBase', info).toPromise();
  }
  getMtxNoteApplicationBaseById(id): Promise<any>{
    return this.http.get('Setting/GetApplicationBase/ViewComment/'+id).toPromise();
  }

  getApplicationComplexity(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetApplicationComplexity/' + costSchemaId).toPromise();
  }
  updateApplicationComplexity(info): Promise<any> {
    return this.http.post('Setting/UpdateApplicationComplexity', info).toPromise();
  }
  getMtxNoteApplicationComplexityById(id): Promise<any>{
    return this.http.get('Setting/GetApplicationComplexity/ViewComment/'+id).toPromise();
  }

  getComplexities(): Promise<any> {
    return this.http.get('Utilities/Complexities').toPromise();
  }

  // ----------------------------------

  getTileTypeWeight(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTileTypeWeight/' + costSchemaId).toPromise();
  }
  updateTileTypeWeight(info): Promise<any> {
    return this.http.post('Setting/UpdateTileTypeWeight', info).toPromise();
  }
  getMtxNoteTileTypeWeightById(id): Promise<any>{
    return this.http.get('Setting/GetTileTypeWeight/ViewComment/'+id).toPromise();
  }

  getTileSizeWeight(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTileSizeWeight/' + costSchemaId).toPromise();
  }
  updateTileSizeWeight(info): Promise<any> {
    return this.http.post('Setting/UpdateTileSizeWeight', info).toPromise();
  }
  getMtxNoteTileSizeWeightById(id): Promise<any>{
    return this.http.get('Setting/GetTileSizeWeight/ViewComment/'+id).toPromise();
  }

  getTilePatternWeight(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTilePatternWeight/' + costSchemaId).toPromise();
  }
  updateTilePatternWeight(info): Promise<any> {
    return this.http.post('Setting/UpdateTilePatternWeight', info).toPromise();
  }
  getMtxNoteTilePatternWeightById(id): Promise<any>{
    return this.http.get('Setting/GetTilePatternWeight/ViewComment/'+id).toPromise();
  }

  getTileSetWeight(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTileSetWeight/' + costSchemaId).toPromise();
  }
  updateTileSetWeight(info): Promise<any> {
    return this.http.post('Setting/UpdateTileSetWeight', info).toPromise();
  }
  getMtxNoteTileSetWeightById(id): Promise<any>{
    return this.http.get('Setting/GetTileSetWeight/ViewComment/'+id).toPromise();
  }

  getTileCuttingTypeWeight(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTileCuttingTypeWeight/' + costSchemaId).toPromise();
  }
  updateTileCuttingTypeWeight(info): Promise<any> {
    return this.http.post('Setting/UpdateTileCuttingTypeWeight', info).toPromise();
  }
  getMtxNoteTileCuttingTypeWeightById(id): Promise<any>{
    return this.http.get('Setting/GetTileCuttingTypeWeight/ViewComment/'+id).toPromise();
  }

  getSpecialConditionWeight(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetSpecialConditionWeight/' + costSchemaId).toPromise();
  }
  updateSpecialConditionWeight(info): Promise<any> {
    return this.http.post('Setting/UpdateSpecialConditionWeight', info).toPromise();
  }
  getMtxNoteSpecialConditionWeightById(id): Promise<any>{
    return this.http.get('Setting/GetSpecialConditionWeight/ViewComment/'+id).toPromise();
  }

  getTileApplicationWasteWeight(costSchemaId: number): Promise<any> {
    return this.http.get('Setting/GetTileApplicationWasteWeight/' + costSchemaId).toPromise();
  }
  updateTileApplicationWasteWeight(info: any): Promise<any> {
    return this.http.post('Setting/UpdateTileApplicationWasteWeight', info).toPromise();
  }
  getMtxNoteTileApplicationWasteWeightById(id: number): Promise<any>{
    return this.http.get('Setting/GetTileApplicationWasteWeight/ViewComment/'+id).toPromise();
  }

  getTileTypeWasteWeight(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTileTypeWasteWeight/' + costSchemaId).toPromise();
  }
  updateTileTypeWasteWeight(info): Promise<any> {
    return this.http.post('Setting/UpdateTileTypeWasteWeight', info).toPromise();
  }
  getMtxNoteTileTypeWasteWeightById(id): Promise<any>{
    return this.http.get('Setting/GetTileTypeWasteWeight/ViewComment/'+id).toPromise();
  }

  // getTileSetWasteWeight(costSchemaId): Promise<any> {
  //   return this.http.get('Setting/GetTileSetWasteWeight/' + costSchemaId).toPromise();
  // }
  // updateTileSetWasteWeight(info): Promise<any> {
  //   return this.http.post('Setting/UpdateTileSetWasteWeight', info).toPromise();
  // }
  // getMtxNoteTileSetWasteWeightById(id): Promise<any>{
  //   return this.http.get('Setting/GetTileSetWasteWeight/ViewComment/'+id).toPromise();
  // }

  getTileComplexityWasteWeight(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTileComplexityWasteWeight/' + costSchemaId).toPromise();
  }
  updateTileComplexityWasteWeight(info): Promise<any> {
    return this.http.post('Setting/UpdateTileComplexityWasteWeight', info).toPromise();
  }
  getMtxNoteTileComplexityWasteWeightById(id): Promise<any>{
    return this.http.get('Setting/GetTileComplexityWasteWeight/ViewComment/'+id).toPromise();
  }

  getTileCuttingTypeWasteWeight(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTileCuttingTypeWasteWeight/' + costSchemaId).toPromise();
  }
  updateTileCuttingTypeWasteWeight(info): Promise<any> {
    return this.http.post('Setting/UpdateTileCuttingTypeWasteWeight', info).toPromise();
  }
  getMtxNoteTileCuttingTypeWasteWeightById(id): Promise<any>{
    return this.http.get('Setting/GetTileCuttingTypeWasteWeight/ViewComment/'+id).toPromise();
  }

  getTilePatternWasteWeight(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTilePatternWasteWeight/' + costSchemaId).toPromise();
  }
  updateTilePatternWasteWeight(info): Promise<any> {
    return this.http.post('Setting/UpdateTilePatternWasteWeight', info).toPromise();
  }
  getMtxNoteTilePatternWasteWeightById(id): Promise<any>{
    return this.http.get('Setting/GetTilePatternWasteWeight/ViewComment/'+id).toPromise();
  }

  getTileSizeWasteWeight(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTileSizeWasteWeight/' + costSchemaId).toPromise();
  }
  updateTileSizeWasteWeight(info): Promise<any> {
    return this.http.post('Setting/UpdateTileSizeWasteWeight', info).toPromise();
  }
  getMtxNoteTileSizeWasteWeightById(id): Promise<any>{
    return this.http.get('Setting/GetTileSizeWasteWeight/ViewComment/'+id).toPromise();
  }

  getTileTrimTypeWeight(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTileTrimTypeWeight/' + costSchemaId).toPromise();
  }
  updateTileTrimTypeWeight(info): Promise<any> {
    return this.http.post('Setting/UpdateTileTrimTypeWeight', info).toPromise();
  }
  getMtxNoteTileTrimTypeWeightById(id): Promise<any>{
    return this.http.get('Setting/GetTileTrimTypeWeight/ViewComment/'+id).toPromise();
  }

  getTileTrimApplicationWeight(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTileTrimApplicationWeight/' + costSchemaId).toPromise();
  }
  updateTileTrimApplicationWeight(info): Promise<any> {
    return this.http.post('Setting/UpdateTileTrimApplicationWeight', info).toPromise();
  }
  getMtxNoteTileTrimApplicationWeightById(id): Promise<any>{
    return this.http.get('Setting/GetTileTrimApplicationWeight/ViewComment/'+id).toPromise();
  }

  getTileTrimSetWeight(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTileTrimSetWeight/' + costSchemaId).toPromise();
  }
  updateTileTrimSetWeight(info): Promise<any> {
    return this.http.post('Setting/UpdateTileTrimSetWeight', info).toPromise();
  }
  getMtxNoteTileTrimSetWeightById(id): Promise<any>{
    return this.http.get('Setting/GetTileTrimSetWeight/ViewComment/'+id).toPromise();
  }

  getTileTrimComplexityWeight(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTileTrimComplexityWeight/' + costSchemaId).toPromise();
  }
  updateTileTrimComplexityWeight(info): Promise<any> {
    return this.http.post('Setting/UpdateTileTrimComplexityWeight', info).toPromise();
  }
  getMtxNoteTileTrimComplexityWeightById(id): Promise<any>{
    return this.http.get('Setting/GetTileTrimComplexityWeight/ViewComment/'+id).toPromise();
  }

  getTileTrimVolumeWeight(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTileTrimVolumeWeight/' + costSchemaId).toPromise();
  }
  updateTileTrimVolumeWeight(info): Promise<any> {
    return this.http.post('Setting/UpdateTileTrimVolumeWeight', info).toPromise();
  }
  getMtxNoteTileTrimVolumeWeightById(id): Promise<any>{
    return this.http.get('Setting/GetTileTrimVolumeWeight/ViewComment/'+id).toPromise();
  }

  getTileTrimEdgeWeight(costSchemaId): Promise<any> {
    return this.http.get('Setting/GetTileTrimEdgeWeight/' + costSchemaId).toPromise();
  }
  updateTileTrimEdgeWeight(info): Promise<any> {
    return this.http.post('Setting/UpdateTileTrimEdgeWeight', info).toPromise();
  }
  getMtxNoteTileTrimEdgeWeightById(id): Promise<any>{
    return this.http.get('Setting/GetTileTrimEdgeWeight/ViewComment/'+id).toPromise();
  }

  getApplicationVolumeTiers(costSchemaId: number, applicationId: number): Promise<any> {
    return this.http.get(`Setting/GetApplicationVolumeTiers/${costSchemaId}/${applicationId}`).toPromise();
  }
  addNewApplicationVolumeTier(costSchemaId: number, applicationId: number, value: number): Promise<any> {
    return this.http.get(`Setting/AddNewApplicationVolumeTier/${costSchemaId}/${applicationId}/${value}`).toPromise();
  }
  removeApplicationVolumeTier(costSchemaId: number, applicationId: number, tierId: number): Promise<any> {
    return this.http.get(`Setting/RemoveApplicationVolumeTier/${costSchemaId}/${applicationId}/${tierId}`).toPromise();
  }
  resetApplicationVolumeTier(costSchemaId: number, applicationId: number): Promise<any> {
    return this.http.get(`Setting/ResetApplicationVolumeTier/${costSchemaId}/${applicationId}`).toPromise();
  }
  saveApplicationVolumeTier(costSchemaId: number, applicationId: number): Promise<any> {
    return this.http.get(`Setting/SaveApplicationVolumeTier/${costSchemaId}/${applicationId}`).toPromise();
  }

  getTileTrimTypes(costSchemaId: number): Promise<any> {
    return this.http.get('Setting/GetTileTrimTypes/' + costSchemaId).toPromise();
  }
  addTileTrimTypes(info): Promise<any> {
    return this.http.post('Setting/AddTileTrimTypes', info).toPromise();
  }
  updateTileTrimTypes(info): Promise<any> {
    return this.http.post('Setting/UpdateTileTrimTypes', info).toPromise();
  }
  deleteTileTrimTypes(tileTypeId: number): Promise<any> {
    return this.http.get('Setting/DeleteTileTrimTypes/' + tileTypeId).toPromise();
  }

  getTileTrimEdges(costSchemaId: number): Promise<any> {
    return this.http.get('Setting/GetTileTrimEdges/' + costSchemaId).toPromise();
  }
  addTileTrimEdges(info): Promise<any> {
    return this.http.post('Setting/AddTileTrimEdges', info).toPromise();
  }
  updateTileTrimEdges(info): Promise<any> {
    return this.http.post('Setting/UpdateTileTrimEdges', info).toPromise();
  }
  deleteTileTrimEdges(tileTypeId: number): Promise<any> {
    return this.http.get('Setting/DeleteTileTrimEdges/' + tileTypeId).toPromise();
  }

  // Tile Thickness
  getTileThickness(): Promise<any> {
    return this.http.get('TileThickness/GetTileThickness').toPromise();
  }
  addTileThickness(info): Promise<any> {
    return this.http.post('TileThickness/AddThickness', info).toPromise();
  }
  updateTileThickness(info): Promise<any> {
    return this.http.post('TileThickness/UpdateThickness', info).toPromise();
  }
  deleteTileThickness(thicknessId: number, dstThicknessId: number): Promise<any> {
    return this.http.get(`TileThickness/RemoveThickness/${thicknessId}?reassignThicknessId=${dstThicknessId}`).toPromise();
  }
  // deleteTileThicknessCheck(thicknessId: number): Promise<any> {
  //   return this.http.get(`TileThickness/RemoveThicknessCheck/${thicknessId}`).toPromise();
  // }

  getTenantTileThicknesses(tenantId: number): Promise<any> {
    return this.http.get(`TileThickness/GetTenantThicknesses/${tenantId}`).toPromise();
  }
  updateTenantTileThickness(info): Promise<any> {
    return this.http.post('TileThickness/UpdateTenantThickness', info).toPromise();
  }

  sortTenantEdgeConfiguration(tenantEdgeConfigurationId, sortNumber): Promise<any> {
    return this.http.post(`Setting/SortTenantEdgeConfiguration/${tenantEdgeConfigurationId}/${sortNumber}`).toPromise();
  }

  // Directionality Waste
  getMtxNoteDirectionalityWasteById(id: number): Promise<any>{
    return this.http.get(`Setting/GetDirectionalityWaste/ViewComment/${id}`).toPromise();
  }
  getDirectionalityWaste(tenantId: number): Promise<any> {
    return this.http.get(`Setting/GetDirectionalityWaste/${tenantId}`).toPromise();
  }
  updateDirectionalityWaste(info: any): Promise<any> {
    return this.http.post(`Setting/UpdateDirectionalityWaste`, JSON.stringify(info)).toPromise();
  }

  // VeinMatching Waste
  getMtxNoteVeinMatchingWasteById(id: number): Promise<any>{
    return this.http.get(`Setting/GetVeinMatchingWaste/ViewComment/${id}`).toPromise();
  }
  getVeinMatchingWaste(tenantId: number): Promise<any> {
    return this.http.get(`Setting/GetVeinMatchingWaste/${tenantId}`).toPromise();
  }
  updateVeinMatchingWaste(info: any): Promise<any> {
    return this.http.post(`Setting/UpdateVeinMatchingWaste`, JSON.stringify(info)).toPromise();
  }

  // LayoutApproval Waste
  getMtxNoteLayoutApprovalWasteById(id: number): Promise<any>{
    return this.http.get(`Setting/GetLayoutApprovalWaste/ViewComment/${id}`).toPromise();
  }
  getLayoutApprovalWaste(tenantId: number): Promise<any> {
    return this.http.get(`Setting/GetLayoutApprovalWaste/${tenantId}`).toPromise();
  }
  updateLayoutApprovalWaste(info: any): Promise<any> {
    return this.http.post(`Setting/UpdateLayoutApprovalWaste`, JSON.stringify(info)).toPromise();
  }

  getServiceClass(tenantId: number, serviceTypeId: number): Promise<any> {
    return this.http.get(`Setting/GetServiceClass/${tenantId}/${serviceTypeId}`).toPromise();
  }

  deleteServiceClass(serviceClassId: number): Promise<any> {
    return this.http.delete(`Setting/DeleteServiceClass/${serviceClassId}`).toPromise();
  }

  addServiceClass(info: any): Promise<any> {
    return this.http.post(`Setting/AddServiceClass`, JSON.stringify(info)).toPromise();
  }
}
