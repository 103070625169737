import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class TenantMaterialConfigService {

  controller = 'TenantMaterialTypeConfiguration';

  constructor(private http: AuthHttp) { }

  add(tenantId: number, materialTypeId: number): Promise<any> {
    return this.http.post(`${this.controller}/Add/${tenantId}/${materialTypeId}`).toPromise();
  }

  remove(tenantId: number, materialTypeId: number, replaceId: number): Promise<any> {
    return this.http.delete(`${this.controller}/Remove/${tenantId}/${materialTypeId}/${replaceId}`).toPromise();
  }

  checkRemove(tenantId: number, materialTypeId: number): Promise<any> {
    return this.http.get(`${this.controller}/CheckRemove/${tenantId}/${materialTypeId}`).toPromise();
  }

  getUnassignMaterialTypes(tenantId: number): Promise<any> {
    return this.http.get(`${this.controller}/GetUnassignMaterialTypes/${tenantId}`).toPromise();
  }
}
