import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface UpdateIsDefault {
  channelId: number;
  costSchemaAssignmentId: number;
  isDefault: boolean;
}

export interface AssignCostSchema {
  tenantId: number;
  channelId: number;
  assignedOn: Date;
  description: string;
  id: number;
  isDefault: boolean;
  schemaId: number;
  schemaName: string;
  schemaNameAlias: string;
  updatedBy: string;
  updatedOn: Date;
  businessUnitId: number;
  businessUnit: string;
}

export interface ReassignChannel {
  // costSchemaAssignmentId: number;
  tenantChannelId: number;
  channelId: number;
}

@Injectable({ providedIn: 'root' })
export class TenantChannelsService {

  public static MessageTypes = {
    SEND_TO_MODAL: 'SEND_TO_MODAL',
    RELOAD_PARENT_COMP: 'RELOAD_PARENT_COMPONENT',
    SEND_TO_MODAL_REASSIGN_CHANNEL: 'SEND_TO_MODAL_REASSIGN_CHANNEL',
    SEND_TO_MODAL_ADD_COST_SCHEMA: 'SEND_TO_MODAL_ADD_COST_SCHEMA',
  };

  arrUpdateIsDefault: UpdateIsDefault[] = [];
  arrDelete: number[] = [];
  arrAdd: AssignCostSchema[] = [];
  arrDeleteChannel: number[] = [];
  arrReassignChannel: ReassignChannel[] = [];

  // sequences: { action: string, payload: UpdateIsDefault | number | AssignCostSchema | ReassignChannel }[] = [];

  private subject = new Subject<any>();

  sendMessage(messageType: string, payload: any = null) {
    this.subject.next({ messageType, payload });
  }

  // clearAll() {
  //   this.subject.next();
  // }

  getMessages(): Observable<any> {
    return this.subject.asObservable();
  }

  isDirty(): boolean {
    return this.arrUpdateIsDefault.length > 0
    || this.arrDelete.length > 0
    || this.arrAdd.length > 0
    || this.arrDeleteChannel.length > 0
    || this.arrReassignChannel.length > 0
  }

  clear(): void {
    this.arrUpdateIsDefault = [];
    this.arrDelete = [];
    this.arrAdd = [];
    this.arrDeleteChannel = [];
    this.arrReassignChannel = [];
  }
}
