import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TenantChannelsService } from '../tenant-channels.service';
import { Subscription } from 'rxjs';
import { CurrentRow } from '../current-row.model';
import { Config } from '@app-admin/config';
import { TileTenantChannelsService } from '../../../tile/tenant-channels/tenant-channels/tenant-channels.service';
import { UserService } from '@app-admin/services/user.service';

@Component({
  selector: 'assigned-cost-schemas-modal',
  templateUrl: './assigned-cost-schemas-modal.html'
})

export class AssignedCostSchemasModalComponent implements OnInit, OnDestroy {

  assignCostSchemaForm: FormGroup;
  unassignedCostSchemas: any[];

  // Tile
  tileUnassignedCostSchemas: any[];
  tileSubscription: Subscription;

  currentRow: CurrentRow;
  subscription: Subscription;
  businessUnitIds = [
    {
      businessUnitId: 1,
      businessUnit: 'Slab',
    },
    {
      businessUnitId: 2,
      businessUnit: 'Tile',
    }
  ];

  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    private tenantChannelsService: TenantChannelsService,
    private tileTenantChannelsService: TileTenantChannelsService,
    private userService: UserService,
  ) {
    this.assignCostSchemaForm = this.fb.group({
      channelId: [null, Validators.required],
      channelName: [{ value: '', disabled: true }, Validators.required],
      tenantId: [null, Validators.required],
      costSchemaIds: [null, Validators.required],
      businessUnitId: [Config.BusinessUnitId.Slab, Validators.required],
    });

    this.subscription = this.tenantChannelsService.getMessages().subscribe(data => {
      if (!data) {
        return;
      }

      switch (data.messageType) {
        case TenantChannelsService.MessageTypes.SEND_TO_MODAL:
          const payload = data.payload;
          this.currentRow = payload.currentRow;
          this.unassignedCostSchemas = payload.unassignedCostSchemas;

          // Form data
          this.assignCostSchemaForm.patchValue({
            channelId: this.currentRow.channelId,
            channelName: this.currentRow.channelNameAlias,
            tenantId: this.currentRow.tenantId,
          });
          break;
      }
    });

    this.tileSubscription = this.tileTenantChannelsService.getMessages().subscribe(data => {
      if (!data) {
        return;
      }

      switch (data.messageType) {
        case TileTenantChannelsService.MessageTypes.SEND_TO_MODAL:
          const payload = data.payload;
          this.currentRow = payload.currentRow;
          this.tileUnassignedCostSchemas = payload.unassignedCostSchemas;

          // Form data
          this.assignCostSchemaForm.patchValue({
            channelId: this.currentRow.channelId,
            channelName: this.currentRow.channelNameAlias,
            tenantId: this.currentRow.tenantId,
          });
          break;
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    this.tileSubscription.unsubscribe();
  }

  save() {
    const userName = this.userService.getCurrentFullName();
    const costSchemaIds: number[] = this.assignCostSchemaForm.get('costSchemaIds').value;
    for (let costSchemaId of costSchemaIds) {
      let cs = null;
      if (this.businessUnitId.value == Config.BusinessUnitId.Slab) {
        cs = this.unassignedCostSchemas.find(x => x.schemaId == costSchemaId);
        if (cs) {
          this.tenantChannelsService.arrAdd.push({
            tenantId: this.currentRow.tenantId,
            channelId: this.currentRow.channelId,
            assignedOn: new Date(),
            description: '',
            id: 0,
            isDefault: false,
            schemaId: costSchemaId,
            schemaName: cs.schemaName,
            schemaNameAlias: cs.schemaNameAlias,
            updatedBy: userName,
            updatedOn: new Date(),
            businessUnit: 'Slab',
            businessUnitId: Config.BusinessUnitId.Slab,
          });
        }
      } else {
        cs = this.tileUnassignedCostSchemas.find(x => x.schemaId == costSchemaId);
        if (cs) {
          this.tileTenantChannelsService.arrAdd.push({
            tenantId: this.currentRow.tenantId,
            channelId: this.currentRow.channelId,
            assignedOn: new Date(),
            description: '',
            id: 0,
            isDefault: false,
            schemaId: costSchemaId,
            schemaName: cs.schemaName,
            schemaNameAlias: cs.schemaNameAlias,
            updatedBy: userName,
            updatedOn: new Date(),
            businessUnit: 'Tile',
            businessUnitId: Config.BusinessUnitId.Tile,
          });
        }
      }
    }

    // this.costSchemaAssignmentService.assignCostSchemasToChannel(this.assignCostSchemaForm.value).then(response => {
    //   if (response.status) {
    //     // Reload parent component
    //     this.tenantChannelsService.sendMessage(
    //       TenantChannelsService.MessageTypes.RELOAD_PARENT_COMP
    //     );
    //   } else {
    //     this.toast.error(response.message);
    //   }
    // });
  }

  get businessUnitId() {
    return this.assignCostSchemaForm.get('businessUnitId') as FormArray;
  }
}
