import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CommonPricingComponent } from '../common-pricing';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from '../../../services/setting.service';
import { UserService } from '../../../services/user.service';
import { ChangeLogSlabPricingComponent } from './change-log/change-log';
import { Config } from '@app-admin/config';
declare var $: any;

@Component({
  templateUrl: './slab-pricing.html'
})

export class SlabPricingComponent extends CommonPricingComponent implements OnInit {
  @ViewChild('tabChangeLog') tabChangeLog: ChangeLogSlabPricingComponent;

  schemas: any;
  schemaPriceId = 0;

  constructor(fb: FormBuilder, toast: ToastrService, settingService: SettingService,
    userService: UserService, cdr: ChangeDetectorRef) {
    super(fb, toast, settingService, userService, cdr);
  }

  ngOnInit() {
    this.tabs = [
      { id: 'ChangeLogSlabPricing', text: 'Change Log' }
    ];
    this.curTab = this.tabs[0];

    setTimeout(() => {
      if (this.tenantId > 0) this.loadSchemas();
    });
    super.ngOnInit();
  }

  loadSchemas() {
    this.schemas = null;
    this.schemaPriceId = 0;
    if (this.tenantId <= 0) return;
    this.settingService.getPriceSchemas(this.tenantId, Config.BusinessUnitId.Slab).then(response => {
      if (response.status) this.schemas = response.data;
      else this.toast.error('Can\'t get list pricing schemas');
    });
  }

  changeTenant() {
    super.changeTenant();
    this.schemas = null;
    this.schemaPriceId = 0;
    this.loadSchemas();
  }

  changeSchema() {
    $('#' + this.curTab.id).addClass('active');
    switch (this.curTab.id) {
      case "ChangeLogSlabPricing":
        this.tabChangeLog.paging.schemaPrice = this.schemaPriceId == 0 ? null : this.schemaPriceId;
        this.tabChangeLog.paging.pageNumber = 1;
        this.tabChangeLog.loadLog();
        break;
    }
  }

  calcHeightTable(id: string) {
    if (this.curTab.id == "ChangeLogSlabPricing") {
      setTimeout(() => {
        try {
          let maxHeightOfTable = $(window).height() - $('#' + id + ' .table-responsive tbody').offset().top - $('footer').outerHeight() - 31 - 64;
          $('#' + id + ' .table-responsive tbody').css('max-height', maxHeightOfTable);
          let widthOfScrollBar = $('#' + id + ' .table-responsive tbody').width() - $('#' + id + ' .table-responsive tbody tr').width();
          $('#' + id + ' .table-responsive thead').width('calc(100% - ' + widthOfScrollBar + 'px)');
        }
        catch{ }
      });
    }
    else {
      super.calcHeightTable(id)
    }
  }
}
