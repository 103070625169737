<div class="content-wrapper">
  <section class="content-header">
    <h1>Workers</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/home"><i class="fa fa-dashboard"></i> Home</a></li>
      <li class="active">Workers</li>
    </ol>
  </section>

  <section class="content">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <i class="fa fa-refresh fa-spin fa-lg" *ngIf="!arrTenant"></i>
        <div class="form-group" *ngIf="arrTenant">
          <label class="control-label">Tenant</label>
          <select [(ngModel)]="tenantId" (change)="changeTenant()" class="form-control">
            <option [ngValue]="0">All Tenants</option>
            <option *ngFor="let t of arrTenant" [ngValue]="t.tenantId">{{t.tenantName}}</option>
          </select>
        </div>
      </div>
      <div class="col-lg-9 col-md-8 col-sm-6 col-xs-12">
        <div class="form-group">
          <label class="control-label">&nbsp;</label>
          <div class="checkbox" style="text-align:right">
            <label>
              <input type="checkbox" [(ngModel)]="showInactive" (change)="onShowInactive()"/> Show Inactive
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-md-6 col-12">
        <div class="form-group">
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="filter.textSearch"
              placeholder="Search for workers by Email Address, First Name, Last Name, Phone, Job Title" (keyup.enter)="search()">
            <span class="input-group-btn">
              <button class="btn btn-primary" type="button" (click)="search()" title="Search">Search</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <h4 style="line-height: 1.5" *ngIf="!workers">{{ waiting_loading }}</h4>
    <div class="table-responsive" *ngIf="workers">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th [style.width.px]="100" class="text-center">Resource ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>User Name</th>
            <th [style.width.px]="250">Email</th>
            <th [style.width.px]="120">Phone</th>
            <th [style.width.px]="120">Worker Type</th>
            <th>Job Title</th>
            <th [style.width.px]="160">Original Start Date</th>
            <th>Related User</th>
            <th>Tenant</th>
            <th [style.width.px]="70" class="text-center">Active</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of workers; let index = index">
            <ng-container *ngIf="item.tenantId != ''">
              <td [style.width.px]="100" class="text-center"><a routerLink="/worker-detail/{{item.peopleId}}">{{ item.resourceId }}</a></td>
              <td><a routerLink="/worker-detail/{{item.peopleId}}">{{ item.firstName }}</a></td>
              <td><a routerLink="/worker-detail/{{item.peopleId}}">{{ item.lastName }}</a></td>
              <td><a routerLink="/worker-detail/{{item.peopleId}}">{{ item.userName }}</a></td>
              <td [style.width.px]="250">{{ item.emailAddress }}</td>
              <td [style.width.px]="120">
                <ng-container *ngIf="item.mobileNumber">({{item.mobileNumber | slice:0:3 }})
                  {{item.mobileNumber | slice:3:6}}-{{item.mobileNumber | slice:6:10}}</ng-container>
                </td>
              <td [style.width.px]="120">{{ item.workerType }}</td>
              <td>{{ item.jobTitle }}</td>
              <td [style.width.px]="160">{{item.originalStartDate | date:'MM/dd/yyyy h:mm a'}}</td>
              <td>{{ item.relatedUser }}</td>
              <td>{{ item.tenantName }}</td>
              <td [style.width.px]="70" class="text-center">{{item.active?'Active':'Inactive'}}</td>
            </ng-container>
          </tr>
        </tbody>
      </table>
      <my-pagination (goPage)="goToPage($event)" (goNext)="onNext()" (goPrev)="onPrev()" [pagesToShow]="10"
        [page]="filter.pageNumber" [perPage]="filter.itemPerPage" [count]="paginationCount"></my-pagination>
    </div>
  </section>
</div>
