import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { SettingService } from '../../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { Config } from '@app-admin/config';
import { TenantExecutionProcessConfigService } from '@app-admin/services/tenant-execution-process-config.service';

@Component({
    selector: 'tab-tile-base-processes',
    templateUrl: './base-processes.html'
})

export class TileBaseProcessesComponent implements OnInit {
    @Input() tenantId: any;
    @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();

    loading = false;
    data: any;
    dataFilter: any;
    dataForm: FormGroup;
    executionProcesses = [];
    allExecutionProcesses = [];

    constructor(private fb: FormBuilder, private toast: ToastrService, private settingService: SettingService
      , private tenantExecutionProcessConfigService: TenantExecutionProcessConfigService) {

    }

    ngOnInit() {
        this.tenantExecutionProcessConfigService.getAssignData(this.tenantId, Config.BusinessUnitId.Tile).then(res => {
          if(res.status) {
            this.allExecutionProcesses = res.data.filter(x => !x.isDefault);
            this.reloadScheduleProcess();
          }
        });

        this.loadData();
    }

    private reloadScheduleProcess(): void {
      this.executionProcesses = this.allExecutionProcesses.filter(x => x.processTypeId == Config.WorkOrderTypeId.Install);
    }

    loadData() {
        this.loading = true;
        this.settingService.getBaseProcesses(this.tenantId, Config.BusinessUnitId.Tile).then(response => {
            if (response.status) {
                this.data = response.data;
                this.filterData();
                this.loading = false;
                this.calcHeightTable.emit();
            }
            else this.toast.error('Can\'t get list base process');
        });
    }

    filterData() {
        this.dataFilter = this.data;
        let dataUpdate = [];
        this.dataFilter.forEach(e => {
            dataUpdate.push(this.createItemView(e));
        });
        this.dataForm = this.fb.group({
            dataUpdate: this.fb.array(dataUpdate)
        });

        // this.reloadScheduleProcess();
    }

    createItemView(e): FormGroup {
        return this.fb.group({
            processId: e.processId,
            processName: e.processName,
            unit: e.unit,
            stdHourlyRate: new DecimalPipe('en-US').transform(e.stdHourlyRate, '.2-2'),
            description: e.description,
            updatedOn: e.updatedOn,
            updatedBy: e.updatedBy,
            tenantId: this.tenantId,
            executionProcessId: e.executionProcessId,
        });
    }

    createItemUpdate(e) {
        let item = this.data.find(i => i.processId == e.processId);
        item.stdHourlyRate = this.settingService.replaceMask(e.stdHourlyRate);
        item.description = e.description;
        return {
            processId: e.processId,
            stdHourlyRate: this.settingService.replaceMask(e.stdHourlyRate),
            description: e.description,
            executionProcessId: e.executionProcessId,
            tenantId: this.tenantId,
        };
    }

    save() {
        let dataUpdate = [];
        (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
            if (e.dirty) {
                dataUpdate.push(this.createItemUpdate(e.value));
                e.markAsPristine();
                e.markAsUntouched();
            }
        });
        if (dataUpdate.length > 0) {
            this.settingService.updateBaseProcesses(dataUpdate).then(response => {
                if (response.status) {
                    this.toast.success('Base process has been updated');
                    this.loadData();
                }
                else this.toast.error('Update base process failed');
            });
        }
    }
}
