import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class TenantTileSizeConfigService {

  controller = 'TenantTileSizeConfiguration';

  constructor(private http: AuthHttp) { }

  add(tenantId: number, tileSizeId: number): Promise<any> {
    return this.http.post(`${this.controller}/Add/${tenantId}/${tileSizeId}`).toPromise();
  }

  remove(tenantId: number, tileSizeId: number, replaceId: number): Promise<any> {
    return this.http.delete(`${this.controller}/Remove/${tenantId}/${tileSizeId}/${replaceId}`).toPromise();
  }

  checkRemove(tenantId: number, tileSizeId: number): Promise<any> {
    return this.http.get(`${this.controller}/CheckRemove/${tenantId}/${tileSizeId}`).toPromise();
  }

  getUnassignTileSizes(tenantId: number): Promise<any> {
    return this.http.get(`${this.controller}/GetUnassignTileSizes/${tenantId}`).toPromise();
  }
}
