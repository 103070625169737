import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';

@Injectable()
export class TenantTileTypeConfigService {

  controller = 'TenantTileTypeConfiguration';

  constructor(private http: AuthHttp) { }

  add(tenantId: number, tileTypeId: number): Promise<any> {
    return this.http.post(`${this.controller}/Add/${tenantId}/${tileTypeId}`).toPromise();
  }

  remove(tenantId: number, tileTypeId: number, replaceId: number): Promise<any> {
    return this.http.delete(`${this.controller}/Remove/${tenantId}/${tileTypeId}/${replaceId}`).toPromise();
  }

  checkRemove(tenantId: number, tileTypeId: number): Promise<any> {
    return this.http.get(`${this.controller}/CheckRemove/${tenantId}/${tileTypeId}`).toPromise();
  }

  getUnassignTileTypes(tenantId: number): Promise<any> {
    return this.http.get(`${this.controller}/GetUnassignTileTypes/${tenantId}`).toPromise();
  }
}
