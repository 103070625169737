<div class="row">
  <div class="col-xs-12">
    <h4 class="text-bold" style="margin-bottom: 15px">Material Type Configuration</h4>
  </div>
  <!-- <div class="col-md-3 col-sm-4 col-xs-12">
    <div class="form-group">
      <label>Material Type</label>
      <input type="text" class="form-control" trim="blur" [(ngModel)]="materialType">
    </div>
  </div>
  <div class="col-md-3 col-sm-4 col-xs-12">
    <div class="form-group">
      <label>Abbreviation Type</label>
      <input type="text" class="form-control" trim="blur" [(ngModel)]="typeAbbreviation">
    </div>
  </div>
  <div class="col-md-3 col-sm-4 col-xs-12">
    <div class="form-group">
      <label>Base Waste%</label>
      <div class="row">
        <div class="col-sm-8">
          <input type="text" class="form-control text-right" mask="separator.2" suffix="%" thousandSeparator="," separatorLimit="100000" [(ngModel)]="baseWaste">
        </div>
        <div class="col-sm-4">
          <button type="button" class="btn btn-primary" (click)="addMaterialTypes()">Add</button>
        </div>
      </div>
    </div>
  </div> -->

  <div class="col-md-3 col-sm-4 col-xs-12">
    <div class="form-group">
      <label>Material Types</label>
      <div class="row">
        <div class="col-sm-8">
          <select [(ngModel)]="materialTypeId" class="form-control" name="materialTypeId">
            <option [value]="0">Select a material type</option>
            <option *ngFor="let item of unassignData" [ngValue]="item.materialTypeId">{{item.materialGroup}}</option>
          </select>
        </div>
        <div class="col-sm-4">
          <button type="button" [disabled]="loading || materialTypeId == 0" class="btn btn-primary" (click)="add()">
            <span><i class="fa fa-spinner fa-spin fa-fw" *ngIf="loading"></i></span>
            <strong *ngIf="!loading">+ </strong> Add
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-12">
    <br/>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Material Type</th>
              <th>Material Type Alias</th>
              <th [style.width.px]="120">Abbreviation Type</th>
              <!-- <th [style.width.px]="120">Base Waste%</th> -->
              <th [style.width.px]="100">Active</th>
              <th *ngIf="serviceClassMode == 2">Service Class</th>
              <th>Updated On</th>
              <th>Updated By</th>
              <!-- <th [style.width.px]="96"></th> -->
              <th [style.width.px]="100"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>{{item.value.materialGroup}}</td>
                  <td>
                    <div class="form-group" style="margin-bottom: 0">
                      <input type="text" class="form-control" formControlName="alias" trim="blur" required (blur)="save()" (keyup.enter)="save()">
                    </div>
                  </td>
                  <td [style.width.px]="120" style="margin-bottom: 0">
                    <input type="text" class="form-control" formControlName="typeAbbreviationAlias" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <!-- <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" mask="separator.2" suffix="%" thousandSeparator="," separatorLimit="100000" formControlName="baseWastePercent" (blur)="save()" (keyup.enter)="save()">
                  </td> -->
                  <td [style.width.px]="100" class="text-center">
                    <input type="checkbox" formControlName="active" (change)="save()">
                  </td>
                  <td *ngIf="serviceClassMode == 2">
                    <select class="form-control" formControlName="serviceClassId" (change)="save()">
                      <option [value]="0">Unassigned</option>
                      <option *ngFor="let t of serviceClass" [ngValue]="t.serviceClassId">{{t.serviceClassName}}</option>
                    </select>
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td>{{item.value.updatedBy}}</td>
                  <!-- <td [style.width.px]="96">
                    <label [ngStyle]="{color: !item.value.isRemove ? 'red': 'blue'}" >{{!item.value.isRemove? 'Inactive':'Active' }}</label>
                    <button type="button" class="btn btn-primary" [disabled]='!item.value.isRemove' (click)="deleteMaterialTypes(item.value.materialTypeId)">Remove</button>
                  </td> -->
                  <td [style.width.px]="100">
                    <button [disabled]="loading" (click)="remove(item.value.materialTypeId)" type="button" class="btn btn-primary">
                      Remove
                    </button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<!-- <bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you want to remove this material type? Removing the material type will remove all rules and bids associated to this material type.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal> -->

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    <p>Are you sure?  This type is currently being used.  Select another type that will replace the removed type.</p>
    <form novalidate>
      <div class="col-12">
        <div class="form-group">
          <label class="control-label">Material Type</label>
          <select class="form-control" [(ngModel)]="replaceId" name="replaceId" required>
            <ng-container *ngFor="let item of data">
              <option *ngIf="item.materialTypeId != materialTypeId" [ngValue]="item.materialTypeId">{{item.materialGroup}}</option>
            </ng-container>
          </select>
        </div>
      </div>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitRemove(true)" id="remove">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalConfirm2>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    <p>Are you sure? This will remove option for tenant.</p>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm2.close()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitRemove(false)">Submit</button>
  </bs-modal-footer>
</bs-modal>
