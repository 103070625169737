<div class="row">
  <div class="col-xs-12">
    <h4 class="text-bold" style="margin-bottom: 15px">Cost Schemas Configuration</h4>
  </div>
  <div class="col-md-3 col-sm-4 col-xs-12">
    <div class="form-group">
      <label>Cost Schemas</label>
      <div class="row">
        <div class="col-sm-8">
          <select [(ngModel)]="costSchemaId" class="form-control">
            <option [value]="0">Select a cost schema</option>
            <option *ngFor="let item of costSchemas" [ngValue]="item.schemaId">{{item.schemaName}}</option>
          </select>
        </div>
        <div class="col-sm-4">
          <button type="button" [disabled]="loading || costSchemaId == 0" class="btn btn-primary" (click)="addCostSchema()">
            <span><i class="fa fa-spinner fa-spin fa-fw" *ngIf="loading"></i></span>
            <strong *ngIf="!loading">+ </strong> Add
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-12">
    <br/>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Cost Schema Name</th>
              <th>Schema Alias</th>
              <th [style.width.px]="80">Active</th>
              <th>Updated On</th>
              <th>Updated By</th>
              <th [style.width.px]="80"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>{{item.value.schemaName}}</td>
                  <td>
                    <div class="form-group" style="margin-bottom: 0">
                      <input type="text" class="form-control" formControlName="alias" maxlength="200" trim="blur" (blur)="save()" (keyup.enter)="save()">
                    </div>
                  </td>
                  <td [style.width.px]="80" class="text-center"><input type="checkbox" formControlName="active" [(ngModel)] = 'item.value.active' (change) = 'save()'/></td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td>{{item.value.updatedBy}}</td>
                  <td [style.width.px]="100">
                    <button [disabled]="loading" (click)="removeCostSchema(item.value.costSchemaId)" type="button" class="btn btn-primary">
                      Remove
                    </button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure?
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitRemoveCostSchema()" id="removeCostSchema">Submit</button>
  </bs-modal-footer>
</bs-modal>
