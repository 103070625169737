<div class="row">
  <div class="col-xs-12">
    <h4 class="text-bold" style="margin-bottom: 15px">Scheduling Process</h4>
  </div>

  <div class="col-12">
    <form [formGroup]="formAdd" (submit)="add()" novalidate style="margin-left: 10px;">
      <div class="row">

        <div class="col-lg-2 col-sm-3">
          <div class="form-group" [class.has-error]="processName.invalid && (processName.dirty || processName.touched)">
            <label class="control-label">Category Name</label>
            <input type="text" trim="blur" class="form-control" formControlName="processName" maxlength="200" required>
            <span *ngIf="processName.invalid && (processName.dirty || processName.touched)" class="help-block">{{getErrorProcessName()}}&nbsp;</span>
          </div>
        </div>

        <div class="col-lg-2 col-sm-3">
          <label class="control-label">Process Type</label>
          <select class="form-control" formControlName="processTypeId">
            <option *ngFor="let t of processTypes" [ngValue]="t.processTypeId">{{t.processType}}</option>
          </select>
        </div>

        <div class="col-lg-2 col-sm-4" style="margin-top: 25px">
          <button type="submit" class="btn btn-primary btn-custom">Add</button>
        </div>
      </div>
    </form>
  </div>

  <!-- <div class="col-md-3 col-sm-4 col-xs-12">
    <div class="form-group">
      <label>Processes</label>
      <div class="row">
        <div class="col-sm-8">
          <select [(ngModel)]="processId" class="form-control" name="processId">
            <option [value]="0">Select a process</option>
            <option *ngFor="let item of unassignData" [ngValue]="item.processId">{{item.processName}}</option>
          </select>
        </div>
        <div class="col-sm-4">
          <button type="button" [disabled]="loading || processId == 0" class="btn btn-primary" (click)="add()">
            <span><i class="fa fa-spinner fa-spin fa-fw" *ngIf="loading"></i></span>
            <strong *ngIf="!loading">+ </strong> Add
          </button>
        </div>
      </div>
    </div>
  </div> -->

  <div class="col-xs-12">
    <br/>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th [style.width.px]="30"></th>
              <th>Category Name</th>
              <th [style.width.px]="200">Process Type</th>
              <th [style.width.px]="80">Complete</th>
              <th [style.width.px]="200">Updated On</th>
              <th [style.width.px]="200">Updated By</th>
              <th [style.width.px]="100"></th>
            </tr>
          </thead>
          <tbody cdkDropList (cdkDropListDropped)="drop($event)">
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr cdkDrag [class.moveable]="item.value.isConfigurable">
                  <!-- Move icon -->
                  <td [class.icon-moveable]="item.value.isConfigurable" [style.width.px]="30">
                    <i *ngIf="item.value.isConfigurable" class="fa fa-bars" aria-hidden="true"></i>
                  </td>

                  <!-- Category Name -->
                  <td>
                    <div class="form-group" style="margin-bottom: 0" [class.has-error]="item.controls.processName.invalid && item.controls.processName.dirty && item.controls.processName.touched">
                      <input type="text" class="form-control" formControlName="processName" maxlength="200" trim="blur" (input)="checkDuplicate(item.controls)" required (blur)="save()" (keyup.enter)="save()">
                    </div>
                  </td>

                  <!-- Process Type -->
                  <td [style.width.px]="200">
                    <div class="form-group" style="margin-bottom: 0">
                      <select class="form-control" formControlName="processTypeId" (change)="save()">
                        <option *ngFor="let t of processTypes" [ngValue]="t.processTypeId">{{t.processType}}</option>
                      </select>
                    </div>
                  </td>

                  <!-- Complete -->
                  <td [style.width.px]="80" class="text-center">
                    <input type="checkbox" formControlName="isComplete" (change)="save()"/>
                  </td>

                  <!-- Updated On -->
                  <td [style.width.px]="200">{{item.value.updatedAt | date:'MM/dd/yyyy h:mm a'}}</td>

                  <!-- Updated By -->
                  <td [style.width.px]="200">{{item.value.updatedBy}}</td>

                  <!-- Remove button -->
                  <td [style.width.px]="100">
                    <button *ngIf="item.value.isConfigurable" [disabled]="loading" (click)="remove(item.value.id)" type="button" class="btn btn-primary">
                      Remove
                    </button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    <p>Are you sure?  This category is currently being used.  Select another category that will replace the removed category.</p>
    <form novalidate>
      <div class="col-12">
        <div class="form-group">
          <label class="control-label">Category Name</label>
          <select class="form-control" [(ngModel)]="replaceId" name="replaceId" required>
            <!-- <option [value]="0">None</option> -->
            <option *ngFor="let item of dataReplace" [ngValue]="item.id">{{item.processName}}</option>
          </select>
        </div>
      </div>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitRemove(true)" id="remove">Submit</button>
  </bs-modal-footer>
</bs-modal>
