import { Injectable } from '@angular/core';
import { AuthHttp } from '../app.auth-http';
import { Router } from '@angular/router';
import * as _ from 'lodash';

export enum ObjectRoles {
  SuperAdmin = 1,
  TenantAdmin = 2,
  // ProcessAdjustments = 4,
  PricingAdmin = 6,
  TenantSecurityAdmin = 7,
  TenantOnly = 8,
  QXMSecurityAdmin = 9,
}

export enum ObjectPermission {
  Account = 1,
  Project = 2,
  Bid = 3,
  OthersAccount = 4,
  OthersProject = 5,
  OthersBid = 6,
  DimensionAdjustment = 7,
  Quote = 8,
  OthersQuote = 9,
  Comment = 12,
  OthersComment = 13,
  Document = 14,
  OthersDocument = 15,
  AccountPricingSchema = 16,
  Contract = 17,
  OthersContract = 18,
  AccountTransferOwnership = 19,
  BidPriceSchemaAssignment = 22,
  BidMaterial = 23,
  AdminQuoteDeletion = 25,
  ApplicationLineBudgetDetails = 26,
  Dashboard = 27,
  ContractOwnership = 28,
  BidSlabGroup = 29,
  BidItemGroup = 31,
  CommunityMaterialGroupChange = 32,
  BulkContactDeletion = 35,
  BidPriceOverride = 36,
  Opportunities = 37,
  OtherOpportunities = 38,
  ChangeProjectChannel = 39,
  EdgeGroups = 40,
  CommunityPlans = 41,
  CommunityPricing = 42,
  Orders = 43,
  ModifyDirectAccountCustomerAssignment = 44,
  TenantRoleSecurity = 45,
  ExcludeApplicationFromBidLevelCharges = 46,
  PlanBillingCodes = 47,
  AdvancedApplicationPricing = 48,
  Community = 49,
  MaterialGroupPricing = 53,
  ItemGroupPricing = 54,
  EdgeGroupPricing = 55,
  DrawApplication = 56,
  TileGroups = 57,
  GlobalTileCatalog = 58,
  EditMaterialGroupCost = 59,
  EditItemGroupCost = 60,
  EditEdgeGroupCost = 61,
  EditTileGroupCost = 62,
  Estimator = 63,
  ViewOtherEstimators = 64,
  Contacts = 65,
  Budget = 66,
  Job = 67,
  WorkOrders = 68,
  Appointments = 69,
  Planning = 70,
  Scheduling = 71,
  Execution = 72,
  Teams = 73,
  People = 74,
  LocationSchedule = 75,
  AccountAuthorizations = 76,
  SubmittedOrders = 77,
  NonStandardOptionQuotes = 78,
  NestingService = 79,
  ModifyProjectStatus = 80,
  OptimizeSlabCount = 81,
  SlabSalesOrderServiceLocation = 82,
  ModifyClosedProjectStatus = 83,
  ForceCompleteWorkOrders = 84,
  ForceJobDeletion = 85,
  EnableProjectScheduling = 86,
  ExcludeProjectFromScheduling = 87,
  NonExecutionAppointmentCompletion = 88,
  ManualOverrideCompletedAppointments = 89,
  ExportOrders = 90,
  ImportOrderDates = 91,
  ModifyBidOwnership = 92,
  ModifyProjectOwnership = 93,
  DeleteSubmittedEditOrders = 94,
  ManageWorkers = 95,
  ChangeStatusOfCompletedSalesOrders = 96,
  TenantProjectMove = 97,
  MoveProjectToAccount = 98,
  SaleOrderMaterialAdjustments = 99,
  SaleOrderProjectInformation = 100,
  Report = 101,
  MergeAccount = 102,
  Resources = 103,
  Settings = 104,
  CommitWorkOrder = 105,
  PreciseSlabAdjustments = 106,
  CompleteWorkOrders = 107,
  ChangeAppointmentStatus = 108,
  ManageWorkerPin = 109,
  LayoutEditor = 110,
  ExportLayout = 111,
  ChangeCommunityStatus = 112,
  RemoveOrderScheduleDates = 113,
  ExportEditor = 115,
  AssignLicenses = 116,
  OrderScheduling = 117,
  AccountContacts = 118,
  CommunityContacts = 119,
  OrderContacts = 120,
  PlanLock = 121,
  ReOpenCompletedWorkOrder = 122,
  Director_AddSchema = 123,
  Director_ManageSchema = 124,
  Director_DefaultRules = 125,
  User_ManageUser = 126,
  User_ResetPasswords = 127,
  User_AssignLicenses = 128,
  User_AssignRoles = 129,
  User_AssignSupportUsers = 130,
  User_TransferUser = 131,
  Worker_ManageWorker = 132,
  Worker_ResetPasswords = 133,
  Worker_AssignLicenses = 134,
  Device_ManageDevices = 135,
  Role_AddRoles = 136,
  Role_ManageRoles = 137,
  Role_Permissions = 138,
  Role_DataAccess = 139,
  Location_AddLocation = 140,
  Location_ManageLocations = 141,
  Location_ManageRegions = 142,
  Tax_AddTaxRegion = 143,
  Tax_ManageRegions = 144,
  Supplier_AddSupplier = 145,
  Supplier_ManageSuppliers = 146,
  Setting_ModifyDefaults = 147,
  ApplicationWorkOrderCompleteStatus = 148,
  Catalog = 149,
  CatalogItem = 150,
  Stock = 151,
  StockItem = 152,
  Inventory_Settings = 153,
  ApproveTime = 154,
}

@Injectable()
export class UserService {

  public roles: any = [];
  public tenants: any = [];
  public ready = false;

  constructor(private http: AuthHttp, private router: Router) {
  }

  hasRoleSupperAdmin() {
    return _.indexOf(this.roles, ObjectRoles.SuperAdmin) !== -1;
  }

  hasRoleTenantOnly() {
    return _.indexOf(this.roles, ObjectRoles.TenantOnly) !== -1;
  }

  isTenantOnly() {
    return !this.hasRoleSupperAdmin() && this.hasRoleTenantOnly();
  }

  hasRoleQXMSecurityAdmin() {
    return _.indexOf(this.roles, ObjectRoles.QXMSecurityAdmin) !== -1;
  }

  checkAuthorization() {
    if (sessionStorage.getItem('Authorization') === null) {
      return false;
    }
    return true;
  }

  checkfieldProAuthorization() {
    if (sessionStorage.getItem('fieldProAuthorization') === null) {
      return false;
    }
    return true;
  }

  login(info): Promise<any> {
    return this.http.post('loginAdmin', JSON.stringify(info)).toPromise();
  }

  getAllUsers(filter): Promise<any> {
    return this.http.post('Users/GetAllUsersByFilter', JSON.stringify(filter)).toPromise();
  }
  getUsersByRoleFromAdmin(filter): Promise<any> {
    return this.http.post('Users/GetUsersByRoleFromAdmin', JSON.stringify(filter)).toPromise();
  }

  getWokersByRoleFromAdmin(filter): Promise<any> {
    return this.http.post('Users/GetWorkersByRoleFromAdmin', JSON.stringify(filter)).toPromise();
  }

  getUsersByGlobalRole(filter): Promise<any> {
    return this.http.post('Users/GetUsersByGlobalRole', JSON.stringify(filter)).toPromise();
  }

  unassignRole(userId, roleId): Promise<any> {
    return this.http.put(`Users/Unassign/${userId}/${roleId}`).toPromise();
  }

  unassignWorkerRole(workerId, roleId): Promise<any> {
    return this.http.put(`Users/UnassignWrokerRole/${workerId}/${roleId}`).toPromise();
  }

  // getAllTenant(): Promise<any> {
  //   return this.http.get('GetAllTenant').toPromise();
  // }

  activeUser(id, status): Promise<any> {
    return this.http.post(`SetActiveUser/${id}/${status}`).toPromise();
  }

  getUserInfo(userId): Promise<any> {
    return this.http.get('Users/' + userId).toPromise();
  }

  getListActivities(userId, paging): Promise<any> {
    return this.http.post(`GetListActivitiesAdmin/${userId}`, JSON.stringify(paging)).toPromise();
  }

  resetPassword(info): Promise<any> {
    return this.http.post(`AdminResetPassword`, JSON.stringify(info)).toPromise();
  }

  updateUserInfo(userId, userInfo): Promise<any> {
    return this.http.put('Users/' + userId, userInfo).toPromise();
  }

  createUserToTenant(info): Promise<any> {
    return this.http.post('CreateUserToTenant', JSON.stringify(info)).toPromise();
  }

  getCurrentUsername(): string {
    return sessionStorage.getItem('Username');
  }

  getCurrentFullName(): string {
    return sessionStorage.getItem('FullName');
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['login']);
  }
  validateUserLicenseType(data): Promise<any> {
    return this.http.post(`UserModuleLicenseTypes/ValidateUserLicenseType`, JSON.stringify(data)).toPromise();
  }
  applyChangeUserLicenseType(data): Promise<any> {
    return this.http.post(`UserModuleLicenseTypes/ApplyChangeUserLicenseType`, JSON.stringify(data)).toPromise();
  }

  getUserModuleLicenseTypes(userId: number): Promise<any> {
    return this.http.get(`User/GetUserModuleLicenseTypes/${userId}`).toPromise();
  }
  getUserLicenseTypesByModuleId(moduleId: number): Promise<any> {
    return this.http.get(`UserModuleLicenseTypes/GetUserLicenseTypesByModuleId/${moduleId}`).toPromise();
  }
  assignUserLicenseType(userId: number, moduleId: number, licenseTypeId: number, tenantId: number): Promise<any> {
    return this.http.post(`UserModuleLicenseTypes/AssignUserLicenseType/${userId}/${moduleId}/${licenseTypeId}/${tenantId}`).toPromise();
  }
  removeUserLicenseTypeAndRoles(id: number): Promise<any> {
    return this.http.delete(`UserModuleLicenseTypes/RemoveUserLicenseTypeAndRoles/${id}`).toPromise();
  }

  assignTenantsToUser(userId: number, tenantIds: number[]): Promise<any> {
    return this.http.put(`Users/AssignTenantsToUser/${userId}`, JSON.stringify(tenantIds)).toPromise();
  }

  removeTenantFromUser(userId: number, tenantId: number): Promise<any> {
    return this.http.delete(`Users/RemoveTenantFromUser/${userId}/${tenantId}`).toPromise();
  }

  resendInvitation(userId: number): Promise<any> {
    return this.http.post(`Users/ResendInvitationToAllTenants/${userId}`).toPromise();
  }

  updateEmailUser(data): Promise<any> {
    return this.http.post('Users/UpdateEmailUser', JSON.stringify(data)).toPromise();
  }

  deleteUser(userId: number): Promise<any> {
    return this.http.get(`SetDeleteUser/${userId}/${true}`).toPromise();
  }

  addChangeLog(data): Promise<any> {
    return this.http.post('Users/AddChangeLog', JSON.stringify(data)).toPromise();
  }
}
