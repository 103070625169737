import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TileCostSchemaAssignmentService } from '../../../../../services/tile-cost-schema-assignment.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tile-tenant-channel-change-log',
  templateUrl: './change-log.html',
})

export class TileTenantChannelChangeLogComponent implements OnInit {
  @Input() tenantId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();

  loading = false;
  data: any;

  constructor(private toast: ToastrService, private costSchemaAssignmentService: TileCostSchemaAssignmentService) {}

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.costSchemaAssignmentService.getChangeLogs(this.tenantId).then(res => {
      if (res.status) {
        this.data = res.data;
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error('Load data failed');
    });
  }
}
