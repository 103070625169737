<div class="row">
  <div>
    <div class="col-xs-12">
      <h4 class="text-bold" style="margin-bottom: 15px">Trim Type Configuration</h4>
    </div>
    <div class="col-md-3 col-sm-4 col-xs-12">
      <div class="form-group">
        <label>Trim Type</label>
        <input type="text" class="form-control" trim="blur" [(ngModel)]="trimTypeName">
      </div>
    </div>
    <div class="col-md-3 col-sm-4 col-xs-12">
      <div class="form-group">
        <label>Description (Optional)</label>
        <input type="text" class="form-control" trim="blur" [(ngModel)]="description">
      </div>
    </div>
    <div class="col-md-3 col-sm-4 col-xs-12">
      <div class="form-group">
        <label>Unit of Measure</label>
        <select [(ngModel)]="unitId" class="form-control">
          <option *ngFor="let t of unitOfMeasures" [ngValue]="t.unitId">{{t.unit}}</option>
        </select>
      </div>
    </div>
    <div class="col-md-3 col-sm-4 col-xs-12">
      <div class="form-group">
        <label>Default Output / HR</label>
        <div class="row">
          <div class="col-sm-8">
            <input step="0.01" type="number" min="0" class="form-control" trim="blur" [(ngModel)]="outputHr">
          </div>
          <div class="col-sm-4">
            <button type="button" class="btn btn-primary" (click)="add()">Add</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xs-12">
    <br/>
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Trim Type</th>
              <th>Description</th>
              <th>Unit of Measure</th>
              <th>Default Output / HR</th>
              <th [style.width.px]="100">Active</th>
              <th>Updated On</th>
              <th [style.width.px]="96"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>
                    <div class="form-group" style="margin-bottom: 0" [class.has-error]="item.controls.trimTypeName.invalid && item.controls.trimTypeName.dirty && item.controls.trimTypeName.touched">
                      <input type="text" class="form-control" formControlName="trimTypeName" trim="blur" required (blur)="save()" (keyup.enter)="save()">
                    </div>
                  </td>
                  <td>
                    <input type="text" class="form-control" formControlName="description" maxlength="200" trim="blur" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>{{item.value.unit}}</td>
                  <td>
                    <input step="0.01" type="number" min="0" class="form-control text-right" formControlName="outputHr" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td [style.width.px]="100" class="text-center">
                    <input type="checkbox" formControlName="active" (change)="save()">
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td [style.width.px]="96">
                    <button type="button" class="btn btn-primary" [disabled]='!item.value.isRemove' (click)="delete(item.value.trimTypeId)">Remove</button>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<bs-modal #modalConfirm>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure you want to remove this type? Removing the type will remove all rules and items associated to this type.
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirm.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal>
