<div class="row">
  <div class="col-xs-12">
    <h4 class="text-bold" style="margin-bottom: 15px">Application Supply Rates</h4>
  </div>

  <div class="col-xs-12">
    <p class="text-center">
      <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
    </p>

    <div class="table-responsive" *ngIf="!loading">
      <form [formGroup]="dataForm" (submit)="save()">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Application</th>
              <th [style.width.px]="120">Override Rate</th>
              <th [style.width.px]="100">Tenant Rate</th>
              <th>Last Comment</th>
              <th>Last Updated</th>
              <th>Updated By</th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="dataUpdate">
              <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
                <tr>
                  <td>{{item.value.applicationName}}</td>
                  <td [style.width.px]="120">
                    <input type="text" class="form-control text-right" currencyMask
                     formControlName="supplyBaseRate" (blur)="item.get('useDefault').setValue(false); save()" (keyup.enter)="item.get('useDefault').setValue(false); save();" (click)="$event.target.select()">
                  </td>
                  <td [style.width.px]="100" class="text-center">
                    <input type="checkbox" style="position: relative; top: 5px" formControlName="useDefault" (change)="save()">
                  </td>
                  <td>
                    <input #note type="text" class="form-control" formControlName="note" (blur)="save()" (keyup.enter)="save()">
                  </td>
                  <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}</td>
                  <td>{{item.value.updatedBy}}<span><a (click) ="viewComment(item)" class="btn btn-primary pull-right" >View Comments</a></span></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
<app-view-comment #modalViewComments [notes] = 'notes' [tab] = 'currentTab' [parentId] = 'currentId'></app-view-comment>
