import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { SettingService } from '../../../../services/setting.service';
import { UserService } from '../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { SlabCostDetailService } from '@app-admin/services/slab-cost-detail.service';

@Component({
  selector: 'tab-edge-process',
  templateUrl: './edge-process.html',
  styleUrls: ['./edge-process.scss']
})

export class EdgeProcessComponent implements OnInit, OnDestroy {
  @Input() costSchemaId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
  @ViewChild('modalViewComments') modalViewComments: any;
  notes: any;
  beginFilterData = new EventEmitter<any>();
  countToBeginFilterData = 0;

  processes: any;
  processId: any;
  processFilter: any;
  processTypeId: any;
  loading = false;
  data: any;
  dataFilter: any;
  dataForm: FormGroup;
  currentTab = 'EdgeProcess';
  currentId: number;

  processFilterSelected = {
    processFabrication: 0,
    processInstall: 0
  };

  constructor(private fb: FormBuilder, private toast: ToastrService, private settingService: SettingService, private userService: UserService,
    private slabCostDetailService: SlabCostDetailService) {
    this.beginFilterData.subscribe(() => {
      this.countToBeginFilterData = this.countToBeginFilterData + 1;
      if (this.countToBeginFilterData == 2) {
        this.filterData();
        this.loading = false;
        this.calcHeightTable.emit();
      }
    });
  }

  ngOnInit() {
    this.processTypeId = 2;
    this.loadData();
  }
  ngOnDestroy() {
    this.save();
  }
  viewComment(item) {
    this.currentId = item.value.id;
    this.settingService.getMtxNoteEdgeProcessById(this.currentId).then(res => {
      if (res.status) {
        this.notes = res.data;
        // this.viewComments.open();
        this.modalViewComments.open();

        //Update last comment when delete comment
        this.slabCostDetailService.getMessages().subscribe(data => {
          if (!data) {
            return;
          }

          switch (data.messageType) {
            case SlabCostDetailService.MessageTypes.RELOAD_PARENT_COMMENT + "_" + this.currentTab + "_" + this.currentId:
              const payload = data.payload;
              if (payload) {
                item.controls.note.setValue(payload.note);
                item.controls.updatedOn.setValue(payload.updateOn);
              }
              break;
          }
        });
      }
      else this.toast.error('view comment failed.');
    });
  }
  loadData(resetProcessId = true) {
    this.loading = true;
    this.countToBeginFilterData = 0;

    this.settingService.getProcess(this.costSchemaId).then(response => {
      if (response.status) {
        this.processes = response.data;
        this.filterProcess(resetProcessId);
        this.beginFilterData.emit();
      }
      else this.toast.error('Can\'t get list process');
    });

    this.settingService.getEdgeProcess(this.costSchemaId).then(response => {
      if (response.status) {
        this.data = response.data;
        this.beginFilterData.emit();
      }
      else this.toast.error("Load data failed");
    });
  }

  saveProcessFilterSelected() {
    if (this.processTypeId == 2)
      this.processFilterSelected.processFabrication = this.processId;
    else if (this.processTypeId == 3)
      this.processFilterSelected.processInstall = this.processId;
  }

  filterProcess(resetProcessId = true) {
    this.processFilter = this.processes.filter(e => e.processTypeId == this.processTypeId);
    if (resetProcessId) {
      if (this.processTypeId == 2 && this.processFilterSelected.processFabrication > 0)
        this.processId = this.processFilterSelected.processFabrication;
      else if (this.processTypeId == 3 && this.processFilterSelected.processInstall > 0)
        this.processId = this.processFilterSelected.processInstall;
      else this.processId = this.processFilter[0].processId;
    }
  }

  filterData() {
    this.dataFilter = this.data.filter(e => e.processId == this.processId);
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
    if (this.userService.isTenantOnly()) this.dataForm.disable();
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      id: e.id,
      edgeType: e.edgeType,
      // percentAdjustment: new PercentPipe('en-US').transform(e.percentAdjustment, '.2-2'),
      percentAdjustment: e.percentAdjustment * 100,
      note: e.note,
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.id == e.id);
    item.percentAdjustment = this.settingService.replaceMask(e.percentAdjustment) / 100;
    item.note = e.note;
    return {
      id: e.id,
      percentAdjustment: this.settingService.replaceMask(e.percentAdjustment) / 100,
      note: e.note,
    };
  }

  save(doClearWeight = false) {
    let dataUpdate = [];
    let cellUpdate: any;
    (this.dataForm?.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty || doClearWeight) {
        if(doClearWeight){
          let updateVal = e.value;
          updateVal.percentAdjustment = 0;
          e.setValue(updateVal)
        }
        cellUpdate = e;
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (dataUpdate.length > 0) {
      this.settingService.updateEdgeProcess(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Edge process has been updated');
          cellUpdate?.get('updatedOn').setValue(response.data);
          cellUpdate?.get('updatedBy').setValue(this.userService.getCurrentFullName());
          if (dataUpdate.length > 0) {
            // this.settingService.setBidsIsRefesh(this.tenantId);
            this.reloadStatusBatchVersions.emit();
          }
        }
        else this.toast.error('Update edge process failed');
      });
    }

  }

  clearWeights() {
    this.save(true)
  }
}
