import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SettingService } from '../../../../services/setting.service';

@Component({
  selector: 'tab-change-log-slab-pricing',
  templateUrl: './change-log.html'
})

export class ChangeLogSlabPricingComponent implements OnInit {
  @Input() tenantId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  dataLog: any;
  loading = true;
  paginationCount: number;
  paging = {
    tenant: 0,
    schemaPrice: null,
    itemPerPage: 20,
    pageNumber: 1,
    serviceId: 1,
  };

  constructor(private toastr: ToastrService, private settingService: SettingService) {

  }

  ngOnInit() {
    this.loadLog();
  }

  loadLog() {
    this.loading = true;
    this.paging.tenant = this.tenantId;
    this.settingService.getDataLogSlabPricing(this.paging).then(response => {
      this.loading = false;
      if (response.status) {
        this.dataLog = response.data.listLog;
        this.paginationCount = response.data.total;
      } else {
        this.toastr.error('Can\'t get list data log');
      }
      this.calcHeightTable.emit();
    });
  }

  goToPage(n: number): void {
    if (this.paging.pageNumber === n) {
      return;
    }
    this.paging.pageNumber = n;
    this.loadLog();
  }

  onNext(): void {
    this.paging.pageNumber = +this.paging.pageNumber;
    this.paging.pageNumber++;
    this.loadLog();
  }

  onPrev(): void {
    this.paging.pageNumber = +this.paging.pageNumber;
    this.paging.pageNumber--;
    this.loadLog();
  }
}
