import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { SettingService } from "@app-admin/services/setting.service";
import { ToastrService } from "ngx-toastr";
import { BsModalComponent } from "ng2-bs3-modal";

@Component({
  selector: "tile-service-class",
  templateUrl: "./tile-service-class.html",
})
export class TileServiceClassComponent implements OnInit {
  @Input() tenantId: any;
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  loading = false;
  data: any;
  dataForm: FormGroup;

  deletingServiceClass = 0;

  formAdd: FormGroup;

  constructor(
    private fb: FormBuilder,
    private toast: ToastrService,
    public settingService: SettingService
  ) {}

  ngOnInit() {
    this.loadData();

    this.formAdd = this.fb.group({
      serviceClassName: ['', Validators.required],
    });
  }

  loadData() {
    this.loading = true;
    this.settingService.getServiceClass(this.tenantId, 2).then((response) => {
      if (response.status) {
        this.loading = false;
        this.data = response.data;
      } else this.toast.error("Load data failed");
    });
  }

  get serviceClassName(): FormControl {
    return this.formAdd.get('serviceClassName') as FormControl;
  }

  getErrorServiceClassName() {
    return this.serviceClassName.hasError('required') ? 'Service Class Name cannot be empty' : '';
  }

  addServiceClass() {
    var dataAdd = {
      serviceClassName : this.formAdd.get("serviceClassName").value,
      serviceTypeId : 2,
      tenantId : this.tenantId
    }
    this.loading = true;
    this.settingService.addServiceClass(dataAdd).then(res => {
      this.loading = false;
      if(res.status) {
        this.toast.success("Service Class added success");
        this.loadData();
      }
      else {
        this.toast.error(res.message);
      }
    })
  }

  submitRemoveServiceClass() {
    this.modalConfirm.close();
    this.loading = true;
    this.settingService.deleteServiceClass(this.deletingServiceClass).then(res => {
      this.loading = false;
      if(res.status) {
        this.toast.success("Service Class removed from Tenant");
        this.loadData();
      }
      else {
        this.toast.error(res.message);
      }
    });
  }

  removeServiceClass(serviceClassId) {
    this.deletingServiceClass = serviceClassId;
    this.modalConfirm.open();
  }
}
