import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { SettingService } from '../../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { TenantTileTypeConfigService } from '@app-admin/services/tenant-tile-type-config.service';

@Component({
  selector: 'tab-tile-types',
  templateUrl: './types.html'
})

export class TileTypesComponent implements OnInit {
  @Input() tenantId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;

  loading = false;
  data: any[] = [];
  unassignData: any[] = [];
  dataFilter: any[] = [];
  dataForm: FormGroup;
  typeId: number = 0;
  replaceId: number = 0;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService
    , private tenantTileTypeConfigService: TenantTileTypeConfigService) {

  }

  ngOnInit() {
    this.loadData();
  }

  private loadData(): void {
    this.loading = true;
    this.settingService.getTileTypes(this.tenantId).then(response => {
      this.loading = false;
      if (!response.status) {
        this.toast.error('Load data failed');
        return;
      }
      this.data = response.data;
      this.filterData();
      this.calcHeightTable.emit();
    });

    this.tenantTileTypeConfigService.getUnassignTileTypes(this.tenantId).then(res => {
      if (!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.unassignData = res.data;
    });
  }

  private filterData(): void {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  private createItemView(e): FormGroup {
    return this.fb.group({
      typeId: e.typeId,
      typeName: [e.typeName, Validators.required],
      active:[e.active],
      isRemove: e.isRemove,
      alias: e.alias,
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
      frequentlyUsed: [e.frequentlyUsed],
    });
  }

  private createItemUpdate(e): any {
    let item = this.data.find(i => i.typeId == e.typeId);
    item.baseWastePercent = this.settingService.replaceMask(e.baseWastePercent) / 100;
    item.typeName = e.typeName;
    item.active = e.active;
    return {
      typeId: e.typeId,
      alias: e.alias,
      typeName: e.typeName,
      active: e.active,
      isGlobal: false,
      tenantId: this.tenantId,
      frequentlyUsed: e.frequentlyUsed,
    };
  }

  save(): void {
    let dataUpdate = [];
    let isValid = true;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
      if (e.value.typeName == null || e.value.typeName.trim() == "" || e.value.typeName.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Type name needs to be greater than 2 characters');
        }
        isValid = false;
      }
    });
    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateTileTypes(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Type has been updated');
          this.loadData();
        }
        else this.toast.error('Update type failed');
      });
    }
  }

  add(): void {
    this.loading = true;
    this.tenantTileTypeConfigService.add(this.tenantId, this.typeId).then(res => {
      this.loading = false;
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.toast.success('Tile Type added.');
      this.loadData();
      this.typeId = 0;
    })
  }

  remove(typeId: number): void {
    if (!typeId) return;
    this.typeId = typeId;
    this.tenantTileTypeConfigService.checkRemove(this.tenantId, this.typeId).then(res => {
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      if (res.data) {
        this.modalConfirm.open();
      } else {
        this.submitRemove(false);
      }
    });
  }

  submitRemove(allowReplace: boolean): void {
    if (allowReplace && this.replaceId <= 0) return;

    this.modalConfirm.close();
    this.loading = true;
    this.tenantTileTypeConfigService.remove(this.tenantId, this.typeId, this.replaceId).then(res => {
      this.loading = false;
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.toast.success('Tile Type removed.');
      this.loadData();
      this.typeId = 0;
      this.replaceId = 0;
    });
  }
}
