<div class="col-xs-12">
  <h5 class="text-center" style="text-decoration: underline; font-weight: bold; text-transform: uppercase;">Base Output Per Hour for Trim</h5>
  <p class="text-center">
    <i class="fa fa-refresh fa-spin fa-3x" *ngIf="loading"></i>
  </p>

  <div class="table-responsive" *ngIf="!loading">
    <form [formGroup]="dataForm" (submit)="save()">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Trim Type</th>
            <!-- <th [style.width.px]="120">Percentage</th> -->
            <th>Unit of Measure</th>
            <th [style.width.px]="120">Output / HR</th>
            <th [style.width.px]="100">Use Global</th>
            <th>Last Comment</th>
            <th>Last Updated</th>
            <th>Updated By</th>
          </tr>
        </thead>
        <tbody>
          <ng-container formArrayName="dataUpdate">
            <ng-container [formGroupName]="i" *ngFor="let item of dataForm.get('dataUpdate')['controls']; let i = index;">
              <tr>
                <td>{{item.value.trimTypeName}}</td>
                <!-- <td [style.width.px]="120">
                  <input type="text" class="form-control text-right" mask="separator.2" suffix="%" thousandSeparator="," separatorLimit="100000" formControlName="percentage" (blur)="save();note.focus()" (keyup.enter)="save();note.focus()">
                </td> -->
                <td>{{item.value.unit}}</td>
                <td [style.width.px]="120">
                  <input type="number" class="form-control text-right" formControlName="outputHr" (blur)="save();" (keyup.enter)="save();" (click)="$event.target.select()">
                </td>
                <td [style.width.px]="100" class="text-center">
                  <input type="checkbox" style="position: relative; top: 5px" formControlName="useGlobal" (change)="save()">
                </td>
                <td>
                  <input #note type="text" class="form-control" formControlName="note" (blur)="save()" (keyup.enter)="save()">
                </td>
                <td>{{item.value.updatedOn | date:'MM/dd/yyyy h:mm a'}}</td>
                <td>{{item.value.updatedBy}}<span><a (click) ="viewComment(item)" class="btn btn-primary pull-right" >View Comments</a></span></td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </form>
  </div>
</div>

<app-view-comment #modalViewComments [notes] = 'notes'></app-view-comment>
