import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { SettingService } from '../../../../services/setting.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalComponent } from 'ng2-bs3-modal';
import { Config } from '@app-admin/config';
import { TenantAppConfigService } from '@app-admin/services/tenant-app-config.service';
import { TenantsService } from '@app-admin/services/tenants.service';
declare var $: any;
@Component({
  selector: 'tab-application',
  templateUrl: './application.html',
  styleUrls: ['./application.scss']
})

export class ApplicationComponent implements OnInit {
  @Input() tenantId: number;
  // @Input() unitsOfMeasure: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @Output('reloadStatusBatchVersions') reloadStatusBatchVersions = new EventEmitter<any>();
  @ViewChild('modalConfirm') modalConfirm: BsModalComponent;
  @ViewChild('modalConfirm2') modalConfirm2: BsModalComponent;
  // application: any;
  // txtDefaultSupplyRate: any;
  loading = false;
  data: any[] = [];
  serviceClass : any[] = [];
  unassignData: any[] = [];
  dataFilter: any[] = [];
  dataForm: FormGroup;
  applicationId: number = 0;
  serviceClassId: number = 0;
  replaceId: number = 0;
  // ddlUnitOfdMeasure: any;
  SLAB = 1;
  serviceClassMode:number=0;

  constructor(private fb: FormBuilder, private toast: ToastrService, public settingService: SettingService, public tenantService:TenantsService
    , private tenantAppConfigService: TenantAppConfigService) {

  }

  ngOnInit() {
    // this.txtDefaultSupplyRate = new PercentPipe('en-US').transform(0, '.2-2');
    // this.ddlUnitOfdMeasure = this.unitsOfMeasure[0].unitId;
    this.loadData();
  }

  private loadData(): void {
    var scrollTop = $('#application .table-responsive tbody')?.scrollTop();
    this.loading = true;
    Promise.all([
      this.settingService.getApplications(this.tenantId, Config.BusinessUnitId.Slab),
      this.settingService.getServiceClassByServiceType(this.tenantId, Config.BusinessUnitId.Slab),
      this.tenantService.getTenantDetails(this.tenantId),
    ]).then(res => {
      this.loading = false;
      if (!res[0].status || !res[1].status) {
        this.toast.error('Load data failed');
        return;
      }
      this.data = res[0].data;
      this.serviceClass = res[1].data;
      this.serviceClassMode = res[2].data.serviceClassMode;
      this.filterData();
      this.calcHeightTable.emit(scrollTop);
    });

    this.tenantAppConfigService.getUnassignApplications(this.tenantId, this.SLAB).then(res => {
      if (!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.unassignData = res.data;
    });
  }

  private filterData(): void {
    this.dataFilter = this.data;
    let dataUpdate = [];
    this.dataFilter.forEach(e => {
      dataUpdate.push(this.createItemView(e));
    });
    this.dataForm = this.fb.group({
      dataUpdate: this.fb.array(dataUpdate)
    });
  }

  private createItemView(e): FormGroup {
    return this.fb.group({
      applicationId: e.applicationId,
      serviceClassId: e.serviceClassId,
      alias: e.alias,
      description: e.description ,
      active: !!e.active,
      applicationName: [e.applicationName, Validators.required],
      defaultSupplyRate: new DecimalPipe('en-US').transform(e.defaultSupplyRate, '.2-2'),
      unit: e.unit,
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
      isFrequent: e.isFrequent
    });
  }

  // validData() {
  //   if (this.tenantId == 0) {
  //     this.toast.error('Please select a tenant');
  //     return false;
  //   }
  //   else if (this.application == null || this.application == '') {
  //     this.toast.error('Application cannot be empty');
  //     return false;
  //   }
  //   return true;
  // }

  // addApplication() {
  //   if (this.validData()) {
  //     this.loading = true;
  //     this.settingService.addApplications({
  //       tenantId: this.tenantId,
  //       applicationName: this.application,
  //       unitOfMeasure: this.ddlUnitOfdMeasure,
  //       defaultSupplyRate: this.settingService.replaceMask(this.txtDefaultSupplyRate)
  //     }).then(response => {
  //       if (response.status) {
  //         this.application = '';
  //         this.ddlUnitOfdMeasure = this.unitsOfMeasure[0].unitId;
  //         this.txtDefaultSupplyRate = new PercentPipe('en-US').transform(0, '.2-2');
  //         this.toast.success('New Application was has been added');
  //         this.reloadStatusBatchVersions.emit();
  //         this.loadData();
  //       }
  //       else {
  //         if (response.data == 0) {
  //           this.toast.error('This application already exists.  System doesn\'t support duplicate applications.  Make appropriate changes and retry.');
  //         }
  //         else {
  //           this.toast.error('The Application is added fail');
  //         }
  //         this.loading = false;
  //       }
  //     });
  //   }
  // }

  // deleteApplication(applicationId) {
  //   this.modalConfirm.open();
  //   this.applicationId = applicationId;
  // }

  // submitDelete() {
  //   this.loading = true;
  //   this.modalConfirm.close();
  //   this.settingService.deleteApplication(this.applicationId).then(response => {
  //     if (response.status) {
  //       this.toast.success('Application has been removed');
  //       this.loading = true;
  //       this.loadData();
  //       // this.settingService.setBidsIsRefesh(this.tenantId);
  //     }
  //     else
  //       this.toast.error(response.message);
  //     this.loading = false;
  //   });
  // }

  private createItemUpdate(e): any {
    let item = this.data.find(i => i.applicationId == e.applicationId);
    item.defaultSupplyRate = this.settingService.replaceMask(e.defaultSupplyRate);
    item.applicationName = e.applicationName;
    return {
      applicationId: e.applicationId,
      serviceClassId: e.serviceClassId,
      active: e.active,
      description: e.description,
      alias: e.alias,
      defaultSupplyRate: this.settingService.replaceMask(e.defaultSupplyRate),
      applicationName: e.applicationName,
      isGlobal: false,
      tenantId: this.tenantId,
      isFrequent: e.isFrequent
    };
  }

  save(isReload: boolean = false): void {
    let dataUpdate = [];
    let isValid = true;
    let duplicate = false;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        dataUpdate.push(this.createItemUpdate(e.value));

        e.markAsPristine();
        e.markAsUntouched();
        duplicate = !!(e.get('applicationName') as FormControl).errors;
      }
      if (e.value.applicationName == null || e.value.applicationName.trim() == "" || e.value.applicationName.trim().length == 0) {
        if (isValid == true) {
          this.toast.error('Application cannot be empty');
        }
        isValid = false;
      }


    });
    if (isValid && duplicate) {
      this.toast.error('This application already exists. System doesn\'t support duplicate applications. Make appropriate changes and retry ');
      isValid = false;
    }

    if (isValid && dataUpdate.length > 0) {
      this.settingService.updateApplications(dataUpdate).then(response => {
        if (!response.status) {
          if (response.code == 199) {
            this.toast.warning(response.warningMessage);
            this.loadData();
            return;
          }
          this.toast.error(response.message);
          return;
        }
        this.toast.success('Application has been updated');

        if (isReload) {
          this.loadData();
        } else {
          // TODO: Update on UI
          // updatedOn
          // updatedBy
        }

        if (dataUpdate.length > 0) {
          // this.settingService.setBidsIsRefesh(this.tenantId);
          this.reloadStatusBatchVersions.emit();
        }
      });
    }
  }

  // checkDuplicate(e) {
  //   (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(m => {
  //     if (e.applicationId.value != m.value.applicationId && e.applicationName.value.trim().toUpperCase() == m.value.applicationName.trim().toUpperCase()) {
  //       e.applicationName.setErrors({ 'hasDup': true });

  //     }

  //     // if (e.value.edgeType == null || e.value.edgeType.trim() == "" || e.value.edgeType.trim().length == 0) {
  //     //   if (isValid == true) {
  //     //     this.toast.error('Edge cannot empty');
  //     //   }
  //     //   isValid = false;
  //     // }
  //   });
  // }

  add(): void {
    this.loading = true;
    this.tenantAppConfigService.add(this.tenantId, this.applicationId, this.serviceClassId).then(res => {
      this.loading = false;
      if (!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.toast.success('Application added.');
      this.loadData();
      this.applicationId = 0;
    })
  }

  remove(applicationId: number): void {
    if (!applicationId) return;
    this.applicationId = applicationId;
    this.tenantAppConfigService.checkRemove(this.tenantId, this.applicationId).then(res => {
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      if (res.data) {
        this.modalConfirm.open();
      } else {
        this.modalConfirm2.open();
        // this.submitRemove(false);
      }
    });
  }

  submitRemove(allowReplace: boolean): void {
    if (allowReplace && this.replaceId <= 0) return;

    this.modalConfirm.close();
    this.modalConfirm2.close();
    this.loading = true;
    this.tenantAppConfigService.remove(this.tenantId, this.applicationId, this.replaceId).then(res => {
      this.loading = false;
      if(!res.status) {
        this.toast.error(res.message);
        return;
      }
      this.toast.success('Application removed.');
      this.loadData();
      this.applicationId = 0;
      this.replaceId = 0;
    });
  }
}
