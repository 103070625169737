<div class="content-wrapper">
  <section class="content-header">
    <h1>Global</h1>
    <ol class="breadcrumb">
      <li><a routerLink="/dashboards/global"><i class="fa fa-dashboard"></i> Dashboard</a></li>
      <li class="active">Global</li>
    </ol>
  </section>

  <section class="content">
    <div *ngIf="showLoading">
      <p class="text-center">
        <i class="fa fa-refresh fa-spin fa-3x" *ngIf="showLoading"></i>
      </p>
    </div>
    <div *ngIf="!showLoading">
      <div class="form-group">
        <button type="button" class="btn btn-primary" (click)="add()">Add New</button>
      </div>
      <div class="table-responsive" *ngIf="dashboards">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th [style.width.px]="40" class="text-center">#</th>
              <th>Dashboard Name
                <!-- <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'dashboardName' && filter.order == 'desc'"></i>
                <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'dashboardName' && filter.order == 'asc'"></i> -->
              </th>
              <th>Dashboard Description</th>
              <th>Connection Name</th>
              <th>Menu Name</th>
              <th>Dashboard Category
                <!-- <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'dashboardCategoryName' && filter.order == 'desc'"></i>
                <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'dashboardCategoryName' && filter.order == 'asc'"></i> -->
              </th>
              <!-- <th [style.width.px]="110" class="text-center">Standard</th> -->
              <!-- <th>Tenant Source Folder</th> -->
              <th>Source Dashboard
                <!-- <i class="fa fa-angle-down" *ngIf="filter.orderBy == 'sourceDashboard' && filter.order == 'desc'"></i>
                <i class="fa fa-angle-up" *ngIf="filter.orderBy == 'sourceDashboard' && filter.order == 'asc'"></i> -->
              </th>

              <!-- <th [style.width.px]="110" class="text-center">Assign Roles</th>
              <th [style.width.px]="80" class="text-center">Active</th> -->
              <th [style.width.px]="80" class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of dashboards; let index = index">
              <td [style.width.px]="40" class="text-center">{{ index + 1 }}</td>
              <!-- <td [style.width.px]="60" class="text-center">
                <input [style.width.px]="40" type="number" oninput="validity.valid||(value='');" min="1" [max]="dashboards.length" [value]="item.orderIndex" (change)="updateOrderIndex($event, item)"/>
              </td> -->
              <td>{{ item.dashboardName }}</td>
              <td>{{ item.dashboardDescription }}</td>
              <td>{{ item.biConnectionName }}</td>
              <td>{{ item.menuName }}</td>
              <td>{{ item.dashboardCategoryName }}</td>
              <!-- <td [style.width.px]="80" class="text-center">
                <i class="fa fa-lg" style="cursor: pointer" [ngClass]="item.isStandard != 1 ? 'fa-toggle-off text-danger' : 'fa-toggle-on text-success'" (click)="setDashboardStandard(item, !item.isStandard)"></i>
              </td> -->
              <!-- <td>{{ item.tenantSourceFolder }}</td> -->
              <td>{{ item.sourceDashboard }}</td>
              <!-- <td [style.width.px]="110" class="text-center">
                <a href="javascript:;" (click)="assignRoles(item.dashboardId)" title="Assign Roles" class="fa fa-lg fa-pencil-square-o text-success"></a>
              </td>
              <td [style.width.px]="80" class="text-center">
                <i class="fa fa-lg" style="cursor: pointer" [ngClass]="item.active == 0 ? 'fa-toggle-off text-danger' : 'fa-toggle-on text-success'" (click)="activeDashboard(item, !item.active)"></i>
              </td> -->
              <td [style.width.px]="80" class="text-center">
                <a href="javascript:;" class="fa fa-lg fa-pencil-square-o text-success" title="Edit" (click)="update(item)"></a>&nbsp;&nbsp;&nbsp;
                <a href="javascript:;" class="fa fa-lg fa-trash-o text-danger" title="Delete" (click)="delete(item.dashboardId)"></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</div>

<!-- Modal -->

<bs-modal #modalAdd>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Add Dashboard</h4>
  </bs-modal-header>
  <bs-modal-body>
    <form novalidate [formGroup]="addForm">
      <div class="nav-tabs-custom">
        <div class="tab-content">
          <div class="tab-pane box active" id="info">
            <div class="form-group" [class.has-error]="dashboardName.invalid && (dashboardName.dirty || dashboardName.touched)">
              <label>Dashboard Name
                <span class="text-danger">*</span>
              </label>
              <input type="text" trim="blur" class="form-control" formControlName="dashboardName" maxlength="250" required>
              <ng-container *ngIf="dashboardName.invalid && (dashboardName.dirty || dashboardName.touched)">
                <span *ngIf="dashboardName.errors.required" class="help-block">Dashboard name is required.</span>
              </ng-container>
            </div>
            <div class="form-group">
              <label>Description</label>
              <textarea type="text" trim="blur" class="form-control" style="resize: none" formControlName="dashboardDescription"></textarea>
            </div>
            <div class="form-group" [class.has-error]="dashboardCategoryId.invalid && (dashboardCategoryId.dirty || dashboardCategoryId.touched)">
              <label>Dashboard Category
                <span class="text-danger">*</span>
              </label>
              <select formControlName="dashboardCategoryId" class="form-control" required>
                <ng-container *ngFor="let rc of dashboardCategories">
                  <option [ngValue]="rc.id">{{rc.name}}</option>
                </ng-container>
              </select>
              <span *ngIf="dashboardCategoryId.invalid && (dashboardCategoryId.dirty || dashboardCategoryId.touched)" class="help-block">{{getErrorDashboardCategoryId()}}</span>
            </div>

            <div class="form-group" [class.has-error]="biConnectionId.invalid && (biConnectionId.dirty || dashboardCategoryId.touched)">
              <label>BI Connection
                <span class="text-danger">*</span>
              </label>
              <select formControlName="biConnectionId" class="form-control" required>
                <ng-container *ngFor="let rc of biConnections">
                  <option [ngValue]="rc.id">{{rc.connectionName}}</option>
                </ng-container>
              </select>
              <span *ngIf="biConnectionId.invalid && (biConnectionId.dirty || biConnectionId.touched)" class="help-block">{{getErrorBiConnectionId()}}</span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalAdd.close()">Cancel</button>
    <button type="button" [disabled]="addForm.invalid" class="btn btn-primary" (click)="submitAdd()">Submit</button>
  </bs-modal-footer>
</bs-modal>

<bs-modal #modalConfirmDelete>
  <bs-modal-header [showDismiss]="true">
    <h4 class="modal-title">Confirm</h4>
  </bs-modal-header>
  <bs-modal-body>
    Are you sure delete this dashboard?
  </bs-modal-body>
  <bs-modal-footer [showDefaultButtons]="false">
    <button type="button" class="btn btn-default" (click)="modalConfirmDelete.close()" id="cancelDelete">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="submitDelete()" id="submitDelete">Submit</button>
  </bs-modal-footer>
</bs-modal>
