import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { SettingService } from '../../../../../../services/setting.service';
import { UserService } from '../../../../../../services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tab-tile-trim-complexities',
  templateUrl: './trim-complexities.html',
})

export class TileTrimComplexitiesComponent implements OnInit, OnDestroy {

  @Input() costSchemaId: any;
  @Output('calcHeightTable') calcHeightTable = new EventEmitter<any>();
  @ViewChild('modalViewComments') modalViewComments: any;

  notes:any;
  loading = false;
  data: any;
  dataForm: FormGroup;

  constructor(private fb: FormBuilder, private toast: ToastrService, private settingService: SettingService, private userService: UserService) {
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy(){
    this.save();
  }

  viewComment(item:any){
    const id = item.value.id;
    this.settingService.getMtxNoteTileTrimComplexityWeightById(id).then(res=> {
      if(res.status){
        this.notes = res.data;
        this.modalViewComments.open();
      }
      else this.toast.error('view comment failed.');
    });
  }
  loadData() {
    this.loading = true;
    this.settingService.getTileTrimComplexityWeight(this.costSchemaId).then(response => {
      if (response.status) {
        this.data = response.data;
        const dataView = [];
        this.data.forEach(e => {
          dataView.push(this.createItemView(e));
        });
        this.dataForm = this.fb.group({
          dataUpdate: this.fb.array(dataView)
        });
        if (this.userService.isTenantOnly()) this.dataForm.disable();
        this.loading = false;
        this.calcHeightTable.emit();
      }
      else this.toast.error("Load data failed");
    });
  }

  createItemView(e): FormGroup {
    return this.fb.group({
      id: e.id,
      complexityName: e.complexityName,
      // percentage: new PercentPipe('en-US').transform(e.percentage, '.2-2'),
      percentage: e.percentage * 100,
      note: e.note,
      updatedOn: e.updatedOn,
      updatedBy: e.updatedBy,
    });
  }

  createItemUpdate(e) {
    let item = this.data.find(i => i.id == e.id);
    item.percentage = this.settingService.replaceMask(e.percentage) / 100;
    item.note = e.note;
    return {
      id: e.id,
      percentage: this.settingService.replaceMask(e.percentage) / 100,
      note: e.note,
    };
  }

  save() {
    let dataUpdate = [];
    let cellUpdate: any;
    (this.dataForm.get('dataUpdate') as FormArray).controls.forEach(e => {
      if (e.dirty) {
        cellUpdate = e;
        dataUpdate.push(this.createItemUpdate(e.value));
        e.markAsPristine();
        e.markAsUntouched();
      }
    });
    if (dataUpdate.length > 0) {
      this.settingService.updateTileTrimComplexityWeight(dataUpdate).then(response => {
        if (response.status) {
          this.toast.success('Base waste has been updated');
          cellUpdate?.get('updatedOn').setValue(response.data);
          cellUpdate?.get('updatedBy').setValue(this.userService.getCurrentFullName());
        }
        else this.toast.error('Update Base waste failed');
      });
    }
  }
}
